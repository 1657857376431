////11-02-2022 => Texting-732 Added docuSignAccountId in conversation object.
////12-13-2022 => MOHIT => Texting-900 Added preAssignedDate in conversation object.  
////01-23-2022 => AV => Texting-1011 utility function to map logical name to display on UI.  
////05-02-2023 => ONKAR => added showNotification in conversation object.  



export function mapConversation(conv, msgSupportNumber) {
    debugger;
    let customerNameInitial = "";
    if (conv?.customerName && conv?.customerName !="Unknown") {
        let nameArray = conv?.customerName?.split(" ");
        if (nameArray.length > 0) customerNameInitial = nameArray[0].charAt(0);
        if (nameArray.length > 1)
            customerNameInitial += "" + nameArray[1].charAt(0);
    }
    return {
        conversationId: conv?.conversationId,
        customerName: conv?.customerName===" "?"Unknown":conv?.customerName,
        customerPhoneNumber: conv?.customerPhoneNumber,
        userPhoneNumber: msgSupportNumber ? msgSupportNumber : conv?.userPhoneNumber,
        customerNameInitials: customerNameInitial,
        showBadge: false,
        refreshMessages: true,
        messageList: [],
        agentName: conv?.agentName,
        assignedDate: conv?.assignedDate,
        agentId: conv?.agentId,
        conversationEndedById: conv?.conversationEndedById,
        conversationEndedDate: conv?.conversationEndedDate,
        conversationEndedByName: conv?.conversationEndedByName,
        conversationAttributes: conv?.conversationAttributes,
        createdBy: conv?.createdBy,
        createdByName: conv?.createdByName,
        createdDate: conv?.createdDate,
        notes: conv?.notes,
        source: conv?.source,
        preAssigneeId:conv?.preAssigneeId,
        preAssignedDate:conv?.preAssignedDate,
        status: conv.status,
        isReadOnly: conv.isReadOnly != null ? conv.isReadOnly :false,
        lastMsgReceivedFrom: conv?.lastMsgReceivedFrom != null ? conv?.lastMsgReceivedFrom : "",
        lastMsgReceivedFromName: conv?.lastMsgReceivedFromName != null ? conv?.lastMsgReceivedFromName : "",
        lastMsgFromId: conv?.lastMsgFromId != null ? conv?.lastMsgFromId : "",
        teamId:conv?.teamId,
        teamName: conv?.teamName,
        email: conv?.email,
        linkedEntities : conv?.linkedEntities,
        recentActivityDate:conv?.recentActivityDate,
        docuSignAccountId: conv?.docuSignAccountId,
        requiredConvAttrUpdate: true,
        teamType: conv?.teamType,
        conversationBody: conv?.conversationBody,
        channel: conv?.channel != null ? conv?.channel : "SMS",
        showNotification: conv?.showNotification,
        authenticationStatus: conv?.authenticationStatus,
        overallSentiment: conv?.overallSentiment,
        msgCountForSentiment:0,
        authenticationStatus: conv?.authenticationStatus,
        authId: conv?.authId

    };
};

export function getEntityDisplayName(entlogicalname,channel) {
    switch (entlogicalname?.toLowerCase()) {
        case 'contact':
            return 'Member';
        case 'opportunity':
            return 'Application';
        case 'wcu_memberprofile':
            return 'Application'
        case 'wcu_sms':
            return 'Activity'
        case 'task':
            return 'Activity';
        case 'wcu_finaccount':
            return 'Fin Account';
        case 'lead':
            return 'Prospect';
        default:
            return '';
    }   
};