/**
 * 11-27-2023 => SUDAM B CHAVAN => TEXTING-1872 Chat Survey Questions Configuration - Admin UI
 * 12-11-2023 => SUDAM B CHAVAN =>TEXTING-1891 0: Survey : Update Questions Admin UI to add options and save , separated in backend
 */
import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    InputAdornment
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTheme } from "@mui/material/styles";
import SearchIcon from '@mui/icons-material/Search';
import { TextField } from "@mui/material";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import { StyledDataGrid } from "../../components/styledComponents/StyledDataGrid";


function AddQuestionsToSurveyTemplate(props) {
    const theme = useTheme();
    const [questionsArr, setQuestionsArr] = useState([]);
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const [searchText, setSearchText] = useState([]);
    const columns = [
        {
            field: "questionName",
            headerName: "Question Name",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "questionBody",
            headerName: "Question Body",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "answerRequired",
            headerName: "Answer Required",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "questionType",
            headerName: "Question Type",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "options",
            headerName: "Options",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
            valueGetter: (params) => params.row.options?.replaceAll('##SEP##', ','),
        },
        {
            
                    field: "Display Order",
                    headerName: "displayOrder",
                    flex: 1,
                    minWidth: 100,
                    headerClassName: "super-app-theme--header",

                    renderCell: (params) => {
                        return (
                            < Box sx={theme.customStyle.textRow} >
                                <TextField
                                    size="small"
                                    margin="dense"
                                    variant="standard"
                                    value={params.row?.displayOrder}
                                    fullWidth
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    id="displayOrder"
                                    inputProps={{ //not show suggetions
                                        autoComplete: 'new-password',
                                        form: {
                                            autocomplete: 'off',
                                        },
                                    }}
                                    onBlur={(e) => {
                                        debugger
                                       questionsArr[
                                            params.row.indexId
                                        ].displayOrder = e.target.value;
                                        setQuestionsArr(questionsArr);
                                    }}
                                />
                            </Box >

                        );
                }
        }
    ];

    useEffect(() => {
        //this useEffect will execute on 1st time on component render and when props.rows are chenged
        setQuestionsArr(props.rows);
        setSearchText('');
        setSelectedQuestions([]);
    }, [props.rows]);

    function filterTable(value) {
        let val = value?.toLowerCase();
        let tempArr = [];
        props.rows.forEach((item) => {
            if (
                item.questionName?.toLowerCase().includes(val) ||
                item.questionBody?.toLowerCase().includes(val) ||
                item.questionType?.toLowerCase().includes(val) ||
                item.options?.toLowerCase().includes(val)
            ) {
                tempArr.push(item);
            }
        });
        console.log("filterQuestions ::",tempArr)
        setQuestionsArr(tempArr);
    }


    return (
        <>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                fullWidth
                maxWidth={"md"}
            >
                <div>
                    <Box sx={theme.customStyle.tableRow}>
                        <DialogTitle>{props.dialogTitle}</DialogTitle>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <TextField
                            sx={{ marginRight: "25px", marginTop: "10px" }}
                            size="small"
                            type="search"
                            variant="outlined"
                            margin="normal"
                            id="outlined-search"
                            label=""
                            value={searchText}
                            placeholder="Questions Search"
                            autoComplete="off"
                            onChange={(e) => {
                                setSearchText(e.target.value)
                                if (e.target.value) {
                                    filterTable(e.target.value);
                                }
                                else {
                                    setQuestionsArr(props.rows);
                                }

                            }}
                            InputLabelProps={{
                                shrink: false,
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Box>
                </div>
                <DialogContent sx={{ paddingTop: "0px" }}>
                    <Box sx={theme.customStyle.teamsTableHead}>
                        <StyledDataGrid
                            loading={props.loading}
                            rows={questionsArr}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            checkboxSelection={props.checkboxSelection}
                            disableSelectionOnClick
                            components={{
                                NoRowsOverlay: CustomNoRowsOverlay,
                            }}
                            onSelectionModelChange={(data) => {
                                console.log(data, "asd");
                                setSelectedQuestions(data);
                                props.setSelectionModel(data);
                            }}
                        />
                    </Box>

                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        disabled={!(selectedQuestions && selectedQuestions.length > 0)}
                        size="small"
                        loading={props.btnLoading}
                        onClick={() => {
                            props.SaveQuestionsToSurveyTemplate(props.btnText,questionsArr);
                        }}
                    >
                        {props.btnText}
                    </LoadingButton>
                    <Button size="small" onClick={props.handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default AddQuestionsToSurveyTemplate;
