/**********
 * 9/11/2022 Texting-766 - removed auto suggetion
 * 11/11/2022 Texting-759=> Resetting users array before search hense loader will be visible
 * *11-11/2022 TextField:766=>disabled auto suggetion
 * 11-16-2022 => ONKAR => Texting-788 => Team name added in chip for selected team in filter.
 * 11-18-2022 => ONKAR => Texting-788 => resetting the table page number to 0 after search or clear filter.
 * 11-28-2022 => yogesh => Texting-866 => removed auto suggestion
 *************/

import React, { useState, useEffect, useContext } from "react";
import {
    TextField,
    Button,
    FormControl,
    Select,
    MenuItem,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import { useTheme } from "@mui/material/styles";
import { InvokePostServiceCall } from "../api/serviceUtil";
import moment from "moment";
import apiConfig from "../api/apiConfig";
import { AuthContext } from "../AuthContext";
import SnackBar from "./SnackBar";
import LoadingButton from '@mui/lab/LoadingButton';
import { dataService } from "../dataService";

function FilterComponent(props) {
    const {
        showFilter,
        filterChip,
        setFilterChip,
        deletedChip,
        setDeletedChip,
        url,
        setFilteredUsers,
        setIsLoading,
        setFilterApplied,
        currentPage,
        setShowFilter,
        setCurrentPage,
        filterData, setFilterData, isLoading,
        usersPage, setFilterTeamName
    } = props;
    const theme = useTheme();
    const { userInfo, contactsFilterData, setContactsFilterData, messageApiToken,
        loadUserFilter, setLoadUserFilter,
        loadContactFilter, setLoadContactFilter,
        usersFilterData, setUsersFilterData
    } = useContext(AuthContext);
    const [teamsList, setTeamsList] = useState([]);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState("");

    useEffect(() => {
        if (deletedChip) {
            if (deletedChip === "First Name") {
                setFilterData({ ...filterData, firstName: "" });
            }
            if (deletedChip === "Last Name") {
                setFilterData({ ...filterData, lastName: "" });
            }
            if (deletedChip === "Phone") {
                setFilterData({ ...filterData, phone: "" });
            }
            if (deletedChip === "Email") {
                setFilterData({ ...filterData, email: "" });
            }
            if (deletedChip === "Is Active") {
                setFilterData({ ...filterData, isActive: "" });
            }
            if (deletedChip === "Team") {
                setFilterData({ ...filterData, teamId: "" });
            }
            setDeletedChip("");
            updateFilter()
            if (filterChip.length > 0) {
                applyFilter();
            }
            else {
                clearFilter();
            }

        }
    }, [deletedChip]);

    useEffect(() => {
        if ((currentPage === "Users" && loadUserFilter) ||
            (currentPage === "Contacts" && loadContactFilter)) {
            applyFilter();
        }
    }, [loadUserFilter, loadContactFilter])

    useEffect(() => {
        if (messageApiToken) {
            setTeamsList(userInfo.role == "Admin" ? userInfo.allTeams : userInfo.teams);
        }
    }, [messageApiToken]);

    useEffect(() => {
        if (filterData.teamId) {
            if (filterData.teamId !== "None" && teamsList.length > 0) {
                teamsList.forEach((item) => {
                    if (item.teamId === filterData.teamId) {
                        setFilterTeamName(item.teamName);
                    }
                });
            } else {
                setFilterTeamName("None");
            }
        }
    }, [teamsList]);

    function applyFilter() {
        let tempArr = [];
        let tempArrOne = [];
        let filterChipArr = filterChip;
        setFilterApplied(true);

        setIsLoading(true);
        let requestBody = {
            firstName: deletedChip === "First Name" ? "" : filterData.firstName,
            lastName: deletedChip === "Last Name" ? "" : filterData.lastName,
            phoneNumber: deletedChip === "Phone" ? "" : filterData.phone,
            email: deletedChip === "Email" ? "" : filterData.email,
            isActive: deletedChip === "Is Active" ? "" : filterData.isActive === "None" ? "" : filterData.isActive,
            teamId: deletedChip === "Team" ? "" : filterData.teamId === "None" ? "" : filterData.teamId,
        };
        InvokePostServiceCall(url, requestBody)
            .then((data) => {
                if (filterData.firstName !== "" && deletedChip !== "First Name") {
                    tempArr.push("First Name");
                }
                if (filterData.lastName !== "" && deletedChip !== "Last Name") {
                    tempArr.push("Last Name");
                }
                if (filterData.phone !== "" && deletedChip !== "Phone") {
                    tempArr.push("Phone");
                }
                if (filterData.email !== "" && deletedChip !== "Email") {
                    tempArr.push("Email");
                }
                if (filterData.isActive !== "" && deletedChip !== "Is Active") {
                    if (currentPage === "Users") {
                        tempArr.push("Is Active");
                    }
                }
                if (filterData.teamId !== "" && deletedChip !== "Team") {
                    tempArr.push("Team");
                }
                if (filterData.firstName === "") {
                    filterChipArr.splice(filterChipArr.indexOf("First Name"), 1);
                }
                if (filterData.lastName === "") {
                    filterChipArr.splice(filterChipArr.indexOf("Last Name"), 1);
                }
                if (filterData.phone === "") {
                    filterChipArr.splice(filterChipArr.indexOf("Phone"), 1);
                }
                if (filterData.email === "") {
                    filterChipArr.splice(filterChipArr.indexOf("Email"), 1);
                }
                if (filterData.isActive === "") {
                    filterChipArr.splice(filterChipArr.indexOf("Is Active"), 1);
                }
                if (filterData.teamId === "") {
                    filterChipArr.splice(filterChipArr.indexOf("Team"), 1);
                }
                if (filterChipArr.length > 0) {
                    tempArr.forEach((item) => {
                        const present = filterChipArr.find((tempItem) => tempItem === item);
                        if (present !== item) {
                            tempArrOne.push(item);
                        }
                    });
                    setFilterChip(filterChipArr.concat(tempArrOne));
                } else {
                    setFilterChip(filterChipArr.concat(tempArr));
                }

                let userArr = [];
                let filteredArr;

                if (currentPage === "Users") {
                    filteredArr = data.data.filteredUsers;
                    filteredArr.forEach((item, index) => {
                        userArr.push({
                            azureAdid: item.azureAdid,
                            connectionStatus: item.connectionStatus,
                            createdBy: item.createdBy,
                            createdDate: moment(item.createdDate).local().format("MM-DD-YYYY"),
                            emailId: item.emailId,
                            firstName: item.firstName,
                            id: index,
                            isActive: item.isActive,
                            lastModifiedBy: item.lastModifiedBy,
                            lastModifiedDate: moment(item.lastModifiedDate)
                                .local()
                                .format("MM-DD-YYYY"),
                            lastName: item.lastName,
                            lastPreAssignmentDate: item.lastPreAssignmentDate,
                            phoneNumber: item.phoneNumber,
                            role: item.role,
                            status: item.status,
                            department: item.department,
                            userId: item.userId,
                            userName: item.userName,
                            clientFacingPhoneNum: item.clientFacingPhoneNum,
                        });
                    });
                    setFilteredUsers(userArr);
                    props.setFilteredUsersCopy(userArr)
                } else {
                    filteredArr = data.data.filteredContacts;
          
                    filteredArr.forEach((item, index) => {
                        userArr.push({
                            contactAttributes: item.contactAttributes,
                            id: item.contactId,
                            createdBy: item.createdBy,
                            createdDate: moment(item.createdDate).local().format("MM-DD-YYYY"),
                            email: item.email,
                            firstName: item.firstName,
                            isActive: item.isActive,
                            lastModifiedBy: item.lastModifiedBy,
                            lastModifiedDate: moment(item.lastModifiedDate)
                                .local()
                                .format("MM-DD-YYYY"),
                            lastName: item.lastName,
                            mobileNumber: item.mobileNumber,
                            name: item.name,
                            contactId: item.contactId,
                        });
                    });
                    setFilteredUsers(userArr);
                    props.setFilteredUsersCopy(userArr)
                }

                if (filterData.teamId === "None") {
                    setFilterTeamName("None");

                } else {
                    teamsList.forEach((item) => {
                        if (item.teamId === filterData.teamId) {
                            setFilterTeamName(item.teamName)
                        }
                    })
                }

                setIsLoading(false);
                setShowFilter(false);
                setCurrentPage(0);
            })
            .catch((error) => {
                debugger;
                setSnackbarMsg(error.message);
                setOpenSnackbar(true);
                setIsLoading(false);
                setShowFilter(false);
                console.log(error);
            });
    }

    function updateFilter() {
        let requestBody = {
            userDetail: {
                user: {
                    userId: userInfo.userId,
                },
            },
        };
        if (currentPage === "Users") {
            requestBody.userDetail.user.userPreferences = JSON.stringify({
                ContactsFilter: {
                    firstName: contactsFilterData.firstName,
                    lastName: contactsFilterData.lastName,
                    phone: contactsFilterData.phone,
                    email: contactsFilterData.email,
                    teamId: contactsFilterData.teamId,
                },
                UsersFilter: {
                    firstName: deletedChip === "First Name" ? "" : usersFilterData.firstName,
                    lastName: deletedChip === "Last Name" ? "" : usersFilterData.lastName,
                    phone: deletedChip === "Phone" ? "" : usersFilterData.phone,
                    email: deletedChip === "Email" ? "" : usersFilterData.email,
                    teamId: deletedChip === "Team" ? "" : usersFilterData.teamId,
                    isActive: deletedChip === "Is Active" ? "" : usersFilterData.isActive
                },
                DefaultTeamId: userInfo.teamId,
            })
        } else {
            requestBody.userDetail.user.userPreferences = JSON.stringify({
                ContactsFilter: {
                    firstName: deletedChip === "First Name" ? "" : contactsFilterData.firstName,
                    lastName: deletedChip === "Last Name" ? "" : contactsFilterData.lastName,
                    phone: deletedChip === "Phone" ? "" : contactsFilterData.phone,
                    email: deletedChip === "Email" ? "" : contactsFilterData.email,
                    teamId: deletedChip === "Team" ? "" : contactsFilterData.teamId,
                },
                UsersFilter: {
                    firstName: usersFilterData.firstName,
                    lastName: usersFilterData.lastName,
                    phone: usersFilterData.phone,
                    email: usersFilterData.email,
                    teamId: usersFilterData.teamId,
                    isActive: usersFilterData.isActive
                },
                DefaultTeamId: userInfo.teamId,
               
            })

        }
        InvokePostServiceCall(apiConfig.SAVE_USER_PREFERENCES, requestBody)
            .then((data) => {
                console.log(data, "saveUserPreferences");
            })
            .catch((error) => {
                console.log(error);
            });
    }


    function clearFilter() {
        setFilterData({
            fullName: "",
            firstName: "",
            lastName: "",
            phone: "",
            email: "",
            isActive: "",
            teamId: "",
        });
        setFilterChip([]);
        setFilterApplied(false);
        props.setPage(0);

        let requestBody = {
            userDetail: {
                user: {
                    userId: userInfo.userId,
                },
            },
        };
        if (currentPage === "Users") {
            setLoadUserFilter(false);
            setUsersFilterData({
                firstName: "",
                lastName: "",
                phone: "",
                email: "",
                isActive: "",
                teamId: "",
            });
            requestBody.userDetail.user.userPreferences = JSON.stringify({
                ContactsFilter: {
                    firstName: contactsFilterData.firstName,
                    lastName: contactsFilterData.lastName,
                    phone: contactsFilterData.phone,
                    email: contactsFilterData.email,
                    teamId: contactsFilterData.teamId,
                },
                UsersFilter: {
                    firstName: "",
                    lastName: "",
                    phone: "",
                    email: "",
                    teamId: "",
                    isActive: ""
                },
                DefaultTeamId: userInfo.teamId,
            })
        } else {
            setLoadContactFilter(false);
            setContactsFilterData({
                firstName: "",
                lastName: "",
                phone: "",
                email: "",
                teamId: "",
            });
            requestBody.userDetail.user.userPreferences = JSON.stringify({
                ContactsFilter: {
                    firstName: "",
                    lastName: "",
                    phone: "",
                    email: "",
                    teamId: "",
                },
                UsersFilter: {
                    firstName: usersFilterData.firstName,
                    lastName: usersFilterData.lastName,
                    phone: usersFilterData.phone,
                    email: usersFilterData.email,
                    teamId: usersFilterData.teamId,
                    isActive: usersFilterData.isActive
                },
                DefaultTeamId: userInfo.teamId,
            })

        }
        InvokePostServiceCall(apiConfig.SAVE_USER_PREFERENCES, requestBody)
            .then((data) => {
                console.log(data, "saveUserPreferences");
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const handleChangeIsActive = (event) => {
        setFilterData({ ...filterData, isActive: event.target.value });
    };
    const handleChangeTeam = (event) => {
        setFilterData({ ...filterData, teamId: event.target.value });
    };
    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };
    const handleSearch = (event) => {
        setFilteredUsers([]) //reseting the filtered user before search
        props.setPage(0);
        if (currentPage === "Contacts") {
            props.setFilteredUsersCopy([])
            if (teamsList.length == 0) {
                dataService.setData({ showAlertMessage: "You are not assigned to any team, Please contact your supervisor or administrator" });
            }
            else {
                if (!loadContactFilter) {
                    setLoadContactFilter(true);
                }
                else {
                    applyFilter();
                }
            }
        }
        else {
            if (!loadUserFilter) {
                setLoadUserFilter(true);
            }
            else {
                applyFilter();
            }
        }
        updateFilter();
    };
    const fakeInputStyle = { opacity: 0, float: 'left', border: 'none', height: '0', width: '0' }
    return (
        <>
            <SnackBar
                openSnackbar={openSnackbar}
                handleCloseSnackbar={handleCloseSnackbar}
                severity={"error"}
                userMessage={snackbarMsg}
            />
            <Collapse in={showFilter}>
                <div>
                    <div style={theme.customStyle.filterMainDiv}>
                        <div style={theme.customStyle.textFieldMainDiv}>
                            <div style={theme.customStyle.textFieldLabel}>First Name</div>
                            <TextField
                                label=""
                                size="small"
                                variant="outlined"
                                sx={theme.customStyle.filterTextField}
                                value={filterData.firstName}
                                onChange={(e) => {
                                    setFilterData({
                                        ...filterData,
                                        firstName: e.target.value,
                                    });
                                }}
                                onFocus={event => {
                                    event.target.setAttribute('autocomplete', 'new-password');
                                }}
                            />
                        </div>
                        <div style={theme.customStyle.textFieldMainDiv}>
                            <div style={theme.customStyle.textFieldLabel}>Last Name</div>
                            <TextField
                                label=""
                                size="small"
                                variant="outlined"
                                sx={theme.customStyle.filterTextField}
                                value={filterData.lastName}
                                onChange={(e) => {
                                    setFilterData({
                                        ...filterData,
                                        lastName: e.target.value,
                                    });
                                }}
                                onFocus={event => {
                                    event.target.setAttribute('autocomplete', 'new-password');
                                }}
                            />
                        </div>
                        <div style={theme.customStyle.textFieldMainDiv}>
                            <div style={theme.customStyle.textFieldLabel}>Email</div>
                            <TextField
                                label=""
                                size="small"
                                variant="outlined"
                                sx={theme.customStyle.filterTextField}
                                value={filterData.email}
                                onChange={(e) => {
                                    setFilterData({
                                        ...filterData,
                                        email: e.target.value,
                                    });
                                }}
                                onFocus={event => {
                                    event.target.setAttribute('autocomplete', 'new-password');
                                }}
                            />
                        </div>
                        <div style={theme.customStyle.textFieldMainDiv}>
                            <div style={theme.customStyle.textFieldLabel}>Phone</div>

                            <TextField
                                label=""
                                size="small"
                                variant="outlined"
                                sx={theme.customStyle.filterTextField}
                                value={filterData.phone}
                                onChange={(e) => {
                                    setFilterData({
                                        ...filterData,
                                        phone: e.target.value,
                                    });
                                }}
                                onFocus={event => {
                                    event.target.setAttribute('autocomplete', 'new-password');
                                }}
                            />
                        </div>
                        {currentPage === "Users" && (
                            <div style={theme.customStyle.textFieldMainDiv}>
                                <div style={theme.customStyle.textFieldLabel}>Is Active</div>
                                <FormControl fullWidth size="small" sx={{ minWidth: 88 }}>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={filterData.isActive}
                                        label=""
                                        onChange={handleChangeIsActive}
                                        sx={theme.customStyle.filterTextField}
                                    >
                                        <MenuItem value={"None"}>None</MenuItem>
                                        <MenuItem value={true}>True</MenuItem>
                                        <MenuItem value={false}>False</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        )}
                        <div style={theme.customStyle.textFieldMainDiv}>
                            <div style={theme.customStyle.textFieldLabel}>Team</div>
                            <FormControl fullWidth size="small" sx={{ minWidth: 140 }}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={filterData.teamId}
                                    label=""
                                    onChange={handleChangeTeam}
                                    sx={theme.customStyle.filterTextField}
                                >
                                    <MenuItem value={"None"}>None</MenuItem>
                                    {teamsList && teamsList.map((option, index) => (
                                        <MenuItem key={option.teamId} value={option.teamId}>
                                            {option.teamName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <LoadingButton
                            loading={isLoading}
                            disabled={
                                !filterData.firstName &&
                                    !filterData.lastName &&
                                    !filterData.email &&
                                    !filterData.phone &&
                                    filterData.isActive !== "None" && filterData.isActive !== true && filterData.isActive !== false &&
                                    !filterData.teamId
                                    ? true
                                    : false
                            }
                            variant="contained"
                            size="small"
                            sx={theme.customStyle.filterButtons}
                            onClick={handleSearch}
                        >
                            Search
                        </LoadingButton>
                        <Button
                            variant="contained"
                            size="small"
                            sx={{ ...theme.customStyle.filterButtons, marginLeft: "10px" }}
                            onClick={() => {
                                clearFilter();
                                setShowFilter(false);
                            }}
                        >
                            Clear
                        </Button>
                    </div>
                </div>
            </Collapse>
        </>
    );
}

export default FilterComponent;
