import axios from "axios";
// import axiosInstance from "./axiosInstance";
import jwt_Decode from "jwt-decode";
import dayjs from "dayjs";
import tokenData from "./authData";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../azure/authConfig";
import apiConfig from "./apiConfig"

const instance = new PublicClientApplication(msalConfig);
const accounts = instance.getAllAccounts();

export async function InvokePostServiceCall(url, requestBody, token) {
    if (!token) {
        token = tokenData.getToken();
    }
    const axiosInstance = axios.create({
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });
    axiosInstance.interceptors.request.use(async (req) => {
        const user = jwt_Decode(token);
        const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;
        if (!isExpired) {
            return req;
        } else {
            const loginRequest = {
                scopes: [apiConfig.API_AD_SCOPE],
            };
            const tokenRequest = { loginRequest, account: accounts[0] };
            instance.acquireTokenSilent(tokenRequest).then((response) => {
                let logInReq = {
                    userDetail: {
                        user: {
                            azureAdid: accounts[0].localAccountId.toLowerCase(),
                        },
                    },
                };
                InvokePostServiceCall(apiConfig.LOGIN, logInReq, response.accessToken)
                    .then((apiresponse) => {
                        tokenData.setToken(apiresponse.data.token);
                    })
                    .catch((e) => {
                        console.log("Error in serviceUtil" + e.message);
                    });
            });
            return req;
        }
    });
    return await axiosInstance.post(url, requestBody).then((response) => {
        return response;
    });
}


export async function InvokeGetServiceCall(url, token) {
    if (!token) {
        token = tokenData.getToken();
    }
    const axiosInstance = axios.create({
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });
    axiosInstance.interceptors.request.use(async (req) => {
        const user = jwt_Decode(token);
        const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;
        if (!isExpired) {
            return req;
        } else {
            const loginRequest = {
                scopes: [apiConfig.API_AD_SCOPE],
            };
            const tokenRequest = { loginRequest, account: accounts[0] };
            instance.acquireTokenSilent(tokenRequest).then((response) => {
                tokenData.setToken(response.accessToken);
            });
            return req;
        }
    });
    return await axiosInstance.get(url).then((response) => {
        return response;
    });
}
export async function InvokePostServiceCallWithoutToken(url, requestBody) {
             const axiosInstance = axios.create({
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
    return await axiosInstance.post(url, requestBody).then((response) => {
        return response
    })
}

export async function InvokePostServiceCallWithJWTToken(url, requestBody, token) {
    const axiosInstance = axios.create({
           headers: {
                Authorization: `Bearer ${token}`,
               "Content-Type": "application/json",
           },
       });
return await axiosInstance.post(url, requestBody).then((response) => {
return response
})
}