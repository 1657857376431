/***************
 * ////11-02-2022 => Texting-732 Added DocuSign Account ID field on UI to Team Detail, removed duplicate ids of textfield.
////11-03-2022 => Texting-732 reduced docusign account id textfield limit to 50.
* 11-11/2022 TextField:766=>disabled auto suggetion
* 05-04-2023 SUDAM B CHAVAN  TEXTING-1247=>Implement the switch based on the new flag on Teams for RED DOT
* 07-10-2023 SUDAM B CHAVAN  TEXTING-1417 - GPT Component Pending observations.
* 08-02-2023 PRABHAT TEXTING-1510 Increase character limit for the fields in Teams to accommodate longer text messages.
* 08-02-2023 PRABHAT TEXTING-1517 Update TCPA Message when message sent on non TCPA hours.
* 08-08-2023 PRABHAT TEXTING-1548 Service Provider on Teams Detail Page.
* 08-28-2023 PRABHAT TEXTING-1629 In Team details page Able to type letters in Inactive conversation hours
* 08-29-2023 => ONKAR => TEXTING-1578 -REACT Upgrade 
* 09-21-2023 => PRABHAT => TEXTING-1730 Enable Audit History for Teams
* 09-22-2023 => PRABHAT => TEXTING-1743 While creating the Team, Opening the Users, Templates tab is showing error 400 popup.
* 10-04-2023 SUDAM B CHAVAN  TEXTING-1777 - Create New Team is not working.
 * 11-27-2023 => SUDAM B CHAVAN => TEXTING-1872 Chat Survey Questions Configuration - Admin UI
 * 12-12-2023 => SUDAM B CHAVAN => TEXTING-1916 0: Configuration to Enable Survey in appsettings
***********/
import React, { useState, useEffect, useContext } from "react";
import { Collapse, Box, MenuItem, Checkbox, DialogTitle, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Button, Divider, IconButton } from "@mui/material";
import TextField from "@mui/material/TextField";
import JSONInput from "react-json-editor-ajrm";
import apiConfig from "../../api/apiConfig";
import { useLocation, useNavigate } from "react-router-dom";
import { InvokePostServiceCall, InvokeGetServiceCall } from "../../api/serviceUtil";
import SnackBar from "../../components/SnackBar";
import { AuthContext } from "../../AuthContext";
import TeamsListOfUsers from "../../components/TeamsListOfUsers";
import LoadingButton from "@mui/lab/LoadingButton";
import moment from "moment";
import { TabContext } from "@mui/lab";
import { useTheme } from "@mui/material/styles";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Tab from "@mui/material/Tab";
import TemplatesComp from "./TemplatesComp";
import CustomTabList from "../../components/CustomTabList";
import ObjectHistoryComp from "../teams/ObjectHistoryComp";

export default function AddUpdateTeam() {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const [voicePhoneErr, setVoicePhoneErr] = useState(false);
  const [collapse, setCollapse] = useState(false)
  const [jsonError, setJsonError] = useState(false);
  const [smsPhoneErr, setSmsPhoneErr] = useState(false);
  const [tabValue, setTabValue] = useState("1");
  const { messageApiToken, userInfo } = useContext(AuthContext);
  const isAddTeam = location.state ? location.state.isAddTeam : true;
  const [teamAttributesJson, setTeamAttributesJson] = useState(isAddTeam ? "" : location.state.teamDetails.teamAttributes);
  const [validJson, setValidJson] = useState(() => {
    try {
      JSON.parse(location.state.teamDetails.teamAttributes);
      return true;
    } catch (e) {
      return false;
    }
  });
  const teamId = isAddTeam ? null : location.state.teamDetails.teamId;//on team create giving error for empty string teamId as it is added to teamData state and same we are passing in payload so updated to null
  const [teamData, setTeamData] = useState({
    teamName: isAddTeam ? "" : location.state.teamDetails.teamName,
    shortCode: isAddTeam ? "" : location.state.teamDetails.shortCode,
    voiceSupportNumber: isAddTeam
      ? ""
      : location.state.teamDetails.voiceSupportNumber,
    msgSupportNumber: isAddTeam
      ? ""
      : location.state.teamDetails.msgSupportNumber,
    inboundMsgAssignment: isAddTeam
      ? ""
      : location.state.teamDetails.inboundMsgAssignment,
    type: isAddTeam ? "" : location.state.teamDetails.type,
    sensitiveDataRegExp: isAddTeam ? "" : location.state.teamDetails.sensitiveDataRegExp,
    isActive: isAddTeam ? true : location.state.teamDetails.isActive,
    businessHoursConfigId: isAddTeam ? "" : location.state.teamDetails.businessHoursConfigId,
    smsprovider: isAddTeam ? "Twilio" : location.state.teamDetails.smsprovider,
    nonBusHrsMessage: isAddTeam ? "" : location.state.teamDetails.nonBusHrsMessage,
    agentUnavailableMessage: isAddTeam ? "" : location.state.teamDetails.agentUnavailableMessage,
    nppistrippedMessage: isAddTeam ? "" : location.state.teamDetails.nppistrippedMessage,
    mediaContentMessage: isAddTeam ? "" : location.state.teamDetails.mediaContentMessage,
      nonTcpahrsMessage: isAddTeam ? "" : location.state.teamDetails.nonTcpahrsMessage,
    docuSignAccountId: isAddTeam ? "" : location.state.teamDetails.docuSignAccountId,
    teamAttributes: isAddTeam ? "" : location.state.teamDetails.teamAttributes,
    inActiveConversationHours: isAddTeam ? "" : location.state.teamDetails.inActiveConversationHours,
    notes: isAddTeam ? "" : location.state.teamDetails.notes,
    isStartConvWithTemplate: isAddTeam ? true : location.state.teamDetails.isStartConvWithTemplate,
    enableDocuments: isAddTeam ? true : location.state.teamDetails.enableDocuments, 
    documentTypes: isAddTeam ? "" : location.state.teamDetails.documentTypes,
    enableConversationReadOnSeen: isAddTeam ? true : location.state.teamDetails.enableConversationReadOnSeen,
    eligibleToTransferConversation: isAddTeam ? true : location.state.teamDetails.eligibleToTransferConversation,
    eligibleToReceiveConversation: isAddTeam ? true : location.state.teamDetails.eligibleToReceiveConversation,
    teamId: teamId,
    isSurveyEnabled: isAddTeam ? true : location.state.teamDetails.isSurveyEnabled,
    surveyTemplateId: isAddTeam ? "" : location.state.teamDetails.surveyTemplateId,

  });
  let lastModifiedByName = isAddTeam ? "" : location.state.teamDetails.lastModifiedByName
  let lastModifiedDate = moment(isAddTeam ? "" : location.state.teamDetails.lastModifiedDate).local().format("LLL")
  let createdByName = isAddTeam ? "" : location.state.teamDetails.createdByName
  let createdDate = moment(isAddTeam ? "" : location.state.teamDetails.createdDate).local().format("LLL")

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [severity, setSeverity] = useState("success");
  const [businessHourConfigList, setBusinessHoursConfigList] = useState([]);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [businessHoursConfig, setBusinessHoursConfig] = useState(
    isAddTeam
      ? "None"
      : location.state.teamDetails.businessHoursConfigId ? location.state.teamDetails.businessHoursConfigId
        : "None"
  );
  const [surveyTemplate, setSurveyTemplate] = useState(
    isAddTeam
      ? "None"
      : location.state.teamDetails.surveyTemplateId ? location.state.teamDetails.surveyTemplateId
        : "None"
  );
  const [surveyTemplates, setSurveyTemplates] = useState([]);
  const AddTeam = "Add Team";
  const UpdateTeam = "Update Team";

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    if (severity === "success") {
      navigate("/teams", { replace: true });
    }
    setOpenSnackbar(false);
  };
  useEffect(() => {
      if (messageApiToken) {
      InvokePostServiceCall(apiConfig.GET_BUSINESS_HOURS_CONFIG, {})
        .then((data) => {
          let tempArr = [];
          data.data.businessHoursConfigDetails.forEach((item) => {
            tempArr.push(item.businessHoursConfig);
          });
          setBusinessHoursConfigList(tempArr);
        })
        .catch((error) => {
          setSeverity("error");
          setSnackbarMsg(error.message);
          setOpenSnackbar(true);
          console.log(error, "error message");
        });
        GetSurveyTemplates();//load survey templates
    }
  }, [messageApiToken]);

  //load survey templates
  const GetSurveyTemplates=()=>{
    InvokeGetServiceCall(apiConfig.GET_SURVEY_TEMPLATES)
      .then((data) => {
        let tempArr = [];
        data.data.surveyTemplates.forEach((item) => {
          tempArr.push(item);
        });
        setSurveyTemplates(tempArr);
      })
      .catch((error) => {
        setSeverity("error");
        setSnackbarMsg(error.message);
        setOpenSnackbar(true);
        console.log(error, "error message");
      });
    
  }

  const handleChange = (event) => {
    setTeamData({ ...teamData, type: event.target.value });
    // teamData.type === "teamName" ? "teamName" : "individual"
  };



  const CheckMobileNumber = (Number) => {
    let chkmobile = false;
    const PHONE_REGEX = /^[+][0-9]{11}$/i
    let PHONE_US = /^\([0-9]{3}\) |[0-9]{3}-[0-9]{3}-[0-9]{4}$/i;
    const PHONE_INDIAN_REGEX = /^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/i;

    if (PHONE_REGEX.test(Number)) {
      chkmobile = true;
    } else if (PHONE_US.test(Number)) {
      chkmobile = true;
    }
    else if (PHONE_INDIAN_REGEX.test(Number)) {
      chkmobile = true;
    }

    return chkmobile;


  }

  const handleAddUpdateTeam = () => {
    debugger;
    let chkVoicemobile = true;
    let chkSmsmobile = true;
    if (teamData.voiceSupportNumber) {
      chkVoicemobile = CheckMobileNumber(teamData.voiceSupportNumber);
    }

    if (teamData.msgSupportNumber) {
      chkSmsmobile = CheckMobileNumber(teamData.msgSupportNumber);
    }




    if (chkVoicemobile && chkSmsmobile) {
      setLoadingBtn(true);
      let requestBody = {
        teamInfo: {
          team: teamData,
          actionType: isAddTeam ? "Add" : "Update",
          users: [],
        },
      };
      if (!isAddTeam) {
        requestBody.teamInfo.team.teamId = location.state.teamDetails.teamId;
      }
      console.log(requestBody, "requestBody");
      InvokePostServiceCall(apiConfig.ADD_UPDATE_TEAM, requestBody)
        .then((data) => {
          setSeverity("success");
          setSnackbarMsg(isAddTeam ? "Team Added Successfully" : "Team Updated Successfully");
          setOpenSnackbar(true);
          setLoadingBtn(false);
        })
        .catch((err) => {
          setSeverity("error")
          setSnackbarMsg(err.message);
          console.log(err, "addTeamError");
          setOpenSnackbar(true);
          setLoadingBtn(false);
        });
    } else {
      if (!chkVoicemobile && !chkSmsmobile) {
        setVoicePhoneErr(true);
        setSmsPhoneErr(true);
        setSnackbarMsg("Invalid Voice Number and SMS Number");
      }
      else if (!chkVoicemobile) {
        setVoicePhoneErr(true);
        setSnackbarMsg("Invalid Voice Number");

      } else if (!chkSmsmobile) {
        setSmsPhoneErr(true);
        setSnackbarMsg("Invalid SMS Number");
      }
      setSeverity("error")
      setOpenSnackbar(true);
      setLoadingBtn(false);
    }
  };

  const handleChangeIsActive = (event) => {
    setTeamData({ ...teamData, isActive: event.target.checked });
  };
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };
  const NoRecordsMessage = () => (
        <div>
            <h5 style={{
                color: "red",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "center"
            }}>No Records.</h5>
        </div>
    );
  const tabList = [
    { label: "Team Details", value: "1", displayOrder: 1 },
    { label: "Users", value: "2", displayOrder: 2 },
    { label: "Templates", value: "3", displayOrder: 3 },
    { label: "History", value: "4", displayOrder: 4 }
  ]
  return (
    <>
      <SnackBar
        openSnackbar={openSnackbar}
        handleCloseSnackbar={handleCloseSnackbar}
        severity={severity}
        userMessage={snackbarMsg}
      />
      {!isAddTeam &&
      <Box sx={theme.customStyle.tableRow}>
          <h2 style={{ marginLeft: 10 }}>{"Team - "+ teamData.teamName}</h2>
      </Box>}
      <TabContext value={tabValue}>
        <CustomTabList tabList={tabList} showTabsCount={4} handleChangeTab={(event, selectedTabValue) => {
          setTabValue(selectedTabValue)
        }}></CustomTabList>
        <TabPanel value="1">
          <div>
            {/* <h2>{isAddTeam ? AddTeam : UpdateTeam}</h2> */}
            <Box sx={styles.textRow}>
              <TextField
                margin="dense"
                label="Team Name"
                type="text"
                required
                value={teamData.teamName}
                fullWidth
                variant="standard"
                size="small"
                id="updateTeamteamName"
                inputProps={{ //not show suggetions
                  autoComplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  setTeamData({ ...teamData, teamName: e.target.value });
                }}
              />
            </Box>

            <Box sx={styles.textRow}>
              <TextField
                margin="dense"
                value={teamData.shortCode}
                label="Short Code"
                type="text"
                fullWidth
                variant="standard"
                size="small"
                id="updateTeamshortCode"
                inputProps={{ //not show suggetions
                  autoComplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  setTeamData({ ...teamData, shortCode: e.target.value });
                }}
              />
            </Box>

            <Box sx={styles.textRow}>
              <TextField
                margin="dense"
                label="Voice Number"
                value={teamData.voiceSupportNumber}
                type="text"
                fullWidth
                variant="standard"
                size="small"
                error={voicePhoneErr}
                InputLabelProps={{
                  shrink: true,
                }}
                id="updateTeamteamvoiceSupportNumber"
                inputProps={{ //not show suggetions
                  autoComplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
                onChange={(e) => {
                  setVoicePhoneErr(false);
                  setTeamData({
                    ...teamData,
                    voiceSupportNumber: e.target.value,
                  });
                }}
                helperText={voicePhoneErr ? "Invalid Voice Number" : ""}
              />
            </Box>

            <Box sx={styles.textRow}>
              <TextField
                margin="dense"
                label="SMS Number"
                value={teamData.msgSupportNumber}
                type="text"
                fullWidth
                variant="standard"
                size="small"
                error={smsPhoneErr}
                id="updateTeamteammsgSupportNumber"
                inputProps={{ //not show suggetions
                  autoComplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  setSmsPhoneErr(false);
                  setTeamData({
                    ...teamData,
                    msgSupportNumber: e.target.value,
                  });
                }}
                helperText={smsPhoneErr ? "Invalid SMS Number" : ""}
              />
            </Box>
            <Box sx={styles.textRow}>
              <TextField
                margin="dense"
                label="Sensitive Data Regex"
                value={teamData.sensitiveDataRegExp}
                type="text"
                fullWidth
                variant="standard"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                id="updateTeamteamsensitiveDataRegExp"
                inputProps={{ //not show suggetions
                  autoComplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
                onChange={(e) => {
                  setTeamData({
                    ...teamData,
                    sensitiveDataRegExp: e.target.value,
                  });
                }}
              />
            </Box>
            <Box sx={{ ...styles.textRow, marginTop: 2.5 }}>
              <TextField
                size="small"
                select
                label="Inbound Message Assignment"
                defaultValue={teamData.inboundMsgAssignment}
                value={teamData.inboundMsgAssignment}
                fullWidth
                id="updateTeamteaminboundMsgAssignment"
                inputProps={{ //not show suggetions
                  autoComplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
                onChange={(e) => {
                  setTeamData({
                    ...teamData,
                    inboundMsgAssignment: e.target.value,
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="standard"
              >
                <MenuItem value={"SupervisorAssign"}>Supervisor Assign</MenuItem>
                <MenuItem value={"AutoAssign-RoundRobin"}>
                  Auto Assign Round-robin
                </MenuItem>
              </TextField>
            </Box>
            <Box sx={{ ...styles.textRow, marginTop: 2.5 }}>
              <TextField
                size="small"
                select
                label="Business Hours Config"
                defaultValue={businessHoursConfig}
                value={businessHoursConfig}
                fullWidth
                id="updateTeamteambusinessHoursConfig"
                inputProps={{ //not show suggetions
                  autoComplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
                onChange={(e) => {
                  if (e.target.value === "None") {
                    setTeamData({
                      ...teamData,
                      businessHoursConfigId: "",
                    });
                    setBusinessHoursConfig(e.target.value)
                  } else {
                    setTeamData({
                      ...teamData,
                      businessHoursConfigId: e.target.value,
                    });
                    setBusinessHoursConfig(e.target.value)
                  }

                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="standard"
              >
                <MenuItem value={"None"}>None</MenuItem>
                {businessHourConfigList.map((item, index) => {
                  return (
                    <MenuItem value={item.businessHoursConfigId}>
                      {item.configName}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Box>
            <Box sx={{ ...styles.textRow, marginTop: 2.5 }}>
               <TextField
                   size="small"
                   select
                   label="SMS Provider"
                   value={teamData.smsprovider}
                   fullWidth
                   id="updateTeamteamSmsProvider"
                   onChange={(e) => {
                         setTeamData({
                               ...teamData,
                         smsprovider: e.target.value,
                         });
                   }}
                   InputLabelProps={{
                        shrink: true,
                   }}
                   variant="standard"
               >
               <MenuItem value={"None"}>None</MenuItem>
               <MenuItem value={"Twilio"}>Twilio</MenuItem>
               </TextField>
            </Box>
            <Box sx={styles.textRow}>
              <TextField
                margin="dense"
                label="Non Business Hours Message"
                value={teamData.nonBusHrsMessage}
                type="text"
                fullWidth
                multiline
                rows={2}
                variant="standard"
                size="small"
                id="updateTeamteamnonBusHrsMessage"
                inputProps={{ //not show suggetions
                  maxLength: 500, 
                  autoComplete: 'new-password',
                  shrink: true,
                  form: {
                    autocomplete: 'off',
                  },
                }}
                onChange={(e) => {
                  setTeamData({
                    ...teamData,
                    nonBusHrsMessage: e.target.value,
                  });
                }}
              />
            </Box>
            <Box sx={styles.textRow}>
              <TextField
                margin="dense"
                label="Agent Unavailable Message"
                multiline
                rows={2}
                value={teamData.agentUnavailableMessage}
                type="text"
                fullWidth
                variant="standard"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                id="updateTeamteamagentUnavailableMessage"
                inputProps={{ //not show suggetions
                  maxLength: 500,
                  autoComplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
                onChange={(e) => {
                  setTeamData({
                    ...teamData,
                    agentUnavailableMessage: e.target.value,
                  });
                }}
              />
            </Box>

            <Box sx={styles.textRow}>
              <TextField
                margin="dense"
                label="Nppi stripped Message"
                multiline
                rows={2}
                value={teamData.nppistrippedMessage}
                type="text"
                fullWidth
                variant="standard"
                size="small"
                id="updateTeamteamnppinppistrippedMessage"
                inputProps={{ //not show suggetions
                  maxLength: 500,
                  autoComplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  setTeamData({
                    ...teamData,
                    nppistrippedMessage: e.target.value,
                  });
                }}
              />
            </Box>
            <Box sx={styles.textRow}>
              <TextField
                margin="dense"
                label="Media Content Message"
                multiline
                rows={2}
                inputProps={{
                  maxLength: 500, autoComplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
                id="updateTeamteammediaContentMessage"
                value={teamData.mediaContentMessage}
                type="text"
                fullWidth
                variant="standard"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  setTeamData({
                    ...teamData,
                    mediaContentMessage: e.target.value,
                  });
                }}
              />
            </Box>
            <Box sx={styles.textRow}>
              <TextField     
                  margin="dense"
                  label="Non TCPA Hours Message"
                  value={teamData.nonTcpahrsMessage}
                  type="text"
                  fullWidth
                  multiline
                  rows={2}
                  variant="standard"
                  size="small"
                  id="updateTeamteamnonTCPAHrsMessage"
                  inputProps={{ //not show suggetions
                         maxLength: 500,
                         autoComplete: 'new-password',
                         shrink: true,
                         form: {
                             autocomplete: 'off',
                         },
                  }}
                  onChange={(e) => {
                       setTeamData({
                         ...teamData,
                           nonTcpahrsMessage: e.target.value,
                       });
                  }}
                />
            </Box>
            <Box sx={styles.textRow}>
              <TextField
                margin="dense"
                value={teamData.docuSignAccountId}
                label="DocuSign Account ID"
                type="text"
                fullWidth
                variant="standard"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                id="updateTeamteamdocuSignAccountId"
                inputProps={{ //not show suggetions
                  maxLength: 50,
                  autoComplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
                onChange={(e) => {
                  setTeamData({ ...teamData, docuSignAccountId: e.target.value });
                }}
              />
            </Box>
            <Box sx={theme.customStyle.textRow}>
              <TextField
                size="small"
                margin="dense"
                value={teamData.teamAttributes}
                // disabled={isAddTeam}
                label="Team Attributes"
                type="text"
                fullWidth
                variant="standard"
                id="updateTeamAttributes"
                inputProps={{ //not show suggetions
                  autoComplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
                InputLabelProps={{
                  shrink: true,

                }}
                InputProps={{
                  readOnly: true
                }}

              />

              <Button
                size="small"
                onClick={() => {
                  setCollapse(!collapse)
                }}
                sx={{
                  ...theme.customStyle.modalbtn02, height: "35px",
                  width: "50px",
                  position: "relative",
                  top: "6px"
                }}
              >
                {collapse ? "close" : "Edit"}
              </Button>
            </Box>
            <Box sx={styles.textRow}>
              <TextField
                margin="dense"
                label="In-Active Conversation Hours"
                value={teamData.inActiveConversationHours}
                type="number"
                fullWidth
                variant="standard"
                size="small"

                id="InActiveConversationHours"
                inputProps={{ //not show suggetions
                  autoComplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                onKeyPress={(e) => {
                   const isValidKey = /^\d+$/.test(e.key); // Only allow digits
                   if (!isValidKey) {
                           e.preventDefault();
                    }
                 }}
                onChange={(e) => {
                  setTeamData({ 
                    ...teamData,
                    inActiveConversationHours: e.target.value,
                   });
                 }}
               />
            </Box>
            <Collapse in={collapse}>
              <div style={{ marginBottom: "20px" }}>
                <JSONInput
                  placeholder={
                    validJson ? JSON.parse(teamAttributesJson) : null
                  }
                  style={{
                    body: { fontSize: "14px" }, container: {
                      borderRadius: "10px"
                    }
                  }}
                  width="100%"
                  height="200px"
                  onChange={(e) => {
                    setTeamData({ ...teamData, teamAttributes: e.json });
                    if (e.error !== false) {
                      setJsonError(true)
                    } else {
                      setJsonError(false)
                    }
                  }}
                />
              </div>
            </Collapse>
            <Box sx={styles.textRow}>
                <TextField
                    margin="dense"
                    value={teamData.documentTypes}
                    label="Available Document Types to Request"
                    type="text"
                    multiline
                    rows={2} 
                    fullWidth
                    variant="standard"
                    size="small"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    id="updateSupportedDocumentTypes"
                    inputProps={{ //not show suggetions
                        maxLength: 500,
                        autoComplete: 'new-password',
                        form: {
                            autoComplete: 'off',
                        },
                    }}
                    onChange={(e) => {
                        setTeamData({ ...teamData, documentTypes: e.target.value });
                    }}
                 />
             </Box>
            <Box sx={styles.textRow}>
              <TextField
                margin="dense"
                label="Notes"
                multiline
                rows={2}
                inputProps={{
                  maxLength: 255, autoComplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
                id="updateTeamNotes"
                value={teamData.notes}
                type="text"
                fullWidth
                variant="standard"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  setTeamData({
                    ...teamData,
                    notes: e.target.value,
                  });
                }}
              />
            </Box>
            <div>
              <span>Enable Documents Upload</span>
              <Checkbox
                checked={teamData.enableDocuments}
                onChange={(e) => {
                  setTeamData({
                    ...teamData,
                    enableDocuments: e.target.checked,
                  });
                }}
              />
            </div>
            <div>
              <span>Start conversation with template</span>
              <Checkbox
                checked={teamData.isStartConvWithTemplate}
                onChange={(e) => {
                  setTeamData({
                    ...teamData,
                    isStartConvWithTemplate: e.target.checked,
                  });
                }}
              />
            </div>
            <div>
              <span>Enable Conversation Read On Seen</span>
              <Checkbox
                checked={teamData.enableConversationReadOnSeen}
                onChange={(e) => {
                  setTeamData({
                    ...teamData,
                    enableConversationReadOnSeen: e.target.checked,
                  });
                }}
              />
            </div>
            <div>
              <span>Eligible to transfer the conversations</span>
              <Checkbox
                checked={teamData.eligibleToTransferConversation}
                onChange={(e) => {
                  setTeamData({
                    ...teamData,
                    eligibleToTransferConversation: e.target.checked,
                  });
                }}
              />
            </div>
            <div>
              <span>Eligible to receive the conversation</span>
              <Checkbox
                checked={teamData.eligibleToReceiveConversation}
                onChange={(e) => {
                  setTeamData({
                    ...teamData,
                    eligibleToReceiveConversation: e.target.checked,
                  });
                }}
              />
            </div>
            <div>
              <span>Is Active</span>
              <Checkbox
                checked={teamData.isActive}
                onChange={handleChangeIsActive}
              />
            </div>
            <Box sx={(styles.textRow, styles.mrT)}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Team Category *</FormLabel>
                <RadioGroup
                  aria-label="category"
                  defaultValue="individual"
                  name="radio-buttons-group"
                  value={teamData.type}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="Department"
                    control={<Radio />}
                    label="Department"
                  />
                  <FormControlLabel
                    value="Individual"
                    control={<Radio />}
                    label="Individual"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            {userInfo.isSurveyEnabledAppSettings && <>
              <div>
                <span>Enable Survey on Conversation End</span>
                <Checkbox
                  checked={teamData.isSurveyEnabled}
                  onChange={(e) => {
                    setTeamData({
                      ...teamData,
                      isSurveyEnabled: e.target.checked,
                    });
                  }}
                />
              </div>{
                teamData.isSurveyEnabled === true &&
                <Box sx={{ ...styles.textRow, marginTop: 2.5 }}>
                  <TextField
                    size="small"
                    select
                    required
                    label="Survey Template"
                    defaultValue={surveyTemplate}
                    value={surveyTemplate}
                    fullWidth
                    id="updateTeamSurveyTemplate"
                    inputProps={{ //not show suggetions
                      autoComplete: 'new-password',
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                    onChange={(e) => {
                      setTeamData({
                        ...teamData,
                        surveyTemplateId: e.target.value,
                      });
                      setSurveyTemplate(e.target.value)
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                  >
                    {surveyTemplates.map((item, index) => {
                      return (
                        <MenuItem value={item.surveyTemplateId}>
                          {item.surveyName}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Box>}
            </>
            }
            {!isAddTeam && (
              <>
                <Box sx={styles.textRow}>
                  <TextField
                    margin="dense"
                    disabled
                    label="Created By"
                    type="text"
                    value={`${createdByName + " (" + createdDate + ")"}`}
                    fullWidth
                    variant="standard"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id="updateTeamteamcreatedByName"
                    inputProps={{ //not show suggetions
                      autoComplete: 'new-password',
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                  />
                </Box>
                <Box sx={styles.textRow}>
                  <TextField
                    margin="dense"
                    label="Modified By"
                    disabled
                    type="text"
                    value={`${lastModifiedByName + " (" + lastModifiedDate + ")"}`}
                    fullWidth
                    variant="standard"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id="updateTeamteamlastModifiedByName"
                    inputProps={{ //not show suggetions
                      autoComplete: 'new-password',
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                  />
                </Box>
              </>
            )}
            <LoadingButton
              loading={loadingBtn}
              disabled={
                jsonError === true ? true :
                  !teamData.teamName ? true :
                    !teamData.type ? true :
                      (teamData.isSurveyEnabled && !teamData.surveyTemplateId)? true
                      : false}
              sx={styles.bottomButton}
              variant="contained"
              onClick={() => {
                handleAddUpdateTeam();
              }}
            >
              {isAddTeam ? AddTeam : UpdateTeam}
            </LoadingButton>
            <Button
              sx={{ ...styles.bottomButton, marginLeft: 2 }}
              variant="contained"
              onClick={() => {
                navigate("/teams");
              }}
            >
              Cancel
            </Button>

          </div>
        </TabPanel>
        <TabPanel value="2">
            {isAddTeam ? <NoRecordsMessage /> : <TeamsListOfUsers teamId={teamId} />}
        </TabPanel>
        <TabPanel value="3">
            {isAddTeam ? <NoRecordsMessage /> : <TemplatesComp teamId={teamId} teamName={teamData.teamName} />}
        </TabPanel>
              <TabPanel value="4">
                  {isAddTeam ? <NoRecordsMessage /> : (
                      <ObjectHistoryComp
                          objectName="Teams"
                          objectKeyname={teamData}
                          numOfRows={10}
                          istab={true}
                      />
                  )}
              </TabPanel>
      </TabContext>
    </>
  );
}
let styles = {
  textRow: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 1.5,
    "& h2": {
      paddingLeft: "0",
    },
  },
  mrT: {
    marginTop: "10px",
  },
  bottomButton: {
    marginBottom: "20px",
  },
};
