//* 04-28-2023 => ONKAR =>TEXTING-1234 - created this component for upload document.
//* 05-03-2023 => SUDAM B CHAVAN =>TEXTING - 1215 - WEB CHAT: Document limit showing different limit in Error
// 08-30-2023 => ONKAR => TEXTING-1578 -REACT Upgrade 
import React from "react";
import {
  Box,
  Divider,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  CircularProgress,
  DialogTitle,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { DropzoneDialog } from "mui-file-dropzone";


function AttachDocumetsDialog(props) {
  const theme = useTheme();

  return (
    <>
      <Dialog open={props.open} onClose={props.handleClose} fullWidth>
        <DialogTitle>Attach Documents</DialogTitle>
        <Divider />
        <DialogContent>
          {props.loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "5%",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box>
              <Box sx={theme.customStyle.textRow}>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => props.setOpen(true)}
                  >
                    Add File
                  </Button>
                  <span
                    style={{
                      fontWeight: "600",
                      fontSize: "13px",
                      marginLeft: "10px",
                    }}
                  >
                    {props.docName !== "" ? props.docName : "No File Chosen"}
                  </span>
                  <DropzoneDialog
                    dialogTitle={"Maximum limit 25MB"}
                    open={props.open}
                    onSave={async (files) => {
                      props.uploadDocument(files);
                      props.setOpen(false);
                    }}
                    acceptedFiles={props.theamSettingsStrObj.acceptedFiles?.split(
                      ","
                    )}
                    showPreviews={true}
                    maxFileSize={26214400}//25MB(25*1024*1024=26214400 bytes)
                    filesLimit={1}
                    onClose={() => props.setOpen(false)}
                  />
                </div>
              </Box>

              {/* uncomment below code to see different style for file input  */}
              {/* <Button  component="label" sx={{width:"100%",border:"1px dashed #1976d2",marginTop:"10px"}}>
                       Choose File
                       <input 
                       onChange={(e)=>{
                         getBase64(e.target.files[0]);
                         setDocName(e.target.files[0].name)
                       }}
                        hidden  type="file" />
                     </Button>
                     <Box sx={{textAlign:"center",marginTop:"5px"}}>
                       <span style={{fontWeight:"600",fontSize:"13px"}}>{docName!==""?docName:"No File Chosen"}</span>
                     </Box> */}
            </Box>
          )}
        </DialogContent>

        <DialogActions>
          {!props.loading && (
            <>
              <Button onClick={() => {}} disabled={document ? false : true}>
                Submit
              </Button>
              <Button
                onClick={() => {
                  props.setDocument("");
                  props.setDocName("");
                }}
              >
                Cancel
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AttachDocumetsDialog;
