/***************
*10/31/2022 =>TEXTING-694 - send button location and icon align changes..
*11-02-2022 => Texting-732 Added condition to show doucusign pdf icon based on conversation DocuSign Account ID.
*11-02-2022 => Texting-731 Added condition to disable/enable doucusign pdf icon based on docusignCode from authContext.
*11-03-2022 => Doucusign error handling.
*04/11/2022 => Texting-742- Added size for the loader
*11-04-2022 => Texting-755 autosuggest template not showing when from second line if you enter first char @ -fixed.
*11-08-2022 => Texting-767 Auto suggest templates popup is not closing when space was given after @ symbol -fixed
*11-11/2022 TextField:766=>disabled auto suggetion
*11-11-2022 => ONKAR => Texting-797 Templates List screen is moving to right as we keep on typing -fixed.
*11-14-2022 => ONKAR => Auto suggest popper height issue. -fixed.
*11-15-2022 => ONKAR => Texting-813 When click on enter button for auto suggest templates is not showing as expected -fixed.
*11-16-2022=> Texting-802 -Pdf icon is replaced by download icon
*29-11-2022 => YOGESH=>Texting-866=> Disabled auto suggection from all textfields
*12-22-2022 => ONKAR=>background screen size changing after opening template popup fixed. 
*12-26-2022 => ONKAR=>Texti-964 If a template is selected from preview from template button, the bottom of conversation page is moving up fixed. 
*04-26-2023 => ONKAR=> Textig-1184 When auto suggestions are shown, in agent UI, the icons on the bottom are moving up - fixed. 
*04-27-2023 => SUDAM B CHAVAN => Texting-1228 Blank space is showing between the messages and assign, info buttons in missed conversation page.
*06-20-2023 => SUDAM B CHAVAN => TEXTING-1083 - webChat - Agent/Member typing preview
*06-30-2023 => ONKAR => TEXTING-1389 - Sentiment UI
*07-04-2023 => ONKAR => TEXTING-1403 - Sentiment UI enhancements.
*07-05-2023 => SUDAM B CHAVAN => TEXTING-1402 - When typing preview appears, Auto suggestion prompts are showing above the inbound messages on agent UI.
*07-12-2023 => ONKAR => TEXTING-1422 - Block Templates to be sent when there is a Irreplaceable token in it.
*07-13-2022 => SUDAM => TEXTING-1412 - Ability to add Team Name in Tokens
*07-13-2023 => ONKAR => TEXTING-1422 - Removed duplicate code.
*07-19-2023 => ONKAR => TEXTING-1438 - Disable Document Request option on Agent Chat Side
*08-08-2023 => SUDAM B CHAVAN => TEXTING-1550 - Auto suggestion message prompts are showing above the Autosuggestion Templates.
*08-30-2023 => ONKAR => TEXTING-1578 -REACT Upgrade
*09-01-2023 => SUDAM B CHAVAN =>TEXTING-1645 - Reporting - Track templates used 
*09-05-2023 => SUDAM B CHAVAN =>TEXTING-1669 - Select and updating the Template data message is still showing the Template I’d.
*09-15-2023 => SUDAM B CHAVAN =>TEXTING-1702 - Document refresh button is updating to download after reopening the conversation page only.
*09-23-2023 => SUDAM B CHAVAN => TEXTING-1729 - Option to Scheudule the message during offhours
*09-28-2023 => SUDAM B CHAVAN => TEXTING-1749 - Schedule outbound messages during off hours observations.
*10-19-2023 => ONKAR => TEXTING-1798 - Auto suggestion message prompts are disabling when click on suggestions on Non-TCPA hrs and click on do not send button on Schedule popup.
*12-11-2023 => SUDAM B CHAVAN => TEXTING-1904 0: Admin UI - Search messages observations.
**********/
import React, { Component } from "react";
import { connect } from "react-redux";
import { MsalContext } from "@azure/msal-react";
import AssignmentReturnOutlinedIcon from "@mui/icons-material/AssignmentReturnOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import VideoCameraBackOutlinedIcon from "@mui/icons-material/VideoCameraBackOutlined";
import CloseIcon from '@mui/icons-material/Close';
import moment from "moment";
import { GetTemplatesWithTokens } from "../extensions/TemplateConfigUtil";
import { dataService } from "../dataService";
import TypingPreviewImg from "../assets/dots-loading-animation.webp"
import {
  Grid,
  Paper,
  Box,
  List,
  FormControl,
  InputAdornment,
  IconButton,
  TextField,
  Tooltip,
  ClickAwayListener,
  Popper,
  Divider,
  Chip,
  ListItemButton,
  Popover,
} from "@mui/material";
import { Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import SendIcon from "@mui/icons-material/Send";
import InsertCommentIcon from "@mui/icons-material/InsertComment";
import ChatMsg from "./ChatMsg";
import {
  getMessagesForConversationId,
  sendMessage,
} from "../api/messageServices";
import { loadCompStart } from "../storage/slices/messageSlice";
import {
  Information,
  TemplateSelector,
  AssignConversation,
} from "../components";

import VideoChat from "./VideoChat";
import CircularProgress from "@mui/material/CircularProgress";
import { InvokePostServiceCall, InvokePostServiceCallWithoutToken } from "../api/serviceUtil";
import apiConfig from "../api/apiConfig";
import { getCursorXY } from "../extensions/getCursorPosition";
import SnackBar from "./SnackBar";
import { textAlign } from "@mui/system";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DownloadTwoToneIcon from '@mui/icons-material/DownloadTwoTone';
import Download from "../assets/Download.png"
import DocuSign from "./DocuSign";
import { Link } from "react-router-dom";
import { getLinkedEntityNames } from "../extensions/crmWrapper";
import { yellow } from "@mui/material/colors";
import SelectDocumentTypes from "./SelectDocumentTypes";
import ChatAutoSuggest from "./ChatAutoSuggest";
import { ConfirmationComponent } from "./ConfirmationComponent";


var dialogFullWidth = "md";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
class Messenger extends Component {
  static contextType = MsalContext;
    
  constructor(props) {
      super(props); 

    this.state = {
      userId: this.props.appContext.userInfo.userId,
      conversation: this.props.conversation,
      inputMessage: null,
      open: false,
      templateOptions: [],
      filteredTemplateOptions: [],
      templatePreviewText: null,
      showLoader: false,
      appContext: this.props.appContext,
      disableSend: false,
      openSnackBar: false,
      showInfo: false,
      showDocuSign: false,
      openAssignConv: false,
      showVideoChat: false,
      anchorEl: null,
      anchorElPopover: null,
      mentionText: "",
      selectedMention: 0,
      popperX: 0,
      popperY: 0,
      openTokenSnackbar: false,
      openMainSnackbar: false,
      snackbarMsg: "",
      codeVal: "",
      textInputHeight: 46,
      showDocumentTypeDailog:false,
      msgAutoSuggestHeight:0,
      timeOutId:0,
      disableSendBtn:false,
      templateId:"",
      showConfirmationDialog:false,
      confirmationDialogContent:"",
      messageRequest:{},
      messageScheduleDate:""
    };
    this.inputRef = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputMsgChange = this.handleInputMsgChange.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.childState = this.childState.bind(this);
    this.handleTemplateSelection = this.handleTemplateSelection.bind(this);
    this.templateSelectorClick = this.templateSelectorClick.bind(this);
    this.assignConversationClick = this.assignConversationClick.bind(this);
    this.checkTokensInTemplate = this.checkTokensInTemplate.bind(this);
    this.handleConfirmationDialog = this.handleConfirmationDialog.bind(this);
  }

  //componentDidMount will execute all the functions inside it after component mounts 
  componentDidMount() {     
    if (this.props.appContext.selectedMsgId) {
      document.getElementById(this.props.appContext.selectedMsgId).scrollIntoView({
        behavior: 'smooth'
      });
    }
    else {
      this.scrollToBottom();
    }
    this.loadTeamTemplates = this.loadTeamTemplates.bind(this);
    this.setState({ codeVal: localStorage.getItem("codeVal") })
    dataService.getData().subscribe((message) => {
      console.log('Messenger Component: ' + message.value);
      if (message.value != null) {
        if (message.value.showAlertMessage != null) {
          //On send message error enable sendmessage button and close snackbar
          this.setState({ disableSend: false, openSnackBar: false }, () => { });
        }
        //this is send from sendMessage call
        else if (message.value.showConfirmationMessage != null) {
         let localFormatedDate = moment(message.value.messageScheduleDate).local().format("LLL")
          let showMessage = message.value.showConfirmationMessage
            ? `${message.value.showConfirmationMessage}, would you like to schedule message on {0}?`
            : "This is Non-TCPA hours, would you like to schedule message on {0}?";
          let ConfirmationMsg = showMessage.replace('{0}', localFormatedDate);
          this.setState({
            disableSend: false, openSnackBar: false,
            showConfirmationDialog: true,
            confirmationDialogContent: ConfirmationMsg,
            messageRequest: message.value.messageRequest,
            messageScheduleDate: message.value.messageScheduleDate
          }, () => { });
        }
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    var conversation = nextProps.conversation;
    var inboundMessage = nextProps.inboundMessage;
    var outboundMessage = nextProps.outboundMessage;

    if (inboundMessage != null) {
      //Add inbound messages from customer to messages list
      //this.addMessageToConv(inboundMessage);
    } else if (outboundMessage != null) {
      // this.addMessageToConv(JSON.parse(outboundMessage.message));
    } else if (conversation != null) {
      //Retrieve messages for selected conversation in parent component
      this.setState({ conversation: conversation });
    }
    }
  SendPubSubNotificationForWebChat = (notificationType) => {
    let requestBody = {
      conversationid: this.state.conversation.conversationId,
      notificationType: notificationType,
    };
    InvokePostServiceCallWithoutToken(
      apiConfig.SEND_PUBSUB_NOTIFICATION_FOR_WEBCHAT,
      requestBody
    )
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          console.log("pubsub event published successfully.");
        } else {
          console.log("failed to send pubsub event :" + response.data.statusDescription);
        }
      })
      .catch((err) => {
        console.log("Failed" + err.toString());
      });
  };
     //this function will execute on onChange event of input message textfield
  handleInputMsgChange = (event) => {
    if (event.target.value === "" && this.state.templateId) {
      this.setState({
         inputMessage: event.target.value,
         templateId: "" });
    }
    else {
      this.setState({ inputMessage: event.target.value });
    }
    this.checkTokensInTemplate(event.target.value)
    //Typing Preview code block start ----------------------------------------------------
    if (this.state.conversation.channel === "Chat") {
      try {
        let timeOutId = this.state.timeOutId;
        if (timeOutId === 0) {
          this.SendPubSubNotificationForWebChat("TypingPreviewIn")
        }
        else {
          clearTimeout(timeOutId)
        }
        let localTimeOutId = setTimeout(() => {
          this.SendPubSubNotificationForWebChat("TypingPreviewOut")
          clearTimeout(localTimeOutId)
          this.setState({ timeOutId: 0 });

        }, 20000)
        this.setState({ timeOutId: localTimeOutId })
      } catch (error) {
        console.log("Typing Preview error ::", error)
      }
    }
  //Typing Preview code block end ----------------------------------------------------
    if (!event.target.value.includes("@")) {  //opens the auto suggest popper
      this.setState({ anchorEl: null });
    }
    const { x, y } = getCursorXY(event.target, event.target.selectionEnd); //get the cursor position inside textfield
    let mentionText = this.getMentionText(event.target.value) //get text value after @ symbol for autosuggest popup
    const filteredData = this.state.templateOptions.filter((item) => item.templateName.toLowerCase().includes(typeof mentionText === "string"
      ? mentionText.toLowerCase()
      : null))
    this.setState({ filteredTemplateOptions: filteredData, selectedMention: 0 });
    if (event.target.value[event.target.selectionEnd - 1] === "@") {
      this.handleTemplatePopper(event);
      this.setState({
        popperX: x - 15,
      })
      this.loadTeamTemplates();
      // if (event.target.value.length > 1) {
      //   if (event.target.value[event.target.selectionEnd - 2] === " ") {
      //     this.handleTemplatePopper(event);
      //     this.loadTeamTemplates();
      //   }
      // } else {
      //   this.handleTemplatePopper(event);
      //   this.loadTeamTemplates();
      // }
    }
    let currentWord = event.target.value.slice(0, event.target.selectionEnd);
    let n = currentWord.split(" ");
    let atVal = n[n.length - 1];
    if (atVal[atVal.length - 2] === "@" && atVal[atVal.length - 1] === "@") {
      this.setState({ anchorEl: null });
    }
    // if (!atVal.includes("@")) {
    //   this.setState({ anchorEl: null });
    // }
  };

  templateSelectorRef = React.createRef();
  templateSelectorClick = (conv) => (event) => {
    if (this.templateSelectorRef.current)
      this.templateSelectorRef.current.templateSelectorClick();
  };

  assignConversationRef = React.createRef();
  assignConversationClick = (conv) => (event) => {
    if (this.assignConversationRef.current)
      this.assignConversationRef.current.assignCoversationClick();
  };

  // used to update state from child component
  childState(state) {
    this.setState(state);
  }
  handleModalClose = () => {
    this.setState({ open: false });
  };

  handleAssignConvModalClose = () => {
    this.setState({ openAssignConv: false });
  };

  handleInfoModalClose = () => {
    this.setState({ showInfo: false });
  };
  handleDocuSignModalClose = () => {
    this.setState({ showDocuSign: false });
  };

  handleVideoModalClose = () => {
    this.setState({ showVideoChat: false });
  };
  handleDocTypeModalClose = () => {
    this.setState({ ...this.state,showDocumentTypeDailog: false });
  };
  handleDocTypeModalRes = (res) => {
    this.setState({ ...this.state, inputMessage: res, showDocumentTypeDailog: false });
  };

  sendVideoChatUrl = (url) => {
    // Zoom
    this.props.loadCompStart();
    this.setState({ disableSend: true, openSnackBar: true });
    var conversation = this.props.conversation;
      this.props.sendMessage(this.state.userId, conversation, url, this.state.appContext);
      this.setState({ inputMessage: " " });
      this.setState({ showVideoChat: false }); 
  };

  handleTemplateSelection = (template) => {
    const { templateBody, templateId } = template;
    if (!this.props.appContext.matches) { this.setState({ textInputHeight: 98 }) }
    if (templateBody.includes("##")) {
      this.handleOpenSnackbar()
    }
    this.setState({ open: false });
    this.checkTokensInTemplate(templateBody)

    this.setState({ inputMessage: templateBody, templateId: templateId });
  };

  ///Invoke API to send message to customer
  ///Add input message to conversation object
  handleSubmit(msgBody) {
    //debugger;
    var message = msgBody;
    let templateId = this.state.templateId;
    this.setState({ inputMessage: "", templateId:"" })
    this.setState({ textInputHeight: 46 })
    if (message !== null && message !== "") {
      if (message.trim().length > 0) {
        this.props.loadCompStart();
        this.state.appContext.setMsgLoadingState(true);
        //   msgLoadingState,setMsgLoadingState
        this.setState({ disableSend: true, openSnackBar: true });
        var conversation = this.props.conversation;
        this.props.sendMessage(this.state.userId, conversation, message.trim(), this.state.appContext, templateId);
        if(this.props.msgAutoSuggestions?.length>0){
          //on click of autosuggestion message chip reset/hide auto suggesttion list
          this.props.setMsgAutoSuggestions([]);
        }
      }
    }
  }

  messagesEndRef = React.createRef();

  componentDidUpdate(prevData) {
    if (this.state.disableSend && !this.props.isCompLoading) {
      this.setState({ disableSend: false, openSnackBar: false });
    }
    if (this.props.appContext.selectedMsgId && document.getElementById(this.props.appContext.selectedMsgId)) {
      document.getElementById(this.props.appContext.selectedMsgId).scrollIntoView({
        behavior: 'smooth'
      });
    }
    else {
      this.scrollToBottom();
    }

    if (prevData.conversation.conversationId !== this.state.conversation.conversationId) {
      this.setState({ inputMessage: "" })
    }
  }

  //scrollToBottom will scroll message bubble to bottom on first render and when new message received
  scrollToBottom = () => {
    if (this.messagesEndRef.current)
      this.messagesEndRef.current.scrollIntoView();
  };

  //handleClickAway will close the auto suggest popper on clicking anywhere outside the popper
  handleClickAway = () => {
    this.setState({ anchorEl: null });
  };
  handleClosePopover = () => {
    this.setState({ anchorElPopover: null });
  };
  handleConfirmationDialog = (isConfirm) => {
    this.setState({ showConfirmationDialog: false }, () => { });
    console.log(`Non-TCPA Confirmation on scheduled message is: ${isConfirm ? "Confirm" : "Cancel"}`)
    console.log(`Non-TCPA Confirmation:${this.state}`)
    if(isConfirm){
      this.setState({ disableSend: true, openSnackBar: true });
      this.props.sendMessage(this.state.userId, this.props.conversation, this.state.messageRequest?.messageBody, this.state.appContext, this.state.messageRequest?.templateId,true,this.state.messageScheduleDate);
    }
  }

  async loadTeamTemplates() {
    debugger;
    this.setState({ showLoader: true });
      var linkedEntitiesNames = [];  
    var filterTemplates = false;
    try {
        if (!!this.state.conversation.linkedEntities) {
        linkedEntitiesNames = this.state.conversation.linkedEntities;
        filterTemplates = true;
      }
      else if (this.props.appContext.crmMode == "true" &&  (this.state.conversation.conversationAttributes != null && this.state.conversation.conversationAttributes != "")) {
        let convAttributesObj = JSON.parse(this.state.conversation.conversationAttributes);
        if (convAttributesObj.hasOwnProperty("ActivityId")) {

          if (this.props.templatesData != null) {
            // Dynamically get the linked entities names from CRM ny passing activityid from conversation attributes.
            var conv = Object.assign({}, this.state.conversation);;
              linkedEntitiesNames = await getLinkedEntityNames(convAttributesObj["ActivityId"], this.props.appContext.crmProvider);              
            conv.linkedEntities = linkedEntitiesNames;
            dataService.setData({ updateConversation: conv });
            filterTemplates = true;
          }
        }
      }

      if (filterTemplates) {    //Filtering the templates based on the linked entities
        var templates = [];        
          // Iterate over the templates obtained from the userInfo call based on teams.
          this.props.templatesData.forEach((tmplt, key) => {
              var includeTemplate = false;
             if (tmplt.teamId === this.state.conversation.teamId && (this.props.appContext.userInfo.department == "" || ((tmplt.department == undefined || tmplt.department == "" || this.props.appContext.userInfo.department == tmplt.department)))) {

            if (tmplt.relatedObjects == null || tmplt.relatedObjects == "") {
              includeTemplate = true;
            }
            else {
              // Get the related Objects names from each template which are comma separeted values and check if each one of them is on the linked entities list
              var relObjs = tmplt.relatedObjects.split(",");
              for (var i = 0; i < relObjs.length; i++) {
                if (linkedEntitiesNames.includes(relObjs[i])) {
                  includeTemplate = true;
                }
                else {
                  //If any one of the related entity is not in the linked entities list, ignore the template
                  includeTemplate = false;
                  break;
                }
              }
            }

          }
          if (includeTemplate == true) {
            templates.push(tmplt);
          }
        })
        this.setState({ templateOptions: templates, filteredTemplateOptions: templates });
      }
      else {
          let filteredTemplatesListByTeam = this.props.templatesData.filter(
              (templateItem) =>
                  templateItem.teamId.toLowerCase() ===
                  this.state.conversation.teamId.toLowerCase()
          );
          this.setState({ templateOptions: filteredTemplatesListByTeam, filteredTemplateOptions: filteredTemplatesListByTeam });

      }
    }
    catch (ex) {

    }


    this.setState({ showLoader: false });
    /*var msgReq = {
      template: {
        TemplateContext: "Team",
        teamId: this.state.conversation.teamId,
        isActive: true,
      },
      isActive: true,
    };
    InvokePostServiceCall(apiConfig.GET_TEMPLATES_BY_CONTEXT, msgReq)
      .then((data) => {
        this.setState({ templateOptions: data.data.templates });
        this.setState({ showLoader: false });
      })
      .catch((error) => {
        this.openMainSnackbar(error.message);
        this.setState({ showLoader: false });
        console.log(error.message);
        //this.setState({ userExists: false, chatIsLoading: true, customerIsLoading: true });
      }); */
  };

  //get the text which user will type after @ for auto suggest popper
  getMentionText = (s) =>
    s.includes("@") && s.substr(s.lastIndexOf("@") + 1);

  handleTemplatePopper = (event) => {
    this.setState({ anchorEl: event.currentTarget, selectedMention: 0 });
  };
    loadTemplatewithTokens = (temp) => {
    this.setState({ showLoader: true });
    var conversation = this.state.conversation;

    var msgReq = {
      templateId: temp.templateId,
      conversationId: conversation.conversationId,
      contextValues: {
        Users: this.state.userId,
      },
      IsActive: true,
        };
        let tempData = "";
        if (conversation.conversationAttributes != undefined && conversation.conversationAttributes != null && conversation.conversationAttributes != "" ) {
            tempData = JSON.parse(conversation.conversationAttributes);
        }
        GetTemplatesWithTokens(temp, tempData, this.props.appContext.tokens, this.props.appContext.userInfo, conversation.teamId).then((tempBody) => {
          if (tempBody.includes("##")) {
                this.handleOpenSnackbar();
            }
            this.setState({ templatePreviewText: tempBody });
            this.checkTokensInTemplate(tempBody)
            this.setState({ showLoader: false });
            if (this.state.anchorEl) {
                this.setState({
                    inputMessage: tempBody,
                    anchorEl: null,
                    templateId: temp.templateId,
                });
            }
       })
       .catch((error) => {
               this.setState({ showLoader: false });
               this.openMainSnackbar(error.message);
               console.log(error.message);
               this.setState({
                   userExists: false,
                   chatIsLoading: true,
                   customerIsLoading: true,
               });
       });
  }; 

  // onKeyDownFunction will execute when user types anything in input message textfield
    onKeyDownFunction = (e) => {
    if (this.state.anchorEl && e.key === "ArrowDown") {
      e.preventDefault();
      if (this.state.selectedMention < this.state.filteredTemplateOptions.length - 1) {
        this.setState({ selectedMention: this.state.selectedMention + 1 });
      }
    } else if (this.state.anchorEl && e.key === "ArrowUp") {
      e.preventDefault();
      if (this.state.selectedMention > 0) {
        this.setState({ selectedMention: this.state.selectedMention - 1 });
      }
    }
    if (this.state.anchorEl && e.key === "Enter") {
      e.preventDefault();
      this.state.filteredTemplateOptions.forEach((item, index) => {
        if (index === this.state.selectedMention) {
          this.loadTemplatewithTokens(item);
        }
      });
    }
    if (this.state.anchorEl && e.key === " " && e.target.value[e.target.value.length - 1] === "@") {  //close Auto suggest templates popup when space is given after @
      this.setState({ anchorEl: null });
    }
  };
  handleOpenSnackbar = () => {
    this.setState({ openTokenSnackbar: true })
  };

  handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ openTokenSnackbar: false, openMainSnackbar: false });
  }
  openMainSnackbar = (msg) => {
    this.setState({ snackbarMsg: msg, openMainSnackbar: true });
  }
  showDocumentTypeDailog = () => {
    this.setState({ ...this.state, showDocumentTypeDailog:true});
  };

  msgAutoSuggestHeightHandler=(height)=>{  //set message auto suggest div height
    this.setState({msgAutoSuggestHeight:height})
  }
  checkTokensInTemplate(messageBody){
    let strArr=messageBody.split(" ")
    for(let i=0;i<strArr.length;i++){
      if(strArr[i].startsWith("##") &&(strArr[i].endsWith("##")  || strArr[i].endsWith("##."))){
        this.setState({disableSendBtn:true}) 
        break;
      }
      this.setState({disableSendBtn:false}) 
    }
  }
  render() {
    const { theme, chatLoading } = this.props;
    var openSnackBar = this.state.openSnackBar;
    var conversation = this.state.conversation;
    const openPoper = Boolean(this.state.anchorEl);
    const popperId = openPoper ? "simple-popper" : undefined;
    const openPopover = Boolean(this.state.anchorElPopover);
    const popoverId = openPopover ? "simple-popover" : undefined;
    if (conversation != null) var msgList = conversation.messageList;
    var isIndividual = conversation?.teamType==="Individual"
    msgList =
      this.props.context == "dialog"
        ? this.props.msgList
        : this.props.selectedConversation?.messageList;
    const showAttachementIcon = this.state.appContext?.userInfo?.teams?.filter(t => t.teamId === conversation.teamId)[0]?.enableDocuments;
    console.log(this.props.conversation,"this.props.conversation")
    return (

      <Grid>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={this.state.openTokenSnackbar}
          autoHideDuration={5000}
          onClose={this.handleCloseSnackbar}
          sx={{ top: "60px" }}
        >
          <Alert
            onClose={this.handleCloseSnackbar}
            severity={"warning"}
            sx={{ width: "100%", paddingTop: 1 }}
          >
            <span>Some of the tokens in this template cannot be replaced with actual values. Please review and update manually.</span>
          </Alert>
        </Snackbar>
        <SnackBar
          openSnackbar={this.state.openMainSnackbar}
          handleCloseSnackbar={this.handleCloseSnackbar}
          severity={"error"}
          userMessage={this.state.snackbarMsg}
        />
        <List
          style={
            this.props.context == "dialog"
              ? theme.customStyle.chatBoxStyleDialog
              :theme.customStyle.chatBoxStyle
          }
        >
          <Box className="wcu_box_padding">
            <Paper
              variant="outlined"
              style={{...theme.customStyle.convStyle,height: `calc(100vh - ${329 + this.state.msgAutoSuggestHeight }px)`}}
              sx={{ ...this.props.conversation?.isReadOnly && this.props.context !== "dialog" && { height: "calc(100vh - 210px) !important", } , ...{ pt: 1, borderWidth: 0 } }}
            >
              <div className="customScroll" style={{ overflow: "auto"}}>
                {msgList &&
                  msgList.map((msg, index) => {
                    let DateCmp = "";
                    if (index !== 0) {
                      if (
                        moment(msgList[index].messageDate).format(
                          " MMM Do YY"
                        ) !==
                        moment(msgList[index - 1].messageDate).format(
                          " MMM Do YY"
                        )
                      ) {
                        DateCmp =
                          moment(msgList[index].messages[0]?.messageDate).local().format(
                            "Do MMMM YY"
                          ) === moment(new Date()).format("Do MMMM YY")
                            ? "Today"
                            : moment(msgList[index].messages[0]?.messageDate).local().format(
                              "Do MMMM YY"
                            );
                      } else {
                        DateCmp = "";
                      }
                    }
                    return (
                        <>
                        {index === 0 &&
                                <Divider >
                                <Chip label={moment(msgList[index].messages[0]?.messageDate).local().format("Do MMMM YY")
                              === moment(new Date()).format("Do MMMM YY")
                              ? "Today"
                                    : moment(msg.messageDate).local().format("Do MMMM YY")
                            }
                              style={theme.customStyle.msgDateStyle} />
                          </Divider>
                        }
                        {DateCmp !== "" && (
                          <Divider >
                            <Chip label={DateCmp}
                              style={theme.customStyle.msgDateStyle} />
                          </Divider>

                        )}
                        <ChatMsg
                          avatar={""}
                          side={`${msg.fromObjectType === "User" ? "right" : "left"
                            }`}
                          messages={msg.messages}
                          customerName={this.props.conversation.customerName}
                          customerNameInitials={
                            this.props.conversation.customerNameInitials
                          }
                          agentNm={msg.fromIdName}
                          isReadOnly={this.props.conversation?.isReadOnly}
                          channelName={this.props.conversation.channel}
                          conversationId={this.props.conversation.conversationId}
                          openMainSnackbar={this.openMainSnackbar}
                          isDialog={this.props.context === "dialog"}
                        />
                      </>
                    );
                  })}
                <div ref={this.messagesEndRef} />
              </div>
            </Paper>
          </Box>
        </List>
        <Box sx={theme.customStyle.inputMsgTextFieldDiv}>
        <Box className="wcu_box_padding">
            {this.props.conversation?.isReadOnly === true ||
              this.props.conversation?.agentId !== this.state.userId ? (
              <></>
            ) : (
              <>
             {this.state.appContext.enableAutoSuggestion==="Y" && msgList && msgList[msgList.length-1]?.fromObjectType==="Customer"&&
             <ChatAutoSuggest msgAutoSuggestions={this.props.msgAutoSuggestions} sendMessage={this.handleSubmit} marBottom={"0px"} msgAutoSuggestHeightHandler={this.msgAutoSuggestHeightHandler} />
             } 
              <FormControl className="wcu_full_width" variant="outlined" sx={{ marginTop: "0.6rem" }}>
                    {this.props.selectedConversation?.showTypingPreview &&
                      <svg version="1.1" id="L4" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 64 64" enable-background="new 0 0 0 0" xmlSpace="preserve" style={{ height: "44px", width: "80px", position:"absolute", top:"-50px",zIndex:"1000"}}>
                        <circle fill="#001950" stroke="none" cx="6" cy="50" r="6">
                          <animate
                            attributeName="opacity"
                            dur="1s"
                            values="0;1;0"
                            repeatCount="indefinite"
                            begin="0.1" />
                        </circle>
                        <circle fill="#001950" stroke="none" cx="26" cy="50" r="6">
                          <animate
                            attributeName="opacity"
                            dur="1s"
                            values="0;1;0"
                            repeatCount="indefinite"
                            begin="0.2" />
                        </circle>
                        <circle fill="#001950" stroke="none" cx="46" cy="50" r="6">
                          <animate
                            attributeName="opacity"
                            dur="1s"
                            values="0;1;0"
                            repeatCount="indefinite"
                            begin="0.3" />
                        </circle>
                      </svg>
                    }
                {/* <InputLabel htmlFor="outlined-adornment-password">
                  Input Message
                </InputLabel> */}
                <TextField
                  id="outlined-adornment-password"
                  type="text"
                  multiline
                  minRows={3}
                  maxRows={3}
                  inputRef={this.inputRef}
                  value={this.state.inputMessage}
                  onChange={this.handleInputMsgChange}
                                    onKeyDown={this.onKeyDownFunction}
                  label="Input Message"
                  onFocus={event => {
                    event.target.setAttribute('autocomplete', 'new-password');
                  }}
                      onBlur={() => {
                        if (this.state.timeOutId > 0) {
                          //onblur event will always fired when we off focuse from textbox
                          //this block will execute only when we have typed someting & set the timeout
                          clearTimeout(this.state.timeOutId);
                          this.setState({ timeOutId: 0 });
                          this.SendPubSubNotificationForWebChat("TypingPreviewOut")
                        }
                      }}
                  inputProps={{
                    maxLength: 512, style: { fontSize: "12px" }
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
              </>
            )}
          </Box>
        <Box className="wcu_box_padding">
            <AssignConversation
              conversation={this.props.conversation}
              onAssignConvSuccess={this.props.onAssignConvSuccess}
              state={this.state}
              handleModalClose={this.handleAssignConvModalClose}
              dialogFullWidth={dialogFullWidth}
              childState={this.childState}
              ref={this.assignConversationRef}
              currentPage="Conversations"
              openMainSnackbar={this.openMainSnackbar}
            />
            <TemplateSelector
              state={this.state}
              handleModalClose={this.handleModalClose}
              handleTemplateSelection={this.handleTemplateSelection}
              dialogFullWidth={dialogFullWidth}
              childState={this.childState}
              conversation={this.props.conversation}
              ref={this.templateSelectorRef}
              handleOpenSnackbar={this.handleOpenSnackbar}
              openMainSnackbar={this.openMainSnackbar}
            />
            <Information
              state={this.state}
              handleInfoModalClose={this.handleInfoModalClose}
              dialogFullWidth={dialogFullWidth}
            />
            <DocuSign
              state={this.state}
              context={this.props.context}
              handleDocuSignModalClose={this.handleDocuSignModalClose}
              dialogFullWidth={dialogFullWidth}
              openMainSnackbar={this.openMainSnackbar}
            />
            <VideoChat
              state={this.state}
              handleVideoModalClose={this.handleVideoModalClose}
              sendVideoChatUrl={this.sendVideoChatUrl}
              dialogFullWidth={dialogFullWidth}
            />
           {this.state.showDocumentTypeDailog && <SelectDocumentTypes
                state={this.state}
                handleModalClose={this.handleDocTypeModalClose}
                handleModalResponse={this.handleDocTypeModalRes}
            ></SelectDocumentTypes>}
            <div style={{ justifyContent: 'space-between', display: "flex" }}>
              <div >
                {this.props.conversation?.isReadOnly === true ? (
                  <></>) : (
                  this.state.appContext.applicationSettings.EnableDocusign === "Y" &&
                  this.props.conversation.docuSignAccountId &&
                    <div
                      style={{ display: "inline", cursor: "pointer" }}
                      onMouseOver={(event) => {
                        if (this.props.appContext.userInfo.generateDocuSignToken == true) {
                          if (!this.props.appContext.docuSignCode) {
                            this.setState({ anchorElPopover: event.currentTarget })
                          }
                        }
                      }}
                    >
                      <IconButton
                        disabled={this.props.appContext.userInfo.generateDocuSignToken == true ? this.props.appContext.docuSignCode ? false : true : false}
                        aria-label="DocuSign"
                        title="DocuSign"
                        onClick={() => {
                          this.setState({ showDocuSign: true });
                        }}
                        edge="end"
                        className="wcu_iconButton"
                      >
                        {
                          !(this.props.appContext.userInfo.generateDocuSignToken == true ? this.props.appContext.docuSignCode ? false : true : false) ? //added new icon for download
                            <img src={Download} style={{ width: "34px", height: "21px" }} />
                            :
                            <DownloadTwoToneIcon sx={theme.customStyle.messangerIcons}
                              style={{ color: "disabled" }}
                            />

                        }
                      </IconButton>
                    </div>
                )}
                {this.props.conversation?.status === "Completed" || isIndividual || this.props.tabName === "Messages" ? (
                  <></>
                ) : (
                    <IconButton
                      aria-label="Assign Conversation"
                      onClick={this.assignConversationClick("")}
                      edge="end"
                      className="wcu_iconButton"
                      title="Assign Conversation"
                    >
                      <AssignmentReturnOutlinedIcon sx={theme.customStyle.messangerIcons} color="primary" />
                    </IconButton>
                )}
                {this.props.conversation?.isReadOnly === true ? (
                  <></>
                ) : (
                    <IconButton
                      aria-label="Insert Template"
                      onClick={this.templateSelectorClick("")}
                      edge="end"
                      className="wcu_iconButton"
                      title="Insert Template"
                    >
                      <InsertCommentIcon sx={theme.customStyle.messangerIcons} color="primary" />
                    </IconButton>
                )}
                {this.props.conversation?.isReadOnly === true ? (
                  <></>
                ) : (
                  this.props.enableVideoCall !== "N" && (
                      <IconButton
                        aria-label="Video Chat"
                        onClick={() => {
                          this.setState({ showVideoChat: true });
                        }}
                        edge="end"
                        className="wcu_iconButton"
                        title="Video Chat"
                      >
                        <VideoCameraBackOutlinedIcon sx={theme.customStyle.messangerIcons} color="primary" />
                      </IconButton>
                  )
                )}
                {this.props.conversation?.isReadOnly === true || !showAttachementIcon || this.props.conversation.channel==="Chat" ? (
                  <></>
                ) : (                  
                    <IconButton
                        aria-label="Attachment"
                      onClick={this.showDocumentTypeDailog}
                      edge="end"
                      className="wcu_iconButton"
                      title="Attachment"
                    >
                        <AttachFileIcon sx={theme.customStyle.messangerIcons} color="primary" />
                    </IconButton>
                )}

                  <IconButton
                    aria-label="info"
                    onClick={() => {
                      this.setState({ showInfo: true });
                    }}
                    edge="end"
                    className="wcu_iconButton"
                    title="Info"
                  >
                    <InfoOutlinedIcon sx={theme.customStyle.messangerIcons} color="primary" />
                  </IconButton>              
                {/* {this.props.conversation?.isConversationClosed === true &&(
                  <IconButton
                    aria-label="Close Conversation"
                    onClick={this.props.onCloseConv }
                    edge="end"
                    className="wcu_iconButton"
                    title="Close Conversation"
                  >
                    <CloseIcon sx={theme.customStyle.messangerIcons} color="primary" />
                  </IconButton>
                )} */}
              </div>
              <div>
                {this.props.conversation?.isReadOnly ? null : (
                   <InputAdornment style={{ marginRight: "15px", marginTop: "17px", fontWeight: "bold" }}>
                                    {this.state.inputMessage?this.state.inputMessage?.length : '0'}/512

                    <IconButton
                      disabled={(this.state.disableSend && this.state.inputMessage.length > 0)||this.state.disableSendBtn}
                      aria-label="toggle password visibility"
                      onClick={()=>{this.handleSubmit(this.state.inputMessage)}}
                      edge="end"
                    >
                      {openSnackBar === false ? (
                        <SendIcon sx={theme.customStyle.messangerIcons} color={this.state.disableSendBtn?"disabled":"primary"} />
                        ) : (
                        <CircularProgress size={20} sx={theme.customStyle.messangerIcons} color="primary" /> //added size for the loader
                      )}
                    </IconButton>
                  </InputAdornment>
                )}
              </div>
            </div>
          </Box>
        </Box>
        <ClickAwayListener onClickAway={this.handleClickAway}>
          <Popper
            placement="top-start"
            style={{ zIndex: 1001 }}
            id={popperId}
            open={openPoper}
            anchorEl={this.state.anchorEl}
            modifiers={[
              {
                name: "offset",
                options: {
                  offset: [this.state.popperX, this.state.popperY],
                },
              },
            ]}
          >
            <Paper elevation={3}>
              <Box className="wcu_circularprogress" sx={theme.customStyle.templatesPopper}>
                <>
                  {this.state.showLoader ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: "12px",
                        minHeight: "68px",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  ) : (
                    this.state.filteredTemplateOptions.map((item, index) => {
                      return (
                        <>
                          <div key={item.templateId}>
                            <ListItemButton
                              sx={{
                                backgroundColor:
                                  this.state.selectedMention === index
                                    ? "#e0e0e054"
                                    : "white",
                                paddingTop: "5px",
                                paddingBottom: "5px",
                              }}
                              onClick={() => {
                                this.loadTemplatewithTokens(item);
                                this.inputRef.current.focus();
                              }}
                            >
                              <span style={{
                                display: "block",
                                width: "220px",
                                wordWrap: "break-word"
                              }}>{item.templateName}</span>
                            </ListItemButton>
                            <Divider />
                          </div>
                        </>
                      );
                    })
                  )}
                </>
              </Box>
            </Paper>
          </Popper>
        </ClickAwayListener>
        <Popover
          id={popoverId}
          open={openPopover}
          anchorEl={this.state.anchorElPopover}
          onClose={this.handleClosePopover}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <div style={{ padding: "5px" }}
            onMouseLeave={() => { this.handleClosePopover() }}
          ><span onClick={() => {
            window.open(process.env.REACT_APP_DOCUSIGN_CODE_URL + "&state=" + this.state.appContext.userInfo.userId, "Ratting", "width=900 height=500 left=150,top=200 ")

          }} style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}>Click Here</span> to login.</div>
        </Popover>
        <ConfirmationComponent
          showConfirmationDialog={this.state.showConfirmationDialog}
          handleConfirmation={this.handleConfirmationDialog}
          confirmationDialogContent={this.state.confirmationDialogContent}
        ></ConfirmationComponent>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedConversation: state.conversations?.selectConversation,
    isCompLoading: state.conversations.isCompLoading,
  };
};

const mapDispatchToProps = {
  loadCompStart,
  getMessagesForConversationId,
  sendMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Messenger);