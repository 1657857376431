/*
* 29-08-2023 => PRABHAT => TEXTING - 1633 - Modification of Profile picture button layout in Agent profile CRM View.
*/

import React from "react";
import IconButton from "@mui/material/IconButton";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import DeleteIcon from "@mui/icons-material/Delete";
import { DropzoneDialog } from "mui-file-dropzone";

const AvatarFile = ({ name, label, value, onChange }) => {
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleFileChange = async (files) => {
    console.log(`${label} Uploaded..`, files);
    if (files.length > 0) {
      const file = files[0];
      const maxSize = 1 * 1024 * 1024; // 1MB in bytes
      if (file.size > maxSize) {
        console.log(`File size exceeds the maximum limit of 1MB (1,048,576 bytes).`);
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        // Converting file to base64 string and updating the logo field in the parent component
        const base64String = reader.result;
        onChange(base64String, name);
        setOpenDialog(false);
      };
    }
  };

  const handleRemoveImage = () => {
    onChange("", name); // Set the value to an empty string to remove the image
  };

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            {value ? (
                <>
                    <div>
                        <IconButton onClick={() => setOpenDialog(true)}>
                            <PhotoCameraIcon />
                        </IconButton>
                        <IconButton onClick={handleRemoveImage}>
                            <DeleteIcon />
                        </IconButton>
                    </div>
                </>
            ) : (
                <IconButton onClick={() => setOpenDialog(true)}>
                    <PhotoCameraIcon />
                </IconButton>
            )}
            <DropzoneDialog
                dialogTitle={"Maximum limit 1MB"}
                open={openDialog}
                onSave={handleFileChange}
                acceptedFiles={["image/*"]}
                showPreviews={true}
                filesLimit={1}
                maxFileSize={1 * 1024 * 1024}
                onClose={() => setOpenDialog(false)}
            />
        </div>
    );
};

export default AvatarFile;
