//// Mohit : 1/3/2023:TEXTING-963 - Diable Add button if no user selected,Remove top padding from table, Clear search box on dialog open
//// Mohit : 1/9/2023:TEXTING-963 - closed reopen issue
// 08-29-2023 => ONKAR => TEXTING-1578 -REACT Upgrade
// 12-07-2023 => ONKAR => TEXTING-1867 -0: Selected Team or User in add Team/users tab is removing if filter the table data.
import React, { useState, useEffect, useContext } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    InputAdornment
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTheme } from "@mui/material/styles";
import SearchIcon from '@mui/icons-material/Search';
import { TextField } from "@mui/material";
import CustomNoRowsOverlay from "../components/CustomNoRowsOverlay";
import { StyledDataGrid } from "./styledComponents/StyledDataGrid";

function TeamsTableDialog(props) {
    const { selectdUsers, setSelectdUsers } =props
    const theme = useTheme();
    const [userArr, setUserArr] = useState([]);
    const [searchText, setSearchText] = useState([]);

    useEffect(() => { setUserArr(props.rows);
        setSearchText('');
        setSelectdUsers([]);
    }, [props.rows]);

    function filterTable(value) {
        let val = value?.toLowerCase();
        let usArr = [];
        props.rows.forEach((item) => {
            if (
                item.userName?.toLowerCase().includes(val) ||
                item.emailId?.toLowerCase().includes(val) ||
                item.clientFacingPhoneNum?.toLowerCase().includes(val)
            ) {
                usArr.push(item);
            }
        });
        setUserArr(usArr);
    }


    return (
        <>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                fullWidth
                maxWidth={"md"}
            >
                <div>
                    <Box sx={theme.customStyle.tableRow}>    
                        <DialogTitle>{props.dialogTitle}</DialogTitle>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <TextField
                            sx={{ marginRight: "25px", marginTop: "10px" }}
                            size="small"
                            type="search"
                            variant="outlined"
                            margin="normal"
                            id="outlined-search"
                            label=""
                            value={searchText}
                            placeholder="Users Search"
                            autoComplete="off"
                            onChange={(e) => {
                            setSearchText(e.target.value)
                            if (e.target.value) {
                                    filterTable(e.target.value);
                                }
                                else {
                                    setUserArr(props.rows);
                                }

                            }}
                            InputLabelProps={{
                                shrink: false,
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                        />
                        </Box>
                </div>
                <DialogContent  sx={{ paddingTop: "0px" }}>
                    <Box sx={theme.customStyle.teamsTableHead}>
                        <StyledDataGrid
                            loading={props.loading}
                            rows={userArr}
                            columns={props.columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            disableSelectionOnClick
                            components={{
                                NoRowsOverlay: CustomNoRowsOverlay,
                            }}
                        />
                    </Box>

                </DialogContent>
                <DialogActions>
                  <LoadingButton
                  disabled= {!(selectdUsers && selectdUsers.length>0)}
                        size="small"
                        loading={props.btnLoading}
                        onClick={() => {
                            props.saveTeamMembers(props.btnText);
                        }}
                    >
                        {props.btnText}
                    </LoadingButton>
                    <Button size="small" onClick={props.handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default TeamsTableDialog;
