////11-01-2022 => updateConversationList method added to sort active conversations by latest time
////11-21-2022 => ONKAR => Texting-844 Auto reject timing update to 120 Secs
////11-23-2022 => ONKAR => Texting-854 For active conversation click on MRM button and after first inbound or outbound message, Previous messages in chat is disappearing - fixed.
////11-23-2022 => ONKAR => Texting-851 If a contact is edited and linked to other contact, then when the customer receives the inbound message after new contact is linked, the previous conversation prior to linking are disappearing from conversation page -fixed.
////12-13-2022 => MOHIT => Texting-900 Updated Logic for removeNewConvTimer  
////04-28-2022 => SUDAM B CHAVAN => TEXTING-1228 Active conversation page is showing info button only. 
////05-01-2023 => SUDAM B CHAVAN => TEXTING-1226 Documents uploaded before a webchat conversation is assigned could not be downloaded after assigning to agent
////05-02-2023 => ONKAR => Texting-1230 added updateConversationRead method to update the showNNotification value in conversation list.
////05-04-2023 => ONKAR => Texting-1243 UI for Message Read Status for Agent and Member.
////05-10-2023 => ONKAR => Texting-1252 for texting-1252 added isTabActive and updateIsTabActiveVal to update active tab status on browser
////05-19-2023 => ONKAR => Texting-1172 -  4:Border Line Auto assign conversation observations.-issues fixed.
////06-20-2023 => SUDAM B CHAVAN => TEXTING-1083-webChat - Agent / Member typing preview
////06-30-2023 => ONKAR => TEXTING-1389 - Sentiment UI(msgCountForSentiment added)
////07-24-2023 => ONKAR => TEXTING-1475 - Critical: Sentiment Optimization
////09-06-2023 => ONKAR => TEXTING-1676 - Messages in active conversation page is disappearing when missed conversation count is updated.
////09-20-2023 => ONKAR => TEXTING-1722 - 0: Conversation read api calls are made twice.
////09-22-2023 => ONKAR => TEXTING-1740 - 0: Conversation read observations.
////09-29-2023 => ONKAR => TEXTING-1751 - Agent UI - New incoming messages are not updating to read automatically when Agent conversation page screen is Active.
////10-12-2023 => ONKAR =>  TEXTING-1796 - Sentiment Re-Calculate button to calculate sentiment with latest messages.
////10-25-2023 => ONKAR =>  TEXTING-1799 - Click on pub sub reconnect button is not refreshing the conversations count on Texting UI.
////12-07-2023 => ONKAR =>  TEXTING-1902 - 0: Texting, Admin UI page is becoming blank.

import { createSlice } from "@reduxjs/toolkit";
import { UpdateConversationAsRead } from "../../api/messageServices";
export const initialState = {
    isCompLoading: true,
    hasErrors: false,
    conversations: [],
    selectConversation: {},
    newConversations: [],
    unassignedConversations: [],
    isTabActive:true,
    msgCountForSentiment:0,
    sentimentLoading:false,
};

export const conversationsSlice = createSlice({
    name: "conversations",
    initialState,
    reducers: {
        loadCompStart: (state) => {
            state.isCompLoading = true;
        },
        loadActiveConversations: (state, { payload }) => {
            if (payload?.length > 0) {
                let conversationArr = [];
                let newConversationsArr = [];
                payload.forEach((item) => {
                    if (item.preAssigneeId == null) {
                        conversationArr.push(item);
                    } else {
                        item.newConvTimer = 120;
                        item.autoRejectTimer = 125;
                        newConversationsArr.push(item);
                    }
                });
                let sortedConvArr=conversationArr.sort(function(a,b){
                    return new Date(b.recentActivityDate).getTime() - new Date(a.recentActivityDate).getTime();
                      });
                state.conversations = sortedConvArr;
                if(newConversationsArr.length>0){
                    state.newConversations = newConversationsArr;
                }

                state.isCompLoading = false;
            }
            if (payload) {
                state.isCompLoading = false;
            }
        },
        loadConversationMessages: (state, { payload }) => {
            let converrsation = null;
            if (state.conversations?.length > 0 && payload?.conversationId) {
                let conversationsState = state.conversations;
                converrsation = conversationsState.find(
                    (x) => x.conversationId === payload.conversationId
                );
            }
            if (converrsation != null) {
                converrsation.messageList = payload?.messageList;
                state.selectConversation = converrsation;
            } else if (payload?.conversationId) {
                state.selectConversation.messageList = payload?.messageList;
            }
            // state.conversations = conversationsState;
            state.isCompLoading = false;
        },
        conversationSelected: (state, { payload }) => {
            state.selectConversation = payload;
            state.isCompLoading = false;
        },
        AddMessageToConv: (state, { payload }) => {
            let conversationsState = state.conversations;
            var newMsg;            
            let converrsation = conversationsState.find(
                (x) => x.conversationId.toLowerCase() === payload.message.conversationId.toLowerCase()
            );
            if (state.selectConversation != null && state.selectConversation.conversationId?.toLowerCase() == payload.message.conversationId?.toLowerCase() && payload.message.showNotification == true) {
                converrsation.isUnReadConv = true;
            }
            if (converrsation?.messageList != null) {
                var msgList = [...converrsation?.messageList];
                let msg = msgList?.pop();
                if (msg != null && msg.fromId === payload.message.fromId && msg.messages.length > 0 && msg.messages[0].messageType == payload.message.messageType) {
                    newMsg = msg;
                }

                if (newMsg == null) {
                    newMsg = {
                        messageDate: payload.message.messageDate,
                        fromId: payload.message.fromId,
                        fromPhoneNumber: payload.message.fromPhoneNumber,
                        fromObjectType: payload.message.fromObjectType,
                        messages: [],
                    };
                    converrsation.messageList = [...converrsation?.messageList, newMsg];
                }
                if (state.selectConversation.conversationId != payload.message.conversationId) {
                    newMsg.isNewMsg = true;
                }
                let tempConv={...payload.message}//update showNotification to true when new message sent.
                tempConv.showNotification=true;
                newMsg.messages = [...newMsg.messages, tempConv];

                //var message = {
                //    messageBody: payload.message.messageBody,
                //    messageDate: payload.message.messageDate,
                //};

                /*  state.conversations = state.conversations.map((c, index) => {
                                  if (c.conversationId === converrsation.conversationId)
                                      return converrsation;
                              }); */
                if (payload.message.messageType === "INBOUND") {
                    console.log("converrsation.msgCountForSentiment Before Update: " + converrsation.msgCountForSentiment);
                    converrsation.msgCountForSentiment = converrsation.msgCountForSentiment + 1;
                    console.log("converrsation.msgCountForSentiment After Update: " + converrsation.msgCountForSentiment);
                }
                state.conversations = conversationsState;
                if (
                    state.selectConversation.conversationId === converrsation.conversationId
                ) {
                    state.selectConversation = converrsation;
                }
                state.isCompLoading = false;
            }
        },
        updateMessageToConv: (state, { payload }) => {
            let conversationsState = state.conversations;
            let converrsation = conversationsState.find(
                (x) => x.conversationId === payload.message.conversationId
            );
            if (converrsation?.messageList && converrsation?.messageList.length > 0) {
                var msgList = [...converrsation?.messageList];
                // if (converrsation?.channel === 'Chat') { 
                let msgTempList = msgList.filter(m => m.fromId === payload.message.fromId);
                if (msgTempList?.length > 0) {
                    for (let msg of msgTempList) { // loop through all the elements which matches with sender Id(fromId)
                        let msgIndex = msg.messages.findIndex(x => x.messageId === payload.message.messageId);
                        if (msgIndex > -1) { //if index found the we are replacing the socket object into msg list 
                            msg.messages.splice(msgIndex, 1, payload.message);
                            state.conversations = conversationsState;
                            if (state.selectConversation.conversationId === converrsation.conversationId) {
                                state.selectConversation = converrsation;
                            }
                            break; //if message found terminate the loop.
                        }
                    }
                }
                // }
                // else {
                //     for (var i = 0; i < 2; i++) {
                //         let msg = msgList?.pop();
                //         if (msg != null && msg.fromId === payload.message.fromId) {
                //             let msgIndex = msg.messages.findIndex(x => x.messageId == payload.message.messageId);
                //             if (msgIndex > -1) {
                //                 msg.messages.splice(msgIndex, 1, payload.message);
                //                 state.conversations = conversationsState;
                //                 if (state.selectConversation.conversationId === converrsation.conversationId) {
                //                     state.selectConversation = converrsation;
                //                 }
                //             }
                //             break;
                //         }
                //     }
                // }
            }
            state.isCompLoading = false;
        },
        removeConversation: (state, { payload }) => {
            let conversationsState = state.conversations;
            let index = conversationsState.findIndex(
                (x) => x.conversationId === payload
            );
            if (index > -1) {
                conversationsState.splice(index, 1);
                state.conversations = conversationsState;

                // Check if the current selected conversation's ID matches the provided payload
                if(state.selectConversation.conversationId===payload){
                    // If there is a match, reset the selected conversation to an empty object
                    state.selectConversation = {};
                }
            }
            state.isCompLoading = false;
        },
        addConversationToList: (state, { payload }) => {
            //Adding the INCOMING NEW Conevrsation when user Accepts/reject
            let conversationsState = state.conversations;
            let index = conversationsState.findIndex(
                (x) => x.conversationId === payload.conversationId
            );
            if (index <= -1) {
                conversationsState.unshift(payload);
                // state.selectConversation = {};
                state.conversations = conversationsState;
                state.isCompLoading = false;
            }
        },
        removeNewConversation: (state, { payload }) => {
            //Removing the INCOMING NEW Conevrsation when user Accepts/reject
            let newConversationState = state.newConversations;
            let index = newConversationState.findIndex(
                (x) => x.conversationId === payload
            );
            if (index > -1) {
                newConversationState.splice(index, 1);
                state.newConversations = newConversationState;
            }
        },
        addNewConversationToList: (state, { payload }) => {
            let newConversationState = state.newConversations;
            let index = newConversationState.findIndex(
                (x) => x.conversationId === payload.conversationId
            );
            if (index <= -1) {
                newConversationState.unshift(payload);
                state.newConversations = newConversationState;
            }
        },

        loadUnassignedConversations: (state, { payload }) => {
            if (payload?.length > 0) {
                let unassignedConversationArr = [];

                payload.forEach((item) => {
                    item.isReadOnly = true;
                    unassignedConversationArr.push(item);
                });
                state.unassignedConversations = unassignedConversationArr;
                state.isCompLoading = false;
            }
            if (payload) {
                state.isCompLoading = false;
            }
        },
        addUnassignedConversationToList: (state, { payload }) => {
            let unassignedConversationState = state.unassignedConversations;
            let index = unassignedConversationState.findIndex(
                (x) => x.conversationId === payload.conversationId
            );
            if (index <= -1) {
                unassignedConversationState.unshift(payload);
                state.unassignedConversations = unassignedConversationState;
            }
        },
        removeUnassignedConversation: (state, { payload }) => {
            let unassignedConversationState = state.unassignedConversations;
            let index = unassignedConversationState.findIndex(
                (x) => x.conversationId === payload
            );
            if (index > -1) {
                unassignedConversationState.splice(index, 1);
                state.unassignedConversations = unassignedConversationState;
                // state.selectConversation = {};
            }
            state.isCompLoading = false;
        },
        removeNewConvTimer: (state, { payload }) => {
            let newConvArr = state.newConversations;
            let tempArr = [];
            newConvArr.forEach((data, index) => {
                if(!data.stopTimer){
                if (data.autoRejectTimer <= 0) {
                    data.autoRejectTimer = 0;
                    console.log(data.autoRejectTimer,"data.autoRejectTimer");
                } else {
                    let autoRejectTimer = data.autoRejectTimer - 1;
                    data.autoRejectTimer = autoRejectTimer;
                    let newConvTimer = data.newConvTimer <= 0 ? 0 : data.newConvTimer - 1;
                    data.newConvTimer = newConvTimer;
                    tempArr.push(data);
                }
              }

            });
            state.newConversations = tempArr;
        },
        stopNewConvTimer:(state,{payload})=>{// this will stop new incoming conversation request timer on click of accept or reject button
            let newConvArr = state.newConversations;
            let index = newConvArr.findIndex(
                (x) => x.conversationId === payload
            );
            if (index > -1) {
                newConvArr[index].stopTimer = true;
            }
        },
        updateConversationList: (state, { payload }) => {           
            let conversationsState = state.conversations;
            let index = conversationsState.findIndex(
                (x) => x.conversationId === payload
            );
            if (index > -1) {
                conversationsState[index].recentActivityDate = new Date();
            }

            let sortedConvArr = conversationsState.sort(function (a, b) {
                return new Date(b.recentActivityDate).getTime() - new Date(a.recentActivityDate).getTime();
            });
            state.conversations = sortedConvArr;

        },
        // Update the conversation in the store
        upsertConversationtoList: (state, { payload }) => {
            let conversationsState = state.conversations;
            let index = conversationsState.findIndex(
                (x) => x.conversationId.toLowerCase() === payload.conversationId.toLowerCase()
            );
            if (index > -1) {
                payload.messageList = conversationsState[index].messageList;
                conversationsState[index] = payload;
            }
            else { // If no conversation found, add as new record in the store
                conversationsState.push(payload);
            }

            state.conversations = conversationsState;           
            state.isCompLoading = false;



        },
        updateCloseWebChatConversation: (state, { payload }) => {
            let conversation = null;
            let conversationsState = state.conversations;
            if (conversationsState?.length > 0 && payload) {
                conversation = conversationsState.find(
                    (x) => x.conversationId === payload
                );
            }
            if (conversation) {
                conversation.isReadOnly = true;
                conversation.status = "Completed";
                conversation.isConversationClosed =true;
                state.conversations = conversationsState;
                state.selectConversation = conversation
            }
        },
        updateConversationAsReadApiCall:(state,{payload})=>{// calling api when new inbound message is received .
            if(state.selectConversation.conversationId?.toLowerCase() === payload?.toLowerCase() && state.isTabActive){
                UpdateConversationAsRead(payload)
            }

        },
        updateConversationRead:(state,{payload})=>{ //added this method to update the showNNotification value in conversation list.(to mark conversation as read.)
            let conversationsState = state.conversations;
            let index = conversationsState.findIndex(   //find the index of payload conversation from conversation list.
                (x) => x.conversationId === payload.conversationId
            );
            let showNotification=payload.showNotification;
            if (index > -1 ) {
                conversationsState[index].showNotification = showNotification;
                if((state.selectConversation.conversationId?.toLowerCase() === payload.conversationId?.toLowerCase() && state.isTabActive) || showNotification===false){
                    //check selected conv and payload conv is same or if selected conversation is not available then check value of showNotificaion === false and call update conv as read api.
                    conversationsState[index].showNotification = false;
                    UpdateConversationAsRead(payload.conversationId)// api call to update conversation as read.
                }
            }
            state.conversations = conversationsState;
        },

        updateMessageRead:(state,{payload})=>{
            //this will update all the messages  for the received conversation id as seen (will update show notification as false)
            let conversationsState = state.conversations;
            let index = conversationsState.findIndex(
                (x) => x.conversationId === payload.conversationId
            );
            if (index > -1) {
                conversationsState[index].messageList.forEach((msgItem)=>{
                    msgItem.messages.forEach((msg)=>{
                        msg.showNotification=false;
                    })
                })

                state.conversations = conversationsState;
                if(state.selectConversation.conversationId===payload.conversationId){
                    state.selectConversation= conversationsState[index]
                }  
        }
       },
       updateIsTabActiveVal:(state,{payload})=>{
        state.isTabActive = payload;
       },
        toggleTypingPreview: (state, { payload }) => {
            let conversationsState = state.conversations;
            let index = conversationsState.findIndex(
                (x) => x.conversationId === payload.conversationId
            );
            if (index > -1) {
                state.conversations[index].showTypingPreview = payload?.notificationType === "TypingPreviewIn";
                if (state.selectConversation.conversationId === payload.conversationId) {
                    state.selectConversation = state.conversations[index]
                }
            }
        },
        updateMsgCountForSentiment:(state,{payload})=>{
            let conversationsState = state.conversations;
            let index = conversationsState.findIndex(
                (x) => x.conversationId === payload.conversationId
            );
            if (index > -1) {
                 state.conversations[index].msgCountForSentiment = 0;
            }
        },
        updateOverallSentiment:(state,{payload})=>{
            let conversationsState = state.conversations;
            let index = conversationsState.findIndex(
                (x) => x.conversationId === payload.conversationId
            );
            if (index > -1) {
                state.conversations[index].overallSentiment = payload?.overallSentiment;
                if (state.selectConversation.conversationId === payload.conversationId) {
                    state.selectConversation = state.conversations[index]
                }
            }

        },
        updateSentimentLoading:(state,{payload})=>{// funtion to update sentiment loading icon state.
            state.sentimentLoading=payload
        },
        resetConversationList:(state,{payload})=>{
            //reset converstaion and unassigned conversation list.
            state.conversations=[]
            state.unassignedConversations=[]
        }
       

    },
});

export const conversationsSelector = (state) => state.conversations;
export const {
    loadCompStart,
    loadActiveConversations,
    loadConversationMessages,
    conversationSelected,
    AddMessageToConv,
    updateMessageToConv,
    removeConversation,
    addConversationToList,
    addNewConversationToList,
    removeNewConversation,
    loadUnassignedConversations,
    addUnassignedConversationToList,
    removeUnassignedConversation,
    removeNewConvTimer,
    newConvTimer, updateConversationList, upsertConversationtoList,
    updateCloseWebChatConversation,
    updateConversationRead,
    updateMessageRead,
    updateConversationAsReadApiCall,
    updateIsTabActiveVal,
    stopNewConvTimer,
    toggleTypingPreview,
    updateMsgCountForSentiment,
    updateOverallSentiment,
    updateSentimentLoading,
    resetConversationList
} = conversationsSlice.actions;
export default conversationsSlice.reducer;
