////05-04-2023 => ONKAR => Texting-1243 UI for Message Read Status for Agent and Member.
////06-27-2023 => ONKAR => TEXTING-1372 Bot avatar should persist for bot messages after member connected with agent.
////06-30-2023 => ONKAR => TEXTING-1370 Show information message to member when agent is assigned to webchat
////07-26-2023 => ONKAR => TEXTING-1463 Animated pic for the bot.
////07-26-2023 => PRABHAT => TEXTING-1445 the agentmsgsentTimeTxtColor and membermsgsentTimeTxtColor configs colors are updating in webchat conv.
////07-27-2023 => ONKAR =>TEXTING-1373 - Client Requirement: Agent image should be displayed instead of company logo based on config.
////08-01-2023 => ONKAR =>TEXTING-1502 - Web chat name and logo logic changes.
////08-04-2023 => ONKAR =>TEXTING-1494 - Webchat ADA testing Issues.
//08-07-2023 => ONKAR => Texting-1543 Refactor webChat UI with new JSON Response.
////08-12-2023 => ONKAR => Texting-1569 Issues in conversation history of webchat conversations in Member UI.
////08-22-2023 => SUDAM B CHAVAN => TEXTING - 1600 - Conversation assignment / reassignment
// 08-30-2023 => ONKAR => TEXTING-1578 -REACT Upgrade
//08-31-2023 => ONKAR => TEXTING-1576 - Webchat: Ability for member to change font size.
//09-04-2023 => ONKAR => TEXTING-1576 - Webchat: Ability for member to change font size.
////11-10-2023 => SUDAM B CHAVAN => TEXTING-1854 - 0: For authenticated conversation, agent image is showing for agent messages when show agent image config is disabled.
////11-30-2023 => SUDAM B CHAVAN => Handle Http text in message body
import React, { useContext } from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import {
    Typography,
    Avatar,
    Grid,
    CircularProgress,
    IconButton,
    Box,
} from "@mui/material";
import moment from "moment";
import CheckIcon from "@mui/icons-material/Check";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import DownloadIcon from "@mui/icons-material/Download";
import { useTheme } from "@mui/material/styles";
import FileSaver from "file-saver";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import LayersIcon from "@mui/icons-material/Layers";
import RefreshIcon from "@mui/icons-material/Refresh";
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import Linkify from 'react-linkify';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ChatBot from "./assets/ChatBot.jpg"
import WebChatInfoCard from "./WebChatInfoCard";


// Defining a functional component called ChatMessage which takes props as parameter
const ChatMessage = (props) => {
  // Destructuring props into smaller variables
    const {
        message,
        side,
        GridContainerProps,
        GridItemProps,
        AvatarProps,
        getTypographyProps,
        customerName,
        customerNameInitials,
        agentNm,
        showName,
        agentImage,
        textSize,
        settings:theamSettingsStrObj
    } = props;



    // var avbackgroundColor = "#E04B2B";
    // var avatar =
    //   "https://1000logos.net/wp-content/uploads/2017/07/Color-T-Mobile-Logo.png";
    // var agentNameTextColor = "#6191C4";
    // var agentmsgTxtColor = "";
    // var agentmsgsentTimeTxtColor = "#6191c6";
    // var agentmsgbgColor = "#E9E9E9";
    // var memberNameTextColor = "#FFFFFF";
    // var membermsgTxtColor = "#FFFFFF";
    // var membermsgsentTimeTxtColor = "#FFFFFF";
    // var membermsgbgColor = "#001950";

    var avbackgroundColor = theamSettingsStrObj.avbackgroundColor;
    var avatar = (agentImage && theamSettingsStrObj.showAgentImage === "Y") ? agentImage : (theamSettingsStrObj?.agentLogo?theamSettingsStrObj.agentLogo:theamSettingsStrObj.companyLogo);
    var agentNameTextColor = theamSettingsStrObj.agentNameTextColor;
    var agentmsgTxtColor = theamSettingsStrObj.agentmsgTxtColor;
    var agentmsgsentTimeTxtColor = theamSettingsStrObj.agentmsgsentTimeTxtColor;
    var agentmsgbgColor = theamSettingsStrObj.agentmsgbgColor;
    var memberNameTextColor = theamSettingsStrObj.memberNameTextColor;
    var membermsgTxtColor = theamSettingsStrObj.membermsgTxtColor;
    var membermsgsentTimeTxtColor = theamSettingsStrObj.membermsgsentTimeTxtColor;
    var membermsgbgColor = theamSettingsStrObj.membermsgbgColor;

  // Initializing a variable called index
    var index = 0;

  // Accessing theme styles using the useStyles function
    const theme = useTheme();

  // Getting agent name and customer name array based on props passed
  const agentName =
    theamSettingsStrObj.agentName != "" ? theamSettingsStrObj.agentName : "";
    let customerNameArr =
        customerName != null && !!customerName && customerName !== ""
            ? customerName.split(" ")
            : "";

  // Getting typography props based on message body
    const TypographyProps = getTypographyProps(message.body, 0, props);

  // Rendering the chat message
    return (
        <>
            <Grid
                container
                justify={side === "right" ? "flex-end" : "flex-start"}
                {...GridContainerProps}
                sx={{ flexWrap: side === "left" ? "nowrap" : "wrap",...theme.customStyle.msgContainer}}
            >
                {side === "left" &&
                    (showName ? (
                        <Grid item sx={{ width: 40, pl: 0.5 }} {...GridItemProps}>
                        {props.message.type !== "BOTMSG" && props.message.type !== "INFO" &&<>
                            <Avatar
                                src={avatar?avatar:""}
                                sx={{ marginRight: "4px", bgcolor: "#034f84",marginTop:showName&&"10px",
                                ...theme.customStyle.msgAvatar
                            }}
                                {...AvatarProps}
                            />
                            </>}
                            {props.message.type==="BOTMSG" && 
                             <Avatar
                             sx={{ marginRight: "4px",marginTop:showName&&"10px",
                                 ...theme.customStyle.msgAvatar
                         }}
                             {...AvatarProps}
                             src={theamSettingsStrObj?.chatBotLogo ? theamSettingsStrObj.chatBotLogo : ChatBot}
                            ></Avatar>
                            }
                        </Grid>
                    ) : (
                        <Grid>
                            <Avatar
                                sx={{
                                    bgcolor: "#034f84",
                                    visibility: "hidden",
                                }}
                            ></Avatar>
                        </Grid>
                    ))}
                <Grid item xs={12} sx={{ pr: 0.5 }}>
                    <>
                        {message.type === "INFO" 
                        && (message.subType === "ASSIGNED" 
                            || message.subType === "CONV_ENDED" )? (
                                <WebChatInfoCard
                                    cardType={message.subType}
                                    message={message.body}
                                    settings={theamSettingsStrObj}
                                    textSize={textSize}
                                ></WebChatInfoCard>
                        ):
                            message.type === "INFO" && 
                            (
                                <div
                                    style={theme.customStyle.transferHistoryDiv}
                                > {/* Old Design below */}
                                    <div style={{...theme.customStyle.transferHistory,paddingBottom: "0px",
                                     ...textSize==="Medium"
                                     ?theme.customStyle.webChatMd12
                                     :textSize==="Large"
                                     ?theme.customStyle.webChatLg12
                                     :theme.customStyle.webChatSm12
                                }}>
                                        {message.body}
                                    </div>
                                    <div><span style={{ color: "#202020" ,
                                  ...textSize==="Medium"
                                  ?theme.customStyle.webChatMd12
                                  :textSize==="Large"
                                  ?theme.customStyle.webChatLg12
                                  :theme.customStyle.webChatSm12
                                  }}>{moment(message.createdDate).format("lll")}</span></div>
                                </div>)
                        }
                       {message.type !== "INFO" && <div
                            key={index}
                            style={{
                                ...side === "left" && theme.customStyle.msgLeftRow,
                                ...side === "right" && theme.customStyle.msgRightRow}}
                        >
                            <Typography
                                align={side}
                                {...TypographyProps}
                                sx={{
                                    ...theme.customStyle.msgBody,
                                    ...side === "left"&&theme.customStyle.msgLeft,
                                    ...side === "right"&&theme.customStyle.msgRight,
                                    ...side === "left" && index === 0 && theme.customStyle.msgLeftFirst,
                                    ...side === "right" && index === 0 && theme.customStyle.msgRightFirst,
                                    marginLeft: side === "right" ? "50px" : "0px",
                                    marginRight: side === "left" ? "50px" : "0px",
                                    paddingTop: "10px !important",
                                    paddingBottom: "10px !important",
                                    marginBottom: "0px !important",
                                    marginTop:showName&&"10px",
                                    backgroundColor:
                                        side === "left" ? agentmsgbgColor : membermsgbgColor,
                                    color: side === "left" ? agentmsgTxtColor : membermsgTxtColor,
                                }}
                            >
                                <Typography
                                    sx={{
                                        ...side === "left"&&theme.customStyle.msgHeaderLeft,
                                        ...side === "right"&&theme.customStyle.msgHeaderRight,
                                        paddingBottom: "0px !important",
                                        color:
                                            side === "left"
                                                ? agentNameTextColor
                                                : memberNameTextColor,
                                    }}
                                >
                                    {showName && (//// show chatbot name if bot is active and agent name if agent is active.
                                        <span 
                                        style={{ 
                                        ...textSize==="Medium"
                                        ?theme.customStyle.webChatMd12
                                        :textSize==="Large"
                                        ?theme.customStyle.webChatLg12
                                        :theme.customStyle.webChatSm12}}
                                        >
                                            {side === "right" ? customerName 
                                            : props.message.type==="BOTMSG"?theamSettingsStrObj.chatBotName:agentName}
                                            </span>
                                    )}
                                </Typography>
                                <Typography sx={{
                                       ...textSize==="Medium"
                                       ?theme.customStyle.webChatMd12
                                       :textSize==="Large"
                                       ?theme.customStyle.webChatLg12
                                       :theme.customStyle.webChatSm12
                                    }} align="left">
                                <Linkify
                                   componentDecorator={(
                                     decoratedHref,
                                     decoratedText,
                                     key
                                   ) => (
                                     <a target="_blank" style={{color:"#0065d1"}} href={decoratedHref} key={key}>
                                       {decoratedText}
                                     </a>
                                   )}
                                 >
                                   <div dangerouslySetInnerHTML={{ __html: message.body }}></div>
                                </Linkify>
                                </Typography>
                                <Typography
                                align={"end"}
                                sx={{ marginBottom: "-6px",
                                    marginTop: "-4px"}}
                            >
                                <span
                                    style={{
                                        color: side === "left" ? agentmsgsentTimeTxtColor : membermsgsentTimeTxtColor,
                                        ...textSize==="Medium"
                                        ?theme.customStyle.webChatMd12
                                        :textSize==="Large"
                                        ?theme.customStyle.webChatLg12
                                        :theme.customStyle.webChatSm12
                                    }}
                                >
                                    {moment(message.createdDate).format("LT")}
                                </span>

                                    {side === "right" && (message.status === "delivered"||  message.showNotification===true) && (
                                        <span
                                            style={{ marginLeft: "5px", color: membermsgsentTimeTxtColor }}
                                            title="Message delivered."
                                        >
                                            <CheckIcon fontSize="18px" />
                                        </span>
                                    )}
                                    {side === "right" &&(message.status === "seen"|| message.showNotification===false) && (
                                        <span style={{ marginLeft: "5px", color: membermsgsentTimeTxtColor }} title={"Seen"}>
                                            <DoneAllIcon fontSize="18px" />
                                        </span>
                                    )}

                            </Typography>
                               
                            </Typography>
                        </div>}
                    </>
                </Grid>
                {side === "right" && <Grid item xs={1} {...GridItemProps}></Grid>}
            </Grid>
        </>
    );
};

// Defining propTypes and defaultProps for the component
ChatMessage.propTypes = {
    avatar: PropTypes.string,
    message: PropTypes.arrayOf(PropTypes.string),
    side: PropTypes.oneOf(["left", "right"]),
    GridContainerProps: PropTypes.shape({}),
    GridItemProps: PropTypes.shape({}),
    AvatarProps: PropTypes.shape({}),
    getTypographyProps: PropTypes.func,
};
ChatMessage.defaultProps = {
    avatar: "",
    message: [],
    side: "left",
    GridContainerProps: {},
    GridItemProps: {},
    AvatarProps: {},
    getTypographyProps: () => ({}),
};
export default ChatMessage;
