////08-04-2023 => ONKAR => TEXTING-1514 - Texting-1514 Continued web messaging for authenticated customers - Web - UI
////08-07-2023 => ONKAR => TEXTING-1514 - Texting-1514 Continued web messaging for authenticated customers - Web - UI
////08-12-2023 => ONKAR => TEXTING-1567 - Issues in conversation history of webchat conversations in Agent UI
////08-22-2023 => SUDAM B CHAVAN => TEXTING - 1600 - Conversation assignment / reassignment
// 08-30-2023 => ONKAR => TEXTING-1578 -REACT Upgrade
////08-30-2023 => SUDAM B CHAVAN => TEXTING-1644 - Authenticated Conversations history issue
////08-31-2023 => SUDAM B CHAVAN => TEXTING - 1634 - Standardize overall Webchat look and feel
////09-08-2023 => ONKAR => TEXTING - 1567 - Issues in conversation history of webchat conversations in Agent UI.
////09-08-2023 => ONKAR => TEXTING - 1686 - Conversation History Enhancements on Agent View.
////09-13-2023 => ONKAR => TEXTING - 1567 - Document Download issue fixed.
////09-20-2023 => ONKAR =>TEXTING-1720 - View history on start conversation UI observations.
////09-26-2023 => SUDAM B CHAVAN => TEXTING-1750 - Message Status Icon Changes
////11-29-2023 => ONKAR => Texting-1865 - Scheduled, Cancelled messages are showing as delivered in New conversation history view.
import React, { useEffect, useState, useRef } from "react";
import { InvokePostServiceCall } from "../api/serviceUtil";
import apiConfig from "../api/apiConfig";
import { Box, Avatar, Paper, Divider, Chip, Grid, Typography,IconButton, CircularProgress } from "@mui/material";
import moment from "moment";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import cx from "clsx";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CheckIcon from "@mui/icons-material/Check";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import useFileDownload from "../customHooks/useFileDownload";
import DownloadIcon from '@mui/icons-material/Download';
import ChatInfoMsg from "./ChatInfoMsg";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import RefreshIcon from '@mui/icons-material/Refresh';
import SnackBar from "./SnackBar"
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';






function ConversationHistory(props) {
  const { conversationDetails, GridContainerProps, getTypographyProps } = props;
  const theme = useTheme();
  const [messageList, setMessageList] = useState([]);
  const messagesEndRef=useRef();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [loading, setLoading] = useState(true);
  const [bottom, setBottom] = useState(false);
  const [showRefreshbtn, setShowRefreshBtn] = useState(false);
  const {
    downloadFile,
    downloading,
    setDownloading,
    fileDownloadError,
    setFileDownloadError} = useFileDownload()

  async function getMessages() {
    // get messages history
    try {
    let requestBody = conversationDetails.customerPhoneNumber
      ? {
          phoneNum: conversationDetails.customerPhoneNumber,
        }
      : conversationDetails?.authenticationStatus === "Authenticated"
      ? {
          externalID: conversationDetails?.authId,
        }
      : {
          email: conversationDetails.email,
        };
   
      let response = await InvokePostServiceCall(
        apiConfig.GET_ALL_CONVERSATIONS_MESSAGES,
        requestBody
      );
      if (response) {
        setMessageList(response.data.messages);
        setLoading(false);
      }
    } catch (error) {
        setSnackbarMsg(error.message);
        setOpenSnackbar(true);
        setLoading(false);
        console.log(error, "error data");
    }
  }
  useEffect(() => {
    getMessages();
      if(window.scrollY === 0){//check scroll bar present or not
        setBottom(true)
      }
      setTimeout(() => {
        setShowRefreshBtn(true);
    }, 3000);
  }, []);
 
  const scrollToBottom = () => {
    if (messagesEndRef.current)
      messagesEndRef.current.scrollIntoView();
  };

  useEffect(()=>{
    if(fileDownloadError){
      setFileDownloadError("")
    }
  },[fileDownloadError])

  useEffect(()=>{
    if(loading===false){
    scrollToBottom();
    }
  },[loading])

  const handleScroll = (e) => {//check if scroll is at bottom or not
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    setBottom(bottom)
  };

  const GetDocumentStatus = async (id) => {
    // get document latest status from server and update on ui.
    try {
      setDownloading({
        isRefreshStatus: true,
        id: id,
      });
      var req = {
        documentStoreId: id,
      };
      let response = await InvokePostServiceCall(
        apiConfig.GET_DOCUMENT_STATUS,
        req
      );
      if (response) {
        if (response.data.status === "FAILED") {
          setOpenSnackbar(true);
          setSnackbarMsg(response.data.statusDescription);
        }
        getMessages();
      }
    } catch (error) {
      console.log(error.message);
      setOpenSnackbar(true);
      setSnackbarMsg(error.message);
    } finally {
      setDownloading({
        isRefreshStatus: false,
      });
    }
  };
const handleCloseSnackbar = (event, reason) => {
  if (reason === "clickaway") {
      return;
  }
  setOpenSnackbar(false);
};
  return (
    <>
     <SnackBar
      openSnackbar={openSnackbar}
      handleCloseSnackbar={handleCloseSnackbar}
      severity={"error"}
      userMessage={snackbarMsg}
      />
      <Box className="wcu_box_padding">
        <Paper
          variant="outlined"
          sx={{
            pt: 1,
            borderWidth: 0,
            height: props.isFromCrm?"calc(100vh - 255px) !important":"calc(100vh - 210px) !important"
          }}
        >
        {loading ?<div style={{display:"flex",justifyContent:'center',marginTop:"10%"}}>
        <CircularProgress/>
        </div>
         : <div className="customScroll" style={{ overflow: "auto",  height: props.isFromCrm?"calc(100vh - 255px)":"calc(100vh - 210px)" }}
         onScroll={handleScroll}
         >
            {!loading &&
              messageList.length > 0 &&
              messageList.map((msg, index) => {
                let DateCmp = "";
                if (index !== 0) {
                  if (
                    moment(msg.createdDate).format(" MMM Do YY") !==
                    moment(messageList[index-1].createdDate).format(" MMM Do YY")
                  ) {
                    DateCmp =
                      moment(msg.createdDate).local().format("Do MMMM YY") ===
                      moment(new Date()).format("Do MMMM YY")
                        ? "Today"
                        : moment(msg.createdDate).local().format("Do MMMM YY");
                  } else {
                    DateCmp = "";
                  }
                }
                let side = msg.isAgentMessage ? "right" : "left";
                let showName = true;
                if (index > 0) {
                    if (msg.isAgentMessage !== messageList[index - 1].isAgentMessage) {
                        showName = true;
                    } else {
                        showName = false;
                    }
                }
                const TypographyProps = getTypographyProps(msg.body, 0, props);
                let customerNameInitials = "";
                if (msg.agentName && msg.agentName !="Unknown") {
                  let nameArray = msg.agentName?.split(" ");
                  if (nameArray.length > 0) customerNameInitials = nameArray[0].charAt(0);
                  if (nameArray.length > 1)
                  customerNameInitials += "" + nameArray[1].charAt(0);
              }
                return (
                  <>
                    {index === 0 && (
                      <div style={{...theme.customStyle.displayFlexCenter,
                        ...!bottom && theme.customStyle.stickyDate
                        }}>
                        <Chip
                          label={
                            moment(msg.createdDate)
                              .local()
                              .format("Do MMMM YY") ===
                            moment(new Date()).format("Do MMMM YY")
                              ? "Today"
                              : moment(msg.createdDate)
                                  .local()
                                  .format("Do MMMM YY")
                          }
                          style={theme.customStyle.msgDateStyle}
                        />
                      </div>
                    )}
                    {DateCmp !== "" && (
                       <div style={{...theme.customStyle.displayFlexCenter,
                        ...!bottom && theme.customStyle.stickyDate
                        }}>
                        <Chip
                          label={DateCmp}
                          style={theme.customStyle.msgDateStyle}
                        />
                      </div>
                    )}

                    {/* chat message comp code starts here */}
                    <>
                      <Grid
                        container
                        justify={side === "right" ? "flex-end" : "flex-start"}
                        {...GridContainerProps}
                        sx={{ flexWrap: side === "left" ? "nowrap" : "wrap",...theme.customStyle.msgContainer}}
                      >
                        {side === "left" && (
                          <Grid item sx={{ width: 40, pl: 0.5 }}>
                            {/* {avatar !== "" ? (
                            <Avatar
                                src={avatar}
                                sx={{ marginRight: "4px", bgcolor: "#034f84" }}
                                className={cx(classes.msgAvatar)}
                            />
                        ) : (
                            <Avatar className={cx(classes.msgAvatar)}
                                alt={customerNameInitials}
                                children={customerNameInitials ? customerNameInitials : null}
                            />
                        )} */}
                            {msg.type !== "INFO" && (
                              <Avatar
                                sx={theme.customStyle.msgAvatar}
                                alt={customerNameInitials}
                                children={
                                  customerNameInitials
                                    ? customerNameInitials
                                    : null
                                }
                              />
                            )}
                          </Grid>
                        )}
                        <Grid item xs={12} sx={{ pr: 0.5 }}>
                          <>
                            {msg.type === "INFO"
                              && <ChatInfoMsg componentType="History" message={msg}></ChatInfoMsg>
                            }
                            {msg.type !== "INFO" && (
                              <div
                                key={msg.id || index}
                                style={{
                                  ...side === "left" && theme.customStyle.msgLeftRow,
                                  ...side === "right" && theme.customStyle.msgRightRow}}
                              >
                                <Typography
                                  align={side}
                                  {...TypographyProps}
                                  sx={{
                                    ...theme.customStyle.msgBody,
                                    ...side === "left"&&theme.customStyle.msgLeft,
                                    ...side === "right"&&theme.customStyle.msgRight,
                                    ...side === "left" && index === 0 && theme.customStyle.msgLeftFirst,
                                    ...side === "right" && index === 0 && theme.customStyle.msgRightFirst,
                                    ...side === "left" && index === messageList.length - 1 && theme.customStyle.msgLeftLast,
                                    ...side === "right" && index === messageList.length - 1 && theme.customStyle.msgRightLast,
                                    marginLeft:
                                      side === "right" ? "50px" : "0px",
                                    marginRight:
                                      side === "left" ? "50px" : "0px",
                                    paddingTop: "6px !important",
                                    paddingBottom: "6px !important",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...side === "left"&&theme.customStyle.msgHeaderLeft,
                                      ...side === "right"&&theme.customStyle.msgHeaderRight,
                                      paddingBottom: "0px !important" }}                                  >
                                    {/* {showName && ( */}
                                    <span>
                                      {side === "left"
                                        ? conversationDetails.customerName
                                        :(msg.type==="BOTMSG"?"Virtual Agent":msg.agentName)}
                                    </span>
                                    {/* )} */}
                                  </Typography>
                                  {msg.type.toLowerCase() !== "document" ? (
                                    <Typography
                                      sx={theme.customStyle.messageText}
                                      align="left"
                                    >
                                      {msg.body}
                                    </Typography>
                                  ) : null}
                                  {msg.type.toLowerCase() === "document" && (
                                    <Typography
                                      sx={theme.customStyle.messageText}
                                      align="left"
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Box>{msg.body}</Box>
                                        <Box>
                                       {msg.status === 'Scanning' ? <>
                                                                    {showRefreshbtn ?
                                                                        <>
                                                                            {downloading.isRefreshStatus && downloading.id === msg.documentId ?
                                                                                <span style={{ marginLeft: "5px" }} title="Refreshing status" >
                                                                                    <CircularProgress size={15} />
                                                                                </span> :
                                                                                <span style={{ marginLeft: "5px" }} title="Refresh status" >
                                                                                  {/* if document status is scanning then get the latest status from server */}
                                                                                    <RefreshIcon fontSize="small" onClick={() => { GetDocumentStatus(msg.documentId) }} />
                                                                                </span>}</> :
                                                                        <span style={{ marginLeft: "5px" }} title="Scanning document" >
                                                                            <CircularProgress size={15} />
                                                                        </span>
                                                                    }
                                                                </>:msg.status === 'Completed' ?<>
                                                                        {
                                                                            downloading.isDownloading && downloading.id === msg.documentId ?
                                                                                <span style={{ marginLeft: "5px" }} title="Downloading document" >
                                                                                    <CircularProgress size={15} />
                                                                                </span> :
                                                                                <span style={{ marginRight: "5px", cursor: "pointer" }} title="Download document" >
                                                                                    <DownloadIcon fontSize="small" onClick={() => { downloadFile(msg) }} />
                                                                                </span>
                                                                        }
                                                                    </>:
                                                                     msg.status === 'Failed' ?
                                                                     <span style={{ marginRight: "5px", cursor: "pointer" }} title="failed to scan document" >
                                                                         <ErrorOutlineIcon fontSize="small" color="error" />
                                                                     </span>
                                                                     : msg.status === '' ?
                                                                         <> {downloading.isRefreshStatus && downloading.id === msg.documentId ?
                                                                             <span style={{ marginLeft: "5px" }} title="Refreshing status" >
                                                                                 <CircularProgress size={15} />
                                                                             </span> :
                                                                             <span style={{ marginRight: "5px", cursor: "pointer" }} title="Refresh status" >
                                                                                 <RefreshIcon fontSize="small" onClick={() => { GetDocumentStatus(msg.documentId) }} />
                                                                             </span>}</> : null
                                                                    }
                                        </Box>
                                      </Box>
                                    </Typography>
                                  )}
                                  <Typography
                                    align="end"
                                    sx={{ marginTop: "-5px !important" }}
                                  >
                                    <span
                                      style={{
                                        paddingLeft: "15px",
                                        fontSize: "11px",
                                        color: side === "left" ? "#6191c6" : "",
                                      }}
                                    >
                                      {moment(msg.createdDate).format("LT")}
                                    </span>
                                    {side !== "left" && (
                                      <>
                                        {conversationDetails.channel ===
                                          "Chat" &&
                                          msg.status !== "undelivered" && (
                                            <span
                                              style={{ marginLeft: "5px" }}
                                              title={
                                                !msg.showNotification
                                                  ? "Seen"
                                                  : "Message delivered"
                                              }
                                            >
                                              {!msg.showNotification ? (
                                                <DoneAllIcon fontSize="18px" />
                                              ) : (
                                                <CheckIcon fontSize="18px" />
                                              )}
                                            </span>
                                          )}
                                        {conversationDetails.channel ===
                                          "SMS" &&
                                          msg.status === "delivered" &&   (
                                            <span
                                              style={{ marginLeft: "5px" }}
                                              title="Message delivered"
                                            >
                                              <CheckIcon fontSize="18px" />
                                            </span>
                                          )}
                                        {(msg.status === "undelivered" || msg.status === "failed") && (
                                          <span
                                            style={{ marginLeft: "5px" }}
                                            title={"Message not delivered"}
                                          >
                                            <ErrorOutlineIcon fontSize="18px" color="error" />
                                          </span>
                                        )}
                                         {msg.status === "scheduled"  && (
                                          // Display a scheduled message indicator if the status is "scheduled"
                                          <span
                                            style={{ marginLeft: "5px" }}
                                            title={ "Message scheduled" +
                                            (msg.scheduleMessageDate
                                              ? " on " +
                                                moment(
                                                  msg.scheduleMessageDate
                                                ).format("lll")
                                              : "")}
                                          >
                                            {/* Use WatchLaterIcon to represent a scheduled message */}
                                            <WatchLaterIcon fontSize="18px" sx={{ color: "#ffff00" }} />                                          
                                          </span>
                                        )}
                                        {msg.status === "cancelled" && (
                                           // Display a cancelled message indicator if the status is "cancelled"
                                          <span
                                            style={{ marginLeft: "5px" }}
                                            title="Message cancelled."
                                          >
                                            {/* Use CancelScheduleSendIcon to represent a cancelled message */}
                                            <CancelScheduleSendIcon fontSize="18px" />
                                          </span>
                                        )}
                                      </>
                                    )}
                                  </Typography>
                                </Typography>
                              </div>
                            )}
                          </>
                          <div ref={messagesEndRef} />
                        </Grid>
                        {side === "right" && <Grid item xs={1}></Grid>}
                      </Grid>
                    </>
                    {/* chat message comp code starts here */}
                  </>
                );
              })}
              {!loading && messageList.length === 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "100px",
                  }}
                >
                  <h6>No SMS History Found.</h6>
                </div>
              )}
                  {!bottom &&
                      <IconButton 
                      sx={{...theme.customStyle.scrollToBottomBtn,bottom:props.isFromCrm?"85px":"20px"}}
                        size="small"
                        title="Scroll to bottom"
                      onClick={scrollToBottom}
                      >
                        <KeyboardDoubleArrowDownIcon />
                      </IconButton>}
          </div>}
        </Paper>
      </Box>
    </>
  );
}
ConversationHistory.propTypes = {
  avatar: PropTypes.string,
  messages: PropTypes.arrayOf(PropTypes.string),
  side: PropTypes.oneOf(["left", "right"]),
  GridContainerProps: PropTypes.shape({}),
  getTypographyProps: PropTypes.func,
};
ConversationHistory.defaultProps = {
  avatar: "",
  messages: [],
  side: "left",
  GridContainerProps: {},
  getTypographyProps: () => ({}),
};

export default ConversationHistory;
