
//04/11/2022 Texting-742=> Descred size of the icon
//11/14/2022 messenger template autosuggest height, version Info styles added.
//08/31/2023 => SUDAM B CHAVAN => TEXTING - 1634 - Standardize overall Webchat look and feel
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
const commonTheme = createTheme({ palette: { mode: "default" } });
const radius = commonTheme.spacing(2.5);
const size = commonTheme.spacing(4);

let wcuTheme = createTheme({
  customStyle: {
    appBarBGColor: "rgb(0, 20, 51)",
    signInAvatar: {
      width: 32,
      height: 32,
      bgcolor: "transparent",
      border: "1px solid #fff",
      fontSize: "13px",
    },
    convAvatar: {
      width: 34,
      height: 34,
      fontSize: "18px",
      bgcolor: "rgb(0, 25, 85)",
    },
    dashboardAvatar: {
      bgcolor: "rgb(0, 25, 80)",
      width: 56,
      height: 56,
    },
    dashboardTextCenter: {
      paddingLeft: "10px",
      textAlign: "center"
    },
    dashboardContent: {
      color: "rgb(50, 49, 48)",
      fontWeight: "normal",
      fontSize: "17px",
    },
    dashboardConvCountDiv: {
      backgroundColor: "rgb(0, 25, 80)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "60px",
    },
    dashboardConvCount: {
      color: "rgb(255, 255, 255)",
      fontWeight: "bold",
      fontSize: "25px",
    },
    dashboardCard: {
      display: "flex",
      alignItems: "center",
      padding: "10px",
      borderTopRightRadius: radius,
      borderBottomRightRadius: radius,
      borderRight: "0.28rem solid rgb(0, 80, 136)",
      width: "100%",
      cursor: "pointer"
    },
    chatBoxStyle: {
      height: "calc(100vh - 174px)",
      paddingTop: "0px",
      paddingBottom: "0px",
      // backgroundColor: "#E9E9E9"
    },
    chatBoxStyleDialog: {
      height: "calc(100vh - 294px)",
      paddingTop: "0px",
      paddingBottom: "0px",
      // backgroundColor: "#E9E9E9"
    },
    convStyle: {
      height: "calc(100vh - 199px)",
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      //  backgroundColor: "#E9E9E9"
    },
    headerText: {
      display: "flex",
      justifyContent: "space-between",
      cursor: "pointer",
    },
    msgContainer: {
      marginTop: "4px",
      //  backgroundColor: "#E9E9E9"
    },
    msgAvatar: {
      width: size,
      height: size,
      fontSize: 14,
      marginRight: "4px"
    },
    msgBody: {
      padding: "0px 8px 15px 15px",
      borderRadius: "4px",
      marginBottom: "4px",
      display: "inline-block",
      wordBreak: "break-word",
      fontSize: "12px",
      whiteSpace: "pre-wrap",
      minWidth: "150px",
      maxWidth: "80%",
      // border: "6px solid rgb(255, 255, 255)",
      backgroundClip: "padding-box",
    },

    msgHeaderLeft: {
      fontSize: "1em",
      align: "left",
      whiteSpace: "pre-wrap",
      lineHeight: "18px",
      paddingBottom: "10px",
    },
    msgHeaderRight: {
      fontSize: "12px",
      textAlign: "left",
      whiteSpace: "pre-wrap",
      lineHeight: "18px",
      paddingBottom: "10px",
    },
    msgLeftRow: {
      textAlign: "left",
      flloat: "left",
      width: "100%",
    },
    msgRightRow: {
      textAlign: "right",
      float: "right",
      width: "100%",
    },
    msgLeft: {
     borderRadius:"10px",
     borderBottomLeftRadius:"0px"
      
    },
    msgRight: {
      borderRadius:"10px",
      borderBottomRightRadius:"0px"
      //backgroundColor: "rgb(0, 25, 80)",
      // borderLeft: "0.28rem solid black",
     // color: "rgb(255, 255, 255)",

      //color: commonTheme.palette.common.white
    },
    msgLeftFirst: {
      borderRadius:"10px",
     borderBottomLeftRadius:"0px"
    },
    msgLeftLast: {
      borderRadius:"10px",
     borderBottomLeftRadius:"0px"
    },
    msgRightFirst: {
      borderRadius:"10px",
      borderBottomRightRadius:"0px"
    },
    msgRightLast: {
      borderRadius:"10px",
      borderBottomRightRadius:"0px"
    },
    msgDateStyle: {
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      backgroundColor: "#E9E9E9",
      fontSize: 11,
      color: "rgb(17, 96, 183)",
      fontWeight: "bold",
      minWidth:"100px"
        // opacity: 0.7,
    },

    //conversation pages styles//

    listItemIcon: {
      marginTop: "4px ",
      marginBottom: "4px ",
      minWidth: "24px ",
      marginRight: "32px ",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    iconText: {
      fontSize: "8px",
    },
    iconSize: {
      width: "26px",
      height: "26px",
    },
    btnStyle: {
      fontSize: "9.5px",
    },
    navLink: {
      textDecoration: "none",
      color: "#212121",
    },
    endConvBtn: {
      backgroundColor: "red !important",
      "&:hover": {
        backgroundColor: "#cd0404!important",
      },
      borderRadius: "20px",
      height: "32px",
      height: "30px",
      lineHeight: "16px",
      fontSize: "12px",
      marginBottom: "5px"
    },
    mrmButton: {
      borderRadius: "20px",
      marginRight: "5px",
      fontSize: "12px",
      height: "30px",
      marginBottom: "5px",
      lineHeight: "16px",
    },
    convCircularProgress: {
      display: "flex",
      justifyContent: "center",
      marginTop: 20,
      marginBottom: 20,
    },
    convBoxHeader: {
      display: "flex",
      justifyContent: "space-between",
    },
    convHeaderCustName: {
      fontWeight: "bold",
      fontSize: "18px",
      color: "#277a7c",
      color: "rgb(0, 0, 0)",
      lineHeight: "21px",
    },
    convHeaderCustPhone: {
      fontWeight: 600,
      fontSize: "16px",
      color: "black",
      fontWeight: 400,
      fontSize: "14px",
      color: "rgb(59, 58, 57)"
     },
    convHeaderEntityName: {
        fontWeight: "bold",
        fontSize: "18px",
        color: "#277a7c",
        color: "rgb(59, 58, 57)",
        lineHeight: "21px",
        textAlign: "right"
    },
    tabContextBox: {
      borderBottom: 1,
      borderColor: "divider",
      marginLeft: "10px",
      marginRight: "10px",
      minHeight: "40px",
    },
    tabStyle: {
      minHeight: "40px",
      fontSize: "13px",
      lineHeight: "15px",
      color: "rgb(0, 0, 0)",
      "&:hover": {
        fontWeight: "600",
        color: "#1976d2"
      },
    },

    //contacts page, user page, teams page styles//

    tableRow: {
      width: "100%",
      display: "flex",
      marginBottom: "10px",
      justifyContent: "space-between",
      alignItems: "center",
    },
    tableHead: {
      height: 640,
      width: "100%",
      paddingLeft: "10px",
      paddingRight: "10px",
      "& .super-app-theme--header": {
        backgroundColor: "#505f79",
      },
    },
    teamsTableHead: {
      height: 400,
      width: "100%",
      "& .super-app-theme--header": {
        backgroundColor: "#505f79",
      },
    },
    teamsAddIconBtn: {
      marginRight: "5px",
      backgroundColor: "#1976d2;",
      color: "white",
      "&:hover": { backgroundColor: "#1562ae" },
    },
    modalbtn: {
      marginRight: "5px",
      marginTop: "10px",
      backgroundColor: "#0288d1",
      color: "#fff",
      borderRadius: "100%",
      minWidth: "50px",
      height: "50px",
      "&:hover": {
        backgroundColor: "#125ca6!important",
      },
    },
    modalbtn02: {
      marginRight: "5px",
      backgroundColor: "#106cc8",
      color: "#fff",
      width: "140px",
      height: "40px",
      marginLeft: "10px",
      "&:hover": {
        backgroundColor: "#125ca6!important",
      },
    },
    removeBtn: {
      marginRight: "5px",
      marginTop: "10px",
      backgroundColor: "red",
      color: "#fff",
      borderRadius: "100%",
      minWidth: "50px",
      height: "50px",
      "&:hover": {
        backgroundColor: "#e10000!important",
      },
    },
    addTokenBtn: {
      marginTop: 1,
      marginLeft: 1,
      backgroundColor: "#106cc8 !important",
      "&:hover": {
        backgroundColor: "#125ca6 !important",
      },
    },
    detailsModalbtn: {
      marginRight: "5px",
      backgroundColor: "#106cc8",
      color: "#fff",
      borderRadius: "100%",
      minWidth: "50px",
      height: "50px",
      "&:hover": {
        backgroundColor: "#125ca6!important",
      },
    },
    textRow: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: 1.5,
      "& h2": {
        paddingLeft: "0",
      },
    },
    bottomButton: {
      marginBottom: 20,
      backgroundColor: "#106cc8 !important",
      "&:hover": {
        backgroundColor: "#125ca6 !important",
      },
    },
    transferHistoryDiv: {
      textAlign: "center",
    },
    transferHistory: {
      color: "#202020",
      fontSize: "12px",
      padding: "5px",
      borderRadius: "4px",
      // backgroundColor: "#e9e9e9",
      marginTop: "15px",
    },
    messageText: {
      fontSize: "12px"
    },
    //Contacts and user Filter styles
    filterMainDiv: {
      display: "flex",
      backgroundColor: "#efefef",
      margin: "10px",
      padding: "15px 5px 30px 5px",
      alignItems: "center",
      borderBottom: "2px dashed #d2d2d2",
      marginBottom: "20px",
    },
    filterTextField: {
      "& legend": { display: "none" },
      "& fieldset": { top: 0 },
      backgroundColor: "white",
    },
    textFieldLabel: {
      marginBottom: "10px",
    },
    textFieldMainDiv: {
      marginLeft: "15px",
    },
    filterButtons: {
      height: "37px",
      borderRadius: "20px",
      width: "115px",
      marginTop: "30px",
      marginLeft: "20px",
    },

    //Information Dialog Styles
    infoListStyle: {
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    infoListLabel: {
      fontWeight: "bold",
    },

    //Contact Details Tab Styles
    tableBoxStyle: {
      maxHeight: 400,
      width: "100%",
      paddingLeft: "5px",
      paddingRight: "5px",
      marginTop: "10px",
      "& .super-app-theme--header": {
        backgroundColor: "#505f79",
      },
    },
    detailTitles: {
      fontWeight: 500,
      fontSize: "16.5px",
      marginBottom: "4px",
    },


    chipStyle: {
      marginLeft: "10px",
      alignItems: "center",
      height: "24px",
    },
    searchConvMainDiv: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      flexWrap: "wrap",
      marginTop: "20px",
      paddingLeft: "10px",
    },
    searchConvTextfield: {
      margin: "2px 0px 10px 16px",
      width: "100%",
    },
    searchConvSelect: {
      m: 0,
      marginLeft: "16px",
      width: "100%",
    },
    searchConvRemoveBox: {
      boxShadow: "none",
      padding: "0px !important",
      marginRight: "30px"
    },
    searchConvBtn: {
      marginLeft: "16px",
      marginTop: "12px",
    },
    activeDrawerMenu: {
      color: "rgb(0, 102, 255)",
    },
    activeDrawerMenuDrawerClosed: {
      position: 'relative',
      top: "7px",
      color: "rgb(0, 102, 255)",
    },
    inActiveDrawerMenu: {
      color: "#555555",
    },
    inActiveDrawerMenuDrawerClosed: {
      color: "#555555",
      position: 'relative',
      top: "7px",
    },
    drawerListItemBtn: {
      paddingLeft: "16px",
    },
    drawerListItemBtnCrm: {
      paddingLeft: "6px",
    },
    drawerSetupStyle: {
      height: "calc(100vh - 130px)", overflow: "auto", overflowX: "hidden"

    },
    drawerSetupStyleCrm: {
      height: "calc(100vh - 77px)", overflow: "auto", overflowX: "hidden"
    },
    listItemIconDrawerClosed: {
      paddingLeft: "16px",
      paddingTop: "0px",
      paddingBottom: "0px",
    },
    listItemIconDrawerClosedNew: {
      paddingLeft: "10px",
      paddingTop: "0px",
      paddingBottom: "0px",
    },
    listItemIconDrawerCrm: {
      paddingLeft: "6px",
      paddingTop: "0px",
      paddingBottom: "0px",
    },
    listItemLabelDiv: {
      position: "relative",
    },
    menuItem: {
      textAlign: "left",
    },
    loaderStyle: {
      width: "100%",
      height: "90vh", display: "flex", justifyContent: "center", alignItems: "center"
    },
    loaderStyle: {
      width: "100%",
      height: "90vh", display: "flex", justifyContent: "center", alignItems: "center"
    },
    statusIcon: {
      borderRadius: "50%",
      marginRight: "10px",
      width: "8px",
      height: "8px"
    },
    available: {
      backgroundColor: "#44b700",
      color: "#44b700",
    },
    busy: {
      backgroundColor: "red",
      color: "red",
    },
    Offline: {
      backgroundColor: "light-gray",
      color: "gray",
    },
    messangerIcons: {
      fontSize: 22, // removed size of the icon
      marginRight: '3px'
    },
    templatesPopper: {  //messenger template autosuggest height
      display: "block",
      maxHeight: "318px",
      overflowY: "auto"
    },
    versionDetails: { //version Info
      paddingLeft: "17px",
      color: 'darkgrey',
      fontSize: "12px"
    },
    CustomStyledCell: {
      padding: "10px 16px !important"
    },
    CustomNestedStyledTableCell: {
      padding: "8px 16px !important"
    },
    inputMsgTextfieldDiv:{
      position: "fixed",
      bottom: "10px",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding:"0px 15px 5px 15px"
    },
    chatHeaderDiv:{         // webchat header main div styles
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#001433",
        color: "white",
        padding: "6px 10px 6px 10px",
        borderRadius:"30px"
    },
    webChatSm12:{
     fontSize:"calc(12px + 0%)"
    },
    webChatMd12:{
      fontSize:"calc(12px + 10%)"
    },
    webChatLg12:{
      fontSize:"calc(12px + 20%)"
    },
    webChatSm11:{
      fontSize:"calc(11px + 0%)"
    },
    webChatMd11:{
      fontSize:"calc(11px + 10%)"
    },
    webChatLg11:{
      fontSize:"calc(11px + 20%)"
    },
    webChatSm16:{
      fontSize:"calc(16px + 0%)"
     },
     webChatMd16:{
       fontSize:"calc(16px + 10%)"
     },
     webChatLg16:{
       fontSize:"calc(16px + 20%)"
     },
    autoSuggestMsgChip: {
      marginRight: "8px",
      borderRadius: "4px",
      marginBottom: "8px",
    },
    stickyDate:{
      position:"sticky",
      top:"0px"
    },
    scrollToBottomBtn:{
      position:"absolute",
      bottom:"1px",
      right:"1px",                  
      backgroundColor:"#E9E9E9",
      color:"black"  
    },
    displayFlexCenter:{
      display:"flex",
      justifyContent:"center"
    },
  },

});

wcuTheme = responsiveFontSizes(wcuTheme);
export default wcuTheme;
