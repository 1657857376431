/********************************************************************************************************************************************
Name: CIFUTIL
=============================================================================================================================================
Purpose:
Contains all the CIF methods to connect with D:
History:
VERSION     AUTHOR              DATE            DETAILS
1.0         Rajesh              12/01/2021      Initial creation of CIF component
1.1         Rajesh              11/08/2022      Updated CIF to start conversation from the member profile
////11-01-2022 => Texting-734 IB/OB, Team Name changes  
////11-01-2022 => Texting-734 IB/OB, Team Name changes
////11-03-2022 => Texting-734 IB/OB, Team Name changes reopen bugs fixed.
////11-04-2022 => Texting-734 IB/OB, Team Name changes reopen bugs fixed (changed values to lowercase for comparing).*/
////11-28-2022 => Texting-847 Ability to take over existing conversation assigned to someone else by agent.
////12-01-2022 => ONKAR  => Texting-874 Assign to me option is showing for missed conversations, nonwhite list alert, Consent unavailable alert fixed.
////12/09/2022 => ONKAR => Texting-913 Prompt the Templates to the User before start the conversation
////12/14/2022 => ONKAR => Texting-925 Active conversation is not starting from M360 form fixed.
////12/20/2022 => RAJESH => Fixed the issue of starting conversation with HomePhone/Mobile/Other phone
////27/04/2023 => SUDAM B CHAVAN => Texting-1199 - Alert is not showing only loading symbol is showing..
////05/06/2022 => RAJESH => Fixed the issue of starting conversation from member contact form
////06/14/2023 => ONKAR => Texting-1339 Inbound Conversation from Unassigned - Queue.
////06/14/2023 => ONKAR => Texting-1347 Red is not disappearing after viewing the customer’s message on the conversation initiated from team that is not opted for start conv with templates and on conv from individual Team.
////06/15/2023 => ONKAR => undo Texting-1347 changes. 
////06-23-2023 => ONKAR => Texting-1364 Team is changing to users default team when member assigns unassigned conversation to himself.
////07-07-2023 =>  SUDAM B CHAVAN => TEXTING-1406 - TCPA - Contact details linking observations(issue 1)
////07-18-2023 =>  SUDAM B CHAVAN => TEXTING-1436 - Team Level Attributes to use in tokens
////07-24-2023 =>  RAJESH => TEXTING-Implemented the timezone from the crm to texting contacts
////07-26-2023 =>  ONKAR => Texting-1470 When webchat conversation details is auto linked, Activity button is showing instead of Member button.


import { InvokePostServiceCall } from "../api/serviceUtil";
import apiConfig from "../api/apiConfig";
import { dataService } from "../dataService";
import moment from "moment";

var isClicked;
///This method is invoked for CRM Contact
export async function startCustomConversationD(data, azureID, crmMemberFields, teamId) {
    console.log("startCustomConversation-cifutil-start" + data);
    try {
        openCifWindow();
        console.log("startCustomConversation- cifutil - executing" + data);
        var contactFields = ["firstname", "fullname", "emailaddress1", "wcu_externalid", "contactid", "mobilephone","address1_stateorprovince","wcu_address1_timezone"];
        var memberProfileFields = ["wcu_donottext", "wcu_opportunityid", "wcu_lastname", "wcu_externalid", "wcu_memberprofileid", "wcu_mobilephone", "wcu_firstname","wcu_address1_stateprovince","wcu_address1_timezone"];
        var oppFields = ["parentcontactid"];
        crmMemberFields.forEach((val, key) => {
            if (val.includes(".")) {
                if (val.split(".")[0].toLowerCase() == 'wcu_memberprofile') {
                    memberProfileFields.push(val.split(".")[1].toLowerCase());
                }
                else if (val.split(".")[0].toLowerCase() == 'opportunity') {
                    oppFields.push(val.split(".")[1].toLowerCase());
                }
                else if (val.split(".")[0].toLowerCase() == 'contact') {
                    contactFields.push(val.split(".")[1].toLowerCase());
                }
            }
        });
        window.Microsoft.CIFramework.setMode(1);
        let ObjData = JSON.parse(data);
        var memberProfile = null;
        var recordId = ObjData.entityId.replace('{', '').replace('}', '');
        let conFilter = '?$select=' + contactFields + '&$filter= contactid  eq ' + recordId + '';
        var conResult = await window.Microsoft.CIFramework.searchAndOpenRecords("contact", conFilter, true);
        if (conResult != null) {
            var conResultObj = JSON.parse(conResult);
            var convAttributes = {};
            convAttributes.ActivityId = "";
            crmMemberFields.forEach((val, key) => {
                if (!convAttributes.hasOwnProperty(key)) {
                    if (val.split(".")[0].toLowerCase() == 'contact') {
                        convAttributes[key] = conResultObj[0][val.split(".")[1].toLowerCase()];
                    }
                }
            });
        }
        var reqDataObj = {};
        reqDataObj.conversation = {};
        reqDataObj.conversation.source = 'CRM';
        reqDataObj.conversation.channel = 'SMS';
        reqDataObj.conversation.agentId = azureID;
        reqDataObj.conversation.externalId = recordId;
        reqDataObj.conversation.externalType = "Contact"
        reqDataObj.conversation.customerPhoneNumber = !!ObjData.customphonenumber?ObjData.customphonenumber:ObjData.value;  // when started the conv from oob contact form, phone number is populated in value
        reqDataObj.contact = {};
        var customerName = conResultObj[0].fullname.split(" ");
        if (customerName.length == 2) {
            reqDataObj.contact.firstName = customerName[0];
            reqDataObj.contact.lastName = customerName[1];
        }
        else if (customerName.length == 3) {
            reqDataObj.contact.firstName = customerName[0];
            reqDataObj.contact.lastName = customerName[1] + ' ' + customerName[2];
        }
        else {
            reqDataObj.contact.firstName = conResultObj[0].fullname;
        }
        reqDataObj.contact.email = conResultObj[0].emailaddress1;
        reqDataObj.contact.stateCode = conResultObj[0].address1_stateorprovince;
        reqDataObj.contact.timezone = conResultObj[0].wcu_address1_timezone;
        reqDataObj.contact.contactAttributes = "{ \"ExternalId\": \"" + conResultObj[0].wcu_externalid + "\",\"ContactId\": \"" + recordId + "\"}";
        convAttributes.wcu_externalid = conResultObj[0].wcu_externalid;
        convAttributes.BranchId = !!ObjData.branchId?ObjData.branchId:"";
        convAttributes.ActivityId = "";
        convAttributes.EntityLogicalName = ObjData.entityLogicalName;
        convAttributes.EntityKeyfield = ObjData.entityLogicalName + "id";
        convAttributes.EntityId = recordId;
        convAttributes.PhoneNumber = !!ObjData.customphonenumber?ObjData.customphonenumber:ObjData.value;
        convAttributes.parentcontactid = conResultObj[0].contactid;
        reqDataObj.teamId = teamId;
        crmMemberFields.forEach((val, key) => {
            if (!convAttributes.hasOwnProperty(key)) {
                convAttributes[key] = conResultObj[val];
            }
        });
        reqDataObj.conversation.ConversationAttributes = JSON.stringify(convAttributes);
        var reqData = JSON.stringify(reqDataObj);
        console.log('REQDATA::' + reqData);
        let url = apiConfig.START_CONVERSATION;
        await InvokePostServiceCall(url, reqData)
            .then((resp) => {
                var response = resp.data;
                if (response != null && response.status === "SUCCESS") {
                    console.log("Conversation Created succesfully" + JSON.stringify(response));
                    dataService.setData({ showAlertMessage: response.status });
                }
                else if (response != null && response.status === 'FAILED' && response.errorDescription != null) {
                    if (response.isActiveinCommList == true || response.errorDescription.includes("Customer is already in active conversation")||response.errorDescription.includes("Conversation is in unassigned queue")) {
                        var selfAssignConvObj = new Object();
                        selfAssignConvObj.conversationId = response.conversationId;
                        selfAssignConvObj.alertText = response.errorDescription;
                        selfAssignConvObj.teamId = response.teamId;
                        dataService.setData({ showSelfAssignAlert: selfAssignConvObj });
                    }
                    else {
                        dataService.setData({ showAlertMessage: response.errorDescription });
                    }

                }
                else {
                    dataService.setData({ showAlertMessage: response.errorDescription });
                }
            })
            .catch((err) => {
                console.log(err, "Error Message");
                dataService.setData({ showAlertMessage: "" });
            });
        isClicked = null;
    } catch (err) {
        console.log(err, "Error Message - Custom");
        dataService.setData({ showAlertMessage: "Something went wrong with member data." });
        isClicked = null;
    }

}
export async function getCustomConvAttributesD(data, azureID, crmMemberFields, teamId) {
    console.log("startCustomConversation-cifutil-start" + data);
    try {
        openCifWindow();

        console.log("startCustomConversation- cifutil - executing" + data);
        var contactFields = ["firstname", "fullname", "emailaddress1", "wcu_externalid", "contactid", "mobilephone","address1_stateorprovince","wcu_address1_timezone"];
        var memberProfileFields = ["wcu_donottext", "wcu_opportunityid", "wcu_lastname", "wcu_externalid", "wcu_memberprofileid", "wcu_mobilephone", "wcu_firstname", "wcu_address1_stateprovince","wcu_address1_timezone"];
        var oppFields = ["parentcontactid"];

        crmMemberFields.forEach((val, key) => {
            if (val.includes(".")) {
                if (val.split(".")[0].toLowerCase() == 'wcu_memberprofile') {
                    memberProfileFields.push(val.split(".")[1].toLowerCase());
                }
                else if (val.split(".")[0].toLowerCase() == 'opportunity') {
                    oppFields.push(val.split(".")[1].toLowerCase());
                }
                else if (val.split(".")[0].toLowerCase() == 'contact') {
                    contactFields.push(val.split(".")[1].toLowerCase());
                }
            }
        });




        window.Microsoft.CIFramework.setMode(1);
        let ObjData = JSON.parse(data);
        var memberProfile = null;
        var recordId = ObjData.entityId.replace('{', '').replace('}', '');

        let conFilter = '?$select=' + contactFields + '&$filter= contactid  eq ' + recordId + '';
        var conResult = await window.Microsoft.CIFramework.searchAndOpenRecords("contact", conFilter, true);
        if (conResult != null) {
            var conResultObj = JSON.parse(conResult);
            var convAttributes = {};
            convAttributes.ActivityId = "";
            crmMemberFields.forEach((val, key) => {
                if (!convAttributes.hasOwnProperty(key)) {
                    if (val.split(".")[0].toLowerCase() == 'contact') {
                        convAttributes[key] = conResultObj[0][val.split(".")[1].toLowerCase()];
                    }
                }
            });
        }

        var reqDataObj = {};
        reqDataObj.conversation = {};


        reqDataObj.conversation.source = 'CRM';
        reqDataObj.conversation.channel = 'SMS';
        reqDataObj.conversation.agentId = azureID;
        reqDataObj.conversation.externalId = recordId;
        reqDataObj.conversation.externalType = "Contact"
        reqDataObj.conversation.customerPhoneNumber = !!ObjData.customphonenumber?ObjData.customphonenumber:ObjData.value;
        reqDataObj.contact = {};
        var customerName = conResultObj[0].fullname.split(" ");
        if (customerName.length > 1) {
            reqDataObj.contact.firstName = customerName[0];
            reqDataObj.contact.lastName = customerName[1];
        }
        else {
            reqDataObj.contact.firstName = conResultObj[0].fullname;
        }

        reqDataObj.contact.email = conResultObj[0].emailaddress1;
        reqDataObj.contact.stateCode = conResultObj[0].address1_stateorprovince;
        reqDataObj.contact.timezone = conResultObj[0].wcu_address1_timezone;
        reqDataObj.contact.contactAttributes = "{ \"ExternalId\": \"" + conResultObj[0].wcu_externalid + "\",\"ContactId\": \"" + recordId + "\"}";
        convAttributes.wcu_externalid = conResultObj[0].wcu_externalid;
        convAttributes.BranchId = !!ObjData.branchId?ObjData.branchId:"";
        convAttributes.ActivityId = "";
        convAttributes.EntityLogicalName = ObjData.entityLogicalName;
        convAttributes.EntityKeyfield = ObjData.entityLogicalName + "id";
        convAttributes.EntityId = recordId;
        convAttributes.PhoneNumber = !!ObjData.customphonenumber?ObjData.customphonenumber:ObjData.value;
        convAttributes.parentcontactid = conResultObj[0].contactid;
        reqDataObj.teamId = teamId;
        crmMemberFields.forEach((val, key) => {
            if (!convAttributes.hasOwnProperty(key)) {
                convAttributes[key] = conResultObj[val];
            }
        });
        reqDataObj.conversation.ConversationAttributes = JSON.stringify(convAttributes);
        isClicked = null;
        return reqDataObj;
    } catch (err) {
        console.log(err, "Error Message - Custom");
        dataService.setData({ showAlertMessage: "Something went wrong with member data." });
        isClicked = null;
    }

}
export async function startConvWithTemplatesD(reqDataObj) {
    var reqData = JSON.stringify(reqDataObj);
    let url = apiConfig.START_CONVERSATION;
    InvokePostServiceCall(url, reqData)
        .then((resp) => {
            var response = resp.data;
            if (response != null && response.status === "SUCCESS") {
                console.log("Conversation Created succesfully" + JSON.stringify(response));
                if (response.sendMessageResp?.status === "FAILED") {
                    dataService.setData({ showAlertMessage: response.sendMessageResp?.errorDescription });

                } else {
                    dataService.setData({ showAlertMessage: response.status });
                }
            }
            else if (response != null && response.status === 'FAILED' && response.errorDescription != null) {
                if (response.isActiveinCommList == true || response.errorDescription.includes("Customer is already in active conversation")||response.errorDescription.includes("Conversation is in unassigned queue")) {
                    var selfAssignConvObj = new Object();
                    selfAssignConvObj.conversationId = response.conversationId;
                    selfAssignConvObj.alertText = response.errorDescription;
                    selfAssignConvObj.teamId = response.teamId;
                    dataService.setData({ showSelfAssignAlert: selfAssignConvObj });
                }
                else {
                    dataService.setData({ showAlertMessage: response.errorDescription });
                }
            }
            else {
                dataService.setData({ showAlertMessage: "" });
            }

        })
        .catch((err) => {
            console.log(err, "Error Message");
            dataService.setData({ showAlertMessage: "" });
        });

}
export async function getConvAttributesD(data, azureID, crmMemberFields, teamId) {
    try {
        console.log("startConversation" + data);
        openCifWindow();
        let ObjData = JSON.parse(data);
        var memberProfile = null;
        let filter = "";
        if (ObjData.entityLogicalName == 'contact') {
            getCustomConvAttributesD(data, azureID, crmMemberFields, teamId);
            return;
        }
        else {
            //var strCrmFields = userInfo.crmMemberFields != null && userInfo.crmMemberFields != '' ? userInfo.crmMemberFields : '';
            var oppId = ObjData.entityId.replace('{', '').replace('}', '');
            var applicationID = ObjData.value;
            var memberProfileFields = ["wcu_donottext", "wcu_opportunityid", "wcu_lastname", "wcu_externalid", "wcu_memberprofileid", "wcu_mobilephone", "wcu_firstname", "wcu_emailaddress1", "wcu_address1_stateprovince","wcu_address1_timezone"];
            var oppFields = ["parentcontactid", "opportunityid"];
            var contactFields = ["firstname"];

            crmMemberFields.forEach((val, key) => {
                if (val.includes(".")) {
                    if (val.split(".")[0].toLowerCase() == 'wcu_memberprofile') {
                        memberProfileFields.push(val.split(".")[1].toLowerCase());
                    }
                    else if (val.split(".")[0].toLowerCase() == 'opportunity') {
                        oppFields.push(val.split(".")[1].toLowerCase());
                    }
                    else if (val.split(".")[0].toLowerCase() == 'contact') {
                        contactFields.push(val.split(".")[1].toLowerCase());
                    }
                }
            });
            if (ObjData.entityLogicalName == 'wcu_memberprofile') {
                filter = '?$select=' + memberProfileFields + ' &$filter= wcu_memberprofileid eq ' + ObjData.entityId + '  &$expand=wcu_opportunityid($select=' + oppFields + ')';

            }
            else {
                filter = '?$select=' + memberProfileFields + ' &$filter= wcu_applicanttype eq 799470000 and wcu_opportunityid/opportunityid eq ' + oppId + '  &$expand=wcu_opportunityid($select=' + oppFields + ')';

            }


            // var memberProfile = await searchRecordinCRM("wcu_memberprofile", filter);
            var result = await window.Microsoft.CIFramework.searchAndOpenRecords("wcu_memberprofile", filter, true);
            var memberProfiles = JSON.parse(result);
            if (memberProfiles != null) {
                memberProfile = memberProfiles[0];
            }
            if (memberProfile != null && (memberProfile.wcu_mobilephone != null || ObjData.customphonenumber)) {
                if (memberProfile.wcu_donottext === false) {
                    let oppFilter = '?$select=' + oppFields + '&$filter= opportunityid eq ' + memberProfile.wcu_opportunityid.opportunityid + '&$expand=parentcontactid($select = ' + contactFields + ')';
                    var oppResult = await window.Microsoft.CIFramework.searchAndOpenRecords("opportunity", oppFilter, true);
                    var oppResultObj = {};
                    if (oppResult != null) {
                        oppResultObj = JSON.parse(oppResult);
                    }
                    var reqDataObj = {};
                    reqDataObj.conversation = {};
                    reqDataObj.conversation.source = 'CRM';
                    reqDataObj.conversation.channel = 'SMS';
                    reqDataObj.conversation.agentId = azureID;
                    reqDataObj.conversation.externalId = oppResultObj[0].opportunityid;
                    reqDataObj.conversation.externalType = "Opportunity"
                    reqDataObj.conversation.customerPhoneNumber = ObjData.customphonenumber ? ObjData.customphonenumber : memberProfile.wcu_mobilephone;
                    reqDataObj.contact = {};
                    reqDataObj.contact.firstName = memberProfile.wcu_firstname;
                    reqDataObj.contact.lastName = memberProfile.wcu_lastname;
                    reqDataObj.contact.email = memberProfile.wcu_emailaddress1;
                    reqDataObj.contact.stateCode = memberProfile["wcu_address1_stateprovince@OData.Community.Display.V1.FormattedValue"];
                    reqDataObj.contact.timezone = memberProfile.wcu_address1_timezone;
                    reqDataObj.contact.contactAttributes = "{ \"ExternalId\": \"" + memberProfile.wcu_externalid + "\",\"ContactId\": \"" + memberProfile.wcu_memberprofileid + "\"}";
                    reqDataObj.teamId = teamId;
                    var convAttributes = {};
                    convAttributes.BranchId = !!ObjData.branchId?ObjData.branchId:"";
                    convAttributes.ActivityId = "";
                    convAttributes.EntityLogicalName = "opportunity";
                    convAttributes.EntityKeyfield = "opportunityid";
                    convAttributes.EntityId = oppResultObj[0].opportunityid;
                    //Store member profile id to conv attributes
                    convAttributes.EntityLogicalName2 = "wcu_memberprofile";
                    convAttributes.EntityKeyfield2 = "wcu_memberprofileid";
                    convAttributes.EntityId2 = memberProfile.wcu_memberprofileid;

                    convAttributes.PhoneNumber = ObjData.customphonenumber ? ObjData.customphonenumber : memberProfile.wcu_mobilephone;
                    if (oppResultObj != null && oppResultObj[0].parentcontactid) {
                        convAttributes.parentcontactid = oppResultObj[0].parentcontactid.contactid;
                    }
                    convAttributes.ApplicationNumber = applicationID;
                    crmMemberFields.forEach((val, key) => {
                        if (!convAttributes.hasOwnProperty(key)) {
                            if (val.includes(".")) {
                                if (val.split(".")[0].toLowerCase() == 'wcu_memberprofile') {
                                    convAttributes[key] = memberProfile[val.split(".")[1].toLowerCase()];
                                }
                                else if (val.split(".")[0].toLowerCase() == 'opportunity') {
                                    convAttributes[key] = memberProfile.wcu_opportunityid[val.split(".")[1].toLowerCase()];
                                }
                                else if (val.split(".")[0].toLowerCase() == 'contact' && oppResultObj[0].parentcontactid) {
                                    convAttributes[key] = oppResultObj[0].parentcontactid[val.split(".")[1].toLowerCase()];
                                }
                            }

                        }
                    });

                    reqDataObj.conversation.ConversationAttributes = JSON.stringify(convAttributes);
                    return reqDataObj;

                }
                else {
                    dataService.setData({ showAlertMessage: 'Customer consent is missing to contact' });
                }
            }
            else {
                if (memberProfile == null) {
                    dataService.setData({ showAlertMessage: 'Primary Member Profile not available' });
                }
                else {
                    dataService.setData({ showAlertMessage: 'Mobile number not present for Primary Member Profile' });
                }
            }
        }
    }
    catch (err) {
        console.log(err, "Error Message");
        dataService.setData({ showAlertMessage: 'Please check Application is having all required data. Eg Contact etc' });
    }
}

///This method is invoked from CRM Member Profile/Application/Contact
export async function startConversationD(data, azureID, crmMemberFields, teamId) {
    try {
        console.log("startConversation" + data);
        openCifWindow();
        let ObjData = JSON.parse(data);
        var memberProfile = null;
        let filter = "";
        if (ObjData.entityLogicalName == 'contact') {   //Start conversation for Contact
            startCustomConversationD(data, azureID, crmMemberFields, teamId);
            return;
        }
        else {
            //var strCrmFields = userInfo.crmMemberFields != null && userInfo.crmMemberFields != '' ? userInfo.crmMemberFields : '';
            var oppId = ObjData.entityId.replace('{', '').replace('}', '');
            var applicationID = ObjData.value;
            var memberProfileFields = ["wcu_donottext", "wcu_opportunityid", "wcu_lastname", "wcu_externalid", "wcu_memberprofileid", "wcu_mobilephone", "wcu_firstname", "wcu_emailaddress1","wcu_address1_stateprovince","wcu_address1_timezone"];
            var oppFields = ["parentcontactid", "opportunityid"];
            var contactFields = ["firstname"];

            crmMemberFields.forEach((val, key) => {
                if (val.includes(".")) {
                    if (val.split(".")[0].toLowerCase() == 'wcu_memberprofile') {
                        memberProfileFields.push(val.split(".")[1].toLowerCase());
                    }
                    else if (val.split(".")[0].toLowerCase() == 'opportunity') {
                        oppFields.push(val.split(".")[1].toLowerCase());
                    }
                    else if (val.split(".")[0].toLowerCase() == 'contact') {
                        contactFields.push(val.split(".")[1].toLowerCase());
                    }
                }
            });
            if (ObjData.entityLogicalName == 'wcu_memberprofile') {
                filter = '?$select=' + memberProfileFields + ' &$filter= wcu_memberprofileid eq ' + ObjData.entityId + '  &$expand=wcu_opportunityid($select=' + oppFields + ')';

            }
            else {
                filter = '?$select=' + memberProfileFields + ' &$filter= wcu_applicanttype eq 799470000 and wcu_opportunityid/opportunityid eq ' + oppId + '  &$expand=wcu_opportunityid($select=' + oppFields + ')';

            }


            // var memberProfile = await searchRecordinCRM("wcu_memberprofile", filter);
            var result = await window.Microsoft.CIFramework.searchAndOpenRecords("wcu_memberprofile", filter, true);
            var memberProfiles = JSON.parse(result);
            if (memberProfiles != null) {
                memberProfile = memberProfiles[0];
            }
            if (memberProfile != null && (memberProfile.wcu_mobilephone != null || ObjData.customphonenumber)) { 
                //start conv only when we have member profile and any of from wcu_mobilephone or customphonenumber
                if (memberProfile.wcu_donottext === false) {
                    let oppFilter = '?$select=' + oppFields + '&$filter= opportunityid eq ' + memberProfile.wcu_opportunityid.opportunityid + '&$expand=parentcontactid($select = ' + contactFields + ')';
                    var oppResult = await window.Microsoft.CIFramework.searchAndOpenRecords("opportunity", oppFilter, true);
                    var oppResultObj = {};
                    if (oppResult != null) {
                        oppResultObj = JSON.parse(oppResult);
                    }
                    var reqDataObj = {};
                    reqDataObj.conversation = {};
                    reqDataObj.conversation.source = 'CRM';
                    reqDataObj.conversation.channel = 'SMS';
                    reqDataObj.conversation.agentId = azureID;
                    reqDataObj.conversation.externalId = oppResultObj[0].opportunityid;
                    reqDataObj.conversation.externalType = "Opportunity"
                    reqDataObj.conversation.customerPhoneNumber = ObjData.customphonenumber ? ObjData.customphonenumber : memberProfile.wcu_mobilephone;
                    reqDataObj.contact = {};
                    reqDataObj.contact.firstName = memberProfile.wcu_firstname;
                    reqDataObj.contact.lastName = memberProfile.wcu_lastname;
                    reqDataObj.contact.email = memberProfile.wcu_emailaddress1;
                    reqDataObj.contact.stateCode = memberProfile["wcu_address1_stateprovince@OData.Community.Display.V1.FormattedValue"]; 
                    reqDataObj.contact.timezone = memberProfile.wcu_address1_timezone;
                    reqDataObj.contact.contactAttributes = "{ \"ExternalId\": \"" + memberProfile.wcu_externalid + "\",\"ContactId\": \"" + memberProfile.wcu_memberprofileid + "\"}";
                    reqDataObj.teamId = teamId;
                    var convAttributes = {};
                    convAttributes.BranchId = !!ObjData.branchId?ObjData.branchId:"";
                    convAttributes.ActivityId = "";
                    convAttributes.EntityLogicalName = "opportunity";
                    convAttributes.EntityKeyfield = "opportunityid";
                    convAttributes.EntityId = oppResultObj[0].opportunityid;

                    //Store member profile id to conv attributes
                    convAttributes.EntityLogicalName2 = "wcu_memberprofile";
                    convAttributes.EntityKeyfield2 = "wcu_memberprofileid";
                    convAttributes.EntityId2 = memberProfile.wcu_memberprofileid;

                    convAttributes.PhoneNumber = ObjData.customphonenumber ? ObjData.customphonenumber : memberProfile.wcu_mobilephone;
                    if (oppResultObj != null && oppResultObj[0].parentcontactid) {
                        convAttributes.parentcontactid = oppResultObj[0].parentcontactid.contactid;
                    }
                    convAttributes.ApplicationNumber = applicationID;
                    crmMemberFields.forEach((val, key) => {
                        if (!convAttributes.hasOwnProperty(key)) {
                            if (val.includes(".")) {
                                if (val.split(".")[0].toLowerCase() == 'wcu_memberprofile') {
                                    convAttributes[key] = memberProfile[val.split(".")[1].toLowerCase()];
                                }
                                else if (val.split(".")[0].toLowerCase() == 'opportunity') {
                                    convAttributes[key] = memberProfile.wcu_opportunityid[val.split(".")[1].toLowerCase()];
                                }
                                else if (val.split(".")[0].toLowerCase() == 'contact' && oppResultObj[0].parentcontactid) {
                                    convAttributes[key] = oppResultObj[0].parentcontactid[val.split(".")[1].toLowerCase()];
                                }
                            }

                        }
                    });

                    reqDataObj.conversation.ConversationAttributes = JSON.stringify(convAttributes);
                    var reqData = JSON.stringify(reqDataObj);
                    let url = apiConfig.START_CONVERSATION;
                    InvokePostServiceCall(url, reqData)
                        .then((resp) => {
                            var response = resp.data;
                            if (response != null && response.status === "SUCCESS") {
                                console.log("Conversation Created succesfully" + JSON.stringify(response));
                                dataService.setData({ showAlertMessage: response.status });
                            }
                            else if (response != null && response.status === 'FAILED' && response.errorDescription != null) {
                                if (response.isActiveinCommList == true || response.errorDescription.includes("Customer is already in active conversation")||response.errorDescription.includes("Conversation is in unassigned queue")) {
                                    var selfAssignConvObj = new Object();
                                    selfAssignConvObj.conversationId = response.conversationId;
                                    selfAssignConvObj.alertText = response.errorDescription;
                                    selfAssignConvObj.teamId = response.teamId;
                                    dataService.setData({ showSelfAssignAlert: selfAssignConvObj });
                                }
                                else {
                                    dataService.setData({ showAlertMessage: response.errorDescription });
                                }
                            }
                            else {
                                dataService.setData({ showAlertMessage: "" });
                            }

                        })
                        .catch((err) => {
                            console.log(err, "Error Message");
                            dataService.setData({ showAlertMessage: "" });
                        });
                }
                else {
                    dataService.setData({ showAlertMessage: 'Customer consent is missing to contact' });
                }
            }
            else {
                if (memberProfile == null) {
                    dataService.setData({ showAlertMessage: 'Primary Member Profile not available' });
                }
                else {
                    dataService.setData({ showAlertMessage: 'Mobile number not present for Primary Member Profile' });
                }
            }
        }
    }
    catch (err) {
        console.log(err, "Error Message");
        dataService.setData({ showAlertMessage: 'Please check Application is having all required data. Eg Contact etc' });
    }
}

export async function searchContactandProspectsD(searchText) {

    if (searchText != null && searchText != '' && searchText != ' ') {
        try {
            var searchItem = searchText.replaceAll(/[^a-zA-Z0-9]/g, '');
            if (searchItem && searchItem.indexOf('1') === 0) {
                searchItem = searchItem.substring(1);
            }
            var searchResults = new Object();
            searchResults.contactResult = [];
            searchResults.prospectResult = [];
            searchResults.memberProfileResult = [];
            var reqDataObj = {
                "search": searchItem
            };
            var reqData = JSON.stringify(reqDataObj);
            console.log('REQDATA::' + reqData);
            let url = apiConfig.SEARCH_CRM_RECORDS;
            var result = await InvokePostServiceCall(url, reqData);
            if (result.data != null && result.data.value != null) {
                var response = result.data;
                response.value.forEach((val, key) => {
                    if (val.searchEntityname == 'contact') {
                        var con = val;
                        con.contactid = val.searchObjectid;
                        searchResults.contactResult.push(con);
                    }
                    else if (val.searchEntityname == 'lead') {
                        var lead = val;
                        lead.leadid = val.searchObjectid;
                        searchResults.prospectResult.push(lead)
                    }
                    else if (val.searchEntityname == 'wcu_memberprofile') {
                        var memnerprofile = val;
                        memnerprofile.memberprofileid = val.searchObjectid;
                        searchResults.memberProfileResult.push(memnerprofile)
                    }
                });
            }
            return searchResults;

        }
        catch (err) {
            console.log(err, "Error Message - Custom");
        }


        /*  var formattedMobileNumber = '';
         var mobile = searchText.replaceAll(/[^a-zA-Z0-9]/g, '');
          if (mobile && mobile.indexOf('1') === 0) {
              mobile = mobile.substring(1);
          }
          formattedMobileNumber = '%25' + mobile.replace(/.{1}/g, '$&%25');
          var searchResults = new Object();
          let contactFilter = "?$select=mobilephone,wcu_emailaddress1,telephone1,
          telephone2,contactid,firstname,lastname, fullname, wcu_mobilephone &$filter=contains( mobilephone,'" + formattedMobileNumber + "') or contains( telephone1,'" + formattedMobileNumber + "') or contains( telephone2,'" + formattedMobileNumber + "') or  wcu_emailaddress1 eq '" + searchText + "' or wcu_telephone1 eq '" + searchText + "' or  firstname eq '" + searchText + "' or lastname eq '" + searchText + "' or fullname eq '" + searchText + "'";
          searchResults.contactResult = await window.Microsoft.CIFramework.searchAndOpenRecords("contact", contactFilter, true);
          let prospectFilter = "?$select=emailaddress1,leadid,firstname,lastname,fullname,telephone1,telephone2,telephone3,mobilephone &$filter=contains( mobilephone,'" + formattedMobileNumber + "') or contains( telephone1,'" + formattedMobileNumber + "') or contains( telephone2,'" + formattedMobileNumber + "') or  firstname eq '" + searchText + "' or lastname eq '" + searchText + "' or fullname eq '" + searchText + "'";
          searchResults.prospectResult = await window.Microsoft.CIFramework.searchAndOpenRecords("lead", prospectFilter, true); */


    }

}



export async function searchOppandFinAccountD(recordId) {
    try {
        var searchResults = new Object();
        let oppFilter = "?$select=name,wcu_externalid,wcu_productname,opportunityid &$filter=parentcontactid/contactid eq '" + recordId + "'";
        searchResults.oppResults = await window.Microsoft.CIFramework.searchAndOpenRecords("opportunity", oppFilter, true);
        var properties = [
            "wcu_finproducttype", "wcu_finproductid", "wcu_accountid", "wcu_name"].join();
        let finAccountFilter = "?$select=wcu_name &$filter=_wcu_contactid_value eq " + recordId + " &$expand=wcu_FinAccountId($select = " + properties + ")";
        searchResults.finAccountResults = await window.Microsoft.CIFramework.searchAndOpenRecords("wcu_cta", finAccountFilter, true);
        console.log(':::FIN::' + JSON.stringify(searchResults.finAccountResults));
    }
    catch (ex) {
        console.log("Exception:" + ex);
    }
    finally {
        return searchResults;
    }


}



export async function updateActivityD(payload) {
    try {
        var data = {};
        var partyList = [];
        var jsonData = '';
        var convObj = JSON.parse(payload.conv.conversationAttributes);
        if (payload.entityType == 'Contact') {
            data["partyid_contact@odata.bind"] = "/contacts(" + payload.entityId + ")";
            data["participationtypemask"] = "2";
            partyList.push(data);
            var sms = {};
            sms["wcu_sms_activity_parties"] = partyList;
            sms["_regardingobjectid_value"] = null;
            jsonData = JSON.stringify(sms);
            var member = {};
            if (payload.channel == 'Chat') {
                member["wcu_currentchat@odata.bind"] = "/wcu_smses(" + convObj.ActivityId + ")";
                let strMember = JSON.stringify(member);
                await window.Microsoft.CIFramework.updateRecord("contact", payload.entityId, strMember);
            }

        }
        else if (payload.entityType == 'Lead') {
            data["partyid_lead@odata.bind"] = "/leads(" + payload.entityId + ")";
            data["participationtypemask"] = "2";
            partyList.push(data);
            var sms = {};
            sms["wcu_sms_activity_parties"] = partyList;
            sms["_regardingobjectid_value"] = null;
            jsonData = JSON.stringify(sms);
            await window.Microsoft.CIFramework.updateRecord("wcu_sms", convObj.ActivityId, jsonData);
        }
        else if (payload.entityType == 'Opportunity') {
            data["regardingobjectid_opportunity@odata.bind"] = "/opportunities(" + payload.entityId + ")";
            jsonData = JSON.stringify(data);
        }
        else if (payload.entityType == 'wcu_memberprofile') {
            convObj.EntityId2 = payload.entityId;
            convObj.EntityKeyfield2 = "wcu_memberprofileid";
            data["wcu_memberprofile_wcu_sms@odata.bind"] = "/wcu_memberprofiles(" + payload.entityId + ")";
            data["regardingobjectid_opportunity@odata.bind"] = "/opportunities(" + payload.opportunityid + ")";
            jsonData = JSON.stringify(data);
        }
        else if (payload.entityType == 'FinAccount') {
            data["regardingobjectid_wcu_finaccount@odata.bind"] = "/wcu_finaccounts(" + payload.entityId + ")"; //need to update
            jsonData = JSON.stringify(data);
        }

        await window.Microsoft.CIFramework.updateRecord("wcu_sms", convObj.ActivityId, jsonData);
        payload.conv = { ...payload.conv, conversationAttributes : JSON.stringify(convObj) }
        openRecordinCRMD(payload.conv, payload.crmMemberFields, false);
    }
    catch (exce) {
        console.log("exception" + exce);
    }

}
export async function closeActivityinCRMD(conv) {
    try {
        if (conv.conversationAttributes != null && conv.conversationAttributes !== '') {
            var convObj = JSON.parse(conv.conversationAttributes);
            if (convObj.ActivityId != null && convObj.ActivityId !== '') {
                var data = {
                    "statecode": 1
                }
                var jsonData = JSON.stringify(data);
                window.Microsoft.CIFramework.updateRecord("wcu_sms", convObj.ActivityId, jsonData);


                var linkedRecords = await getLinkedEntitiesD(convObj.ActivityId);
                
                if (!!linkedRecords.contact) {
                    var obj = JSON.parse(linkedRecords.contact);
                    if (!!obj) {
                        var member = {};
                        member["wcu_currentchat"] = null;
                        let strMember = JSON.stringify(member);
                        await window.Microsoft.CIFramework.updateRecord("contact", obj[0].contactid, strMember);
                    }
                }
               
            }
        }
    }
    catch (err) {
        console.log(err, "Error Message");
    }

}


export async function openCifWindow() {
    if (window.Microsoft != null && window.Microsoft.CIFramework != null) {
        window.Microsoft.CIFramework.setMode(1);
    }
}

export async function createOrSearchTaskinCRMD(conv, crmMemberFields, createTask, activityDueDataHours, context, userTeams) {
    try {
        console.log("createOrSearchTaskinCRM-context" + context);
        console.log("createOrSearchTaskinCRM-createTask" + createTask);

        if (window.Microsoft != null && window.Microsoft.CIFramework != null) {
            var convObj = {};
            var searchActivityinCRM = false;
            console.log("createOrSearchTaskinCRM-CIF");
            if (conv.conversationAttributes != null && conv.conversationAttributes !== '' && createTask == true) {
                console.log("createOrSearchTaskinCRM-Conv Attributes Exits");
                convObj = JSON.parse(conv.conversationAttributes);
                if (convObj.ActivityId != null && convObj.ActivityId !== "") {
                    console.log("createOrSearchTaskinCRM-ActivityId Exist");
                    dataService.setData({ addConversation: conv });
                    console.log("createOrSearchTaskinCRM-openRecordinCRMD");
                    openRecordinCRMD(conv, crmMemberFields, true);
                    return;
                }
                else {
                    searchActivityinCRM = true;
                }
            }
            else {
                searchActivityinCRM = true;
            }
            console.log("createOrSearchTaskinCRM-searchActivityinCRM " + searchActivityinCRM);
            if ((context == 'TransferConversation' || context == 'SelfAssignConversation') || searchActivityinCRM == true) {
                console.log("createOrSearchTaskinCRM-searchActivityinCRM");
                var filter = '?$select= wcu_conversationid,activityid &$filter=wcu_conversationid  eq ' + conv.conversationId + ' ';
                var result = await window.Microsoft.CIFramework.searchAndOpenRecords("wcu_sms", filter, true);  // Retrieve activity  records with that conv id
                console.log("createOrSearchTaskinCRM-searchActivityinCRM result " + result);
                var activities = JSON.parse(result);
                console.log("createOrSearchTaskinCRM-Retrieve activity  records with that conv id " + Object.keys(activities).length);
                if (Object.keys(activities).length != 0) {
                    let conversation = {};
                    let convAttributes = {};
                    conversation = Object.assign({}, conv);
                    if (conversation.conversationAttributes != null && conversation.conversationAttributes != "") {
                        convAttributes = JSON.parse(conversation.conversationAttributes);
                    }
                    convAttributes.EntityLogicalName = "wcu_sms";
                    convAttributes.EntityKeyfield = "activityid";
                    convAttributes.EntityId = convAttributes.ActivityId = activities[0].activityid;
                    conversation.conversationAttributes = JSON.stringify(convAttributes);
                    dataService.setData({ updateConversation: conversation });           // Updates the conversation customerName on the client side.
                    var reqDataObj = {};
                    reqDataObj.conversation = conversation;
                    console.log("createOrSearchTaskinCRM-updateConversation");
                    updateConversation(reqDataObj);
                    console.log("createOrSearchTaskinCRM-openRecordinCRMD");
                    openRecordinCRMD(conversation, crmMemberFields, true);
                    return conversation;
                }


            }
            if (createTask == true) {            

                console.log("createOrSearchTaskinCRM-Inside Create Task");
                var data = {};
                var reqDataObj = {};
                 debugger;
                var convAttributes = {};
                convAttributes.conversationId = conv.conversationId;
                convAttributes.userId = conv.agentId != null ? conv.agentId.toLowerCase() : "";
                convAttributes.agentName = conv.agentName;
                convAttributes.wcu_firstname = conv.wcu_firstname;
                data = {};
                data["subject"] = "Message conversation";
                data["scheduledend"] = moment(new Date()).add(activityDueDataHours, "hours");
                data["wcu_to"] = conv.customerPhoneNumber;
                data["wcu_conversationid"] = conv.conversationId;
                data["wcu_externalsystem"] = 'Texting';
                data["wcu_externalid"] = conv.conversationId;
              if(!!convObj.BranchId){
                data["wcu_branch_wcu_sms@odata.bind"] =  "/wcu_branchs("+convObj.BranchId+")"
              }
                var teamAttributesobj = userTeams.filter(t => t.teamId === conv.teamId)?.[0]?.teamAttributes;
                var crmTeamId = teamAttributesobj ? JSON.parse(teamAttributesobj)?.CRMTeamId : null;
                data["wcu_teamname"] = crmTeamId ? crmTeamId : 799470004;
                // data["wcu_teamname"] = conv.teamName.toLowerCase()==="digital"?799470000:conv.teamName.toLowerCase()==="collections"?799470001:conv.teamName.toLowerCase()==="mortgage"?799470002:conv.teamName.toLowerCase()==="lending"?799470003:799470004;
                if (conv.source.toLowerCase() === "crm") {
                    data["wcu_messagetype"] = 799470000;

                }
                if (conv.source.toLowerCase() === "list") {
                    data["wcu_messagetype"] = 799470002;
                }
                
                if (conv.source.toLowerCase() === "inbound" && conv.channel == 'SMS' ) {
                    console.log("createOrSearchTaskinCRM-source Inbound");
                    var mobile = conv.customerPhoneNumber.replaceAll(" ", "").slice(-10);
                    // Serach for the existing contact in crm
                    let filter = "?$select=wcu_mobilephone&$search= " + mobile;
                    window.Microsoft.CIFramework.searchAndOpenRecords("contact", filter, false, '', 0);
                    data["wcu_messagetype"] = 799470001;

                }
                else if (conv.channel == 'Chat') {
                    var filter;
                    console.log("memberId: " + convObj.memberId);
                    
                    data["wcu_messagetype"] = 395310001;
                    if (conv.authId) {
                        data["wcu_authflag"] = "Biometrics";
                        data["wcu_intent"] = "Enquiry";
                    }

                    //Search and link the member record if member id passed by parent site
                    if (conv.authId) {
                        var contactId;
                         filter = "?$select= contactid &$filter=wcu_externalid eq '" + conv.authId + "'";

                        result = await window.Microsoft.CIFramework.searchAndOpenRecords("contact", filter, true);  // Retrieve activity  records with that conv id
                        console.log("createOrSearchTaskinCRM-searchContact result " + result);
                        var contacts = JSON.parse(result);
                        console.log("createOrSearchTaskinCRM-Retrieve contact  records with that member id " + Object.keys(contacts).length);
                        if (Object.keys(contacts).length != 0) {
                            contactId = contacts[0].contactid;
                        }

                        console.log("createOrSearchTaskinCRM-convObj " + convObj);
                        dataService.setData({ addConversation: conv });
                        if (contactId) {    //If contact id found with memberid, open record in CRM
                            openEnityinCRMD(contactId, "contact", "contactid");
                            data["regardingobjectid_contact@odata.bind"] = "/contacts(" + contactId + ")";
                        }
                        else {      //If contact id not found with member id seach in CRM
                            //If memberId is passed by parent site search contacts by member id otherwise search by email
                            if (convObj.memberId) {
                                filter = "?$select=wcu_externalid&$search= '" + convObj.memberId + "'";
                            }
                            else {
                                filter = "?$select=wcu_emailaddress1&$search= " + conv.email;
                            }
                            window.Microsoft.CIFramework.searchAndOpenRecords("contact", filter, false, '', 0);
                        }
                    }
                    else {      //If memberid not passed from parent do global search by email
                        filter = "?$select=wcu_emailaddress1&$search= " + conv.email;
                        window.Microsoft.CIFramework.searchAndOpenRecords("contact", filter, false, '', 0);
                    }
                }
                else if (convObj != null && convObj !== {}) {
                    console.log("createOrSearchTaskinCRM-convObj " + convObj);
                    dataService.setData({ addConversation: conv });
                    if (convObj.EntityLogicalName != null && convObj.EntityLogicalName == 'opportunity') {

                        if (convObj.parentcontactid != null && convObj.parentcontactid !== '') {
                            var partyList = [];
                            var partyrcd = {};
                            //Member
                            partyrcd["partyid_contact@odata.bind"] = "/contacts(" + convObj.parentcontactid + ")";
                            partyrcd["participationtypemask"] = "2";
                            partyList.push(partyrcd);
                            data["wcu_sms_activity_parties"] = partyList;
                        }

                        //Application
                        data["regardingobjectid_opportunity@odata.bind"] = "/opportunities(" + convObj.EntityId + ")";
                        //Memberprofile
                        if (convObj.EntityLogicalName2 && convObj.EntityLogicalName2 === 'wcu_memberprofile') {
                            data["wcu_memberprofile_wcu_sms@odata.bind"] = "/wcu_memberprofiles(" + convObj.EntityId2 + ")";
                        }
                        
                    }
                    else if (convObj.EntityLogicalName != null && convObj.EntityLogicalName == 'contact') {
                        data["regardingobjectid_contact@odata.bind"] = "/contacts(" + convObj.EntityId + ")";
                    }
                }
                // var response = await createRecord("wcu_sms", );
                console.log("createOrSearchTaskinCRM-Before Create");
                debugger;

                var result = "";
                var response = new Object();
                var activityId = "";
                try {

                    result = await window.Microsoft.CIFramework.createRecord("wcu_sms", JSON.stringify(data));
                    response = JSON.parse(result);
                    activityId = response.id;
                }
                catch (err) {
                    console.log(err, "Error Message");
                    var filter = '?$select= wcu_conversationid,activityid &$filter=wcu_conversationid  eq ' + conv.conversationId + ' ';

                    result = await window.Microsoft.CIFramework.searchAndOpenRecords("wcu_sms", filter, true);  // Retrieve activity  records with that conv id
                    console.log("createOrSearchTaskinCRM-searchActivityinCRM result " + result);
                    var activities = JSON.parse(result);
                    console.log("createOrSearchTaskinCRM-Retrieve activity  records with that conv id " + Object.keys(activities).length);
                    if (Object.keys(activities).length != 0) {
                        activityId = activities[0].activityid;
                    }
                }
                finally {
                    console.log("createOrSearchTaskinCRM-Create SMS Task " + result);

                    convObj.ActivityId = activityId;
                    if (conv.source === "Inbound" || conv.channel === 'Chat') {
                        convObj.EntityLogicalName = "wcu_sms";
                        convObj.EntityKeyfield = "activityid";
                        convObj.EntityId = activityId;
                    }    
                    if (contactId) {
                        convObj.EntityLogicalName = "contact";
                        convObj.EntityKeyfield = "contactid";
                        convObj.EntityId = contactId;
                    }  
                    reqDataObj.conversation = {};
                    reqDataObj.conversation.conversationId = conv.conversationId;
                    reqDataObj.conversation.ConversationAttributes = JSON.stringify(convObj);
                    var conversation = Object.assign({}, conv);
                    conversation.conversationAttributes = reqDataObj.conversation.ConversationAttributes;
                    dataService.setData({ updateConversation: conversation });
                    console.log("createOrSearchTaskinCRM-updateConversation" + result);
                    updateConversation(reqDataObj); //DB
                }
            }
        }
    }
    catch (err) {
        console.log(err, "Error Message");
    }


}

export function updateConversation(reqDataObj) {
    let url = apiConfig.UPDATE_CONVERSATION;
    InvokePostServiceCall(url, JSON.stringify(reqDataObj))
        .then((resp) => {
            console.log("Conversation updated");
        })
        .catch((err) => {
            console.log(err, "Error Message");
        });

}


export async function getLinkedEntitiesD(activityId) {
    var linkedRecords = {};
    linkedRecords.isRecordAvailable = false;
    var filter = '?$select= participationtypemask,_partyid_value,_activityid_value &$orderby= participationtypemask asc &$filter= (participationtypemask eq 2 or participationtypemask eq 8) and  _activityid_value  eq ' + activityId + ' ';
    var result = await window.Microsoft.CIFramework.searchAndOpenRecords("activityparty", filter, true);  // Retrieve activity party records which are "To" and "Regarding"
    var activities = JSON.parse(result);
    for (var i = 0; i < Object.keys(activities).length; i++) {
        if (activities[i]["_partyid_value@Microsoft.Dynamics.CRM.lookuplogicalname"] == "opportunity") {
            let oppFilter = "?$select=name,wcu_externalid,wcu_productname,opportunityid &$filter=opportunityid eq " + activities[i]["_partyid_value"] + "";
            linkedRecords.opportunity = await window.Microsoft.CIFramework.searchAndOpenRecords("opportunity", oppFilter, true);
            linkedRecords.isRecordAvailable = true;
            try {
                var filter1 = '?$select=_wcu_memberprofile_value &$filter=activityid eq ' + activityId + ' &$expand=wcu_memberprofile_wcu_sms($select =wcu_firstname,wcu_lastname,wcu_name)';
                linkedRecords.memberprofile = await window.Microsoft.CIFramework.searchAndOpenRecords("wcu_sms", filter1, true);  // Retrieve member profile data by activity id
            } catch (error) {
                console.log(error)
            }
        }
        if (activities[i]["_partyid_value@Microsoft.Dynamics.CRM.lookuplogicalname"] == "contact") {
            let contactFilter = "?$select=wcu_emailaddress1,wcu_telephone1,wcu_telephone2,contactid,firstname,lastname, fullname, wcu_mobilephone &$filter= contactid eq " + activities[i]["_partyid_value"] + "";
            linkedRecords.contact = await window.Microsoft.CIFramework.searchAndOpenRecords("contact", contactFilter, true);
            linkedRecords.isRecordAvailable = true;
        }
        if (activities[i]["_partyid_value@Microsoft.Dynamics.CRM.lookuplogicalname"] == "lead") {
            let prospectFilter = "?$select=emailaddress1,leadid,firstname,lastname,fullname,telephone1,telephone2,telephone3 &$filter=leadid  eq " + activities[i]["_partyid_value"] + "";
            linkedRecords.lead = await window.Microsoft.CIFramework.searchAndOpenRecords("lead", prospectFilter, true);
            linkedRecords.isRecordAvailable = true;
        }
       
        // if (activities[i]["_partyid_value@Microsoft.Dynamics.CRM.lookuplogicalname"] === "wcu_memberprofile") {
        //     let oppFilter = "?$select=wcu_donottext,wcu_opportunityid,wcu_lastname,wcu_externalid,wcu_memberprofileid,wcu_mobilephone,wcu_firstname &$filter=wcu_memberprofileid eq " + activities[i]["_partyid_value"] + "";
        //     linkedRecords.memberprofile = await window.Microsoft.CIFramework.searchAndOpenRecords("wcu_memberprofile", oppFilter, true);
        //     linkedRecords.isRecordAvailable = true;
        // }
        if (activities[i]["_partyid_value@Microsoft.Dynamics.CRM.lookuplogicalname"] == "wcu_finaccount") {
            var properties = ["wcu_finproducttype", "wcu_finproductid", "wcu_accountid", "wcu_name"].join();
            let finAccountFilter = "?$select=" + properties + " &$filter=wcu_finaccountid eq " + activities[i]["_partyid_value"] + "";
            linkedRecords.finaccount = await window.Microsoft.CIFramework.searchAndOpenRecords("wcu_finaccount", finAccountFilter, true);
            linkedRecords.isRecordAvailable = true;
        }

    }

    return linkedRecords;


}


export async function getLinkedEntityNamesD(activityId) {
    var linkedRecords = {};
    var hasContact=false;
    linkedRecords.isRecordAvailable = false;
    var filter = '?$select= participationtypemask,_partyid_value,_activityid_value &$orderby= participationtypemask asc &$filter= (participationtypemask eq 2 or participationtypemask eq 8) and  _activityid_value  eq ' + activityId + ' ';
    var result = await window.Microsoft.CIFramework.searchAndOpenRecords("activityparty", filter, true);  // Retrieve activity party records which are "To" and "Regarding"
    var activities = JSON.parse(result);
    var linkedEntitiesNames = [];
    for (var i = 0; i < Object.keys(activities).length; i++) {
        linkedEntitiesNames.push(activities[i]["_partyid_value@Microsoft.Dynamics.CRM.lookuplogicalname"]);
    }
    linkedEntitiesNames.push("users");
    linkedEntitiesNames.push("userattributes");
    linkedEntitiesNames.push("teamattributes");
    hasContact = ["contact","opportunity"].every(value => {
        return linkedEntitiesNames.includes(value);
    });
    if (hasContact)
        linkedEntitiesNames = linkedEntitiesNames.filter(item => item !== "contact")

    return linkedEntitiesNames;
}



export async function openRecordinCRMD(conv, crmMemberFields, openRecord) {
    try {
        console.log("openRecordinCRMD-Started");
        let filter = '';

        if (conv.conversationAttributes != null && conv.conversationAttributes !== '') {
            var convObj = JSON.parse(conv.conversationAttributes);
            var entityKeyField = convObj.EntityKeyfield;
            var entityId = convObj.EntityId;
            var entityLogicalName = convObj.EntityLogicalName;
            if (convObj.ActivityId != null && convObj.ActivityId !== '') {  // Check if the activity id is present
                console.log("openRecordinCRMD-ActivityId Exists & Open in CRM");
                filter = '?$select= participationtypemask,_partyid_value,_activityid_value &$orderby= participationtypemask desc &$filter= (participationtypemask eq  2 or participationtypemask eq  8 ) and  _activityid_value  eq ' + convObj.ActivityId + ' ';
                var result = await window.Microsoft.CIFramework.searchAndOpenRecords("activityparty", filter, true);  // Retrieve activity party records which are "To" and "Regarding"
                console.log("openRecordinCRMD-result" + result);
                var activity = JSON.parse(result)[0];
                // searchRecordinCRM("wcu_sms", filter);
                if (activity != null && activity._partyid_value != null) {  // Checking the actvity is having the  linked to contact or opp
                    entityKeyField = activity["_partyid_value@Microsoft.Dynamics.CRM.lookuplogicalname"] + "id";
                    entityId = activity["_partyid_value"];
                    entityLogicalName = activity["_partyid_value@Microsoft.Dynamics.CRM.lookuplogicalname"];
                    var lstactivities = JSON.parse(result);
                    console.log("openRecordinCRMD-updatetokenswithcrmdata" + result);
                    updateTokenswithCRMdata(lstactivities, conv, convObj, crmMemberFields);
                }
            }
            filter = '?$select= ' + entityKeyField + '&$filter= ' + entityKeyField + ' eq ' + entityId + ' ';
            console.log("openRecordinCRMD-openRecord" + openRecord);
            if (openRecord == true) {
                window.Microsoft.CIFramework.searchAndOpenRecords(entityLogicalName, filter, false).then(
                    function success(result) {
                    },
                    function (error) {
                        console.log(error.message);
                    }
                );
            }
        }
        console.log("openRecordinCRMD-Ended");
    }
    catch (err) {
        console.log(err, "Error Message");
    }
}


export async function openEnityinCRMD (entityId, entityLogicalName, entityKeyField) {
    var filter = '?$select= ' + entityKeyField + '&$filter= ' + entityKeyField + ' eq ' + entityId + ' ';
    window.Microsoft.CIFramework.searchAndOpenRecords(entityLogicalName, filter, false).then(
        function success(result) {
        },
        function (error) {
            console.log(error.message);
        }

    );
}




export const openContextRecordInCRM = (conv, crmMemberFields, entity) => (event) => {
    openRecordinCRMD(conv, crmMemberFields, entity);
}

export async function updateTokenswithCRMdata(activities, conv, convObj, crmMemberFields) {
    try {
        let conversation = {};
        let convAttributes = {};
        let contact = new Object();
        var memberProfileFields = ["wcu_donottext", "wcu_opportunityid", "wcu_lastname", "wcu_externalid", "wcu_memberprofileid", "wcu_mobilephone", "wcu_firstname", "wcu_emailaddress1", "wcu_address1_stateprovince","wcu_address1_timezone"];
        var oppFields = ["parentcontactid"];
        var contactFields = ['firstname', 'emailaddress1', 'address1_stateorprovince','wcu_address1_timezone'];
        var finAcctFields = ["wcu_finproducttype", "wcu_finproductid", "wcu_accountid", "wcu_name"];
        conversation = Object.assign({}, conv);
        convAttributes = JSON.parse(conversation.conversationAttributes);
        crmMemberFields.forEach((val, key) => {
            if (val.includes(".")) {
                if (val.split(".")[0].toLowerCase() == 'wcu_memberprofile') {
                    memberProfileFields.push(val.split(".")[1].toLowerCase());
                }
                else if (val.split(".")[0].toLowerCase() == 'opportunity') {
                    oppFields.push(val.split(".")[1].toLowerCase());
                }
                else if (val.split(".")[0].toLowerCase() == 'contact') {

                    contactFields.push(val.split(".")[1].toLowerCase());
                }
                else if (val.split(".")[0].toLowerCase() == 'wcu_finaccount') {
                    finAcctFields.push(val.split(".")[1].toLowerCase());
                }
            }
        });
        // Update tokens for all the linked entities
        for (let i = Object.keys(activities).length; i > 0; i--) {
            convAttributes = JSON.parse(conversation.conversationAttributes);
            var activity = activities[i - 1];
            let entityKeyField = activity["_partyid_value@Microsoft.Dynamics.CRM.lookuplogicalname"] + "id";
            let entityId = activity["_partyid_value"];
            let entityLogicalName = activity["_partyid_value@Microsoft.Dynamics.CRM.lookuplogicalname"];




            //if ((conv.customerName == null || !!conv.customerName || conv.customerName == 'Unknown')) {
            if (entityLogicalName === 'contact' || entityLogicalName === 'lead') {
                if (activity.participationtypemask == 2) {
                    conversation.customerName = activity["_partyid_value@OData.Community.Display.V1.FormattedValue"];
                    convAttributes.CustomerName = activity["_partyid_value@OData.Community.Display.V1.FormattedValue"];
                    convAttributes.wcu_externalid = convObj.wcu_externalid;
                    convAttributes.wcu_firstname = activity["_partyid_value@OData.Community.Display.V1.FormattedValue"];
                }
                if (entityLogicalName === 'contact') {

                    let contactFilter = '?$select=' + contactFields + '&$filter= contactid eq ' + entityId + "";
                    var conResult = await window.Microsoft.CIFramework.searchAndOpenRecords("contact", contactFilter, true);
                    var conResultObj = {};
                    if (conResult != null) {
                        conResultObj = JSON.parse(conResult);
                        contact.email = conResultObj[0]["emailaddress1"];
                        contact.stateCode = conResultObj[0]["address1_stateorprovince"];
                        contact.timezone = conResultObj[0]["wcu_address1_timezone"];
                        crmMemberFields.forEach((val, key) => {
                            // if (!convAttributes.hasOwnProperty(key)) {
                            if (val.includes(".")) {
                                if (val.split(".")[0].toLowerCase() == 'contact') {
                                    convAttributes[key] = conResultObj[0][val.split(".")[1].toLowerCase()];
                                }
                            }
                            //  }
                        })
                    }


                }
            }
            else if (entityLogicalName === 'opportunity') {
                let oppfilter = '?$select= wcu_externalid &$filter= opportunityid  eq ' + entityId + '';
                var result = await window.Microsoft.CIFramework.searchAndOpenRecords("opportunity", oppfilter, true);
                var opportunities = JSON.parse(result);
                var opp = {};
                if (opportunities != null) {
                    opp = opportunities[0];
                    var formattedMobileNumber = '';
                    var mobile = conv.customerPhoneNumber.replaceAll(/[^a-zA-Z0-9]/g, '');
                    if (mobile && mobile.indexOf('1') === 0) {
                        mobile = mobile.substring(1);
                    }
                    formattedMobileNumber = '%25' + mobile.replace(/.{1}/g, '$&%25');
                    //formattedMobileNumber = '*' + mobile.replace(/.{1}/g, '$&*');
                    let filter ="";
                    if (convObj.EntityId2 && convObj.EntityKeyfield2 === "wcu_memberprofileid") {
                        filter = "?$select=" + memberProfileFields + "&$filter=wcu_memberprofileid eq " + convObj.EntityId2 //get only member profile details by id
                    } else {
                        filter = "?$select=" + memberProfileFields + "&$filter=contains(wcu_mobilephone, '" + formattedMobileNumber + "') and  wcu_opportunityid/opportunityid eq " + entityId + "  &$expand=wcu_opportunityid($select=" + oppFields + ")";
                    }

                    try {
                        result = await window.Microsoft.CIFramework.searchAndOpenRecords("wcu_memberprofile", filter, true);
                        if (result != null) {
                            var memberProfiles = JSON.parse(result);
                            var memberProfile;
                            if (memberProfiles != null) {

                                memberProfile = memberProfiles[0];
                            }
                            if (memberProfile != null) {
                                var firstName = memberProfile["wcu_firstname"] != null ? memberProfile["wcu_firstname"] : "";
                                var lstName = memberProfile["wcu_lastname"] != null ? memberProfile["wcu_lastname"] : ""
                                conversation.customerName = firstName + " " + lstName;
                                contact.email = memberProfile["wcu_emailaddress1"];
                                contact.stateCode = memberProfile["wcu_address1_stateprovince@OData.Community.Display.V1.FormattedValue"];
                                contact.timezone = memberProfile.wcu_address1_timezone;
                                convAttributes.ApplicationNumber = opp["wcu_externalid"];
                                convAttributes.PhoneNumber = memberProfile.wcu_mobilephone;
                                crmMemberFields.forEach((val, key) => {
                                    //  if (!convAttributes.hasOwnProperty(key)) {
                                    if (val.includes(".")) {
                                        if (val.split(".")[0].toLowerCase() == 'wcu_memberprofile') {
                                            convAttributes[key] = memberProfile[val.split(".")[1].toLowerCase()];
                                        }
                                        else if (val.split(".")[0].toLowerCase() == 'opportunity' && memberProfile.wcu_opportunityid) {
                                            convAttributes[key] = memberProfile.wcu_opportunityid[val.split(".")[1].toLowerCase()];
                                        }
                                    }
                                    // }
                                })
                            }
                        }
                    }
                    catch (ex) {
                        console.log("Excepton" + ex);
                    }
                    finally {
                        convAttributes.ApplicationNumber = opp["wcu_externalid"];
                    }
                }
            }
            else if (entityLogicalName == 'wcu_finaccount') {
                let finAccFilter = '?$select=' + finAcctFields + '&$filter= wcu_finaccountid eq ' + entityId + "";
                var finAccResult = await window.Microsoft.CIFramework.searchAndOpenRecords("wcu_finaccount", finAccFilter, true);
                var finAccResultObj = {};
                if (finAccResult != null) {
                    finAccResultObj = JSON.parse(finAccResult);
                }
                crmMemberFields.forEach((val, key) => {
                    //  if (!convAttributes.hasOwnProperty(key)) {
                    if (val.includes(".")) {
                        if (val.split(".")[0].toLowerCase() == 'wcu_finaccount') {
                            convAttributes[key] = finAccResultObj[0][val.split(".")[1].toLowerCase()];
                        }
                    }
                    // }
                })
            }
            convAttributes.ActivityId = convObj.ActivityId;
            convAttributes.EntityLogicalName = entityLogicalName;
            convAttributes.EntityKeyfield = entityKeyField;
            convAttributes.EntityId = entityId;
            conversation.conversationAttributes = JSON.stringify(convAttributes);
        }


        conversation.requiredConvAttrUpdate = false;
        conversation.linkedEntities = "";
        dataService.setData({ updateContextConv: conversation });           // Updates the conversation customerName on the client side.
        var reqDataObj = {};
        reqDataObj.conversation = conversation;
        reqDataObj.contact = contact;
        updateConversation(reqDataObj);
    }
    catch (exception) {
        console.log("exception" + exception)
    }
    // }
}