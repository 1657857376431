/***************
* 11-11/2022 TextField:766=>disabled auto suggetion
* 08-01-2023    SUDAM B CHAVAN     TEXTING-1522 - templates across teams observations.
* 08-04-2023    SUDAM B CHAVAN     TEXTING-1545 - Multiple records of same templates are creating when we click on add
*08-31-2023     PRABHAT           TEXTING-1632 - Implementation changes in object history for templates
*09-06-2023     PRABHAT           TEXTING-1663 - History Tab is not needed on Add Templates page.
*09-07-2023     SUDAM B CHAVAN    TEXTING-1669 - Select and updating the Template data message is still showing the Template I’d.
*12-08-2023     ONKAR             TEXTING-1907 - Admin UI - Click on cancel button in Template details page is removing highlight for template menu icon.
***********/
import React, { useState, useEffect, useContext, useRef } from "react";
import Box from "@mui/material/Box";
import apiConfig from "../../api/apiConfig";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../AuthContext";
import { InvokePostServiceCall } from "../../api/serviceUtil";
import SnackBar from "../../components/SnackBar";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Divider,
  Paper,
  ClickAwayListener,
  Checkbox,
  Dialog,
  Autocomplete,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  MenuItem,
  Popper,
} from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { ListItemButton } from "@mui/material";
import { getCursorXY } from "../../extensions/getCursorPosition";
import moment from "moment";
import { useTheme } from "@mui/material/styles";
import CustomTabList from "../../components/CustomTabList";
import AssociateTeamsComp from "./AssociateTeamsComp";
import ObjectHistoryComp from "../teams/ObjectHistoryComp";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export default function AddUpdateTemlate() {
  const AddTemplate = "Add Template";
  const UpdateTemplate = "Update Template";
  const location = useLocation();
  const navigate = useNavigate();
  const appContext = useContext(AuthContext);
  const theme = useTheme();
  const inputRef = useRef();
  const isAddTemplate = location.state ? location.state.isAddTemplate : true;

  const [checked, setChecked] = React.useState(
    isAddTemplate ? true : location.state.templateDetails.isActive
  );
  const [open, setOpen] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(
    isAddTemplate ? "" : location.state.templateDetails.templateBody
  );
  const [autocompleteValue, setAutoCompleteValue] = useState("");
  const [cursorPosition, setCursorPosition] = useState();
  const [teamsData, setTeamsData] = useState([]);
  const [tokens, seTokens] = useState([]);
  const [severity, setSeverity] = useState("success");
  const [mentionText, setMentionText] = useState("");
  const [selectedMention, setSelectedMention] = useState(0);
  const [popperX, setPopperX] = useState(0);
  const [popperY, setPopperY] = useState(0);
  const [posY, setPosY] = useState(0);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [templateData, setTemplateData] = useState({
    azureAd: null,
    azureAdid: null,
    createdBy: null,
    createdByNavigation: null,
    createdDate: null,
    isActive: null,
    lastModifiedBy: null,
    lastModifiedByNavigation: null,
    lastModifiedDate: null,
    team: null,
    teamId: null,
    templateBody: null,
    templateContext: null,
    templateId: null,
    templateName: null,
    templateType: null,
    user: null,
    userId: appContext.userInfo.userId,
    category: null,
    department : null
  });
  const [modifiedBy, setModifiedBy] = useState({
    lastModifiedByName: "",
    lastModifiedDate: "",
  });
  const [createdBy, setCreatedBy] = useState({
    createdByName: "",
    createdDate: "",
  })
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState("1");
  const tabList = [
    { label: "Details", value: "1", displayOrder: 1 },
    { label: "Associated Teams", value: "2", displayOrder: 2 },
    { label: "History", value: "3", displayOrder: 3 }
  ];
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setSelectedMention(0)
  };
  const handleClickAway = () => {
    setAnchorEl(null);
  }
  const openPoper = Boolean(anchorEl);
  const id = openPoper ? 'simple-popper' : undefined;
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    //prevented page navigation on add/update//uncommented due to  TEXTING-1545
    if (severity === "success") {
      navigate("/templates", { replace: true });
    }
    setOpenSnackbar(false);
  };

  useEffect(() => {
    if (appContext.messageApiToken) {
      //on refreshed the page wouldn't be get call 
       InvokePostServiceCall(apiConfig.GET_TOKENS, { isActive: true })
      .then((data) => {
        console.log("data", data);
        if (data.data.tokens.length > 0) {
          let tempArr = [];
          data.data.tokens.forEach((item) => {
            item.id = item.tokenId;
            item.display = item.tokenName;
            tempArr.push(item)

          })
          seTokens(tempArr);
        }

      })
      .catch((error) => {
        setSeverity("error");
        setSnackbarMsg(error.message);
        setOpenSnackbar(true);
        console.log("teamsApi", error);
      });

    }
  }, [appContext.messageApiToken]);

  useEffect(() => {
    if (!isAddTemplate) {
      let templateDetails = location.state.templateDetails;
      setTemplateData({
        azureAd: templateDetails.azureAd,
        azureAdid: templateDetails.azureAdid,
        createdBy: templateDetails.createdBy,
        createdByName: templateDetails.createdByName,
        createdByNavigation: templateDetails.createdByNavigation,
        createdDate: templateDetails.createdDate,
        isActive: templateDetails.isActive,
        lastModifiedBy: templateDetails.lastModifiedBy,
        lastModifiedByName: templateDetails.lastModifiedByName,
        lastModifiedByNavigation: templateDetails.lastModifiedByNavigation,
        lastModifiedDate: templateDetails.lastModifiedDate,
        team: templateDetails.team,
        teamId: templateDetails.teamId,
        templateBody: templateDetails.templateBody,
        templateContext: templateDetails.templateContext,
        templateId: templateDetails.templateId,
        templateName: templateDetails.templateName,
        templateType: templateDetails.templateType,
        user: templateDetails.user,
        userId: templateDetails.userId,
        category: templateDetails.category,
        department: templateDetails.department === "" ? "None" : templateDetails.department,
      });
      setModifiedBy({
        lastModifiedByName: templateDetails.lastModifiedByName,
        lastModifiedDate: moment(templateDetails.lastModifiedDate).local().format("LLL"),
      })
      setCreatedBy({
        createdByName: templateDetails.createdByName,
        createdDate: moment(templateDetails.createdDate).local().format("LLL")
      })
    }
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleChangeContext = (value, selectLabel) => {
    if (selectLabel === "Context") {
      setTemplateData({ ...templateData, templateContext: value });
    } else if (selectLabel === "Category") {
      setTemplateData({ ...templateData, category: value });
    } else if (selectLabel === "Department") {
      setTemplateData({ ...templateData, department: value });
    } else if (selectLabel === "Template Type") {
      setTemplateData({ ...templateData, templateType: value });
    } else if (selectLabel === "Teams") {
      console.log(value, "teamValue");

      setTemplateData({
        ...templateData,
        teamId: value,
      });
    }
  };

  function handleSelectTemplate(main_string, ins_string, pos, mode) {
    if (typeof pos == "undefined") {
      pos = 0;
    }
    if (typeof ins_string == "undefined") {
      ins_string = "";
    }
    let startString = main_string;
    if (mode === "popper") {
      startString = main_string.slice(0, pos - (mentionText.length + 1))
    }
    setSelectedTemplate(
      startString.slice(0, pos) +
      " ##" +
      ins_string +
      "## " +
      main_string.slice(pos)
    );
    handleClose();
  }

  const handleAddUpdateTemplate = () => {
    setLoadingBtn(true);
    let templateBody = selectedTemplate?.trim();
    let addRequestBody = {
      actionType: "Add",
      template: {
        templateName: templateData.templateName,
        templateBody: templateBody,
        isActive: checked,
        templateContext: "TEAM",
        templateType: templateData.templateType,
        category: templateData.category,
        department: templateData.department === 'None' ? "" : templateData.department,
        teamId: templateData.teamId,
        userId: templateData.userId,
        team: null,
      },
    };
    let updateRequestBody = {
      actionType: "Update",
      template: {
        templateId: templateData.templateId,
        templateName: templateData.templateName,
        templateBody: templateBody,
        isActive: checked,
        templateContext: "TEAM",
        templateType: templateData.templateType,
        category: templateData.category,
        department: templateData.department === 'None' ? "" : templateData.department,
        teamId: templateData.teamId,
        userId: templateData.userId,
        team: templateData.team,
      },
    };
    console.log(
      isAddTemplate ? addRequestBody : updateRequestBody,
      "requestBody"
    );

    InvokePostServiceCall(
      apiConfig.SAVE_TEMPLATE,
      isAddTemplate ? addRequestBody : updateRequestBody
    )
      .then((data) => {
        setSeverity("success");
        if (isAddTemplate) {
          setTemplateData({
            ...templateData,
            templateId: data.data.templateId,
          });
        }
        setSnackbarMsg(isAddTemplate ? "Template Added Successfully." : "Template Updated Successfully.");
        setOpenSnackbar(true);
        setLoadingBtn(false);
      })
      .catch((err) => {
        setSeverity("error")
        setSnackbarMsg(err.message)
        console.log(err.message, "ERROR DATA");
        setOpenSnackbar(true);
        setLoadingBtn(false);
      });
  };
  const getMentionText = (s) =>
    s.includes("@") && s.substr(s.lastIndexOf("@") + 1).split(" ")[0];

  const onKeyDownFuntion = (e) => {
    if (anchorEl && e.key === "ArrowDown") {
      e.preventDefault();
      if (selectedMention < tokens.length - 1) {
        setSelectedMention(selectedMention + 1);
      }
      setCursorPosition(e.target.selectionStart);
    } else if (anchorEl && e.key === "ArrowUp") {
      e.preventDefault();
      if (selectedMention > 0) {
        setSelectedMention(selectedMention - 1);
      }
      setCursorPosition(e.target.selectionStart);
    }
    if (!anchorEl) {
      setCursorPosition(e.target.selectionStart);
    }
    if (anchorEl && e.key === "Enter") {
      e.preventDefault();
      tokens.forEach((item, index) => {
        if (index === selectedMention) {
          handleSelectTemplate(
            selectedTemplate,
            item.tokenName,
            e.target.selectionStart,
            "popper"
          );
          setAnchorEl(null);
        }
      });
    }
    if (e.key === "Backspace") {
      if (selectedTemplate.includes("#")) {
        if (selectedTemplate[e.target.selectionStart - 1] === "#" && selectedTemplate[e.target.selectionStart - 2] === "#" || selectedTemplate[e.target.selectionStart] === "#") {
          let totalString = selectedTemplate;
          let indexArray = [];
          for (let i = e.target.selectionStart; i > 0; i--) {
            if (selectedTemplate[i] === "#") {
              indexArray.push(i);
            }
          }
          if (selectedTemplate[e.target.selectionStart] === "#") {
            if (indexArray.length > 1) {
              totalString = totalString.slice(0, indexArray[2] ? indexArray[2] : indexArray[1]) + totalString.slice(indexArray[0] + 2, totalString.length)
              setSelectedTemplate(totalString + " ");
            }
          } else {
            let secondindex = totalString.slice(0, e.target.selectionStart - 2).lastIndexOf("##");
            let vl = totalString.slice(secondindex, e.target.selectionStart - 2) && totalString.slice(secondindex, e.target.selectionStart - 2).replace(/ /g, '')
            if (vl && totalString.includes(vl)) {
              totalString = totalString.slice(0, indexArray[3]) + totalString.slice(indexArray[0] + 1, totalString.length);
              setSelectedTemplate(totalString + " ");
            } else {
              let index = totalString.indexOf("##", indexArray[1] + 1);
              totalString = totalString.slice(0, indexArray[1] - 1) + totalString.slice(index + 2, totalString.length);
            }
            setSelectedTemplate(totalString + " ");
          }
        }
      }
    }
  };
  const tempContentOnChange = (e) => {
    setSelectedTemplate(e.target.value);
    setCursorPosition(e.target.selectionStart);
    const { x, y } = getCursorXY(e.target, e.target.selectionEnd);
    if (e.target.offsetHeight === 23) {
      setPosY(y);
    }
    if (e.target.offsetHeight > 23) {
      let offsetVal = e.target.offsetHeight / 23;
      let valOne = offsetVal - 2;
      let valTwo = valOne * 46;
      let valThree = y - posY;
      let finalVal = valTwo - valThree + 23;
      if (finalVal > 0) {
        setPopperY(-finalVal);
      } else {
        setPopperY(0);
      }
    }
    setPopperX(x);
    if (e.target.value[e.target.selectionEnd - 1] === "@") {
      if (e.target.value.length > 1) {
        if (e.target.value[e.target.selectionEnd - 2] === " ") {
          handleClick(e);
        }
      } else {
        handleClick(e);
      }
    }
    let currentWord = e.target.value.slice(0, e.target.selectionEnd);
    let n = currentWord.split(" ");
    let atVal = n[n.length - 1];
    if (atVal.indexOf("@", 1) !== -1) {
      setAnchorEl(null);
    }
    if (!atVal.includes("@")) {
      setAnchorEl(null);
    }
    setMentionText(getMentionText(e.target.value));
  };

  return (
    <>
      <SnackBar
        openSnackbar={openSnackbar}
        handleCloseSnackbar={handleCloseSnackbar}
        severity={severity}
        userMessage={snackbarMsg}
      />
      <div style={{ marginLeft: 20, marginRight: 20 }}>
        <h2>{isAddTemplate ? AddTemplate : UpdateTemplate}</h2>
        <TabContext value={value} >
          <CustomTabList tabList={tabList} showTabsCount={3} handleChangeTab={(event, selectedTabValue) => {
            setValue(selectedTabValue)
          }}></CustomTabList>
          <TabPanel value="1">
            <div>
        <Box sx={theme.customStyle.textRow}>
          <TextField
            size="small"
            margin="dense"
            variant="standard"
            value={templateData.templateName}
            fullWidth
            required
            label="Template Name"
            InputLabelProps={{
              shrink: true,
            }}
            id="updatetemplateName"
            inputProps={{ //not show suggetions
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
            onChange={(e) => {
              setTemplateData({
                ...templateData,
                templateName: e.target.value,
              });
            }}
          />
        </Box>
        <Box sx={theme.customStyle.textRow}>
          <TextField
            size="small"
            margin="dense"
            variant="standard"
            fullWidth
            multiline
            required
            value={selectedTemplate}
            label="Template Content"
            InputLabelProps={{
              shrink: true,
            }}
            id="updatetemplateselectedTemplate"
            inputProps={{ //not show suggetions
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
            inputRef={inputRef}
            onChange={tempContentOnChange}
            onClick={(e) => {
              setCursorPosition(e.target.selectionStart);
            }}
            onKeyDown={onKeyDownFuntion}
          />

          <Button
            size="small"
            onClick={() => {
              handleClickOpen();
            }}
            sx={theme.customStyle.modalbtn02}
          >
            Select Tokens
          </Button>
        </Box>

        <span>Is Active</span>
        <Checkbox checked={checked} onChange={handleChange} />
        <Box sx={{ ...theme.customStyle.textRow, marginTop: 2 }}>
          <TextField
            size="small"
            select
            required
            label="Template Type"
            value={templateData.templateType}
            fullWidth
            onChange={(e) => {
              handleChangeContext(e.target.value, "Template Type");
            }}
            InputLabelProps={{
              shrink: true,
            }}
            helperText="Please select template type."
            variant="standard"
            id="updatetemplatetemplatetype"
            inputProps={{ //not show suggetions
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          >
            <MenuItem value={"Sales"}>Sales</MenuItem>
            <MenuItem value={"Services"}>Services</MenuItem>
          </TextField>
        </Box>
        <Box sx={{ ...theme.customStyle.textRow, marginTop: 2 }}>
          <TextField
            size="small"
            select
            required
            label="Department"
            value={templateData.department}
            fullWidth
            onChange={(e) => {
              handleChangeContext(e.target.value, "Department");
            }}
            inputProps={{ //not show suggetions
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
            InputLabelProps={{
              shrink: true,
            }}
            helperText="Please select Department type."
            variant="standard"
          >
            <MenuItem
              key="None"
              value="None"
            >
              None
            </MenuItem>
            {appContext.departments && appContext.departments.map((option) => (
              <MenuItem
                key={option}
                value={option}
              >
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box sx={{ ...theme.customStyle.textRow, marginTop: 2 }}>
          <TextField
            size="small"
            select
            required
            label="Category "
            value={templateData.category}
            fullWidth
            onChange={(e) => {
              handleChangeContext(e.target.value, "Category");
            }}
            InputLabelProps={{
              shrink: true,
            }}
            id="updatetemplateCategory"
            inputProps={{ //not show suggetions
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
            helperText="Please select Category."
            variant="standard"
          >
            <MenuItem value={"Introduction"}>Introduction</MenuItem>
            <MenuItem value={"Other"}>Other</MenuItem>
          </TextField>l
        </Box>
        {!isAddTemplate && (
          <>
            <Box sx={theme.customStyle.textRow}>
              <TextField
                size="small"
                margin="dense"
                disabled
                variant="standard"
                value={`${createdBy.createdByName + " (" + createdBy.createdDate + ")"}`}
                fullWidth
                label="Created By"
                InputLabelProps={{
                  shrink: true,
                }}
                id="updatetemplatecreatedBy"
                inputProps={{ //not show suggetions
                  autoComplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
              />
            </Box>
            <Box sx={theme.customStyle.textRow}>
              <TextField
                size="small"
                margin="dense"
                disabled
                variant="standard"
                value={`${modifiedBy.lastModifiedByName + " (" + modifiedBy.lastModifiedDate + ")"}`}
                fullWidth
                label="Modified By"
                InputLabelProps={{
                  shrink: true,
                }}
                id="updatetemplatetemodifiedBy"
                inputProps={{ //not show suggetions
                  autoComplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
              />
            </Box>
          </>
        )}
        <LoadingButton
          loading={loadingBtn}
          variant="contained"
          disabled={
            !templateData.templateName ? true :
              // !templateData.templateContext ? true :
              !templateData.templateType ? true :
                // !templateData.teamId ? true :
                !templateData.category ? true :
                  !templateData.department ? true :
                    !selectedTemplate ? true : false
          }
          onClick={() => {
            handleAddUpdateTemplate();
          }}
        >
          {!isAddTemplate ? UpdateTemplate : AddTemplate}
        </LoadingButton>
        <Button
          sx={{ marginLeft: 2 }}
          variant="contained"
          onClick={() => {
            navigate("/templates");
          }}
        >
          Cancel
        </Button>
              </div>
          </TabPanel>
          <TabPanel value="2">
           
            <AssociateTeamsComp isAdd={isAddTemplate} templateId={templateData.templateId} />
             
                  </TabPanel>
                  <TabPanel value="3">
                      {isAddTemplate ? (
                          <div>
                              <h5 style={{
                                  color: "red",
                                  display: "flex",
                                  alignItems: "flex-end",
                                  justifyContent: "center"
                              }} >No Records.</h5>
                          </div>
                      ) : (
                          <ObjectHistoryComp
                              objectName="Template"
                              objectKeyname={templateData}
                              numOfRows={10}
                              istab={true}
                          />
                      )}
                  </TabPanel>
        </TabContext>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle>Select Token</DialogTitle>
        <DialogContent>
          <Autocomplete
            freeSolo
            id="tags-standard"
            options={tokens}
            disableClearable
            getOptionLabel={(option) => option.tokenName}
            onChange={(event, newValue) => {
              // console.log(newValue.title, "NEWVALUE");
              setAutoCompleteValue(newValue.tokenName);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Tokens"
                placeholder="Favorites"
                id="updatetemplatetokenName"
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              handleSelectTemplate(
                selectedTemplate,
                autocompleteValue,
                cursorPosition,
                "dialog"
              );
            }}
          >
            Add Token
          </Button>
        </DialogActions>
      </Dialog>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Popper
          placement="bottom-start"
          style={{ zIndex: theme.zIndex.modal }}
          id={id}
          open={openPoper}
          anchorEl={anchorEl}
          modifiers={[
            {
              name: "offset",
              options: {
                offset: [popperX, popperY],
              },
            },
          ]}
        >
          <Paper elevation={3}>
            <Box
              style={{
                backgroundColor: "white",
                maxHeight: "350px",
                overflow: "auto",
              }}
            >
              {tokens.map((item, index) => {
                return (
                  <>
                    {item.tokenName.toLowerCase().includes(typeof mentionText === 'string' ? mentionText.toLowerCase() : null) ? (
                      <div key={item.tokenId}>
                        <ListItemButton
                          sx={{
                            backgroundColor:
                              selectedMention === index
                                ? "#e0e0e054"
                                : "white",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                          onClick={() => {
                            handleSelectTemplate(
                              selectedTemplate,
                              item.tokenName,
                              cursorPosition,
                              "popper"
                            );
                            inputRef.current.focus();
                            setAnchorEl(null);
                          }}
                        >
                          <span>{item.tokenName}</span>
                        </ListItemButton>
                        <Divider />
                      </div>
                    ) : null}
                  </>
                );

              })}
            </Box>
          </Paper>
        </Popper>
      </ClickAwayListener>
    </>
  );
}