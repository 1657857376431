class authData {
    token = "";

    getToken = () => this.token;

    setToken = (token) => {
        this.token = token;
    };
}

const tokenData = new authData();

export default tokenData;