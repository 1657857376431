/***************
* 11-11/2022 TextField:766=>disabled auto suggetion
  11-17-2022 => ONKAR => Texting-826 Select token is not working in templates -fixed.
* 25/11/2022 => yogesh Texting-861=> added new state if the user update the template now it will redirect to first pageafter update
  12-06-2022 => ONKAR => Texting-842 Update Local State on successful server response else error (dont update state).
  12-13-2022 => ONKAR => Texting-846 Table Search - Templates table reopen issue fixed.
  1/6/2023   => Mohit => TEXTING-963 - closed reopen issue
  25/04/2023   => Sudam B Chavan => TEXTING-1200 - Admin UI Regression issues
  08-03-2023 => SUDAM B CHAVAN => TEXTING-1522 - templates across teams observations.
  09-07-2023 => SUDAM B CHAVAN => TEXTING-1669 - Select and updating the Template data message is still showing the Template I’d.
***********/
import React, { useState, useEffect, useContext, useRef } from "react";
import Box from "@mui/material/Box";
import apiConfig from "../../api/apiConfig";
// import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../AuthContext";
import { InvokePostServiceCall } from "../../api/serviceUtil";
import SnackBar from "../../components/SnackBar";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Divider,
  Paper,
  ClickAwayListener,
  Checkbox,
  Dialog,
  Autocomplete,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  MenuItem,
  Popper,
} from "@mui/material";
import { ListItemButton } from "@mui/material";
import { getCursorXY } from "../../extensions/getCursorPosition";
import moment from "moment";
import { useTheme } from "@mui/material/styles";

function AddUpdateTemplateComp(props) {
  const AddTemplate = "Add Template";
  const UpdateTemplate = "Update Template";
  //   const location = useLocation();
  //   const navigate = useNavigate();
  const appContext = useContext(AuthContext);
  const theme = useTheme();
  const inputRef = useRef();
  const isAddTemplate = props.isAddTemplate;

  const [checked, setChecked] = React.useState(
    isAddTemplate ? true : props.templateDetails.isActive
  );
  const [open, setOpen] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(
    isAddTemplate ? "" : props.templateDetails.templateBody
  );
  const [autocompleteValue, setAutoCompleteValue] = useState("");
  const [cursorPosition, setCursorPosition] = useState();
  const [teamsData, setTeamsData] = useState([]);
  const [tokens, seTokens] = useState([]);
  const [severity, setSeverity] = useState("success");
  const [mentionText, setMentionText] = useState("");
  const [selectedMention, setSelectedMention] = useState(0);
  const [popperX, setPopperX] = useState(0);
  const [popperY, setPopperY] = useState(0);
  const [posY, setPosY] = useState(0);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [templateData, setTemplateData] = useState({
    azureAd: null,
    azureAdid: null,
    createdBy: null,
    createdByNavigation: null,
    createdDate: null,
    isActive: null,
    lastModifiedBy: null,
    lastModifiedByNavigation: null,
    lastModifiedDate: null,
    team: null,
    teamId: null,
    templateBody: null,
    templateContext: null,
    templateId: null,
    templateName: null,
    templateType: null,
    user: null,
    userId: appContext.userInfo.userId,
      category: null,
    department : null
  });
  const [modifiedBy, setModifiedBy] = useState({
    lastModifiedByName: "",
    lastModifiedDate: "",
  });
  const [createdBy, setCreatedBy] = useState({
    createdByName: "",
    createdDate: "",
  })
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setSelectedMention(0)
  };
  const handleClickAway = () => {
    setAnchorEl(null);
  }
  const openPoper = Boolean(anchorEl);
  const id = openPoper ? 'simple-popper' : undefined;
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    props.handleClose();
    setOpenSnackbar(false);
  };

  useEffect(() => {
    if (!isAddTemplate) {
      InvokePostServiceCall(apiConfig.GET_TEAMS, { isActive: true, userId: appContext.userInfo.userId })
        .then((data) => {
          let tempArr = [];
          if (data.data.teamDetails.length > 0) {
            data.data.teamDetails.forEach((item) => {
              tempArr.push({
                teamName: item.team.teamName,
                teamId: item.team.teamId,
              })
            })
          }
          setTeamsData(tempArr);
        })
        .catch((error) => {
          setSeverity("error");
          setSnackbarMsg(error.message);
          setOpenSnackbar(true);
          console.log("Error Message", error);
        });
    } else {
      setTeamsData(appContext.userInfo.role == "Admin" ? appContext.userInfo.allTeams : appContext.userInfo.teams);
    }
    InvokePostServiceCall(apiConfig.GET_TOKENS, { isActive: true })
      .then((data) => {
        console.log("data", data);
        if (data.data.tokens.length > 0) {
          let tempArr = [];
          data.data.tokens.forEach((item) => {
            item.id = item.tokenId;
            item.display = item.tokenName;
            tempArr.push(item)

          })
          seTokens(tempArr);
        }

      })
      .catch((error) => {
        setSeverity("error");
        setSnackbarMsg(error.message);
        setOpenSnackbar(true);
        console.log("teamsApi", error);
      });
  }, [appContext.userInfo]);

  useEffect(() => {
    if (!isAddTemplate) {
      let templateDetails = props.templateDetails;
      setTemplateData({
        azureAd: templateDetails.azureAd,
        azureAdid: templateDetails.azureAdid,
        createdBy: templateDetails.createdBy,
        createdByName: templateDetails.createdByName,
        createdByNavigation: templateDetails.createdByNavigation,
        createdDate: templateDetails.createdDate,
        isActive: templateDetails.isActive,
        lastModifiedBy: templateDetails.lastModifiedBy,
        lastModifiedByName: templateDetails.lastModifiedByName,
        lastModifiedByNavigation: templateDetails.lastModifiedByNavigation,
        lastModifiedDate: templateDetails.lastModifiedDate,
        team: templateDetails.team,
        teamId: templateDetails.teamId,
        templateBody: templateDetails.templateBody,
        templateContext: templateDetails.templateContext,
        templateId: templateDetails.templateId,
        templateName: templateDetails.templateName,
        templateType: templateDetails.templateType,
        user: templateDetails.user,
        userId: templateDetails.userId,
        category: templateDetails.category,
        department: templateDetails.department === "" ? "None" : templateDetails.department,
      });
      setModifiedBy({
        lastModifiedByName: templateDetails.lastModifiedByName,
        lastModifiedDate: moment(templateDetails.lastModifiedDate).local().format("LLL"),
      })
      setCreatedBy({
        createdByName: templateDetails.createdByName,
        createdDate: moment(templateDetails.createdDate).local().format("LLL")
      })
    }
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleChangeContext = (value, selectLabel) => {
    if (selectLabel === "Context") {
      setTemplateData({ ...templateData, templateContext: value });
    } else if (selectLabel === "Template Type") {
      setTemplateData({ ...templateData, templateType: value });
    } else if (selectLabel === "Category") {
      setTemplateData({ ...templateData, category: value });
    } else if (selectLabel === "Department") {
        setTemplateData({ ...templateData, department: value });
    } else if (selectLabel === "Teams") {
      console.log(value, "teamValue");
      
      setTemplateData({
        ...templateData,
        teamId: value,
      });
    }
  };

  function handleSelectTemplate(main_string, ins_string, pos, mode) {
    if (typeof pos == "undefined") {
      pos = 0;
    }
    if (typeof ins_string == "undefined") {
      ins_string = "";
    }
    let startString = main_string;
    if (mode === "popper") {
      startString = main_string.slice(0, pos - (mentionText.length + 1))
    }
    setSelectedTemplate(
      startString.slice(0, pos) +
      " ##" +
      ins_string +
      "## " +
      main_string.slice(pos)
    );
    handleClose();
  }

  const handleAddUpdateTemplate = () => {
    setLoadingBtn(true);
    let templateBody = selectedTemplate?.trim();
    let addRequestBody = {
      actionType: "Add",
      template: {
        templateName: templateData.templateName,
        templateBody: templateBody,
        isActive: checked,
        // templateContext: templateData.templateContext,
        templateContext: "TEAM",
        templateType: templateData.templateType,
          category: templateData.category,
          department: templateData.department=== 'None' ? "" : templateData.department,
        // teamId: templateData.teamId,
        teamId: props.teamId,
        userId: templateData.userId,
        team: null,
      },
    };
    let updateRequestBody = {
      actionType: "Update",
      template: {
        templateId: templateData.templateId,
        templateName: templateData.templateName,
        templateBody: templateBody,
        isActive: checked,
        // templateContext: templateData.templateContext,
        templateContext: "TEAM",
        templateType: templateData.templateType,
          category: templateData.category,
        department: templateData.department === 'None' ? "" : templateData.department,
        // teamId: templateData.teamId,
        teamId: props.teamId,
        userId: templateData.userId,
        team: templateData.team,
      },
    };
    console.log(
      isAddTemplate ? addRequestBody : updateRequestBody,
      "requestBody"
    );

    InvokePostServiceCall(
      apiConfig.SAVE_TEMPLATE,
      isAddTemplate ? addRequestBody : updateRequestBody
    )
      .then((data) => {
        setSeverity("success");
        setSnackbarMsg(isAddTemplate ? "Template Added Successfully." : "Template Updated Successfully.");
        setOpenSnackbar(true);
        setLoadingBtn(false);
        if (!isAddTemplate) {
          let tempArr = props.templatesArr;
          let tempArray = [];
          props.templatesArr.forEach((item, index) => {
            if (item.templateId === updateRequestBody.template.templateId) {
              tempArr[index].templateId = templateData.templateId;
              tempArr[index].templateName = templateData.templateName;
              tempArr[index].templateBody = selectedTemplate?.trim();
              tempArr[index].isActive = checked;
              tempArr[index].templateContext = "TEAM";
              tempArr[index].templateType = templateData.templateType;
              tempArr[index].category = templateData.category;
              tempArr[index].department = templateData.department;
              tempArr[index].teamId = props.teamId;
              tempArr[index].userId = templateData.userId;
              tempArr[index].team = templateData.team;
            }
            tempArray.push(tempArr[index])
            //tempArr is readonly array can not perform splice/unshift actions so new array created
          });
          let updatedEleIndex = tempArr.findIndex(x => x.templateId === updateRequestBody.template.templateId);
          if (updatedEleIndex > 0) {
          tempArray.unshift(tempArray.splice(updatedEleIndex, 1)[0])
          }
          //finding the index of updated template object and moving to the top.
          props.setTemplatesArr(tempArray);
          props.setTemplateDetails(tempArray);
          props.setRefresh(false)
          if (props.setSearchText) {
          props.setSearchText('');
          }
        }else{
          props.setRefresh(true)
        }
      })
      .catch((err) => {
        setSeverity("error")
        setSnackbarMsg(err.message)
        console.log(err.message, "ERROR DATA");
        setOpenSnackbar(true);
        setLoadingBtn(false);
      });
  };
  const getMentionText = (s) =>
    s.includes("@") && s.substr(s.lastIndexOf("@") + 1).split(" ")[0];

  const onKeyDownFuntion = (e) => {
    if (anchorEl && e.key === "ArrowDown") {
      e.preventDefault();
      if (selectedMention < tokens.length - 1) {
        setSelectedMention(selectedMention + 1);
      }
      setCursorPosition(e.target.selectionStart);
    } else if (anchorEl && e.key === "ArrowUp") {
      e.preventDefault();
      if (selectedMention > 0) {
        setSelectedMention(selectedMention - 1);
      }
      setCursorPosition(e.target.selectionStart);
    }
    if (!anchorEl) {
      setCursorPosition(e.target.selectionStart);
    }
    if (anchorEl && e.key === "Enter") {
      e.preventDefault();
      tokens.forEach((item, index) => {
        if (index === selectedMention) {
          handleSelectTemplate(
            selectedTemplate,
            item.tokenName,
            e.target.selectionStart,
            "popper"
          );
          setAnchorEl(null);
        }
      });
    }
    if (e.key === "Backspace") {
      if (selectedTemplate.includes("#")) {
        if (selectedTemplate[e.target.selectionStart - 1] === "#" && selectedTemplate[e.target.selectionStart - 2] === "#" || selectedTemplate[e.target.selectionStart] === "#") {
          let totalString = selectedTemplate;
          let indexArray = [];
          for (let i = e.target.selectionStart; i > 0; i--) {
            if (selectedTemplate[i] === "#") {
              indexArray.push(i);
            }
          }
          if (selectedTemplate[e.target.selectionStart] === "#") {
            if (indexArray.length > 1) {
              totalString = totalString.slice(0, indexArray[2] ? indexArray[2] : indexArray[1]) + totalString.slice(indexArray[0] + 2, totalString.length)
              setSelectedTemplate(totalString + " ");
            }
          } else {
            let secondindex = totalString.slice(0, e.target.selectionStart - 2).lastIndexOf("##");
            let vl = totalString.slice(secondindex, e.target.selectionStart - 2) && totalString.slice(secondindex, e.target.selectionStart - 2).replace(/ /g, '')
            if (vl && totalString.includes(vl)) {
              totalString = totalString.slice(0, indexArray[3]) + totalString.slice(indexArray[0] + 1, totalString.length);
              setSelectedTemplate(totalString + " ");
            } else {
              let index = totalString.indexOf("##", indexArray[1] + 1);
              totalString = totalString.slice(0, indexArray[1] - 1) + totalString.slice(index + 2, totalString.length);
            }
            setSelectedTemplate(totalString + " ");
          }
        }
      }
    }
  };
  const tempContentOnChange = (e) => {
    setSelectedTemplate(e.target.value);
    setCursorPosition(e.target.selectionStart);
    const { x, y } = getCursorXY(e.target, e.target.selectionEnd);
    if (e.target.offsetHeight === 23) {
      setPosY(y);
    }
    if (e.target.offsetHeight > 23) {
      let offsetVal = e.target.offsetHeight / 23;
      let valOne = offsetVal - 2;
      let valTwo = valOne * 46;
      let valThree = y - posY;
      let finalVal = valTwo - valThree + 23;
      if (finalVal > 0) {
        setPopperY(-finalVal);
      } else {
        setPopperY(0);
      }
    }
    setPopperX(x);
    if (e.target.value[e.target.selectionEnd - 1] === "@") {
      if (e.target.value.length > 1) {
        if (e.target.value[e.target.selectionEnd - 2] === " ") {
          handleClick(e);
        }
      } else {
        handleClick(e);
      }
    }
    let currentWord = e.target.value.slice(0, e.target.selectionEnd);
    let n = currentWord.split(" ");
    let atVal = n[n.length - 1];
    if (atVal.indexOf("@", 1) !== -1) {
      setAnchorEl(null);
    }
    if (!atVal.includes("@")) {
      setAnchorEl(null);
    }
    setMentionText(getMentionText(e.target.value));
  };

  return (
    <>
      <SnackBar
        openSnackbar={openSnackbar}
        handleCloseSnackbar={handleCloseSnackbar}
        severity={severity}
        userMessage={snackbarMsg}
      />
      <div style={{ marginLeft: 20, marginRight: 20 }}>
        <h2>{isAddTemplate ? AddTemplate : UpdateTemplate}</h2>
        <Box sx={theme.customStyle.textRow}>
          <TextField
            size="small"
            margin="dense"
            variant="standard"
            value={templateData.templateName}
            fullWidth
            required
            label="Template Name"
            id="updatetemplateName"
            inputProps={{ //not show suggetions
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              setTemplateData({
                ...templateData,
                templateName: e.target.value,
              });
            }}
          />
        </Box>
        <Box sx={theme.customStyle.textRow}>
          <TextField
            size="small"
            margin="dense"
            variant="standard"
            fullWidth
            multiline
            required
            value={selectedTemplate}
            label="Template Content"
            id="updatetemplateselectedTemplate"
            inputProps={{ //not show suggetions
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={inputRef}
            onChange={tempContentOnChange}
            onClick={(e) => {
              setCursorPosition(e.target.selectionStart);
            }}
            onKeyDown={onKeyDownFuntion}
          />

          <Button
            size="small"
            onClick={() => {
              handleClickOpen();
            }}
            sx={theme.customStyle.modalbtn02}
          >
            Select Tokens
          </Button>
        </Box>

        <span>Is Active</span>
        <Checkbox checked={checked} onChange={handleChange} />
        {/* <Box sx={{ ...theme.customStyle.textRow, marginTop: 1 }}>
          <TextField
            size="small"
            select
            required
            label="Context"
            value={templateData.templateContext}
            fullWidth
            onChange={(e) => {
              handleChangeContext(e.target.value, "Context");
            }}
            InputLabelProps={{
              shrink: true,
            }}
            helperText="Please select department."
            variant="standard"
          >
            <MenuItem value={"INDIVIDUAL"}>Individual</MenuItem>
            <MenuItem value={"TEAM"}>Team</MenuItem>
          </TextField>
        </Box> */}
        <Box sx={{ ...theme.customStyle.textRow, marginTop: 2 }}>
          <TextField
            size="small"
            select
            required
            label="Template Type"
            value={templateData.templateType}
            fullWidth
            onChange={(e) => {
              handleChangeContext(e.target.value, "Template Type");
            }}
            InputLabelProps={{
              shrink: true,
            }}
            id="updatetemplatetemplateType"
            inputProps={{ //not show suggetions
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
            helperText="Please select template type."
            variant="standard"
          >
            <MenuItem value={"Sales"}>Sales</MenuItem>
            <MenuItem value={"Services"}>Services</MenuItem>
          </TextField>
        </Box>
        {/* <Box sx={{ ...theme.customStyle.textRow, marginTop: 2 }}>
          <TextField
            size="small"
            select
            required
            label="Teams"
            value={templateData.teamId}
            fullWidth
            onChange={(e) => {
              handleChangeContext(e.target.value, "Teams");
            }}
            InputLabelProps={{
              shrink: true,
            }}
            helperText="Please select Team."
            variant="standard"
          >
            {teamsData && teamsData.map((option, index) => (
              <MenuItem key={option.teamId} value={option.teamId}>
                {option.teamName}
              </MenuItem>
            ))}
          </TextField>
        </Box> */}
              <Box sx={{ ...theme.customStyle.textRow, marginTop: 2 }}>
                  <TextField
                      size="small"
                      select
                      required
                      label="Department"
                      value={templateData.department}
                      fullWidth
                      onChange={(e) => {
                          handleChangeContext(e.target.value, "Department");
                      }}                      
                      inputProps={{ //not show suggetions
                          autoComplete: 'new-password',
                          form: {
                              autocomplete: 'off',
                          },
                      }}
                      InputLabelProps={{
                          shrink: true,
                      }}
                      helperText="Please select Department type."
                      variant="standard"
                  >
                      <MenuItem
                          key="None"
                          value="None"
                      >
                          None
                      </MenuItem>
                      {appContext.departments && appContext.departments.map((option) => (
                          <MenuItem
                              key={option}
                              value={option}
                          >
                              {option}
                          </MenuItem>
                      ))}
                  </TextField>
              </Box>
        <Box sx={{ ...theme.customStyle.textRow, marginTop: 2 }}>
          <TextField
            size="small"
            select
            required
            label="Category "
            value={templateData.category}
            fullWidth
            onChange={(e) => {
              handleChangeContext(e.target.value, "Category");
            }}
            InputLabelProps={{
              shrink: true,
            }}
            id="updatetemplateCategory"
            inputProps={{ //not show suggetions
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
            helperText="Please select Category."
            variant="standard"
          >
            <MenuItem value={"Introduction"}>Introduction</MenuItem>
            <MenuItem value={"Other"}>Other</MenuItem>
          </TextField>
        </Box>
        {!isAddTemplate && (
          <>
            <Box sx={theme.customStyle.textRow}>
              <TextField
                size="small"
                margin="dense"
                disabled
                variant="standard"
                value={`${createdBy.createdByName + " (" + createdBy.createdDate + ")"}`}
                fullWidth
                label="Created By"
                id="updatetemplatecreatedBy"
                inputProps={{ //not show suggetions
                  autoComplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
            <Box sx={theme.customStyle.textRow}>
              <TextField
                size="small"
                margin="dense"
                disabled
                variant="standard"
                value={`${modifiedBy.lastModifiedByName + " (" + modifiedBy.lastModifiedDate + ")"}`}
                fullWidth
                label="Modified By"
                id="updatetemplatemodifiedBy"
                inputProps={{ //not show suggetions
                  autoComplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          </>
        )}
        <LoadingButton
          loading={loadingBtn}
          variant="contained"
          disabled={
            !templateData.templateName ? true :
              // !templateData.templateContext ? true :
              !templateData.templateType ? true :
                // !templateData.teamId ? true :
                !templateData.category ? true :
                !templateData.department ? true :
                !selectedTemplate ? true : false
          }
          onClick={() => {
            handleAddUpdateTemplate();
          }}
        >
          {!isAddTemplate ? UpdateTemplate : AddTemplate}
        </LoadingButton>
        <Button
          sx={{ marginLeft: 2 }}
          variant="contained"
          onClick={() => {
            props.handleClose()
          }}
        >
          Cancel
        </Button>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle>Select Token</DialogTitle>
        <DialogContent>
          <Autocomplete
            freeSolo
            id="tags-standard"
            options={tokens}
            disableClearable
            getOptionLabel={(option) => option.tokenName}
            onChange={(event, newValue) => {
              // console.log(newValue.title, "NEWVALUE");
              setAutoCompleteValue(newValue.tokenName);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Tokens"
                placeholder="Favorites"
                id="updatetemplatetokenName"
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              handleSelectTemplate(
                selectedTemplate,
                autocompleteValue,
                cursorPosition,
                "dialog"
              );
            }}
          >
            Add Token
          </Button>
        </DialogActions>
      </Dialog>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Popper
          placement="bottom-start"
          style={{ zIndex: theme.zIndex.modal }}
          id={id}
          open={openPoper}
          anchorEl={anchorEl}
          modifiers={[
            {
              name: "offset",
              options: {
                offset: [popperX, popperY],
              },
            },
          ]}
        >
          <Paper elevation={3}>
            <Box
              style={{
                backgroundColor: "white",
                maxHeight: "350px",
                overflow: "auto",
              }}
            >
              {tokens.map((item, index) => {
                return (
                  <>
                    {item.tokenName.toLowerCase().includes(typeof mentionText === 'string' ? mentionText.toLowerCase() : null) ? (
                      <div key={item.tokenId}>
                        <ListItemButton
                          sx={{
                            backgroundColor:
                              selectedMention === index
                                ? "#e0e0e054"
                                : "white",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                          onClick={() => {
                            handleSelectTemplate(
                              selectedTemplate,
                              item.tokenName,
                              cursorPosition,
                              "popper"
                            );
                            inputRef.current.focus();
                            setAnchorEl(null);
                          }}
                        >
                          <span>{item.tokenName}</span>
                        </ListItemButton>
                        <Divider />
                      </div>
                    ) : null}
                  </>
                );

              })}
            </Box>
          </Paper>
        </Popper>
      </ClickAwayListener>
    </>
  )
}
export default AddUpdateTemplateComp