/***************
* 11-11/2022 TextField:766=>disabled auto suggetion
* 11-22-2022 => ONKAR => Texting-806 Error while saving the JSON content on JSON editor (On user upadate and Settings Update), replace Json editor with normal textfield.
* 05-02-2022 => RAJESH => Added new field for admin setting. (IsAdminSetting)
* 26-07-2023 => PRABHAT => Texting-1368 Added createdby,modifiedby textfields and also changed save Settings to update only one setting at a time.
* 09-22-2023 => PRABHAT => TEXTING-1732 Do not allow duplicate key in admin settings
* 11-15-2023 => SUDAM B CHAVAN => TEXTING-1863 Validation is not happening on updating the setting with duplicate key.
***********/
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  CircularProgress,
  MenuItem,
  Typography,
  Checkbox
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import { useTheme } from "@mui/material/styles";
import JSONInput from "react-json-editor-ajrm";
import { InvokePostServiceCall } from "../../api/serviceUtil";
import apiConfig from "../../api/apiConfig";
import SnackBar from "../../components/SnackBar";
import { AuthContext } from "../../AuthContext";
import moment from "moment";

function AddUpdateSettings() {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const numberRegEx = /^(0|[1-9]\d*)$/;
  const isAddSettings = location.state ? location.state.isAddSettings : true;
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const { messageApiToken } = useContext(AuthContext);
  const [severity, setSeverity] = useState("success");
  const [appSettingsData, setAppSettingsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");

  const [addSettings, setAddSettings] = useState({
    name: "",
    settingsKey: "",
    settingsValue: "",
    type: "String",
    settingsId: "",
    isActive: true,
    isadminSetting : true
  });
  const handleChange = (event) => {
    setAddSettings({ ...addSettings, isActive: event.target.checked });
  };
  const handleChangeAdminSetting = (event) => {
      setAddSettings({ ...addSettings, isadminSetting: event.target.checked });
  };
  const [errors, setErrors] = useState({
    settingValueErr: false,
  });
    const [createdBy, setCreatedBy] = useState({
        createdByName: "",
        createdDate: "",
    });

    const [modifiedBy, setModifiedBy] = useState({
        lastModifiedByName: "",
        lastModifiedDate: "",
    });
  const [jsonError, setJsonError] = useState(false);
  const handleChangeSettingType = (event) => {
    setAddSettings({
      ...addSettings,
      type: event.target.value,
      settingsValue: "",
    });
    setErrors({ settingValueErr: false });
  };
  useEffect(() => {
    if (!isAddSettings) {
      let settingDetails = location.state.settingDetails;
      console.log(settingDetails, "settingDEtails");
      setAddSettings({
        name: settingDetails.name,
        settingsKey: settingDetails.settingsKey,
        settingsValue: settingDetails.settingsValue,
        type: settingDetails.type ? settingDetails.type : "string",
        settingsId: settingDetails.settingsId,
        isActive: settingDetails.isActive ? settingDetails.isActive : false,
          isadminSetting: settingDetails.isadminSetting ? settingDetails.isadminSetting : false,
      });
        // Set the CreatedBy and ModifiedBy information
        setCreatedBy({
            createdByName: settingDetails.createdByName,
            createdDate: moment(settingDetails.createdDate).local().format("LLL"),
        });

        setModifiedBy({
            lastModifiedByName: settingDetails.lastModifiedByName,
            lastModifiedDate: moment(settingDetails.lastModifiedDate)
                .local()
                .format("LLL"),
        });
      }
  }, []);

  function getSettings() {
    InvokePostServiceCall(apiConfig.GET_SETTINGS, {})
      .then((data) => {
        setAppSettingsData(data.data.settings);
      })
      .catch((error) => {
        setSeverity("error");
        setSnackbarMsg(error.message);
        console.log(error, "error message");
        setOpenSnackbar(true);
      });
  }
  useEffect(() => {
    if (messageApiToken) {
      getSettings();
    }
  }, [messageApiToken]);

    // This function saves the updated setting to the backend using an API call.
    function saveSettings(updatedSetting) {
        setLoading(true);
        // Create a request body containing only the updated setting.
        let requestBody = { settings: [updatedSetting] };
        InvokePostServiceCall(apiConfig.SAVE_SETTINGS, requestBody)
            .then((data) => {
                if (data.data.status === "failed") {
                    setSeverity("error");
                    setSnackbarMsg(data.data.statusDescription);
                }
                else {
                    setSeverity("success");
                    setSnackbarMsg(isAddSettings ? "Setting Added Successfully." : "Setting Updated Successfully.");
                }
                console.log(data, "Success Response");
                setLoading(false);
                setOpenSnackbar(true);
            })
            .catch((error) => {
                setSeverity("error");
                setSnackbarMsg(error.message);
                console.log(error, "Error Message");
                setLoading(false);
                setOpenSnackbar(true);
            });
    }
    function addSetting() {
        const newSetting = {
            name: addSettings.name,
            type: addSettings.type,
            settingsKey: addSettings.settingsKey,
            settingsValue: addSettings.settingsValue,
            isActive: addSettings.isActive,
            isadminSetting: addSettings.isadminSetting,
        };
        setAppSettingsData((prevSettings) => [...prevSettings, newSetting]);
        saveSettings(newSetting);
    }
     // This function updates the specified setting in the appSettingsData array and calls saveSettings with the updated setting.
    function updateSettings() {
        const indexToUpdate = appSettingsData.findIndex(
            (item) => item.settingsId === addSettings.settingsId
        );

        if (indexToUpdate !== -1) {
            const updatedSettings = [...appSettingsData];
             // Update the setting at the found index with the new values from addSettings.
            updatedSettings[indexToUpdate] = {
                ...updatedSettings[indexToUpdate],
                name: addSettings.name,
                type: addSettings.type,
                settingsKey: addSettings.settingsKey,
                settingsValue: addSettings.settingsValue,
                isActive: addSettings.isActive,
                isadminSetting: addSettings.isadminSetting,
            };
            setAppSettingsData(updatedSettings);// Update the state with the modified appSettingsData array containing the updated setting.
            saveSettings(updatedSettings[indexToUpdate]); // Call the saveSettings function with the updated setting
        }
    }
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    if (severity === "success") {
      navigate("/applicationSettings", { replace: true });
    }
    setOpenSnackbar(false);
  };
  const isJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  return (
    <>
      <SnackBar
        openSnackbar={openSnackbar}
        handleCloseSnackbar={handleCloseSnackbar}
        severity={severity}
        userMessage={snackbarMsg}
      />
      <div style={{ marginLeft: 20, marginRight: 20 }}>
        <h2>{isAddSettings ? "Add Setting" : "Update Setting"}</h2>
        <Box sx={{ ...theme.customStyle.textRow, marginTop: "20px" }}>
          <TextField
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            value={addSettings.name}
            InputLabelProps={{
              shrink: true,
            }}
            id="updateSettingsname"
            inputProps={{ //not show suggetions
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
            onChange={(e) => {
              setAddSettings({
                ...addSettings,
                name: e.target.value,
              });
            }}
          />
        </Box>

        <Box sx={theme.customStyle.textRow}>
          <TextField
            margin="dense"
            label="Setting key"
            type="text"
            fullWidth
            value={addSettings.settingsKey}
            variant="standard"
            id="updatesettingsKey"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ //not show suggetions
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
            onChange={(e) => {
              setAddSettings({
                ...addSettings,
                settingsKey: e.target.value,
              });
            }}
          />
        </Box>

        <Box sx={{ ...theme.customStyle.textRow, marginTop: 2.5 }}>
          <TextField
            size="small"
            select
            label="Setting Type"
            defaultValue={addSettings.type}
            value={addSettings.type}
            fullWidth
            onChange={handleChangeSettingType}
            InputLabelProps={{
              shrink: true,
            }}
            id="updateSettingstype"
            inputProps={{ //not show suggetions
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
            variant="standard"
          >
            <MenuItem value={"string"}>string</MenuItem>
            <MenuItem value={"number"}>number</MenuItem>
            <MenuItem value={"json"}>json</MenuItem>
          </TextField>
        </Box>
        <Box sx={theme.customStyle.textRow}>
          <TextField
            margin="dense"
            label="Setting Value"
            type={addSettings.type === "number" ? "number" : "text"}
            fullWidth
            helperText={
              errors.settingValueErr ? "Please Enter Numbers Only (without decimals)" : jsonError?"Invalid JSON": ""
            }
            id="updatesettingsValue"
            inputProps={{ //not show suggetions
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
            error={errors.settingValueErr || jsonError }
            value={addSettings.settingsValue}
            variant="standard"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              setAddSettings({
                ...addSettings,
                settingsValue: e.target.value,
              });
              if (addSettings.type === "number") {
                let testNum = numberRegEx.test(
                  String(e.target.value).toLowerCase()
                );
                if (testNum === false) {
                  setErrors({ ...errors, settingValueErr: true });
                } else {
                  setErrors({ ...errors, settingValueErr: false });
                }
              }
              if (addSettings.type === "json") {
                if (!isJsonString(e.target.value)) {
                  setJsonError(true);
                } else {
                  setJsonError(false);
                }
              }
            }}
          />
        </Box>
              {!isAddSettings && (
                  <>
                      <Box sx={theme.customStyle.textRow}>
                          <TextField
                              size="small"
                              margin="dense"
                              disabled
                              variant="standard"
                              value={`${createdBy.createdByName + " (" + createdBy.createdDate + ")"}`}
                              fullWidth
                              label="Created By"
                              InputLabelProps={{
                                  shrink: true,
                              }}
                              id="createdBy"
                          />
                      </Box>
                      <Box sx={theme.customStyle.textRow}>
                          <TextField
                              size="small"
                              margin="dense"
                              disabled
                              variant="standard"
                              value={`${modifiedBy.lastModifiedByName + " (" + modifiedBy.lastModifiedDate + ")"}`}
                              fullWidth
                              label="Modified By"
                              id="modifiedBy"
                              InputLabelProps={{
                                  shrink: true,
                              }}
                          />
                      </Box>
                  </>
              )}

        <div style={{ marginBottom: "10px" }}>
          <span>Is Active</span>
          <Checkbox checked={addSettings.isActive} onChange={handleChange} />
        </div>
        <div style={{ marginBottom: "10px" }}>
          <span>Is Admin Setting</span>
                  <Checkbox checked={addSettings.isadminSetting} onChange={handleChangeAdminSetting} />
        </div>
        <Button
          variant="contained"
          onClick={() => {
            navigate("/applicationSettings", { replace: true });
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          style={{ marginLeft: "10px" }}
          variant="contained"
          loading={loading}
          disabled={
            jsonError === true
              ? true
              : !addSettings.settingsValue
                ? true
                : !addSettings.name
                  ? true
                  : !addSettings.settingsKey
                    ? true
                    : errors.settingValueErr
                      ? true
                      : false
          }
          onClick={() => {
            isAddSettings ? addSetting() : updateSettings();
          }}
          autoFocus
        >
          {isAddSettings ? "Add" : "Update"}
        </LoadingButton>
      </div>
    </>
  );
}

export default AddUpdateSettings;
