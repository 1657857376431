////05-04-2023 => ONKAR => Texting-1243 UI for Message Read Status for Agent and Member.
////05-12-2023 => ONKAR => Texting-1243 System user message is shown as seen in missed conversation and in the same way virtual agent messages should also be shown as seen. -Fixed
////06-12-2023 => SUDAM B CHAVAN => TEXTING-1287 - Communication list - Send Secuduled Outbound messages
////07-04-2023 => ONKAR => TEXTING-1292 - Message delivered, Read status ticks should show in SMS history and SMS activity pages.
////08-22-2023 => SUDAM B CHAVAN => TEXTING - 1600 - Conversation assignment / reassignment
// 08-30-2023 => ONKAR => TEXTING-1578 -REACT Upgrade
////09-15-2023 => SUDAM B CHAVAN => TEXTING-1702 - Document refresh button is updating to download after reopening the conversation page only.
////09-23-2023 => ONKAR => TEXTING-1728 - 1: Friendly Description Twilio error message  - https://www.twilio.com/docs/api/errors/30001.
////09-26-2023 => ONKAR => TEXTING-1748 - Twilio Error Message observations - Embed URL with error code instead of displaying URL separately.
////09-26-2023 => SUDAM B CHAVAN => TEXTING-1750 - Message Status Icon Changes
////10-31-2023 => ONKAR =>Textig-1824 Cancel/Edit Scheduled Messages
////11-03-2023 => ONKAR =>Textig-1824 Cancel/Edit Scheduled Messages
////11-09-2023 => SUDAM B CHAVAN =>TEXTING-1842 - Sent and failed status text is still showing for message search results.
////12-11-2023 => ONKAR =>TEXTING-1912 - 0: Support HTML content in messages for agent view.
////12-13-2023 => ONKAR =>TEXTING-1922 - 0: refresh page should load total page with loader.
////                                     2.When we have continuous outbound messages, message scroll is not working for some outbound messages.

import React, { useContext,useState,useEffect } from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import { Typography, Avatar, Grid, CircularProgress, IconButton, Box, TextField, ClickAwayListener } from "@mui/material";
import moment from "moment";
import { AuthContext } from "../AuthContext";
import CheckIcon from "@mui/icons-material/Check";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DownloadIcon from '@mui/icons-material/Download';
import { useTheme } from "@mui/material/styles";
import FileSaver from 'file-saver';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import LayersIcon from '@mui/icons-material/Layers';
import RefreshIcon from '@mui/icons-material/Refresh';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import apiConfig from "../api/apiConfig";
import { InvokePostServiceCall } from "../api/serviceUtil";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import ErrorIcon from '@mui/icons-material/Error';
import CancelIcon from '@mui/icons-material/Cancel';
import ChatInfoMsg from "./ChatInfoMsg";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import twilioErrors from "../twilioErrors.json"
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from "react-redux";
import { updateMessageToConv } from "../storage/slices/messageSlice";
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';


const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

const ChatMsg = (props) => {
    const {
        avatar,
        messages,
        side,
        GridContainerProps,
        GridItemProps,
        AvatarProps,
        getTypographyProps,
        customerName,
        customerNameInitials,
        agentNm,
        openMainSnackbar,
        channelName,
        isDialog,
        isReadOnly
    } = props;

    const appContext = useContext(AuthContext);
    const [showRefreshbtn, setShowRefreshBtn] = useState(false);
    const dispatch=useDispatch();
    const [showEditMsgInputField,setShowEditMsgInputField]=useState(false);
    const [openEditMsgMenu, setOpenEditMsgMenu] = useState("");
    const [editedMessage,setEditedMessage]=useState("");
    const [selectedMessageId,setSelectedMessageId]=useState("");
    const [downloading, setDownloading] = useState({
        isDownloading:false,
        isRefreshStatus:false,
        id:''
    });
    const [localMessages, setLocalMessages] = useState(messages);
    const theme = useTheme();
    const agentName = agentNm != null ? agentNm : appContext.userInfo.userName;
    let customerNameArr =
        customerName != null && !!customerName && customerName !== ""
            ? customerName.split(" ")
            : "";
    const downloadFile = (msgObj) => {
        setDownloading({
            isDownloading:true,
            id: msgObj.documentStoreId
        });
        var req = {
            documentStoreId: msgObj.documentStoreId
        };
        InvokePostServiceCall(apiConfig.GET_DOCUMENT_FROM_STORE, req)
            .then((data) => {
                if (data.data.status === "FAILED") {
                    openMainSnackbar(data.data.statusDescription)
                } else {
                    //openBase64NewTab(data.data)
                    FileSaver.saveAs("data:" + data.data.mediaType + ";base64," + data.data.documentBase64, msgObj.messageBody);
                }
                setDownloading({
                    isDownloading: false,
                    id: msgObj.documentStoreId
                });
            })
            .catch((error) => {
                console.log(error.message);
            }).finally(() => {
                setDownloading({
                    isDownloading: false,
                    id: msgObj.documentStoreId
                });
            });
    }
    const openBase64NewTab=(data)=> {
        var blob = base64toBlob(data.documentBase64, data.mediaType);
      
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, data.documentType + data.fileExtension);
        } else {
            const blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl);
        }
    }
    const base64toBlob = (base64Data, mediaType) =>{
        const sliceSize = 1024;
        const byteCharacters = atob(base64Data);
        const bytesLength = byteCharacters.length;
        const slicesCount = Math.ceil(bytesLength / sliceSize);
        const byteArrays = new Array(slicesCount);

        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            const begin = sliceIndex * sliceSize;
            const end = Math.min(begin + sliceSize, bytesLength);

            const bytes = new Array(end - begin);
            for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return new Blob(byteArrays, { type: mediaType });
    }
    const GetDocumentStatus = (id,index) => {
        let msgIndex = index;
        setDownloading({
            isRefreshStatus: true,
            id: id
        });
        var req = {
            documentStoreId: id
        };
        InvokePostServiceCall(apiConfig.GET_DOCUMENT_STATUS, req)
            .then((data) => {
                console.log("response::",data.data)
                if (data.data.status === "FAILED") {
                    openMainSnackbar(data.data.statusDescription)
                }
                else {
                    if (props.isHistory) {
                        props.getMessages();
                    }
                    else if (isDialog){
                        //when this component open in Dialog then we just update status..
                        localMessages[msgIndex].status ='Completed';
                        setLocalMessages(localMessages);
                    }
                }
            })
            .catch((error) => {
                console.log(error.message);
            }).finally(() => {
                setDownloading({
                    isRefreshStatus: false,
                });
            });
    }

    let updateMessage = async (msg, status = "") => {
      try {
        let tempMsg = {
          message: { ...msg },
        };
        tempMsg.message.conversationId = props.conversationId;
        tempMsg.message.status = "sending"; //update msg status as sending to show loading icon.
        dispatch(updateMessageToConv(tempMsg));// update  msg object in store.
        let payload = { messageId: tempMsg.message.messageId };
        if (status !== "") { //check if msg status is updated or message body.
          payload.status = status;
        } else {
          payload.messageBody = editedMessage.trim();
        }
        let response = await InvokePostServiceCall(
          apiConfig.UPDATE_MESSAGE,
          payload
        );
        console.log(response);
        //after successful api call update messageBody/status.
        let tempMsgObj = {
            message: { ...msg },
          };
          tempMsgObj.message.conversationId = props.conversationId;
        if (status !== "") {
          tempMsgObj.message.status = status;
        } else {
          tempMsgObj.message.messageBody = editedMessage.trim();
        }
        dispatch(updateMessageToConv(tempMsgObj));
      } catch (error) {
        console.log(error, "Error Message");
        //reset the msg object
        let tempMsg = {
          message: { ...msg },
        };
        tempMsg.message.conversationId = props.conversationId;
        dispatch(updateMessageToConv(tempMsg));
      } finally {
        handleEditMsgMenuClose();
        setShowEditMsgInputField(false);
        setEditedMessage("");
        setSelectedMessageId("")
      }
    };

    const handleEditMsgMenuClose = () => {
        setOpenEditMsgMenu("");
    };

    useEffect(() => {
     setTimeout(() => {
         setShowRefreshBtn(true);
     }, 3000);
    }, [])
    useEffect(() => {
        //for update component/resender on Messages updated on parent component 
        setLocalMessages(messages)
    }, [messages])
    
    // let firstName = customerNameArr !== "" ? customerNameArr[0].charAt(0) : "";
    // let lastName = customerNameArr.length > 1 ? customerNameArr[1].charAt(0) : "";

    return (
        <>
            <Grid
                container
                justify={side === "right" ? "flex-end" : "flex-start"}
                {...GridContainerProps}
                sx={{ flexWrap: side === "left" ? "nowrap" : "wrap",...theme.customStyle.msgContainer}}
            >
                {side === "left" && (
                    <Grid item sx={{ width: 40, pl: 0.5 }} {...GridItemProps}>
                        {avatar !== "" ? (
                            <Avatar
                                src={avatar}
                                sx={{ marginRight: "4px", bgcolor: "#034f84",...theme.customStyle.msgAvatar }}
                                {...AvatarProps}
                            />
                        ) : (
                            <Avatar {...AvatarProps} sx={theme.customStyle.msgAvatar}
                                alt={customerNameInitials}
                                children={customerNameInitials ? customerNameInitials : null}
                            />
                        )}
                    </Grid>
                )}
                <Grid item xs={12} sx={{ pr: 0.5 }}>
                    {localMessages.map((msg, index) => {
                        let showName = true;
                        if (index > 0) {
                            if (msg?.fromId !== messages[index - 1]?.fromId) {
                                showName = true;
                            } else {
                                showName = false;
                            }
                        }
                        const TypographyProps = getTypographyProps(
                            msg.messageBody,
                            index,
                            props
                        );
                        let twilioErrorMessage=""
                        let twilioErrorUrl=""
                        if(msg.status === "failed" || msg.status === "undelivered"){
                            if(msg.statusCode?.includes("TWILIO")){
                                let errorMsg=twilioErrors[msg.statusCode.split("-")[1]]
                                if(errorMsg){
                                    twilioErrorMessage=`${errorMsg}`
                                    twilioErrorUrl=`https://www.twilio.com/docs/api/errors/${msg.statusCode.split("-")[1]}`
                                }
                            }
                        }
                        return (
                          <div key={msg.messageId}>
                            {msg.messageType === "INFO" && (
                              <ChatInfoMsg message={msg}></ChatInfoMsg>
                            )}
                            {msg.messageType !== "INFO" && (
                              <ClickAwayListener
                                onClickAway={() => {
                                  if (msg.status === "scheduled" && msg.messageId===openEditMsgMenu) {
                                    handleEditMsgMenuClose();
                                  }
                                }}
                              >
                                <div
                                  key={msg.id || index}
                                  style={{
                                    ...(side === "left" &&
                                      theme.customStyle.msgLeftRow),
                                    ...(side === "right" &&
                                      theme.customStyle.msgRightRow),
                                  }}
                                >
                                  <LightTooltip
                                  placement="top-end"
                                    title={
                                      msg.status === "scheduled" && isReadOnly!==true && (
                                        <>
                                          {!showEditMsgInputField && (
                                            <>
                                              <IconButton
                                                title="Edit message"
                                                onClick={() => {
                                                  setShowEditMsgInputField(
                                                    true
                                                  );
                                                  handleEditMsgMenuClose();
                                                }}
                                              >
                                                <EditIcon fontSize="small" />
                                              </IconButton>
                                              <IconButton
                                                title="Cancel scheduled message"
                                                onClick={() => {
                                                  updateMessage(
                                                    msg,
                                                    "cancelled"
                                                  );
                                                }}
                                              >
                                                <CancelIcon fontSize="small" />
                                              </IconButton>
                                            </>
                                          )}
                                        </>
                                      )
                                    }
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    open={openEditMsgMenu === msg.messageId}
                                    onClose={handleEditMsgMenuClose}
                                  >
                                    <Typography
                                      align={side}
                                      id={msg.messageId}
                                      {...TypographyProps}
                                      sx={{
                                        ...theme.customStyle.msgBody,
                                        ...(side === "left" &&
                                          theme.customStyle.msgLeft),
                                        ...(side === "right" &&
                                          theme.customStyle.msgRight),
                                        ...(side === "left" &&
                                          index === 0 &&
                                          theme.customStyle.msgLeftFirst),
                                        ...(side === "right" &&
                                          index === 0 &&
                                          theme.customStyle.msgRightFirst),
                                        ...(side === "left" &&
                                          index === messages.length - 1 &&
                                          theme.customStyle.msgLeftLast),
                                        ...(side === "right" &&
                                          index === messages.length - 1 &&
                                          theme.customStyle.msgRightLast),
                                        marginLeft:
                                          side === "right" ? "50px" : "0px",
                                        marginRight:
                                          side === "left" ? "50px" : "0px",
                                        paddingTop: "6px !important",
                                        paddingBottom: "6px !important",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          ...(side === "left" &&
                                            theme.customStyle.msgHeaderLeft),
                                          ...(side === "right" &&
                                            theme.customStyle.msgHeaderRight),
                                          paddingBottom: "0px !important",
                                          position: "relative",
                                        }}
                                      >
                                        {showName && (
                                          <span>
                                            {side === "left"
                                              ? customerName
                                              : msg.messageType === "BOTMSG"
                                              ? "Virtual Agent"
                                              : msg != null &&
                                                msg.fromId != null &&
                                                msg.fromId ==
                                                  appContext.userInfo
                                                    .systemUserId
                                              ? "SYSTEM USER"
                                              : agentName}
                                          </span>
                                        )}
                                       {msg.status === "scheduled" && !showEditMsgInputField && isReadOnly!== true && <IconButton
                                          color="inherit"
                                          sx={{
                                            position: "absolute",
                                            right: "-4px",
                                            top: "-6px",
                                            padding:"4px"
                                          }}
                                          onClick={() => {

                                            if (openEditMsgMenu !== msg.messageId) {
                                              setEditedMessage(msg.messageBody);
                                              setSelectedMessageId(msg.messageId);
                                              setOpenEditMsgMenu(
                                                msg.messageId
                                            );
                                            }else{
                                                setOpenEditMsgMenu(
                                                   ""
                                                );
                                            }
                                          }}
                                        >
                                          <MoreHorizIcon fontSize="small" />
                                        </IconButton>}
                                      </Typography>
                                      {msg.messageType.toLowerCase() !==
                                      "document" ? (
                                        <Typography
                                          sx={{...theme.customStyle.messageText,
                                            ...(msg.status === "scheduled" && !showEditMsgInputField && isReadOnly!== true && {paddingRight:"24px"})}}
                                          align="left"
                                        >
                                          {showEditMsgInputField && selectedMessageId===msg.messageId ? (
                                            <>
                                            <TextField
                                              type="text"
                                              size="small"
                                              variant="outlined"
                                              sx={{
                                                backgroundColor: "#FFF",
                                                borderRadius: "4px",
                                              }}
                                              multiline={true}
                                              maxRows={5}
                                              value={editedMessage}
                                              onChange={(e) => {
                                                setEditedMessage(
                                                  e.target.value
                                                );
                                              }}
                                              inputProps={{
                                                  maxLength: 512, style: { fontSize: "12px" }
                                                }}
                                            />
                                            <IconButton
                                              title="Save"
                                              color="inherit"
                                              sx={theme.customStyle.scheduleMsgEditInputSaveCanceBtn}
                                              disabled={!editedMessage}
                                              onClick={() => {
                                                updateMessage(msg);
                                              }}
                                            >
                                              <CheckIcon fontSize="small" />
                                            </IconButton>
                                            <IconButton
                                              title="Cancel"
                                              color="inherit"
                                              sx={theme.customStyle.scheduleMsgEditInputSaveCanceBtn}
                                              onClick={() => {
                                                handleEditMsgMenuClose();
                                                setShowEditMsgInputField(
                                                  false
                                                );
                                              }}
                                            >
                                              <CloseIcon fontSize="small" />
                                            </IconButton>
                                          </>
                                          ) : (
                                            <Typography sx={theme.customStyle.messageText} dangerouslySetInnerHTML={{ __html: msg.messageBody }}>
                                            </Typography>
                                          )}
                                        </Typography>
                                      ) : null}
                                      {msg.messageType.toLowerCase() ===
                                        "document" && (
                                        <Typography
                                          sx={theme.customStyle.messageText}
                                          align="left"
                                        >
                                          <Box
                                            sx={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Box>{msg.messageBody}</Box>
                                            <Box>
                                              {msg.status === "InQueue" ? (
                                                <span
                                                  style={{ marginLeft: "5px" }}
                                                  title="Document is In-Queue"
                                                >
                                                  <LayersIcon fontSize="small" />
                                                </span>
                                              ) : msg.status === "Scanning" ? (
                                                <>
                                                  {showRefreshbtn ? (
                                                    <>
                                                      {downloading.isRefreshStatus &&
                                                      downloading.id ===
                                                        msg.documentStoreId ? (
                                                        <span
                                                          style={{
                                                            marginLeft: "5px",
                                                          }}
                                                          title="Refreshing status"
                                                        >
                                                          <CircularProgress
                                                            size={15}
                                                          />
                                                        </span>
                                                      ) : (
                                                        <span
                                                          style={{
                                                            marginLeft: "5px",
                                                          }}
                                                          title="Refresh status"
                                                        >
                                                          <RefreshIcon
                                                            fontSize="small"
                                                            onClick={() => {
                                                              GetDocumentStatus(
                                                                msg.documentStoreId,
                                                                index
                                                              );
                                                            }}
                                                          />
                                                        </span>
                                                      )}
                                                    </>
                                                  ) : (
                                                    <span
                                                      style={{
                                                        marginLeft: "5px",
                                                      }}
                                                      title="Scanning document"
                                                    >
                                                      <CircularProgress
                                                        size={15}
                                                      />
                                                    </span>
                                                  )}
                                                </>
                                              ) : msg.status === "Completed" ? (
                                                <>
                                                  {downloading.isDownloading &&
                                                  downloading.id ===
                                                    msg.documentStoreId ? (
                                                    <span
                                                      style={{
                                                        marginLeft: "5px",
                                                      }}
                                                      title="Downloading document"
                                                    >
                                                      <CircularProgress
                                                        size={15}
                                                      />
                                                    </span>
                                                  ) : (
                                                    <span
                                                      style={{
                                                        marginRight: "5px",
                                                        cursor: "pointer",
                                                      }}
                                                      title="Download document"
                                                    >
                                                      <DownloadIcon
                                                        fontSize="small"
                                                        onClick={() => {
                                                          downloadFile(msg);
                                                        }}
                                                      />
                                                    </span>
                                                  )}
                                                </>
                                              ) : msg.status === "Failed" ? (
                                                <span
                                                  style={{
                                                    marginRight: "5px",
                                                    cursor: "pointer",
                                                  }}
                                                  title="failed to scan document"
                                                >
                                                  <ErrorOutlineIcon
                                                    fontSize="small"
                                                    color={"error"}
                                                  />
                                                </span>
                                              ) : msg.status === "" ? (
                                                <>
                                                  {" "}
                                                  {downloading.isRefreshStatus &&
                                                  downloading.id ===
                                                    msg.documentStoreId ? (
                                                    <span
                                                      style={{
                                                        marginLeft: "5px",
                                                      }}
                                                      title="Refreshing status"
                                                    >
                                                      <CircularProgress
                                                        size={15}
                                                      />
                                                    </span>
                                                  ) : (
                                                    <span
                                                      style={{
                                                        marginRight: "5px",
                                                        cursor: "pointer",
                                                      }}
                                                      title="Refresh status"
                                                    >
                                                      <RefreshIcon
                                                        fontSize="small"
                                                        onClick={() => {
                                                          GetDocumentStatus(
                                                            msg.documentStoreId,
                                                            index
                                                          );
                                                        }}
                                                      />
                                                    </span>
                                                  )}
                                                </>
                                              ) : null}
                                            </Box>
                                          </Box>
                                        </Typography>
                                      )}
                                      <Typography
                                        align="end"
                                        sx={{ marginTop: "-5px !important" }}
                                      >
                                        <span
                                          style={{
                                            paddingLeft: "15px",
                                            fontSize: "11px",
                                            color:
                                              side === "left" ? "#6191c6" : "",
                                          }}
                                        >
                                          {moment(msg.messageDate).format("LT")}
                                        </span>
                                        {msg.status &&
                                        msg.status === "scheduled" ? (
                                          <span
                                            style={{ marginLeft: "5px" }}
                                            title={
                                              "Message scheduled" +
                                              (msg.scheduleMessageDate
                                                ? " on " +
                                                  moment(
                                                    msg.scheduleMessageDate
                                                  ).format("lll")
                                                : "")
                                            }
                                          >
                                            <WatchLaterIcon
                                              fontSize="18px"
                                              sx={{ color: "#ffff00" }}
                                            />
                                          </span>
                                        ) : null}
                                        {msg.status &&
                                        msg.status === "sending" ? (
                                          <span
                                            style={{ marginLeft: "5px" }}
                                            title="Message sending."
                                          >
                                            <CircularProgress
                                              size={12}
                                              color={"inherit"}
                                            />
                                          </span>
                                        ) : null}
                                        {msg.status &&
                                        msg.status === "sent" &&
                                        (msg.showNotification === true ||
                                          channelName === "SMS") ? (
                                          <span
                                            style={{ marginLeft: "5px" }}
                                            title="Message sent."
                                          >
                                            <CheckIcon fontSize="18px" />
                                          </span>
                                        ) : null}
                                        {(msg.status &&
                                          (msg.status === "delivered" ||
                                            msg.status === "SUCCESS") &&
                                          (msg.showNotification === true ||
                                            channelName === "SMS")) ||
                                        (props.isHistory &&
                                          msg.messageType === "BOTMSG" &&
                                          msg.showNotification === true &&
                                          channelName === "Chat" &&
                                          msg.fromObjectType !== "Customer") ? (
                                          <span
                                            style={{ marginLeft: "5px" }}
                                            title="Message delivered."
                                          >
                                            <CheckIcon fontSize="18px" />
                                          </span>
                                        ) : null}
                                        {(msg.status &&
                                          msg.status === "failed") ||
                                        msg.status === "undelivered" ? (
                                          <span style={{ marginLeft: "5px" }}>
                                            <LightTooltip
                                              title={
                                                twilioErrorMessage ? (
                                                  <>
                                                    <a
                                                      href={twilioErrorUrl}
                                                      target="_blank"
                                                    >
                                                      {msg.statusCode + ": "}
                                                    </a>
                                                    {twilioErrorMessage}{" "}
                                                  </>
                                                ) : msg.statusDescription ? (
                                                  msg.statusDescription
                                                ) : (
                                                  msg.statusCode +
                                                  " Message not delivered."
                                                )
                                              }
                                            >
                                              <CancelIcon
                                                fontSize="18px"
                                                sx={{ color: "#ff0000" }}
                                              />
                                            </LightTooltip>
                                          </span>
                                        ) : null}
                                        {(msg.status &&
                                          msg.status === "delivered" &&
                                          !msg.showNotification &&
                                          channelName === "Chat") ||
                                        (msg.messageType === "BOTMSG" &&
                                          !msg.showNotification &&
                                          channelName === "Chat" &&
                                          msg.fromObjectType !== "Customer") ||
                                        (props.isHistory &&
                                          msg.messageType === "BOTMSG" &&
                                          !msg.showNotification &&
                                          channelName === "Chat" &&
                                          msg.fromObjectType !== "Customer") ? (
                                          <span
                                            style={{ marginLeft: "5px" }}
                                            title={
                                              msg.statusDescription
                                                ? msg.statusDescription
                                                : "Seen."
                                            }
                                          >
                                            <DoneAllIcon fontSize="18px" />
                                          </span>
                                        ) : null}
                                        {msg.status &&
                                        msg.status === "cancelled" ? (
                                          <span
                                            style={{ marginLeft: "5px" }}
                                            title="Message cancelled."
                                          >
                                            <CancelScheduleSendIcon fontSize="18px" />
                                          </span>
                                        ) : null}
                                      </Typography>
                                    </Typography>
                                  </LightTooltip>
                                </div>
                              </ClickAwayListener>
                            )}
                            {/* {props.endedByName && <div 
                   style={theme.customStyle.transferHistoryDiv}
                   >
                     <div   style={theme.customStyle.transferHistory}>
                     Conversation Ended By:  {props.endedByName}
                     </div>
                     <div style={{marginBottom: "15px"}}><span style={{fontSize:'13px',color:"grey"}}>{moment(props.endedByDate).format("lll")}</span></div>
                    
                   </div>} */}
                          </div>
                        );
                    })}
                </Grid>
                {side === "right" && <Grid item xs={1} {...GridItemProps}></Grid>}
            </Grid>
        </>
    );
};

ChatMsg.propTypes = {
    avatar: PropTypes.string,
    messages: PropTypes.arrayOf(PropTypes.string),
    side: PropTypes.oneOf(["left", "right"]),
    GridContainerProps: PropTypes.shape({}),
    GridItemProps: PropTypes.shape({}),
    AvatarProps: PropTypes.shape({}),
    getTypographyProps: PropTypes.func,
};
ChatMsg.defaultProps = {
    avatar: "",
    messages: [],
    side: "left",
    GridContainerProps: {},
    GridItemProps: {},
    AvatarProps: {},
    getTypographyProps: () => ({}),
};
export default ChatMsg;
