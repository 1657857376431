/***************
*11-04-2022 => Fixed issue with the loading symbol for contact details on fro unassigned conv - Rajesh.
*11-24-2022 => ONKAR => Texting-814 Re-editing the contact details is not showing the previously saved contact and application as not selected -fixed.
*11-29-2022 => ONKAR => Texting-814 When contact is not changed, application is delinking on click of back button -fixed.
*06-12-2023 => SUDAM B CHAVAN => TEXTING-1322 - EXISTING IN PROD : When editing the contact details, previously saved member profile is not showing as selected. - exists in production also.
*08-30-2023 => ONKAR => TEXTING-1578 -REACT Upgrade 
*10-20-2023 => SUDAM B CHAVAN -TEXTING-1758 - Exist in prod: Reediting the contact details without linking the fin account is not working.
***********/
import React, { useEffect, useState } from "react";
import {
    Button,
    TextField,
    Box,
    Divider,
    IconButton,
    Collapse,
    CircularProgress,
    Checkbox
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";
import { Edit } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Chip from '@mui/material/Chip';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import { getLinkedEntities, openEnityinCRM, searchContactandProspects, updateActivity, searchOppandFinAccount, createOrSearchTaskinCRM } from "../extensions/crmWrapper";

const StyledDataGrid= styled(DataGrid)(({ theme }) => ({
    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
    {
        display: "none",
    }
}))

function ConversationContactTab(props) {
    const theme = useTheme();
    const { convData, conversationAttributes, crmMemberFields, activityDueDataHours, crmMode, userInfo, crmProvider } = props;
    const [userData, setUserData] = useState({
        contactAttributes: "",
        contactId: "",
        conversations: [],
        createdBy: "",
        createdByName: "",
        createdByNavigation: "",
        createdDate: "",
        email: "",
        firstName: "",
        lastModifiedBy: "",
        lastModifiedByName: "",
        lastModifiedByNavigation: "",
        lastModifiedDate: "",
        lastName: "",
        mobileNumber: "",
        name: "",
        teams: [],
    });
    const [contactData, setContactData] = useState({});
    const [leadData, setLeadData] = useState({});
    const [oppData, setOppData] = useState({});
    const [finaccData, setFinAccData] = useState({});
    const [memberProfileData, setMemberProfileData] = useState({});
    const [showContactLeadTables, setShowContactLeadTables] = useState(true);
    const [showNextTables, setShowNextTables] = useState(false);
    const [showSearch, setShowSearch] = useState(true);
    const [showDetails, setShowDetails] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [contactsTableArr, setContactsTableArr] = useState([]);
    const [leadsTableArr, setLeadsTableArr] = useState([]);
    const [memberProfileTableArr, setMemberProfileTableArr] = useState([]);
    const [applicationsTableArr, setApplicationsTableArr] = useState([]);
    const [investmentsTableArr, setInvestmentsTableArr] = useState([]);
    const [loading, setLoading] = useState(true);
    const [contactLoading, setContactLoading] = useState(false);
    const [selectionModelContacts, setSelectionModelContacts] = useState([]);
    const [selectionModelLeads, setSelectionModelLeads] = useState([]);
    const [selectionModelMemberProfile, setSelectionModelMemberProfile] = useState([]);
    const [selectionModelApp, setSelectionModelApp] = useState([]);
    const [selectionModelInvestment, setSelectionModelInvestment] = useState([]);
    const [collapse, setCollapse] = useState({
        contacts: true,
        leads: true,
        memberprofile: true,
        applications: true,
        investments: true,
    });
    const [selectedContactDetails, setSelectedContactDetails] = useState({
        name: "",
        email: "",
        entityType: "",
        entityId: "",
        conversationAttributes: conversationAttributes,
        opportunityid: "",
    });
    useEffect(() => {
        if (crmMode == "true") {
            setSelectedContactDetails({});
            checkActivityhadRelatedRecords();
        }
        else {
            setShowSearch(false);
            setLoading(false);
        }
    }, []);
    const checkifActivityLinked = function () {
        console.log("Inside the check activity linked method");
        if (convData.conversationAttributes != null && convData.conversationAttributes != "") {
            let convAttributesObj = JSON.parse(convData.conversationAttributes);
            if (convAttributesObj.hasOwnProperty("ActivityId") && convAttributesObj["ActivityId"] != "") {
                return true;
            }
            else return false;
        } else return false;
    }

    const showContactLeadTablesWithSearch = function () {
        setLoading(true);
        let searchprm = convData.channel === 'Chat' ? convData.email : convData.customerPhoneNumber;
        setSearchText(searchprm);
        searchRecords(searchprm, 'contacts');
        setShowDetails(false);
        setShowContactLeadTables(true);
        setLoading(false);
    }
    const checkActivityhadRelatedRecords = async function () {
        var conversation = Object.assign({}, convData);
        let convAttributesObj = {};
        if (checkifActivityLinked() == false) {
            var updatedConv = await createOrSearchTaskinCRM(convData, crmMemberFields, false, activityDueDataHours, 'contactDetails',crmProvider);
            if (updatedConv != null && updatedConv.conversationAttributes != null) {
                convAttributesObj = JSON.parse(updatedConv.conversationAttributes);
                showLinkedRecordfortheConv(convAttributesObj);
                return;
            }
        }

         setSearchText(conversation.channel === 'Chat' ? conversation.email : conversation.customerPhoneNumber);

        if (checkifActivityLinked() == true) {
            conversation = Object.assign({}, convData);
            convAttributesObj = JSON.parse(conversation.conversationAttributes);
            showLinkedRecordfortheConv(convAttributesObj);
        } else if (conversation.source == 'CRM') {
            setTimeout(async function () {   // If conversation is outbound from CRM, wait for 2 more seconds to get task data updated to conv on store
                if (checkifActivityLinked() == true) {
                    convAttributesObj = JSON.parse(convData.conversationAttributes);
                    conversation = Object.assign({}, convData);
                    let convAttributesObj = JSON.parse(conversation.conversationAttributes);
                    showLinkedRecordfortheConv(convAttributesObj);
                }
                else {
                    setLoading(false);
                    setShowContactLeadTables(true);
                    if (convData.channel == 'SMS') {
                        await searchRecords(convData.customerPhoneNumber, 'contacts');
                    }
                    else {
                        await searchRecords(convData.email, 'contacts');
                    }

                    showContactLeadTablesWithSearch();
                }

            }, 4000);

        }
        else {
            setLoading(false);
            setShowContactLeadTables(true);
            let tempSearchText = convData.channel === 'Chat' ? conversation.email : conversation.customerPhoneNumber
            await searchRecords(tempSearchText, 'contacts');
            showContactLeadTablesWithSearch();
        }



    }

    const showLinkedRecordfortheConv = async function (convAttributesObj) {
        setLoading(true);
        setShowSearch(false);
        var linkedEntities = await getLinkedEntities(convAttributesObj["ActivityId"], crmProvider);
        // debugger;
        setContactData({});
        setLeadData({});
        setOppData({});
        setFinAccData({});
        setMemberProfileData({});
        if (linkedEntities != null && linkedEntities.isRecordAvailable === true) {
            if (!linkedEntities.opportunity && linkedEntities.contact != null) {
                var obj = JSON.parse(linkedEntities.contact);
                setContactData(obj[0]);
            }
            if (!linkedEntities.opportunity && linkedEntities.lead != null) {
                var obj = JSON.parse(linkedEntities.lead);
                setLeadData(obj[0]);
            }
            if (linkedEntities.opportunity != null) {
                var obj = JSON.parse(linkedEntities.opportunity);
                setOppData(obj[0]);
            }
            if (linkedEntities.memberprofile != null) {
                var obj = JSON.parse(linkedEntities.memberprofile);
                if (obj[0].wcu_memberprofile_wcu_sms != null){
                    setMemberProfileData(obj[0].wcu_memberprofile_wcu_sms);}
            }
            if (linkedEntities.finaccount != null) {
                var obj = JSON.parse(linkedEntities.finaccount);
                setFinAccData(obj[0]);
            }

            setShowDetails(true);
            setShowContactLeadTables(false);
            setShowSearch(false);
        } else {

            if (convData.channel == 'SMS') {
                setSearchText(convData.customerPhoneNumber);
                searchRecords(convData.customerPhoneNumber, 'contacts');
            }
            else {
                setSearchText(convData.email);
                searchRecords(convData.email, 'contacts');
            }

            setShowDetails(false);
            setShowContactLeadTables(true);
            setShowSearch(true);
        }
        setLoading(false);
    }

    const contactsColumns = [
        {
            field: "checkBox",
            headerName: "-",
            flex: 1,
            minWidth: 50,
            filterable: false,
            align: "center",
            headerClassName: "super-app-theme--header",
            renderCell: (params) => {
                return (
                    <Checkbox onClick={() => {
                        if (selectionModelContacts !== params.id) {
                            setSelectionModelContacts(params.id)
                            setSelectionModelLeads("")
                            setSelectionModelMemberProfile({})
                        } else {
                            setSelectionModelContacts({})
                        }

                    }} checked={selectionModelContacts === params.id} value={params.id} />
                )
            }
        },
        {
            field: "fullname",
            headerName: "Full Name",
            flex: 1,
            minWidth: 120,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "mobilephone",
            headerName: "Mobile phone",
            flex: 1,
            minWidth: 120,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "telephone2",
            headerName: "Home Phone",
            flex: 1,
            minWidth: 120,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "telephone1",
            headerName: "Business Phone",
            flex: 1,
            minWidth: 120,
            headerClassName: "super-app-theme--header",
        },
    ];
    console.log(convData, "convData");
    const leadsColumns = [
        {
            field: "checkBox",
            headerName: "-",
            flex: 1,
            minWidth: 50,
            filterable: false,
            align: "center",
            headerClassName: "super-app-theme--header",
            renderCell: (params) => {
                return (
                    <Checkbox onClick={() => {
                        if (selectionModelLeads !== params.id) {
                            setSelectionModelLeads(params.id)
                            setSelectionModelContacts({})
                            setSelectionModelMemberProfile({})
                        } else {
                            setSelectionModelLeads("")
                        }

                    }} checked={selectionModelLeads === params.id} value={params.id} />
                )
            }
        },
        {
            field: "fullname",
            headerName: "Full Name",
            flex: 1,
            minWidth: 120,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "mobilephone",
            headerName: "Mobile phone",
            flex: 1,
            minWidth: 120,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "telephone2",
            headerName: "Home Phone",
            flex: 1,
            minWidth: 120,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "telephone1",
            headerName: "Business Phone",
            flex: 1,
            minWidth: 120,
            headerClassName: "super-app-theme--header",
        },
    ];
    const memberProfileColumns = [
        {
            field: "checkBox",
            headerName: "-",
            flex: 1,
            minWidth: 50,
            filterable: false,
            align: "center",
            headerClassName: "super-app-theme--header",
            renderCell: (params) => {
                return (
                    <Checkbox onClick={() => {
                        if (selectionModelMemberProfile !== params.id) {

                            setSelectionModelMemberProfile(params.id)
                            setSelectionModelContacts({})
                            setSelectionModelLeads("")
                        } else {
                            setSelectionModelMemberProfile({})
                        }

                    }} checked={selectionModelMemberProfile === params.id} value={params.id} />
                )
            }
        },
        {
            field: "wcu_name",
            headerName: "Full Name",
            flex: 1,
            minWidth: 120,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "wcu_mobilephone",
            headerName: "Mobile phone",
            flex: 1,
            minWidth: 120,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "wcu_telephone2",
            headerName: "Home Phone",
            flex: 1,
            minWidth: 120,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "wcu_telephone1",
            headerName: "Business Phone",
            flex: 1,
            minWidth: 120,
            headerClassName: "super-app-theme--header",
        },
    ];
    const appColumns = [
        {
            field: "checkBox",
            headerName: "-",
            flex: 1,
            minWidth: 50,
            filterable: false,
            align: "center",
            headerClassName: "super-app-theme--header",
            renderCell: (params) => {
                return (
                    <Checkbox onClick={() => {
                        if (selectionModelApp !== params.id) {
                            setSelectionModelApp(params.id)
                            setSelectionModelInvestment({})
                        } else {
                            setSelectionModelApp({})
                        }

                    }} checked={selectionModelApp === params.id} value={params.id} />
                )
            }
        },
        {
            field: "name",
            headerName: "Application Name",
            flex: 1,
            minWidth: 120,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "wcu_externalid",
            headerName: "Application No",
            flex: 1,
            minWidth: 120,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "wcu_productname",
            headerName: "Product Name",
            flex: 1,
            minWidth: 120,
            headerClassName: "super-app-theme--header",
        },
    ];
    const investmentColumns = [
        {
            field: "checkBox",
            headerName: "-",
            flex: 1,
            minWidth: 50,
            filterable: false,
            align: "center",
            headerClassName: "super-app-theme--header",
            renderCell: (params) => {
                return (
                    <Checkbox onClick={() => {
                        if (selectionModelInvestment !== params.id) {
                            setSelectionModelInvestment(params.id)
                            setSelectionModelApp({})
                        } else {
                            setSelectionModelInvestment({})
                        }

                    }} checked={selectionModelInvestment === params.id} value={params.id} />
                )
            }
        },
        {
            field: "wcu_finproducttype@OData.Community.Display.V1.FormattedValue",
            headerName: "Account Type",
            flex: 1,
            minWidth: 120,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "wcu_finproductid",
            headerName: "Account Number",
            flex: 1,
            minWidth: 120,
            headerClassName: "super-app-theme--header",
        },
    ];

    const searchRecords = async function (searchText, entityName) {
        setSelectedContactDetails({});
        setSelectionModelContacts({});
        setSelectionModelLeads({});
        setSelectionModelMemberProfile({})
        setContactsTableArr([]);
        setLeadsTableArr([]);
        setMemberProfileTableArr([]);
        if (entityName == "contacts") {
            setContactLoading(true);
            var searchResults = await searchContactandProspects(searchText, crmProvider);
            if (searchResults) {
                let tempContactArr = [];
                let tempLeadArr = [];
                let tempMemberProfileArr = [];
                let contactResult = searchResults.contactResult;
                contactResult.forEach((item, index) => {
                    item.id = index;
                    tempContactArr.push(item);
                if(item.contactid === contactData.contactid){
                    setSelectionModelContacts(index);
                    setSelectedContactDetails({
                        ...selectedContactDetails,
                        entityType: "Contact",
                        entityId: item.contactid,
                    });
                }
                });
                setContactsTableArr(tempContactArr);
                let prospectResult = searchResults.prospectResult;
                prospectResult.forEach((item, index) => {
                    item.id = index;
                    tempLeadArr.push(item);
                    if(item.leadid === leadData.leadid){
                        setSelectionModelLeads(index);
                        setSelectedContactDetails({
                            ...selectedContactDetails,
                            entityType: "Lead",
                            entityId: item.leadid,
                        });
                    }
                });
                setLeadsTableArr(tempLeadArr);
                let memberProfileResult = searchResults.memberProfileResult;
                if (!!searchResults.memberProfileResult) {
                    memberProfileResult.forEach((item, index) => {
                    item.id = index;
                    tempMemberProfileArr.push(item);
                    if (item.memberprofileid === memberProfileData.wcu_memberprofileid) {
                        setSelectionModelMemberProfile(index);//default setting selected wcu_memberprofileid
                        setSelectedContactDetails({
                            ...selectedContactDetails,
                            entityType: "wcu_memberprofile",
                            entityId: item.memberprofileid,
                            opportunityid: item.wcu_opportunityid
                        });
                    }
                });
                setMemberProfileTableArr(tempMemberProfileArr);
            }
                setCollapse({
                    ...collapse,
                    contacts: tempContactArr?.length > 0,
                    leads: tempLeadArr?.length > 0,
                    memberprofile: tempMemberProfileArr?.length > 0
                });
                console.log("searchResults", prospectResult);
                setContactLoading(false);
            }
            else{
                console.log("searchResults", searchResults);
                setContactLoading(false);
            }
        }
    };
    const handleSave = async function () {
        try {
            if (selectedContactDetails != null && Object.keys(selectedContactDetails).length != 0) {
                if (showNextTables == true && (selectedContactDetails.entityType == "Contact" || selectedContactDetails.entityType == "Lead" || selectedContactDetails.entityType == "wcu_memberprofile")) {
                    checkActivityhadRelatedRecords();
                    return;
                }
                if (isNaN(selectionModelApp) && isNaN(selectionModelInvestment) && (selectedContactDetails.entityType != "Contact" && selectedContactDetails.entityType != "Lead" && selectedContactDetails.entityType != "wcu_memberprofile")) {
                    setSelectionModelApp({});
                    setSelectionModelInvestment({});
                    return;
                }
                let payload = {
                    entityType: selectedContactDetails.entityType, //Lead/Opportunity/FinAccount,
                    entityId: selectedContactDetails.entityId, // wcu_finaccountid/opportunityid/contactid/leadid
                    conv: convData,
                    crmMemberFields: crmMemberFields,
                    opportunityid: selectedContactDetails.opportunityid,//added for member profile auto linked to application
                    channel: convData.channel
                };
                console.log(payload, "payload");
                let saveResult = await updateActivity(payload, crmProvider);
                if (crmProvider == 'S') {
                    showLinkedRecordfortheConv(JSON.parse(convData.conversationAttributes));
                }
                if (payload.entityType != 'Contact') {
                    checkActivityhadRelatedRecords();
                }
            }
            else {
                checkActivityhadRelatedRecords();
            }
            //setSelectionModelContacts({});
            // setSelectionModelApp({});
            // setSelectionModelInvestment({});

        } catch (err) {
            console.log(err, "updateActivityErr");
        }
    };

    const searchNext = async function () {

        if (selectedContactDetails.entityType == "Contact") {
            setLoading(true);
            try {
                let searchResult = await searchOppandFinAccount(selectedContactDetails.entityId,crmProvider);
                if (searchResult) {
                    let tempOppArr = [];
                    let tempFinArr = [];

                    // let oppResult = Object.values(JSON.parse(searchResult.oppResults));
                    // oppResult.forEach((item, index) => {
                    //     item.id = index;
                    //     tempOppArr.push(item);
                    //     if(item.opportunityid === oppData.opportunityid){
                    //         setSelectionModelApp(index);
                    //         setSelectedContactDetails({
                    //             ...selectedContactDetails,
                    //             entityType: "Opportunity",
                    //             entityId: item.opportunityid,
                    //         });
                    //     }
                    // });
                    // setApplicationsTableArr(tempOppArr);

                    let finAccountResults = Object.values(
                        JSON.parse(searchResult.finAccountResults)
                    );
                    finAccountResults.forEach((item, index) => {
                        if (item.wcu_FinAccountId != null) {
                            item.wcu_FinAccountId.id = index;
                            tempFinArr.push(item.wcu_FinAccountId);
                            if(item.wcu_FinAccountId.wcu_finaccountid === finaccData.wcu_finaccountid){
                                setSelectionModelInvestment(index);
                                setSelectedContactDetails({
                                    ...selectedContactDetails,
                                    entityType: "FinAccount",
                                    entityId: item.wcu_FinAccountId.wcu_finaccountid,
                                });
                            }
                        }
                    });
                    console.log('FINARR:::' + JSON.stringify(tempFinArr));
                    setInvestmentsTableArr(tempFinArr);
                    setCollapse({
                        ...collapse,
                        investments: tempFinArr?.length > 0,
                    });
                }
                setLoading(false);
            } catch (err) {
                console.log(err, "searchNextErr");
                setLoading(false);
            }
        }
    };

    return (
        <>
            <div>
                {crmMode != "true" && (

                    <div style={{ textAlign: "center", paddingTop: "10px" }}>Contact Details are available in CRM View</div>

                )}
            </div>
            {crmMode == "true" && (
                <div
                    className="customScroll"
                    style={{ height: "calc(100vh - 194px)", overflow: "auto", marginLeft: "10px" }}

                >
                    <div
                        style={{
                            paddingTop: "25px",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >

                        {showSearch && !showNextTables && (
                            <>
                                <TextField
                                    sx={{
                                        "& legend": { display: "none" },
                                        "& fieldset": { top: 0 },
                                    }}
                                    variant="outlined"
                                    size="small"
                                    label=""
                                    id="SearchText"
                                    value={searchText}
                                    onChange={(e) => {
                                        setSearchText(e.target.value);
                                    }}
                                    inputProps={{ //not show suggetions
                                        autoComplete: 'new-password',
                                        form: {
                                            autocomplete: 'off',
                                        },
                                    }}
                                />
                                <Button
                                    sx={{ marginLeft: "10px", height: "37px" }}
                                    variant="contained"
                                    size="small"
                                    disabled={searchText ? false : true}
                                    onClick={(e) => {
                                        searchRecords(searchText, "contacts");
                                    }}
                                >
                                    Search
                                </Button>
                            </>
                        )}
                        {/* {showSearch === false && (
            <>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex" }}>
                  <div style={{ fontWeight: 600 }}>
                    <div>Name:</div>
                    <div>Email:</div>
                    {selectedContactDetails.applicationNo !== "" && (
                      <div>Application No:</div>
                    )}
                  </div>
                  <div style={{ marginLeft: "15px" }}>
                    <div>{selectedContactDetails.name}</div>
                    <div>{selectedContactDetails.email}</div>
                    {selectedContactDetails.applicationNo !== "" && (
                      <div>{selectedContactDetails.applicationNo}</div>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => {
                      setShowContactLeadTables(true);
                      setShowNextTables(false);
                      setShowSearch(true);
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    sx={{ marginLeft: "10px" }}
                    size="small"
                    variant="contained"
                    disabled={
                      selectedContactDetails.applicationNo === "" ? true : false
                    }
                  >
                    Save
                  </Button>
                </div>
              </div>
            </>
          )} */}
                    </div>
                    {/* {showSearch === false && (
          <Divider sx={{ margin: "10px 15px 0px 15px" }} />
        )} */}
                    {loading || contactLoading ? (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "20%",
                            }}
                        >
                            <CircularProgress />
                        </div>
                    ) : <></>}
                    {showContactLeadTables && loading === false && !contactLoading && !showNextTables && (
                        <>
                            <div style={{ marginTop: "15px" }}>
                                <Button
                                    size="small"
                                    variant="text"
                                    onClick={() => {
                                        setCollapse({
                                            ...collapse,
                                            contacts: !collapse.contacts,
                                        });
                                    }}
                                >
                                    <span
                                        style={{
                                            fontWeight: 600,
                                            color: "black",
                                            marginLeft: "2px",
                                        }}
                                    >
                                        Contacts
                                        <span
                                            style={{
                                                marginLeft: "10px",
                                                position: "relative",
                                                bottom: "1px",
                                            }}
                                        >
                                            {collapse.contacts ? (
                                                <ExpandLess sx={{ height: "20px", width: "20px" }} />
                                            ) : (
                                                <ExpandMore sx={{ height: "20px", width: "20px" }} />
                                            )}
                                        </span>
                                    </span>
                                </Button>

                                <Collapse in={collapse.contacts}>
                                    <Box sx={theme.customStyle.tableBoxStyle}>
                                        <StyledDataGrid
                                            loading={contactLoading}
                                            rows={contactsTableArr}
                                            columns={contactsColumns}
                                            pageSize={5}
                                            rowsPerPageOptions={[5]}
                                            disableSelectionOnClick
                                            checkboxSelection={false}
                                            rowHeight={38}
                                            headerHeight={40}
                                            autoHeight={true}
                                            showCellRightBorder={true}
                                            hideFooter={contactsTableArr.length > 5 ? false : true}
                                            // onSelectionModelChange={(newSelectionModel) => {
                                            //     if (newSelectionModel.length > 0) {
                                            //         setSelectionModelContacts(
                                            //             newSelectionModel[newSelectionModel.length - 1]
                                            //         );
                                            //         setSelectionModelLeads([]);
                                            //     } else {
                                            //         setSelectionModelContacts([]);
                                            //     }
                                            // }}
                                            // selectionModel={selectionModelContacts}
                                            onRowClick={(e) => {
                                                console.log(e, "RowData");
                                                setSelectedContactDetails({
                                                    ...selectedContactDetails,
                                                    entityType: "Contact",
                                                    entityId: e.row.contactid,
                                                });
                                                if (selectionModelContacts !== e.row.id) {
                                                    setSelectionModelContacts(e.row.id)
                                                    setSelectionModelLeads({});
                                                    setSelectionModelMemberProfile({})
                                                } else {
                                                    setSelectionModelContacts({})
                                                }
                                            }}
                                        />
                                    </Box>
                                </Collapse>
                            </div>
                            <div style={{ marginTop: "15px" }}>
                                <Button
                                    size="small"
                                    variant="text"
                                    onClick={() => {
                                        setCollapse({
                                            ...collapse,
                                            leads: !collapse.leads,
                                        });
                                    }}
                                >
                                    <span style={{ fontWeight: 600, color: "black" }}>
                                        Leads
                                        <span
                                            style={{
                                                marginLeft: "10px",
                                                position: "relative",
                                                bottom: "1px",
                                            }}
                                        >
                                            {collapse.leads ? (
                                                <ExpandLess sx={{ height: "20px", width: "20px" }} />
                                            ) : (
                                                <ExpandMore sx={{ height: "20px", width: "20px" }} />
                                            )}
                                        </span>
                                    </span>
                                </Button>

                                <Collapse in={collapse.leads}>
                                    <Box sx={theme.customStyle.tableBoxStyle}>
                                        <StyledDataGrid
                                            loading={contactLoading}
                                            rows={leadsTableArr}
                                            columns={leadsColumns}
                                            pageSize={5}
                                            rowsPerPageOptions={[5]}
                                            disableSelectionOnClick
                                            checkboxSelection={false}
                                            rowHeight={38}
                                            headerHeight={40}
                                            autoHeight={true}
                                            showCellRightBorder={true}
                                            hideFooter={leadsTableArr.length > 5 ? false : true}
                                            // onSelectionModelChange={(newSelectionModel) => {
                                            //     if (newSelectionModel.length > 0) {
                                            //         setSelectionModelLeads(
                                            //             newSelectionModel[newSelectionModel.length - 1]
                                            //         );
                                            //         setSelectionModelContacts([]);
                                            //     } else {
                                            //         setSelectionModelLeads([]);
                                            //     }
                                            // }}
                                            // selectionModel={selectionModelLeads}
                                            onRowClick={(e) => {
                                                setSelectedContactDetails({
                                                    ...selectedContactDetails,
                                                    entityType: "Lead",
                                                    entityId: e.row.leadid,
                                                });
                                                if (selectionModelLeads !== e.row.id) {
                                                    setSelectionModelLeads(e.row.id)
                                                    setSelectionModelContacts({})
                                                    setSelectionModelMemberProfile({})
                                                } else {
                                                    setSelectionModelLeads({})
                                                }
                                            }}
                                        />
                                    </Box>
                                </Collapse>
                            </div>
                            <div style={{ marginTop: "15px" }}>
                                <Button
                                    size="small"
                                    variant="text"
                                    onClick={() => {
                                        setCollapse({
                                            ...collapse,
                                            memberprofile: !collapse.memberprofile,
                                        });
                                    }}
                                >
                                    <span style={{ fontWeight: 600, color: "black" }}>
                                        Member profile
                                        <span
                                            style={{
                                                marginLeft: "10px",
                                                position: "relative",
                                                bottom: "1px",
                                            }}
                                        >
                                            {collapse.memberprofile ? (
                                                <ExpandLess sx={{ height: "20px", width: "20px" }} />
                                            ) : (
                                                <ExpandMore sx={{ height: "20px", width: "20px" }} />
                                            )}
                                        </span>
                                    </span>
                                </Button>

                                <Collapse in={collapse.memberprofile}>
                                    <Box sx={theme.customStyle.tableBoxStyle}>
                                        <StyledDataGrid
                                            loading={contactLoading}
                                            rows={memberProfileTableArr}
                                            columns={memberProfileColumns}
                                            pageSize={5}
                                            rowsPerPageOptions={[5]}
                                            disableSelectionOnClick
                                            checkboxSelection={false}
                                            rowHeight={38}
                                            headerHeight={40}
                                            autoHeight={true}
                                            showCellRightBorder={true}
                                            hideFooter={memberProfileTableArr.length > 5 ? false : true}
                                            onRowClick={(e) => {
                                                setSelectedContactDetails({
                                                    ...selectedContactDetails,
                                                    entityType: "wcu_memberprofile",
                                                    entityId: e.row.memberprofileid,
                                                    opportunityid: e.row.wcu_opportunityid,//to link application to member profile
                                                });
                                                if (selectionModelMemberProfile !== e.row.id) {
                                                    setSelectionModelMemberProfile(e.row.id)
                                                    setSelectionModelLeads("")
                                                    setSelectionModelContacts({})
                                                } else {
                                                    setSelectionModelMemberProfile({})
                                                }
                                            }}
                                        />
                                    </Box>
                                </Collapse>
                            </div>
                            {(!isNaN(selectionModelLeads) ||
                                !isNaN(selectionModelContacts) || !isNaN(selectionModelMemberProfile)) && (convData.conversationAttributes !== "") && (convData?.agentId === userInfo.userId) && (
                                    <Button
                                        size="small"
                                        variant="contained"
                                        sx={{ marginLeft: "9px", marginTop: "10px" }}
                                        onClick={() => {
                                            handleSave();
                                            if (!isNaN(selectionModelContacts) && crmProvider != 'S') {
                                                searchNext();
                                                setShowContactLeadTables(false);
                                                setShowNextTables(true);
                                                setShowSearch(false);

                                            }
                                            else if (crmProvider == 'S') {
                                                setShowSearch(false);
                                                setShowContactLeadTables(false);
                                                setShowNextTables(false);
                                                setSelectionModelApp({});
                                                setSelectionModelInvestment({});

                                            }
                                            else {
                                                setShowSearch(true);
                                                setShowContactLeadTables(false);
                                                setShowNextTables(false);
                                            }
                                        }}
                                    >
                                    {selectedContactDetails.entityType ==="Contact" ? "Next" : "Save"}
                                    </Button>
                                )}
                        </>
                    )}
                    {showNextTables && !loading && (
                        <>
                            {!isNaN(selectionModelMemberProfile) && <div style={{ marginTop: "15px" }}>
                                <Button
                                    size="small"
                                    variant="text"
                                    onClick={() => {
                                        setCollapse({
                                            ...collapse,
                                            applications: !collapse.applications,
                                        });
                                    }}
                                >
                                    <span
                                        style={{
                                            fontWeight: 600,
                                            color: "black",
                                            marginLeft: "2px",
                                        }}
                                    >
                                        Applications
                                        <span
                                            style={{
                                                marginLeft: "10px",
                                                position: "relative",
                                                bottom: "1px",
                                            }}
                                        >
                                            {collapse.applications ? (
                                                <ExpandLess sx={{ height: "20px", width: "20px" }} />
                                            ) : (
                                                <ExpandMore sx={{ height: "20px", width: "20px" }} />
                                            )}
                                        </span>
                                    </span>
                                </Button>

                                <Collapse in={collapse.applications}>
                                    <Box sx={theme.customStyle.tableBoxStyle}>
                                        <StyledDataGrid
                                            rows={applicationsTableArr}
                                            columns={appColumns}
                                            pageSize={5}
                                            rowsPerPageOptions={[5]}
                                            disableSelectionOnClick
                                            checkboxSelection={false}
                                            rowHeight={38}
                                            headerHeight={40}
                                            autoHeight={true}
                                            hideFooter={true}
                                            showCellRightBorder={true}
                                            // onSelectionModelChange={(newSelectionModel) => {
                                            //     if (newSelectionModel.length > 0) {
                                            //         setSelectionModelApp(
                                            //             newSelectionModel[newSelectionModel.length - 1]
                                            //         );
                                            //         setSelectionModelInvestment([]);
                                            //     } else {
                                            //         setSelectionModelApp([]);
                                            //     }
                                            // }}
                                            // selectionModel={selectionModelApp}
                                            onRowClick={(e) => {
                                                console.log(e, "RowData");
                                                setSelectedContactDetails({
                                                    ...selectedContactDetails,
                                                    entityType: "Opportunity",
                                                    entityId: e.row.opportunityid,
                                                });
                                                if (selectionModelApp !== e.row.id) {
                                                    setSelectionModelApp(e.row.id)
                                                    setSelectionModelInvestment({})
                                                } else {
                                                    setSelectionModelApp({})
                                                }
                                            }}
                                        />
                                    </Box>
                                </Collapse>
                            </div>}
                            {!isNaN(selectionModelContacts) &&
                            <div style={{ marginTop: "15px" }}>
                                <Button
                                    size="small"
                                    variant="text"
                                    onClick={() => {
                                        setCollapse({
                                            ...collapse,
                                            investments: !collapse.investments,
                                        });
                                    }}
                                >
                                    <span
                                        style={{
                                            fontWeight: 600,
                                            color: "black",
                                            marginLeft: "2px",
                                        }}
                                    >
                                        Financial Accounts
                                        <span
                                            style={{
                                                marginLeft: "10px",
                                                position: "relative",
                                                bottom: "1px",
                                            }}
                                        >
                                            {collapse.investments ? (
                                                <ExpandLess sx={{ height: "20px", width: "20px" }} />
                                            ) : (
                                                <ExpandMore sx={{ height: "20px", width: "20px" }} />
                                            )}
                                        </span>
                                    </span>
                                </Button>

                                <Collapse in={collapse.investments}>
                                    <Box sx={theme.customStyle.tableBoxStyle}>
                                        <StyledDataGrid
                                            rows={investmentsTableArr}
                                            columns={investmentColumns}
                                            pageSize={5}
                                            rowsPerPageOptions={[5]}
                                            disableSelectionOnClick
                                            checkboxSelection={false}
                                            rowHeight={38}
                                            headerHeight={40}
                                            autoHeight={true}
                                            hideFooter={true}
                                            showCellRightBorder={true}
                                            // onSelectionModelChange={(newSelectionModel) => {
                                            //     if (newSelectionModel.length > 0) {
                                            //         setSelectionModelInvestment(
                                            //             newSelectionModel[newSelectionModel.length - 1]
                                            //         );
                                            //         setSelectionModelApp([]);
                                            //     } else {
                                            //         setSelectionModelInvestment([]);
                                            //     }
                                            // }}
                                            // selectionModel={selectionModelInvestment}
                                            onRowClick={(e) => {
                                                console.log(e, "RowData");
                                                setSelectedContactDetails({
                                                    ...selectedContactDetails,
                                                    entityType: "FinAccount",
                                                    entityId: e.row.wcu_finaccountid,
                                                });
                                                if (selectionModelInvestment !== e.row.id) {
                                                    setSelectionModelInvestment(e.row.id)
                                                    setSelectionModelApp({})
                                                } else {
                                                    setSelectionModelInvestment({})
                                                }
                                            }}
                                        />
                                    </Box>
                                </Collapse>
                            </div>}
                            <div
                                style={{
                                    paddingTop: "25px",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <Button
                                    startIcon={<ArrowBackIcon />}
                                    size="small"
                                    variant="contained"
                                    sx={{ marginLeft: "5px", marginTop: "10px" }}
                                    onClick={() => {
                                        // setSelectionModelApp({});
                                        // setSelectionModelInvestment({});
                                        setShowContactLeadTables(true);
                                        setShowNextTables(false);
                                        setShowSearch(true);
                                    }}
                                >
                                    Back
                                </Button>
                                <Button
                                    startIcon={<SaveIcon />}
                                    size="small"
                                    variant="contained"
                                    sx={{ marginLeft: "5px", marginTop: "10px" }}
                                    onClick={() => {
                                        if (!isNaN(selectionModelInvestment) || !isNaN(selectionModelApp)) {
                                            handleSave();
                                        }
                                        else {
                                            checkActivityhadRelatedRecords();
                                        }


                                        setContactData({});
                                        setLeadData({});
                                        setOppData({});
                                        setFinAccData({});
                                        setShowNextTables(false);
                                    }}
                                >
                                    Save
                                </Button>
                            </div>
                            {/* <Button
               size="small"
               variant="contained"
               sx={{ marginLeft: "5px", marginTop: "10px" }}
               onClick={() => {
                setShowDetails(true);
                setShowContactLeadTables(false);
                setShowNextTables(false);
                setShowSearch(false);
               }}
             >
               Cancel
             </Button> */}
                        </>
                    )}


                    {showDetails && !showNextTables && !showContactLeadTables && (

                        <>

                            {contactData.contactid && (
                                <div style={{ padding: "10px" }}>
                                    <div>
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <span style={theme.customStyle.detailTitles}>Member  </span>
                                            <Chip sx={theme.customStyle.chipStyle} color="primary" label={contactData.fullname} onClick={openEnityinCRM(contactData.contactid, 'contact', 'contactid', crmProvider)} />
                                            <div>
                                                <IconButton
                                                    sx={{ marginLeft: "5px" }}
                                                    onClick={() => {
                                                        setSelectionModelContacts({});
                                                        setSelectionModelLeads({});
                                                        setSelectionModelMemberProfile({});
                                                        setShowDetails(false);
                                                        setShowContactLeadTables(true);
                                                        setShowSearch(true);
                                                        setLoading(true);

                                                        if (convData.channel == 'SMS') {
                                                            setSearchText(convData.customerPhoneNumber);
                                                             searchRecords(convData.customerPhoneNumber, 'contacts');
                                                        }
                                                        else {
                                                            setSearchText(convData.email);
                                                            searchRecords(convData.email, 'contacts');
                                                        }
                                                        setLoading(false);
                                                        setShowContactLeadTables(true);
                                                    }}
                                                >
                                                    <Edit />
                                                </IconButton>
                                            </div>
                                        </Box>

                                    </div>

                                </div>
                            )
                            }

                            {memberProfileData.wcu_memberprofileid && (
                                <div style={{ padding: "10px" }}>
                                    <div>
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <span style={theme.customStyle.detailTitles}>Member Profile </span>
                                            <Chip sx={theme.customStyle.chipStyle} color="primary" label={memberProfileData.wcu_name} onClick={openEnityinCRM(memberProfileData.wcu_memberprofileid, 'wcu_memberprofile', 'wcu_memberprofileid', crmProvider)} />
                                            <div>
                                                <IconButton
                                                    sx={{ marginLeft: "5px" }}
                                                    onClick={() => {
                                                        setSelectionModelContacts({});
                                                        setSelectionModelLeads({});
                                                        setSelectionModelMemberProfile({});
                                                        setShowDetails(false);
                                                        setShowContactLeadTables(true);
                                                        setShowSearch(true);
                                                        setLoading(true);
                                                        if (convData.channel == 'SMS') {
                                                            setSearchText(convData.customerPhoneNumber);
                                                             searchRecords(convData.customerPhoneNumber, 'contacts');
                                                        }
                                                        else {
                                                            setSearchText(convData.email);
                                                             searchRecords(convData.email, 'contacts');
                                                        }
                                                        setLoading(false);
                                                        setShowContactLeadTables(true);
                                                    }}
                                                >
                                                    <Edit />
                                                </IconButton>
                                            </div>
                                        </Box>

                                    </div>

                                </div>
                            )
                            }

                            {leadData.leadid && (
                                <div style={{ padding: "10px" }}>
                                    <div>
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <span style={theme.customStyle.detailTitles}>Prospect  </span>
                                            <Chip sx={theme.customStyle.chipStyle} color="primary" label={leadData.fullname} onClick={openEnityinCRM(leadData.leadid, 'lead', 'leadid')} />

                                            <div>
                                                <IconButton
                                                    sx={{ marginLeft: "5px" }}
                                                    onClick={() => {
                                                        setSelectionModelContacts({});
                                                        setSelectionModelLeads({});
                                                        setSelectionModelMemberProfile({});
                                                        setShowDetails(false);
                                                        setShowContactLeadTables(true);
                                                        setShowSearch(true);
                                                        setLoading(true);
                                                        if (convData.channel == 'SMS') {
                                                            setSearchText(convData.customerPhoneNumber);
                                                             searchRecords(convData.customerPhoneNumber, 'contacts');
                                                        }
                                                        else {
                                                             setSearchText(convData.email);
                                                             searchRecords(convData.email, 'contacts');
                                                        }
                                                        setLoading(false);
                                                        setShowContactLeadTables(true);
                                                    }}
                                                >
                                                    <Edit />
                                                </IconButton>
                                            </div>
                                        </Box>
                                    </div>


                                </div>
                            )
                            }

                            {oppData.opportunityid && (
                                <div style={{ padding: "10px" }}>
                                    <div>
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <span style={theme.customStyle.detailTitles}>Application</span>
                                            <Chip sx={theme.customStyle.chipStyle} color="primary" label={oppData.name} onClick={openEnityinCRM(oppData.opportunityid, 'opportunity', 'opportunityid')} />

                                            <div>
                                                <IconButton
                                                    sx={{ marginLeft: "5px" }}
                                                    onClick={() => {
                                                        setSelectionModelContacts({});
                                                        setSelectionModelLeads({});
                                                        setSelectionModelMemberProfile({});
                                                        setShowDetails(false);
                                                        setShowContactLeadTables(true);
                                                        setShowSearch(true);
                                                        setLoading(true);
                                                        if (convData.channel == 'SMS') {
                                                            setSearchText(convData.customerPhoneNumber);
                                                            searchRecords(convData.customerPhoneNumber, 'contacts');
                                                        }
                                                        else {
                                                            setSearchText(convData.email);
                                                            searchRecords(convData.email, 'contacts');
                                                        }
                                                        setLoading(false);
                                                        setShowContactLeadTables(true);
                                                    }}
                                                >
                                                    <Edit />
                                                </IconButton>
                                            </div>
                                        </Box>
                                    </div>


                                </div>
                            )
                            }

                            {finaccData.wcu_finaccountid && (
                                <div style={{ padding: "10px" }}>
                                    <div>
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <span style={theme.customStyle.detailTitles}>Financial Account </span>
                                            <Chip sx={theme.customStyle.chipStyle} color="primary" label={finaccData.wcu_name} onClick={openEnityinCRM(finaccData.wcu_finaccountid, 'wcu_finaccount', 'wcu_finaccountid')} />

                                            <div>
                                                <IconButton
                                                    sx={{ marginLeft: "5px" }}
                                                    onClick={() => {
                                                        setSelectionModelContacts({});
                                                        setSelectionModelLeads({});
                                                        setSelectionModelMemberProfile({});
                                                        setShowDetails(false);
                                                        setShowContactLeadTables(true);
                                                        setShowSearch(true);
                                                        setLoading(true);
                                                        if (convData.channel == 'SMS') {
                                                            setSearchText(convData.customerPhoneNumber);
                                                            searchRecords(convData.customerPhoneNumber, 'contacts');
                                                        }
                                                        else {
                                                            setSearchText(convData.email);
                                                            searchRecords(convData.email, 'contacts');
                                                        }
                                                        setLoading(false);
                                                        setShowContactLeadTables(true);
                                                    }}
                                                >
                                                    <Edit />
                                                </IconButton>
                                            </div>
                                        </Box>
                                    </div>


                                </div>
                            )
                            }

                        </>
                    )}



                </div>
            )}
        </>
    );
}


export default ConversationContactTab;