
/**
 * 11-27-2023 => SUDAM B CHAVAN => TEXTING-1872 Chat Survey Questions Configuration - Admin UI
 * 12-06-2023 => SUDAM B CHAVAN => TEXTING-1894 0: Survey: Questions Order arrangement while associating to Survey Template.
 * 12-11-2023 => SUDAM B CHAVAN =>TEXTING-1891 0: Survey : Update Questions Admin UI to add options and save , separated in backend
 */
import React, { useState, useEffect, useContext } from "react";
import AddIcon from "@mui/icons-material/Add";
import {
    IconButton,
    Button,
    Box,
    Dialog,
    DialogContent,
    DialogActions,
    InputAdornment
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import apiConfig from "../../api/apiConfig";
import { AuthContext } from "../../AuthContext";
import { InvokePostServiceCall, InvokeGetServiceCall } from "../../api/serviceUtil";
import { useTheme } from "@mui/material/styles";
import RemoveIcon from "@mui/icons-material/Remove";
import SnackBar from "../../components/SnackBar"
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import { TextField } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { StyledDataGrid } from "../../components/styledComponents/StyledDataGrid";
import AddQuestionsToSurveyTemplate from "./addQuestionsToSurveyTemplate";


const QuestionsToSurveyTemplate = (props) => {
    const { messageApiToken, userInfo } = useContext(AuthContext);
    const theme = useTheme();
    const [questionsData, setQuestionsData] = useState([]);
    const [questionsDataCopy, setQuestionsDataCopy] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [questionsForAssociation, setQuestionsForAssociation] = React.useState([]);
    const [dialogLoading, setDialogLoading] = useState(true);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [removeSelectionModel, setRemoveSelectionModel] = useState([]);
    const [btnLoading, setBtnLoading] = useState(false);
    const [addSelectionModel, setAddSelectionModel] = useState([]);
    const [openWarningDialog, setOpenWarningDialog] = useState(false);
    const [updateDisplayOrderDialog, setUpdateDisplayOrderDialog] = useState(false);
    const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(null);    
    const [severity, setSeverity] = useState("error");
    const columns = [
        {
            field: "questionName",
            headerName: "Question Name",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "questionBody",
            headerName: "Question Body",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "answerRequired",
            headerName: "Answer Required",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "questionType",
            headerName: "Question Type",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "options",
            headerName: "Options",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
            valueGetter: (params) => params.row.options?.replaceAll('##SEP##', ','),
        },
           {
                    field: "displayOrder",
                    headerName: "Display Order",
                    flex: 1,
                    minWidth: 100,
                    headerClassName: "super-app-theme--header",
     }     
    ];
    useEffect(() => {
        //this useEffect will execute on 1st time on component render and when messageApiToken value chenged
        if (messageApiToken) {
            GetQuestionsBySurveyTemplateId();
        }
    }, [messageApiToken]);
    const GetQuestionsBySurveyTemplateId = () => {
        if (props.surveyTemplateId) {
            setIsLoading(true);
            InvokePostServiceCall(apiConfig.GET_QUESTIONS_BY_SURVEY_TEMPLATE, {
                SurveyTemplateId: props.surveyTemplateId,
            })
                .then((data) => {
                    let tempArr = [];
                    data.data.surveyQuestions.forEach((question, index) => {
                        tempArr.push({
                            ...question, id: index
                            ,indexId: index
                        })
                    })
                    setQuestionsData(tempArr);
                    setQuestionsDataCopy(tempArr);
                    setIsLoading(false);
                    console.log(data, "@@@@@@@@@@@");
                })
                .catch((error) => {
                    console.log("GET_QUESTIONS_BY_SURVEY_TEMPLATE", error);
                    setSnackbarMsg(error.message);
                    setSeverity("error");
                    setOpenSnackbar(true);
                    setIsLoading(false);
                });
        }
    }
    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };
    const handleClose = () => {
        setOpenWarningDialog(false);
    };
    const handleDialogClose = () => {
        setOpenAddDialog(false);
        setOpenWarningDialog(false);
    };

    const SaveQuestionsToSurveyTemplate = (actionType,dataList) => {
        setBtnLoading(true);
        let templateToTeamsArr = [];
        if (actionType === "Add") {
            dataList.forEach((item) => {
                let present = addSelectionModel.some((data) => data === item.id);
                if (present === true) {
                    templateToTeamsArr.push({
                        SurveyQuestionId: item.surveyQuestionId,
                        displayOrder: item.displayOrder,
                        surveyTemplateId: props.surveyTemplateId,
                    });
                }
            });
        }
        else if (actionType === "Delete") {
            questionsDataCopy.forEach((item) => {
                let present = removeSelectionModel.some((data) => data === item.id);
                if (present === true) {
                    templateToTeamsArr.push({
                        SurveyQuestionId: item.surveyQuestionId,
                        surveyTemplateId: props.surveyTemplateId,
                    });
                }
            });
            setRemoveSelectionModel([]);
        }
        else if (actionType === "Update") {
            let questionDetail = questionsData[selectedQuestionIndex];
                    templateToTeamsArr.push({
                        SurveyQuestionId: questionDetail.surveyQuestionId,
                        displayOrder: questionDetail.displayOrder,
                        surveyTemplateId: props.surveyTemplateId,
                    });
        }
        let requestBody = {
            actionType: actionType,
            surveyTemplateToQuestions: templateToTeamsArr,
        };
        InvokePostServiceCall(apiConfig.ADD_QUESTIONS_TO_SURVEY_TEMPLATE, requestBody)
            .then((data) => {
                console.log(data);
                GetQuestionsBySurveyTemplateId();
                handleDialogClose();
                setQuestionsDataCopy([])
                setRemoveSelectionModel([]);
                setBtnLoading(false);
                setSnackbarMsg(`Questions ${actionType === 'Delete' ? "Removed" : actionType === 'Update' ?"Updated": "Added"} Successfully`);
                setSeverity("success");
                setOpenSnackbar(true);
                setUpdateDisplayOrderDialog(false);
                setSelectedQuestionIndex(null);
            })
            .catch((error) => {
                console.log(error, "Error Message");
                setSnackbarMsg(error.message);
                setSeverity("error");
                setOpenSnackbar(true);
                setBtnLoading(false);
            });
    };


    const filterTable = (value) => {
        let usArr = [];
        let val = value?.toLowerCase();
        questionsDataCopy.forEach((item) => {

            if (
                item.questionName?.toLowerCase().includes(val) ||
                item.questionBody?.toLowerCase().includes(val) ||
                item.questionType?.toLowerCase().includes(val) ||
                item.options?.toLowerCase().includes(val)
            ) {
                usArr.push(item);
            }
        });
        setQuestionsData(usArr);
    }
    const getAllTeams = () => {
        setQuestionsForAssociation([]);
        setDialogLoading(true);
        InvokeGetServiceCall(apiConfig.GET_SURVEY_QUESTIONS)
            .then((data) => {
                const questionsArr = [];
                const tempArr = [];
                console.log("questionsDataCopy :: ", questionsDataCopy)
                data.data.surveyQuestions.forEach((item, index) => {
                    debugger
                    let present = questionsDataCopy.some((t) => item.surveyQuestionId === t.surveyQuestionId);
                    if (present === false) {
                        tempArr.push(item);
                    }
                });
                tempArr.forEach((item, index) => {
                    questionsArr.push({
                        ...item
                        , id: item.surveyQuestionId
                        , indexId: index
                    });
                });
                setQuestionsForAssociation(questionsArr);
                //Set Timeout added To fix No record found message issue
                setTimeout(() => {
                    setDialogLoading(false);
                }, 1);

            })
            .catch((error) => {
                console.log(error, "Error Message");
                setDialogLoading(false);
                setSnackbarMsg(error.message);
                setSeverity("error");
                setOpenSnackbar(true);
            });
    };

    return (
        <>
            {props.isAdd && !props.surveyTemplateId
                ? <>
                    <h5 style={{
                        color: "red",
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "center"
                    }} >Please add survey template first to associate questions.</h5>
                </> :


                <>
                    <SnackBar
                        openSnackbar={openSnackbar}
                        handleCloseSnackbar={handleCloseSnackbar}
                        severity={severity}
                        userMessage={snackbarMsg}
                    />
                    <div>
                        <Box sx={theme.customStyle.tableRow}>
                            <h2 style={{ marginLeft: 10 }}>Questions</h2>
                        </Box>
                    </div>
                    <div style={{ display: "flex", alignItems: "flex-start", paddingLeft: 10 }}>
                        <Box sx={theme.customStyle.tableRow}>
                            <div>
                                <Box sx={theme.customStyle.tableRow}>
                                    <IconButton
                                        disabled={removeSelectionModel.length > 0 ? true : false}
                                        sx={theme.customStyle.teamsAddIconBtn}
                                        onClick={() => {
                                            getAllTeams();
                                            setOpenAddDialog(true);
                                        }}
                                    >
                                        <AddIcon />
                                    </IconButton>
                                    <IconButton
                                        disabled={removeSelectionModel.length < 1 ? true : false}
                                        sx={{
                                            backgroundColor: "red",
                                            color: "white",
                                            "&:hover": { backgroundColor: "#db0000" },
                                        }}
                                        onClick={() => {
                                            setOpenWarningDialog(true);
                                        }}
                                    >
                                        <RemoveIcon />
                                    </IconButton>
                                </Box>
                            </div>
                            {openAddDialog && <AddQuestionsToSurveyTemplate
                                rows={questionsForAssociation}
                                loading={dialogLoading}
                                columns={columns}
                                open={openAddDialog}
                                handleClose={handleDialogClose}
                                SaveQuestionsToSurveyTemplate={SaveQuestionsToSurveyTemplate}
                                checkboxSelection={true}
                                selectionModel={addSelectionModel}
                                setSelectionModel={setAddSelectionModel}
                                dialogTitle={"Add Question"}
                                btnText={"Add"}
                                btnLoading={btnLoading}
                                isFromTemplate={true}
                            /> }
                            <Dialog
                                open={openWarningDialog}
                                onClose={handleClose}
                                fullWidth
                                maxWidth={"sm"}
                            >
                                <DialogContent>
                                    <h6>
                                        Are you sure you want to remove {removeSelectionModel.length} questions
                                        from survey template ?
                                    </h6>
                                </DialogContent>
                                <DialogActions>
                                    <LoadingButton
                                        loading={btnLoading}
                                        size="small"
                                        onClick={() => {
                                            SaveQuestionsToSurveyTemplate("Delete");
                                        }}
                                    >
                                        Remove
                                    </LoadingButton>
                                    <Button
                                        size="small"
                                        onClick={() => {
                                            setOpenWarningDialog(false);
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                <TextField
                                    sx={{ marginRight: "10px", marginBottom: "10px" }}
                                    size="small"
                                    type="search"
                                    variant="outlined"
                                    margin="normal"
                                    id="outlined-search"
                                    label=""
                                    placeholder="Teams Search"
                                    autoComplete="off"
                                    onChange={(e) => {
                                        if (e.target.value) {
                                            filterTable(e.target.value);
                                        } else {
                                            setQuestionsData(questionsDataCopy);
                                        }
                                    }}
                                    InputLabelProps={{
                                        shrink: false,
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Box>
                        </Box>
                    </div>

                    <Box
                        sx={theme.customStyle.tableHead}
                    >
                        <StyledDataGrid
                            loading={isLoading}
                            rows={questionsData}
                            columns={columns}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                            checkboxSelection
                            disableSelectionOnClick
                            components={{
                                NoRowsOverlay: CustomNoRowsOverlay,
                            }}
                            onSelectionModelChange={(data) => {
                                setRemoveSelectionModel(data);
                            }}
                            onRowDoubleClick={(e) => {
                                console.log("Updated modaql ::",e)
                                setUpdateDisplayOrderDialog(true);
                                setSelectedQuestionIndex(e.row.id)
                            }}
                        />
                    </Box>
                </>
            }

            <Dialog
                open={updateDisplayOrderDialog}
                onClose={()=>{
                    setUpdateDisplayOrderDialog(false);
                    setSelectedQuestionIndex(null);
                }}
                fullWidth
                maxWidth={"lg"}
            >
                <DialogContent>
                    <div style={{ marginLeft: 20, marginRight: 20 }}>
                        <h2>Update Question Display Order</h2>
                        <Box sx={theme.customStyle.textRow}>
                            <TextField
                                size="small"
                                margin="dense"
                                variant="standard"
                                value={questionsData[selectedQuestionIndex]?.questionName}
                                fullWidth
                                disabled
                                label="Question Name"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                id="UpdateQuestionName"
                                inputProps={{ //not show suggetions
                                    autoComplete: 'new-password',
                                    form: {
                                        autocomplete: 'off',
                                    },
                                }}
                            />
                        </Box>
                        <Box sx={theme.customStyle.textRow}>
                            <TextField
                                size="small"
                                margin="dense"
                                fullWidth
                                variant="standard"
                                disabled
                                value={questionsData[selectedQuestionIndex]?.questionBody}
                                label="Question Body"
                                id="updateQuestionBody"
                                inputProps={{ //not show suggetions

                                    autoComplete: 'new-password',
                                    form: {
                                        autocomplete: 'off',
                                    },
                                }}
                            />
                        </Box>
                        <Box sx={theme.customStyle.textRow}>
                            <TextField
                                size="small"
                                margin="dense"
                                variant="standard"
                                fullWidth
                                value={questionsData[selectedQuestionIndex]?.displayOrder}
                                label="Display Order"
                                id="updatedisplayOrder"
                                inputProps={{ //not show suggetions

                                    autoComplete: 'new-password',
                                    form: {
                                        autocomplete: 'off',
                                    },
                                }}
                                onChange={(e) => {
                                    let arr = questionsData;
                                    arr[selectedQuestionIndex].displayOrder = e.target.value;
                                    setQuestionsData([...arr]);
                                }}
                            />
                        </Box>
                        <LoadingButton
                            loading={props.updateBtnLoading}
                            variant="contained"
                            onClick={() => {
                                SaveQuestionsToSurveyTemplate("Update");
                            }}
                        >
                            Update
                        </LoadingButton>
                        <Button
                            sx={{ marginLeft: 2 }}
                            variant="contained"
                            onClick={() => {
                                setUpdateDisplayOrderDialog(false);
                                setSelectedQuestionIndex(null);
                            }}
                        >
                            Cancel
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>

        </>
    );
};

export default QuestionsToSurveyTemplate;