/***************
*11-11/2022 TextField:766=>disabled auto suggetion
***********/
import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  TextField,
  Button,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import { useLocation, useNavigate } from "react-router-dom";
import { InvokePostServiceCall } from "../../api/serviceUtil";
import apiConfig from "../../api/apiConfig";
import { AuthContext } from "../../AuthContext";
import moment from "moment";
import SnackBar from "../../components/SnackBar";

function AddUpdateCommunications() {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { messageApiToken } = useContext(AuthContext);
  const isAddCommunication = location.state
    ? location.state.isAddCommunication
    : true;
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [snackbarMsg, setSnackBarMsg] = useState("");
  const [communicationData, setCommunicationData] = useState({
    communicationListId: "00000000-0000-0000-0000-000000000000",
    communicationListName: "",
    communicationListCode: "",
    isActive: true,
  });
  const [modifiedBy, setModifiedBy] = useState({
    lastModifiedByName: "",
    lastModifiedDate: "",
  });
  const [createdBy, setCreatedBy] = useState({
    createdByName: "",
    createdDate: "",
  });

  useEffect(() => {
    if (!isAddCommunication) {
      setLoading(true);
      if (messageApiToken) {
        InvokePostServiceCall(apiConfig.GET_COMMUNICATION_LIST, {
          communicationListId:
            location.state.communicationDetails.communicationListId,
        })
          .then((data) => {
            let communicationDetails = data.data.communicationListDetails[0];
            setCommunicationData({
              communicationListId:
                communicationDetails.communicationList.communicationListId,
              communicationListName:
                communicationDetails.communicationList.communicationListName,
              communicationListCode:
                communicationDetails.communicationList.communicationListCode,
              isActive: communicationDetails.communicationList.isActive,
            });
            setModifiedBy({
              lastModifiedByName:
                communicationDetails.communicationList.lastModifiedByName,
              lastModifiedDate: moment(
                communicationDetails.communicationList.lastModifiedDate
              )
                .local()
                .format("LLL"),
            });
            setCreatedBy({
              createdByName:
                communicationDetails.communicationList.createdByName,
              createdDate: moment(
                communicationDetails.communicationList.createdDate
              )
                .local()
                .format("LLL"),
            });
            setLoading(false);
          })
          .catch((error) => {
            setSnackBarMsg(error.message);
            setSeverity("error");
            setOpenSnackbar(true);
            setLoading(false);
            console.log(error, "Error Message");
          });
      }
    }
  }, [messageApiToken]);

  const handleAddUpdate = () => {
    setLoadingBtn(true);
    let requestBody = {
      communicationList: {
        communicationListId: communicationData.communicationListId,
        communicationListName: communicationData.communicationListName,
        communicationListCode: communicationData.communicationListCode,
        isActive: communicationData.isActive,
      },
      actionType: isAddCommunication ? "Add" : "Update",
    };
    InvokePostServiceCall(apiConfig.SAVE_COMMUNICATION_LIST, requestBody)
      .then((data) => {
        setLoadingBtn(false);
        setSnackBarMsg(
          isAddCommunication
            ? "Communication Added Successfully"
            : "Communication Updated Successfully"
        );
        setSeverity("success");
        setOpenSnackbar(true);
      })
      .catch((error) => {
        setLoadingBtn(false);
        setSnackBarMsg(error.message);
        setSeverity("error");
        setOpenSnackbar(true);
        console.log(error, "Error Message");
      });
  };

  const handleChange = (event) => {
    setCommunicationData({
      ...communicationData,
      isActive: event.target.checked,
    });
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    if (severity === "success") {
      navigate("/communications", { replace: true });
    }
    setOpenSnackbar(false);
  };

  return (
    <>
      <SnackBar
        openSnackbar={openSnackbar}
        handleCloseSnackbar={handleCloseSnackbar}
        severity={severity}
        userMessage={snackbarMsg}
      />
      <div style={{ marginLeft: 20, marginRight: 20 }}>
        {loading && !isAddCommunication ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "15%",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <h2>
              {isAddCommunication
                ? "Add Communication"
                : "Update Communication"}
            </h2>
            <Box sx={theme.customStyle.textRow}>
              <TextField
                size="small"
                margin="dense"
                label="Communication Name"
                type="text"
                required
                fullWidth
                value={communicationData.communicationListName}
                id="updateCommunicationcommunicationListName"
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  maxLength: 255, autoComplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
                onChange={(e) => {
                  setCommunicationData({
                    ...communicationData,
                    communicationListName: e.target.value,
                  });
                }}
              />
            </Box>
            <Box sx={theme.customStyle.textRow}>
              <TextField
                size="small"
                margin="dense"
                label="Communication Code"
                type="text"
                fullWidth
                required
                value={communicationData.communicationListCode}
                variant="standard"
                id="updateCommunicationcommunicationListCode"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  maxLength: 255, autoComplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
                onChange={(e) => {
                  setCommunicationData({
                    ...communicationData,
                    communicationListCode: e.target.value,
                  });
                }}
              />
            </Box>
            <Box>
              <span>Is Active</span>
              <Checkbox
                checked={communicationData.isActive}
                onChange={handleChange}
              />
            </Box>
            {!isAddCommunication && (
              <>
                <Box sx={theme.customStyle.textRow}>
                  <TextField
                    size="small"
                    margin="dense"
                    disabled
                    value={`${createdBy.createdByName +
                      " (" +
                      createdBy.createdDate +
                      ")"
                      }`}
                    label="Created By"
                    type="text"
                    fullWidth
                    variant="standard"
                    id="updateCommunicationcreatedByName"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ //not show suggetions

                      autoComplete: 'new-password',
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                  />
                </Box>
                <Box sx={theme.customStyle.textRow}>
                  <TextField
                    size="small"
                    margin="dense"
                    disabled
                    value={`${modifiedBy.lastModifiedByName +
                      " (" +
                      modifiedBy.lastModifiedDate +
                      ")"
                      }`}
                    label="Modified By"
                    type="text"
                    fullWidth
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id="updateCommunicationlastModifiedByName"
                    inputProps={{ //not show suggetions

                      autoComplete: 'new-password',
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                  />
                </Box>
              </>
            )}
            <div style={{ marginTop: "20px", marginBottom: "20px" }}>
              <LoadingButton
                disabled={
                  communicationData.communicationListCode &&
                    communicationData.communicationListName
                    ? false
                    : true
                }
                loading={loadingBtn}
                variant="contained"
                onClick={() => {
                  handleAddUpdate();
                }}
              >
                {isAddCommunication ? "Add" : "Update"}
              </LoadingButton>
              <Button
                sx={{ marginLeft: 2 }}
                variant="contained"
                onClick={() => {
                  navigate("/communications");
                }}
              >
                Cancel
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default AddUpdateCommunications;
