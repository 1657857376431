/***************
*11-11/2022 TextField:766=>disabled auto suggetion
12-13-2022 => ONKAR => Texting-898 CP: Template PreviewShow all the templates with tokens populated 
12-15-2022 => ONKAR => Texting-898 CP: Template PreviewShow all the templates with tokens populated reopen issue fixed. 
12-22-2022 =>ONKAR => Filter templates in insert templates component from 1st char entered.
12-26-2022 =>ONKAR => Removed the api call for templates.
01-20-2022 =>RAJESH =>Fixed issue with department rendering.
07-13-2022 =>SUDAM => TEXTING-1412 - Ability to add Team Name in Tokens
07-18-2022 =>SUDAM => TEXTING-1436 - Team Level Attributes to use in tokens
09-01-2023 => SUDAM B CHAVAN =>TEXTING-1645 - Reporting - Track templates used
***********/
import React, { forwardRef, useImperativeHandle, useContext, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  CircularProgress,
  Typography,
  TextField,
  DialogTitle,
  Divider,
  ListItem,
} from "@mui/material";
import { AuthContext } from "../AuthContext";


import { useState } from "react";
import { getLinkedEntityNames } from "../extensions/crmWrapper";
import { GetTemplatesWithTokens } from "../extensions/TemplateConfigUtil";
import { dataService } from "../dataService";

export const TemplateSelector = forwardRef((props, ref) => {
  const {
    tokens,
    userInfo,
    templatesData,crmProvider , crmMode
  } = useContext(AuthContext);
  const [templatesList, setTemplatesList] = useState([]);
  const [templatesListCopy, setTemplatesListCopy] = useState([]);
  const [emptyMessage, setEmptyMessage] = useState("");


  useImperativeHandle(ref, () => ({
    templateSelectorClick,
  }));
  const templateSelectorClick = () => {
    props.childState({ open: !props.state.open });
    props.childState({ templatePreviewText: null });
  };

  async function getLinkedEntities(){
    var linkedEntitiesNames = [];
    let convAttributesObj = props.state.conversation.conversationAttributes?JSON.parse(props.state.conversation.conversationAttributes):"";
      if (props.state.conversation.linkedEntities!= undefined && props.state.conversation.linkedEntities != null && props.state.conversation.linkedEntities != "") {
      linkedEntitiesNames = props.state.conversation.linkedEntities;
      return linkedEntitiesNames
    }
      else if (crmMode == "true" && window != null && (!!window.Microsoft || !!window.sforce) && convAttributesObj.hasOwnProperty("ActivityId")) {
      // Dynamically get the linked entities names from CRM ny passing activityid from conversation attributes.
      linkedEntitiesNames = await getLinkedEntityNames(convAttributesObj["ActivityId"], crmProvider);
      linkedEntitiesNames.push("users");
      linkedEntitiesNames.push("userattributes");
      linkedEntitiesNames.push("teamattributes");
      var conv = Object.assign({}, props.state.conversation);
      conv.linkedEntities = linkedEntitiesNames;
      dataService.setData({ updateConversation: conv });
      return linkedEntitiesNames;
      }
      return null;
  }

  async function getTemplatesWithTokensReplaced(tempArr,tempData){
    let finalArr=[];
    tempArr.forEach(async (item) => {
      let templateBody = await GetTemplatesWithTokens(
        item,
        tempData,
        tokens,
        userInfo,
        props.state.conversation.teamId
      );
      finalArr.push({
          category: item.category,
          relatedObjects: item.relatedObjects,
          teamId: item.teamId,
          templateBody: templateBody,
          templateContext: item.templateContext,
          templateId:item.templateId ,
          templateName: item.templateName,
        });
    });
    return finalArr;
  }

  useEffect(() => {
    if (props.conversation?.isReadOnly !== true && props.state.open) {
      setTemplatesList([]);
      setTemplatesListCopy([]);
      setEmptyMessage("");
      props.childState({ showLoader: true });
      getLinkedEntities()
        .then((linkedEntities) => {
          let tempArr = [];
          let filteredTemplatesListByTeam = templatesData.filter(
            (templateItem) =>
              templateItem.teamId.toLowerCase() ===
              props.state.conversation.teamId.toLowerCase()
          );
          if (linkedEntities) {
            let tempData = JSON.parse(
              props.state.conversation.conversationAttributes
            );
              filteredTemplatesListByTeam.forEach((item) => {
                  var includeTemplate = false;
                  if (item.relatedObjects != undefined && item.relatedObjects) {                    
                    let relatedObjectsArr = item.relatedObjects.split(",");
                    if (relatedObjectsArr.every((r) => linkedEntities?.includes(r)) || (item.relatedObjects == null || item.relatedObjects == "")) {
                        if ((userInfo.department == "" || (item.department == undefined || item.department == "" || userInfo.department == item.department))) {
                            includeTemplate = true; 
                        }
                    }
                }
                  else if ((item.relatedObjects == null || item.relatedObjects == "" ) && (userInfo.department == "" || (item.department == undefined || item.department == "" || userInfo.department == item.department))) {
                      includeTemplate = true;
                  }
                  if (includeTemplate == true) {
                      tempArr.push(item);
                  }

            });
            if (tempArr.length === 0) {
              setEmptyMessage("No Data Found");
            } else {
              setEmptyMessage("");
              getTemplatesWithTokensReplaced(tempArr, tempData)
                .then((finalArr) => {
                  setTemplatesList(finalArr);
                  setTemplatesListCopy(finalArr);
                  if (finalArr.length === 0) {
                    setEmptyMessage("No Data Found");
                  } else {
                    setEmptyMessage("");
                  }
                })
                .catch((error) => {
                  console.log(error, "error message");
                });
            }
          } else {
            setTemplatesList(filteredTemplatesListByTeam);
            setTemplatesListCopy(filteredTemplatesListByTeam);
            if (filteredTemplatesListByTeam.length === 0) {
              setEmptyMessage("No Data Found");
            } else {
              setEmptyMessage("");
            }
          }
          props.childState({ showLoader: false });
        })
        .catch((error) => {
          console.log(error, "Error Message");
        });
    }
  }, [props.state.open]);

  function filterTemplates(value){
    let filteredData= templatesListCopy.filter((item)=>item.templateName
    .toLowerCase().includes(value))
    setTemplatesList(filteredData);
    if (filteredData.length === 0) {
      setEmptyMessage("No Data Found");
    } else {
      setEmptyMessage("");
    }
  }

  return (
    <Dialog
      open={props.state.open}
      onClose={props.handleModalClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <>
        <DialogContent>
          <DialogTitle></DialogTitle>

          <TextField
                      variant="standard"
                      label="Search Templates"
                      placeholder="Template Name"
                      autoComplete="off"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => {
                        if(e.target.value){
                          filterTemplates(e.target.value.toLowerCase())
                        }else{
                        setTemplatesList(templatesListCopy)
                        setEmptyMessage("");
                        }
                       
                      }}
                    />
          <Box sx={{ marginTop: "10px" }}>
            <Box
              sx={{ minHeight:"250px",maxHeight: "250px", marginTop: "10px", overflowY: "auto" }}
            >
              {emptyMessage !== "" && (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <span>No Records Found</span>
                </Box>
              )}
              {props.state.showLoader && (
                <Box
                  sx={{ display: "flex", marginLeft: "44%", marginTop: "2%" }}
                >
                  <CircularProgress />
                </Box>
              )}
              {templatesList?.map((item, index) => {
                return (
                  <>
      
                    {index > 0 && <Divider />}
                    <ListItem
                      button
                      sx={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "center"
                      }}
                      key={index}
                      onClick={() => {
                        props.handleTemplateSelection(item);
                      }}
                    >
                      <span style={{ fontSize: "13px" }}><strong>{item.templateName}</strong></span>
                      <Typography style={{ fontSize: "12px" }}>{item.templateBody}</Typography>
                    </ListItem>
                  
                  </>
                );
              })}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleModalClose} autoFocus>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <span>
                <strong> Cancel</strong>
              </span>
            </div>
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
});
