/********************************************************************************************************************************************
Name: Header
=============================================================================================================================================
Purpose:
Header component to display side bar/drawer components and user profile menu. Key funtionality Includes:
1. Establish connection with pubsub and listens to pubsub events
2. Establish connection with CRM Dyanamics and listen to CRM clicktoact evnts
3. Load menu / drawer component
History:
VERSION     AUTHOR              DATE            DETAILS
1.0         SRINATH             10/01/2021      Initial creation of header component
1.1         SUDAM               10/21/2022      TEXTING-713 Added condition to handle multi team modal
1.2         ONKAR               11/01/2022      updateConversationList method added to sort active conversations by latest time
1.3         ONKAR               11/02/2022      removed updateConversationList method from handleCreateConversation function.
1.4         RSATTI              11/03/2022      using the startConversation - same method for both Opportunity Context and Member Context
1.5         ONKAR               11/09/2022      Texting-750- addred setOpenConversationDrawer before api call response
1.6         SRINATH             11/13/2022      Do not establish pubsub connection for crmEmbededView and fix blank screen issue on new conversation
1.7         ONKAR               11/14/2022      Texting-750 New conversation page was not closing after auto rejecting the conversation request -fixed
1.8         YOGESH              11/16/2022      Texting-815 added setConvLoading in props
1.9         ONKAR               11/17/2022      Texting-808 after reloading frame Conversation is already in Active" Alert is showing when we initiate outbound from Member-fixed. 
1.9         ONKAR               11/18/2022      Texting-828 Active conversation page is not opening automatically when conversation is assigned between agents fixed. 
1.9         ONKAR               11/19/2022      Assigning the active conversation from omni channel is not showing in side panel until refresh - fixed.
1.10        ONKAR               11/21/2022      Texting-844 Auto reject timing update to 120 Secs
1.11        ONKAR               11/28/2022      Texting-847 Ability to take over existing conversation assigned to someone else by agent.
1.11        ONKAR               11/28/2022      Texting-865 Empty new conversation page is displaying when click on hamburger icon after message request was rejected or auto rejected fixed.
1.12        ONKAR               12/01/2022      Texting-874 Assign to me option is showing for missed conversations, nonwhite list alert, Consent unavailable alert fixed.
1.12        ONKAR               12/09/2022      Texting-913 Prompt the Templates to the User before start the conversation
1.13        MOHIT               12/13/2022      Texting-900 Auto Routing V2 : Removed handleAcceptReject method (auto reject api call), change auto reject timer on the basis of preassindExpirationDate Date, RemoveConvTimer update on the basis of preassindExpirationDate
1.14        ONKAR               12/14/2022      Texting-925 Active conversation is not starting from M360 form fixed.
1.15        ONKAR               12/16/2022      added condition to check introduction in template category .
1.16        RAJESH              12-16-2022      Added related entities logic for Stat Conv
1.17        Mohit               12-21-2022      Added getAccessToken method to call GetOrValidateAccessToken Api
1.18        ONKAR               12-28-2022      Removed the api call for templates in start conversation with templates popup.
1.19        RAJESH              01-20-2022      Fixed the issue with plain text remplates not laoding for the indroction scenario.
1.20        SUDAM B CHAVAN      04-27-2023      Texting - 1199 - Alert is showing but click on cancel button on alert is still showing grey texting screen.
1.21        ONKAR               05/02/2023      Texting-1230 Red DOT treatment: Remove red dot for conversations if messages are read.
1.22        ONKAR               05/04/2023      Texting-1243 UI for Message Read Status for Agent and Member.
1.23        ONKAR               05/10/2023      Texting-1252 If conversation page is in open state, then new inbound messages for agent are automatically showing as read.
1.23        ONKAR               05/10/2023      Texting-1255 Message Read status observations.
1.23        ONKAR               05/10/2023      Texting-1243 UI for Message Read Status for Agent and Member.
1.24        ONKAR               05/12/2023      Texting-1255 For Member, only single tick is showing for messages after assigning the missed conversation.
1.25        ONKAR               05/19/2023      Texting-1190 Missed icon in texting left side panel is not automatically showing when missed conversation is created on auto assign round robin.
1.25        ONKAR               06/07/2023      Texting-1314 Icons in left panel is not highlighting automatically, after reject/auto reject the new message request.
1.26        SUDAM B CHAVAN      06/07/2023      TEXTING-1083 - webChat - Agent/Member typing preview
1.27        ONKAR               06/23/2023      Texting-1364 Team is changing to users default team when member assigns unassigned conversation to himself.
1.28        SUDAM B CHAVAN      07/13/2023      TEXTING-1412 - Ability to add Team Name in Tokens
1.29        SUDAM B CHAVAN      07/18/2023      TEXTING-1436 - Team Level Attributes to use in tokens
1.26        RAJESH              07/21/2023      Implemented the default selection of team from the CRM request
1.27        RAJESH              07/22/2023      Implemented error message when the teamid passed is not part of the texting team from crm
1.28        SUDAM B CHAVAN      09/04/2023      TEXTING-1650 - Web Chat: Auto routing observation.
1.29        ONKAR               09/15/2023      TEXTING-1697 - Client Req: Ability to view the chat history of the member without initiating the conversation.
1.29        ONKAR               09/18/2023      TEXTING-1720 - View history on start conversation UI observations.
1.30        ONKAR               09/20/2023      TEXTING-1722 - 0: Conversation read api calls are made twice.
1.30        ONKAR               09/21/2023      TEXTING-1720 - View history on start conversation UI observations.
1.31        ONKAR               09/22/2023      TEXTING-1745 - Conversation Reassignment observations.
1.32        SUDAM B CHAVAN      09/28/2023      TEXTING-1749 - Schedule outbound messages during off hours observations.
1.33        ONKAR               10/03/2023      TEXTING-1762 - Priority 1: When conversation is assigned to agent from Omni channel, Search conversations then conversation page is not opening automatically.
                                                Texting-1767-Priority 1: On start conversation with Template, home page is showing with out loader before active conversation page is opened.,Texting-1768-Priority 1: Start conversation with template when user is part of single team is not working.
1.34        SUDAM B CHAVAN      10/03/2023      TEXTING-1733 - Priority 1: If SMS conversations Auto close on server side, Agent UI is not showing system message and input message box is not removing.
1.35        SUDAM B CHAVAN      10/04/2023      TEXTING-1753 - On start conversation from CRM, Select team page is showing Next button for Start with Templates disabled Team. - Not consistent.
1.36        SUDAM B CHAVAN      10/05/2023      TEXTING-1785 - Select team popup is showing first team in the dropdown instead of Users preferred team when starting the conversation from CRM.
1.36        ONKAR               10/06/2023      TEXTING-1784 -  User Status is not updating to busy when conversation request timer is struck and conversation is removed from user.
1.37        ONKAR               10/12/2023      TEXTING-1796 - Sentiment Re-Calculate button to calculate sentiment with latest messages.
1.38        ONKAR               10/25/2023      TEXTING-1799 - Click on pub sub reconnect button is not refreshing the conversations count on Texting UI.
1.39        ONKAR               11/02/2023      TEXTING-1831 - Admin UI - New conversation panel is moving to active automatically when click on update button.
1.40        ONKAR               11/08/2023      TEXTING-1839 - Adding the conversation I'd in URL should open the conversation page.
1.41        ONKAR               11/09/2023      TEXTING-1763 - Existing Prod Issue: On refresh New conversation request panel is going away.
1.41        ONKAR               11/10/2023      TEXTING-1763 - Existing Prod Issue: On refresh New conversation request panel is going away.
1.42        ONKAR               11/10/2023      Texting-1839 Adding the conversation I'd in URL should open the conversation page.
1.42        ONKAR               11/17/2023      Texting-1839 Adding the conversation I'd in URL should open the conversation page.
1.43        ONKAR               11/18/2023      Texting-1862 Reloading the Agent UI is again showing ended conversation request.
1.44        ONKAR               11/29/2023      Texting-1871 Confirmation popup is still coming when trying to refresh the Texting and Admin UI pages.
1.45        ONKAR               12/05/2023      Texting-1860 Only conversation ended info message is showing on agent UI when webchat conversation is ended.
1.46        ONKAR               12/06/2023      Texting-1855 Editing the contact details is not updating the conversation name and CRM button until page refresh.
1.47        ONKAR               12/08/2023      Texting-1883 Click on reconnect is auto highlighting the active icon in left panel.
1.48        SUDAM B CHAVAN      12/11/2023      Texting-1906 0:Hardcoded TCPA message is showing instead of Team TCPA message.
1.49        ONKAR               12/12/2023      Texting-1905 0: Starting the conversation from CRM with single team is showing 'conversation already active' popup.
********************************************************************************************************************************************/

import React, { useState, useEffect, useContext } from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import { useLocation, useNavigate } from "react-router-dom";
import { dataService } from "../dataService";
import { useMsal } from "@azure/msal-react";
import { SignIn } from "../azure/SignIn";
import { AuthContext } from "../AuthContext";
import UserProfile from "../components/UserProfile";
import { startConversation, createOrSearchTaskinCRM, getConvAttributes, getCustomConvAttributes, startCustomConversation} from "../extensions/crmWrapper";
import { checkStartConversationEligibility, getUnassignedConversationAPI, updateSentimentInStore } from "../api/messageServices";
import chatStore from "../storage/localStore"
import { clearInterval, setInterval } from 'worker-timers';
import {
    IconButton,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    CircularProgress,
    DialogTitle,
    Divider,
    Badge,
    Backdrop,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    Stack,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
    AddMessageToConv,
    addConversationToList,
    conversationSelected,
    updateMessageToConv,
    removeNewConvTimer,
    addNewConversationToList,
    removeNewConversation,
    conversationsSelector,
    removeUnassignedConversation,
    addUnassignedConversationToList, removeConversation,
    updateConversationList,
    updateConversationRead,
    updateConversationAsReadApiCall,
    updateCloseWebChatConversation,
    updateMessageRead,
    updateIsTabActiveVal,
    toggleTypingPreview,
    resetConversationList
} from "../storage/slices/messageSlice";
import {
    loadAssignmentNotifications,
    addNotificationToList,
    notificationSelector,
    removeNotificationfromList,
    resetUnAssignedNotifList,
} from "../storage/slices/notificationSlice";
import { getMessagesForConversationId } from "../api/messageServices";
import { useDispatch, useSelector } from "react-redux";
import HeaderNotifications from "./HeaderNotifications";
import { openCifWindow } from "../extensions/cifUtil";
import { mapConversation } from "../extensions/Util";
import { InvokePostServiceCall } from "../api/serviceUtil";
import apiConfig from "../api/apiConfig";
import { SendToAllUserConnections } from "../azure/pubSub";
import DrawerListCmpNew from "./DrawerListCmpNew";
import { GetTemplatesWithTokens } from "../extensions/TemplateConfigUtil";
import StartConvWithTemplatesDialog from "./StartConvWithTemplatesDialog";
import SnackBar from "../components/SnackBar";
import { ConfirmationComponent } from "./ConfirmationComponent";
import moment from "moment";
export default function Header(props) {
    const notify = require("../assets/notify.mp3");
    const NewConversation = require("../assets/NewConversation.wav");
    const NewMessage = require("../assets/NewMessage.wav");
    const { unAssignedNotifList } = useSelector(notificationSelector);
    const { newConversations } = useSelector(conversationsSelector);
    const theme = useTheme();
    const { accounts } = useMsal();
    const location = useLocation();
    const navigate = useNavigate();
    const [stateContact, setStateContact] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [showDialogtoReconnect, setShowDialogtoReconnect] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertText, setAlertText] = useState(false);
    const [conversationId, setConversationId] = useState(false);
    const [convTeamId, setConvTeamId] = useState("");
    const [showSelfAssignButton, setShowSelfAssignButton] = useState(false);
    const [showBackdrop, setShowbackdrop] = useState(false)
    const [paramStrteams, setparamStrteams] = useState("");
    const availableColor = "#44b700";
    const busyColor = "red";
    const offlineColor = "#b8bfb3";
    const availableStatus = "Available";
    const busyStatus = "Busy";
    const [userStatusBgColor, setUserStatusBgColor] = useState(offlineColor);
    const [showprofile, setShowprofile] = useState(false);
    const [hasTeams, sethasTeams] = useState(false);
    const [teamList, setteamList] = useState([]);
    const [templatesList, setTemplatesList] = useState([]);
    const [showTemplatesDialog, setShowTemplatesDialog] = useState(false);
    const [templateSearchVal, setTemplateSearchVal] = useState("");
    const [startConvReqObjData, setStartConvReqObjData] = useState("");
    const [selectedTemplateBody, setSelectedTemplateBody] = useState("")
    const [selectedTemplateName, setSelectedTemplateName] = useState("")
    const [showTeamTemplateDialog, setShowTeamTemplateDialog] = useState(false)
    const [templateLoading, setTemplateLoading] = useState(false);
    const [templateMsg, setTemplateMsg] = useState(false);
    const [severity, setSeverity] = useState("success");
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [warningMsg, setWarningMsg] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [closeSnackbarManually, setCloseSnackbarManually] = useState(false);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [confirmationText, setConfirmationText] = useState("");
    const [startConvConfirmationObj, setStartConvConfirmationObj] = useState(false);
    const appContext = useContext(AuthContext);

    const {
        userInfo,
        updateappContext,
        setSelectedConv,
        crmMode, setCrmMode,
        crmEmbededView, setCrmEmbededView,
        unAssignedConvData,
        matches,
        showNotificationIndicator,
        setMsgLoadingState,
        statusChanged,
        setStatusChanged,
        setDecision,
        setInactiveConvCount,
        openDrawer, setOpenDrawer,
        setAssignedConvId,
        openActive, setOpenActive,
        activityDueDataHours, showDrawer, setShowDrawer,
        openConversationDrawer, setOpenConversationDrawer,
        setUserInfo,
        setupCollapse,
        setSetupCollapse,
        showDocuSignMessage,
        selectedMenu, setSelectedMenu,
        setPrevSelectedmenu,
        docuSignCode, setDocuSignCode,
        convLoading, setConvLoading,
        setHideNewConvMenu,
        tokens,
        crmProvider, setCrmProvider,
        inactiveConvCountByTeams, setInactiveConvCountByTeams,
        inactiveConvCount, setInactiveConversations,
        setCollapseInactive,
        setHeaderNotification,
        notiFicationFilter,
        setNotificationFilter,
        templatesData,
        enableSentimentCalculation,
        sentimentCalculateEveryXMessages
    } = useContext(AuthContext);
    const [startConvWithTemplate, setStartConvWithTemplate] = useState(false);
    const [showStartConvModal, setShowStartConvModal] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState(userInfo.teamId);
    const drawerWidthNew = "49px";
    const dispatch = useDispatch();
    const UnAssignedAdd = "UnAssignedAdd";
    const UnAssignedRemove = "UnAssignedRemove";
    const MessageDeliveryStatus = "MessageDeliveryStatus";
    const ConversationEndedByMember = "ConversationEndedByMember";
    const ConversationEndedBySystem = "ConversationEndedBySystem";


    //check tab is active or not in browser.
    window.onblur = function () {
        dispatch(updateIsTabActiveVal(false));
        console.log("browser tab is changed </br>")
    };
    // If users come back to the current tab again, the below function will invoke
    window.onfocus = function () {
        dispatch(updateIsTabActiveVal(true))
        console.log("Browser tab is again active! </br>")
    };
    var wSocket = {};

    useEffect(() => {
        //debugger;

        // setUserId(accounts[0].localAccountId);
        //setCrmEmbededView(window.self != window.top);
        if (!crmEmbededView) {      //Do not listen to data service events when application loaded in CRM embeded view
            dataService.getData().subscribe((message) => {
                console.log(message.value);
                if (message.value != null) {
                    if (message.value.headerDrawer != null) {
                        //Drawer close  from conversation component
                        setOpenDrawer(message.value.headerDrawer);
                    }
                    if (message.value.showAlertMessage != null) {
                        //Drawer close  from conversation component
                        //Loading symbold will be removed after receving event from server on conversation creation/updation
                        if (message.value.showAlertMessage !== "SUCCESS" && message.value.showAlertMessage != "") {
                            setConvLoading(false);      //Hide loading when call failed                          
                            setShowAlert(true);
                            setShowTeamTemplateDialog(false)
                            setAlertText(message.value.showAlertMessage);
                        }
                    }
                    else if (message.value.showSelfAssignAlert != null) {
                        setConvLoading(false);
                        if (message.value.showSelfAssignAlert != "") {
                            setShowAlert(true);
                            setShowSelfAssignButton(true);
                            setAlertText(message.value.showSelfAssignAlert.alertText);
                            setConversationId(message.value.showSelfAssignAlert.conversationId);
                            setConvTeamId(message.value.showSelfAssignAlert.teamId)
                        }
                    }
                    //it will be involk from check start conv eligibility call
                    else if (message.value.showConfirmationOnStartConv) {
                        //handle show confirmation for scheduled messages in non tcpa hrs..
                        setConvLoading(false);
                        let localFormatedDate = moment(message.value.showConfirmationOnStartConv.messageScheduleDate).local().format("LLL")
                        let showMessage = message.value.showConfirmationOnStartConv.confrmationText
                            ? `${message.value.showConfirmationOnStartConv.confrmationText}, would you like to schedule message on {0}?`
                            : "This is Non-TCPA hours, would you like to schedule message on {0}?";
                        let ConfirmationMsg = showMessage.replace('{0}', localFormatedDate);
                        setShowConfirmationDialog(true)
                        setConfirmationText(ConfirmationMsg);
                        setStartConvConfirmationObj(message.value.showConfirmationOnStartConv);
                    }else if(message.value.updateContextConv){ // Check if the 'updateContextConv' property exists in the 'message.value' object
                         // If 'updateContextConv' is present, update the state 'setSelectedConv' with its value
                         // Using the spread operator ({...}) to create a new object and avoid mutating the state directly
                        setSelectedConv({...message.value.updateContextConv}) //Fix for Texting-1855 Editing the contact details is not updating the conversation name and CRM button until page refresh.
                    }
                }
            });
        }


    }, []);

    useEffect(() => {
        if (!matches) {
            props.handleBodyContainerMargin("49px", "49px");
        } else {
            props.handleBodyContainerMargin("49px", "329px");
        }
    }, [matches]);
    useEffect(() => {
        let activeMenu=selectedMenu
        if (location.pathname.lastIndexOf("conversations/") != -1) {
            var path = location.pathname;
            var subPath = path.substring(path.lastIndexOf("conversations/") + 14);
            if (!stateContact) setStateContact(true);
            if (subPath.lastIndexOf("crm") != -1) {
            }
        }
        if (!location.pathname?.includes("/conversations")) {
            setSelectedMenu(location.pathname);
            activeMenu=location.pathname;
            ///if selected menu not equals to active new missed then close the coversation drawer.
            if(activeMenu!=="active"||activeMenu!=="new"|| activeMenu!=="unassigned"||activeMenu!=="inactive"){
                setOpenConversationDrawer(false)
            }
        }
    }, [location]);


    //Use window location as search params is null with memory router implementation
    useEffect(() => {
        console.log(window.location.search);
        var qStr = window.location.search;
        if (qStr != null && qStr.lastIndexOf('crmEmbededView=') > -1) {
            setCrmEmbededView(qStr.substring(qStr.lastIndexOf('crmEmbededView=') + 15));
        }
    }, [window.location.search]);

    useEffect(() => {
        //debugger;

        if (userInfo) {
            console.log("In Listener- Use Effect");
            let statusColor = offlineColor;
            if (userInfo.status === availableStatus) {
                statusColor = availableColor;
            } else if (userInfo.status === busyStatus) {
                statusColor = busyColor;
            } else {
                statusColor = offlineColor;
            }
            setUserStatusBgColor(statusColor);
            if (userInfo.teams && userInfo.teams.length > 0) {
                setteamList(userInfo.teams);
                setStartConvWithTemplate(userInfo.teams[0].isStartConvWithTemplate)
            }
        }

        //on refresh if any one of menu selected from setup then open setup menu.
        let setupMenuList=["users","addUpdateUser","teams","addUpdateTeam","templates","addUpdateTemplate","tokens",'addUpdateToken',"phoneNumberOptOut","businessHoursConfig","addUpdateBusinessHoursConfig","holidays","addUpdateHolidays","phoneNumbers","addUpdatePhoneNumber","tcpa","applicationSettings","addUpdateSettings","chatConfigs","addUpdatechatConfig"]

        setupMenuList.forEach((item)=>{
            if(location.pathname?.includes(item)){// check if url contains any menu from setup menus
                setSetupCollapse(true)
            }
        })

        //if (userInfo.connectionId != null) {
        //    setIsConnected(true);
        //}
        // Create DocuSign URL

    }, [userInfo]);

    useEffect(() => {
        if (userInfo.userId != null) {

            window.addEventListener("CIFInitDone", cifInit);
        }

    }, [userInfo]);


    useEffect(() => {
        if (window.sforce != null) {
            setCrmMode("true");
            setCrmProvider("S");
            console.log("Salesforce CTI Initialized");
            window.sforce.opencti.enableClickToDial({ callback: enableClickToDial });
            window.sforce.opencti.onClickToDial({ listener: onClicktoDail });
        }
    }, [window.sforce]);






    var enableClickToDial = function (payload) {

        console.log("Salesforce CTI Initialized-test");
        //console.log('Clicked phone number: ' + payload.number);
    };


    var onClicktoDail = function (payload) {
        console.log("Salesforce CTI Initialized-test");
        if (userInfo != null && userInfo.crmMemberFields != null) {
            window.sforce.opencti.isSoftphonePanelVisible({
                visible: true, callback: function (res) {
                    if (res.success == true && res.returnValue.visible == false) {
                        window.sforce.opencti.setSoftphonePanelVisibility({ visible: true, callback: function (res) { } });

                    }
                }
            });
            payload.entityLogicalName = 'contact';
            var payloadstr = JSON.stringify(payload);
            handleStartConversation(payloadstr);
            // startConversation(paramStr, accounts[0].localAccountId, userInfo.crmMemberFields, userInfo.teams[0].teamId, crmProvider);
        }
        //console.log('Clicked phone number: ' + payload.number);
    };



    const useUnload = (fn) => {
        const cb = React.useRef(fn);

        useEffect(() => {
            const onUnload = cb.current;
            window.addEventListener("beforeunload", onUnload);
            return () => {
                window.removeEventListener("beforeunload", onUnload);
            };
        }, [cb]);
    };
    useUnload((e) => {
        e.preventDefault();
        if (window.Microsoft) {
            window.Microsoft.CIFramework.setClickToAct(false);
            window.Microsoft.CIFramework.removeHandler(
                "onclicktoact",
                handleStartConversation
            );
            window.Microsoft.CIFramework.removeHandler(
                "onclicktoactcustom",
                handleStartConversation
            );
        }
    });

    useEffect(() => {
        //Do not establish connection with pubsub when application loaded in CRM embeded view
        if (userInfo.wsURL != null && crmEmbededView !== "true") {
            connectToWebSocket(userInfo.userId);
            setShowLoader(false);
            setShowDialogtoReconnect(false);
        }
        else if (crmEmbededView === "true") {
            setShowDrawer(true);
        }
    }, [userInfo?.wsURL]);

    useEffect(() => {
        dispatch(loadAssignmentNotifications(unAssignedConvData));
    }, [unAssignedConvData]);
    const cifInit = function (e) {
        console.log("CIF Initialized");
        window.Microsoft.CIFramework.getEnvironment().then((result) => {
            var data = JSON.parse(result);
            if (data != null && data.customParams == 'CRM') {
                setCrmMode("true");
            }
        }, (error) => { });

        window.Microsoft.CIFramework.setClickToAct(true);
        window.Microsoft.CIFramework.addHandler(
            "onclicktoact",
            handleStartConversation
        );
        window.Microsoft.CIFramework.addHandler(
            "onclicktoactcustom",
            handleStartConversation
        );
    };


    const handleCloseTeams = () => {
        setShowStartConvModal(false)
        sethasTeams(false);
        setShowTemplatesDialog(false);
        setShowTeamTemplateDialog(false);
        setSelectedTemplateBody("")
        setSelectedTemplateName("")
        setTemplateSearchVal("")
        setTemplateMsg(false)
        setTemplateLoading(false);
    }

    const handleTeamChange = (event) => {
        setSelectedTeam(event.target.value);
        let seletecedteam = teamList.filter(item => item.teamId === event.target.value)
        setStartConvWithTemplate(seletecedteam[0].isStartConvWithTemplate)
        //setDefaultTeamId(event.target.value);
    };
    const handleCustomStartConversation = async function (paramStr) {
        if (userInfo.teams.length == 1) {
            openCifWindow();
            setConvLoading(true);
            if (userInfo != null && userInfo.crmMemberFields != null) {
                startCustomConversation(paramStr, accounts[0].localAccountId, userInfo.crmMemberFields, crmProvider);
            }
        }
        else {
            openCifWindow();
            setparamStrteams(paramStr);
            sethasTeams(true)
        }
    };
  
    const handleStartConversation = async function (paramStr) {
        console.log("Custom Event Received" + paramStr);
        let teamId = ""
        // Check if the user has teams and if the first team has the property "isStartConvWithTemplate", otherwise default to false
        let isStartConvWithTemplate = userInfo.teams.length>0?userInfo.teams[0].isStartConvWithTemplate:false;
        var obj = JSON.parse(paramStr);
        debugger;
        // Check if the "teamId" property exists in the  teams of the users
        if (!!obj.teamId && !!userInfo.teams && userInfo.teams.length > 0) {
            let selectedTeam = userInfo.teams.filter(item => !!item.teamId && (item.teamId === obj.teamId))
            debugger;
            // Assign the "teamId" and "isStartConvWithTemplate" properties from the found team from CRM
            if (!!selectedTeam && selectedTeam.length > 0) {
                teamId = selectedTeam[0].teamId;
                isStartConvWithTemplate = selectedTeam[0].isStartConvWithTemplate;
            }
            else {
               // setWarningMsg("We could not find your team. Please select your team to start conversation");
               setSeverity("warning");
               setSnackbarMsg("Can't start a conversation as you are not part of the specific team!");
               setOpenSnackbar(true);
               return;
            }
        }
        setTemplateLoading(false);
        setShowTemplatesDialog(false);
        setTemplatesList([]);
        setSelectedTemplateBody("");
        setSelectedTemplateName("");
        debugger;
        if (userInfo.teams.length == 1 || (!!teamId)) {
            if(!teamId){
                teamId = userInfo.teams[0].teamId;
            }
            setSelectedTeam(teamId);
            setStartConvWithTemplate(isStartConvWithTemplate);
            openCifWindow();
            setparamStrteams(paramStr);
            if (userInfo != null && userInfo.crmMemberFields != null) {
                if (isStartConvWithTemplate) {
                    setConvLoading(true);
                    sethasTeams(false);
                    setTemplateLoading(true);
                    debugger
                    let convObjwctData = await checkStartConvEligibility(teamId,paramStr);
                    if (!convObjwctData || convObjwctData?.isNonTcpa) {
                        /**this block will execute in following scenarios
                         * 1 not eligible to start conv
                         * 2 failed to load conv attribute
                         * 3 start conv initiated in not tcpa hrs
                        */
                        //if don't have convObjwctData details closing the start conv 
                        if (!convObjwctData?.isNonTcpa) {
                            /**this block will execute when the user is not eligible to start conv 
                            *but not when the conv start in non tcpa hrs 
                            *as we need some react variables state to proccess the schedule message on user confirmation*/
                            setConvLoading(false);
                            setTemplateLoading(false);
                        }
                        return;
                    }
                    setConvLoading(false);
                    if (convObjwctData) {
                       //open template dailog box
                        openTemplatesDailog(convObjwctData);
                    }
                } else {
                    setConvLoading(true);
                    startConversation(paramStr, accounts[0].localAccountId, userInfo.crmMemberFields, teamId, crmProvider);
                }
            }
        } else if (userInfo.teams.length > 1) {
            if (userInfo.teamId) {//when user has preferred team then assigning that team as selected team.
                setSelectedTeam(userInfo.teamId);
                let seletecedteam = userInfo.teams.filter(item => item.teamId === userInfo.teamId);
                setStartConvWithTemplate(seletecedteam[0].isStartConvWithTemplate);
            }
            else {//when user does not have preferred team then assigning 1st team from list as selected team.
                setSelectedTeam(userInfo.teams[0].teamId);//setting 1st team as selected team at top we are getting isStartConvWithTemplate of 1st team
                setStartConvWithTemplate(isStartConvWithTemplate);//at the top getting 1st team isStartConvWithTemplate that setting to state to get updated value
            }
            setShowStartConvModal(true);
            setOpenConversationDrawer(false)
            openCifWindow();
            setparamStrteams(paramStr);
            sethasTeams(true)
            setShowTeamTemplateDialog(true)
        }
        else {
            setSeverity("warning");
            setSnackbarMsg("Can't start a conversation as you are not part of any team!");
            setOpenSnackbar(true);
        }
    };

    const openTemplatesDailog = (convObjwctData) =>{
         //open template dailog box
        setShowStartConvModal(true);
        setOpenConversationDrawer(false)
        setShowTeamTemplateDialog(true);
        setStartConvReqObjData(convObjwctData);
        setShowTemplatesDialog(true);
    }
    const checkStartConvEligibility = async (teamId,paramStr="") =>{
        let ObjData = JSON.parse(paramStr!==""?paramStr:paramStrteams);
        let convObjwctData;
        if (ObjData.entityLogicalName == 'contact') {
            convObjwctData = await getCustomConvAttributes(
                paramStr!==""?paramStr:paramStrteams,
                accounts[0].localAccountId,
                userInfo.crmMemberFields,
                teamId,
                crmProvider
            );
        } else {
            convObjwctData = await getConvAttributes(
                paramStr!==""?paramStr:paramStrteams,
                accounts[0].localAccountId,
                userInfo.crmMemberFields,
                teamId,
                crmProvider
            );
        }
        if (!convObjwctData) {
            //if don't have convObjwctData details
            return;
        }
        let requestBody = {
            // userId: userInfo.userId,
            customerPhoneNumber: convObjwctData.conversation.customerPhoneNumber,
            stateCode: convObjwctData.contact.stateCode,
            timezone: convObjwctData.contact.timezone,
            teamId: teamId,
            userId: userInfo.userId

        };
        var res = await checkStartConversationEligibility(requestBody, convObjwctData);
        if (res == null || res.status != 'SUCCESS') {
            debugger
            if (res?.isNonTcpa){
                //if it's non tcpa hrs then returning with flag true to handle states
            return {
                isNonTcpa:true
            };}
            return;
        }
        return convObjwctData;
    }

    const assignConversationToMe = async function () {
        handleAlertModalClose();
        setConvLoading(true);
        let msgReq = {
            conversation: {
                conversationId: conversationId,
                AssignedDate: new Date().toISOString(),
            },
            teamId: convTeamId,
            userId: userInfo.userId,
            requestedUserId: userInfo.userId
        };
        InvokePostServiceCall(apiConfig.ASSIGN_CONVERSATION, msgReq)
            .then((data) => {

                setConvLoading(false);
            })
            .catch((error) => {
                console.log(error.message);
                setConvLoading(false);
            });
    };

    const handleCreateConversation = async function (data, context) {
        // setConvLoading(false);
        console.log("handleCreateConversation");
        let newConv = mapConversation(data.conversation, data.conversation.teamPhoneNumber);
        newConv.status = 'Active';
        if ((crmMode == 'true' && window.Microsoft != null && window.Microsoft.CIFramework != null) || (window.sforce != null && crmProvider == 'S')) {
            await createOrSearchTaskinCRM(newConv, userInfo.crmMemberFields, true, activityDueDataHours, context, userInfo.teams, crmProvider);
            setSelectedMenu("active");
            setOpenDrawer(false);
            setOpenConversationDrawer(false);
            navigate("/conversations");
        } else {
            dispatch(addConversationToList(newConv));
            dispatch(conversationSelected(newConv));
            setSelectedConv(newConv);
            navigate(`/conversations/${newConv.conversationId}`);
            setSelectedMenu("active");
            setOpenActive(true);
            setSetupCollapse(false)
            // setConvLoading(false);
            //if(location.pathname!=="/"){
            //  navigate("/conversations");
            //}
        }
        dispatch(getMessagesForConversationId(newConv.conversationId, setConvLoading)); {/**added setConvLoading in props */ }
        if (context === "TransferConversation" || context === "SelfAssignConversation") {
            dispatch(updateConversationAsReadApiCall(data.conversation.conversationId))
        }
    };

    useEffect(() => {
        let interval;
        if (newConversations.length > 0) {
            sessionStorage.setItem("newConversations",JSON.stringify(newConversations));
            interval = setInterval(() => {
                dispatch(removeNewConvTimer());
            }, 1000);

            newConversations.forEach((item, index) => {
                if (item.autoRejectTimer === 1) {
                    handleAutoRejectConv(item.conversationId);
                    sessionStorage.removeItem("newConversations")
                }
                if (newConversations.length == 1 && item.autoRejectTimer === 2) {
                    sessionStorage.removeItem("newConversations")
                    setOpenConversationDrawer(false); //new conversion drawer is closed by this
                    setSelectedMenu("")
                    setHideNewConvMenu(true);
                }
            });
        }
        return () => {
            clearInterval(interval);
        };
    }, [newConversations]);

    function handleAutoRejectConv(conversationId) {
        setStatusChanged(!statusChanged);
        setDecision("Rejected");
        let removeNewConversationFromList = {
            messageType: "RemoveNewConversationFromList",
            conversation:{
            conversationId: conversationId,}
        };
        setOpenConversationDrawer(false); // closing drawer if auto rejected the conversion
        SendToAllUserConnections(
            userInfo.userId,
            JSON.stringify(removeNewConversationFromList)
        );


        var updateUserStatusReq = {
            userDetail: {
                user:
                {
                    userId: userInfo.userId,
                    status: "Busy"
                }
            }
        };

        InvokePostServiceCall(apiConfig.UPDATE_USER_STATUS, updateUserStatusReq)
            .then((res) => {

                console.log(res);
            })
            .catch((error) => {
                console.log("User status update failed: ", error.message);
            });



    }

    const dialogFullWidth = "md";
    const connectToWebSocket = (userId) => {
        wSocket = new WebSocket(userInfo.wsURL, "json.webpubsub.azure.v1");
        console.log("wSocket.readyStatus Before: " + wSocket.readyState);
        wSocket.onopen = (conn) => {
            setIsConnected(true);
            console.log("connected");
            setShowDrawer(true);
            console.log("wSocket.readyStatus Open: " + wSocket.readyState);
            let newConversationsArr=sessionStorage.getItem("newConversations");
            if(newConversationsArr){
            // on refresh if request start time + 120 secs - current time < 0 then only show new conversation request.
                let newConvArr=JSON.parse(newConversationsArr)
                let secToMili=120*1000;
                let currentTime=new Date().getTime();
                let conversationRequestExpireTime=new Date(newConvArr[0].preAssignedDate).getTime() + secToMili;
                let result= (currentTime - conversationRequestExpireTime)/1000;
                if(result<0){
                dispatch(addNewConversationToList(newConvArr[0]))
                        setTimeout(()=>{setHideNewConvMenu(false)},[3000])
                        setSelectedMenu("new")
                        setOpenConversationDrawer(true);
                }else{
                    sessionStorage.removeItem("newConversations")
                }
            }
        };

        wSocket.onclose = (conn) => {
            setShowDialogtoReconnect(true);
            setIsConnected(false);
            console.log("Disconnected");
            console.log("wSocket.readyStatus Close: " + wSocket.readyState);
        };
        const NewIncomingConversation = "NewIncomingConversation";
        const CreateConversation = "CreateConversation";
        const SelectConversation = "SelectConversation";
        const MultiTabEndConversation = "MultiTabEndConversation";
        const OutBoundMessage = "OutBoundMessage";
        const Notification = "Notification";
        const InboundMessage = "InboundMessage";
        const RemoveNewConversationFromList = "RemoveNewConversationFromList";
        const UserStatus = "UserStatus";
        const TransferConversation = "TransferConversation";
        const SelfAssignConversation = "SelfAssignConversation";
        const CreateUnAssigned = "CreateUnAssigned";
        const InActiveCount = "InActiveCount";
        const RemoveConvFromActiveList = "RemoveConvFromActiveList";
        const UpdateUserStatus = "UpdateUserStatus";
        const DocuSignCode = "DocuSignCode";
        const MessageRead = "MessageRead"

        wSocket.onmessage = (event) => {
            var audio = new Audio(notify);
            var newConversion = new Audio(NewConversation);
            var newMessage = new Audio(NewMessage);
            var reposne = event.data;
            var data = JSON.parse(reposne);
            if (data.data != null) {
                try {
                    data = JSON.parse(data.data);
                } catch (e) { }
            }
            console.log(data, "adadasd");
            let newConv = {};
            switch (data.messageType != null && data.messageType) {
                case NewIncomingConversation:
                    setSelectedMenu((prev)=>{ if(prev!=="new"){
                        setPrevSelectedmenu(prev)
                      }
                      return 'new'
                      });
                    setHideNewConvMenu(false)
                    setOpenConversationDrawer(true);
                    setSetupCollapse(false);
                    newConversion.play();
                    newConv = mapConversation(
                        data.conversation,
                        data.conversation.teamPhoneNumber
                    );
                    newConv.newConvTimer = 120;
                    newConv.autoRejectTimer = 122;

                    if (data.convAutoRejectTimeInSec) {
                        newConv.newConvTimer = data.convAutoRejectTimeInSec;
                        newConv.autoRejectTimer = data.convAutoRejectTimeInSec + 2;
                    }
                    dispatch(addNewConversationToList(newConv));
                    setTimeout(()=>{setSelectedMenu("new");setOpenConversationDrawer(true)},[500])  //delay new menu selection for 500 ms because of continuous newIncoming and unassignedRemove events.
                    setSetupCollapse(false)
                    openCifWindow();
                    break;
                case CreateConversation:
                    handleCreateConversation(data, CreateConversation);
                    if (data?.conversation?.source === "Inbound") {
                        openCifWindow();
                        newConversion.play();
                        setCloseSnackbarManually(true)
                        setSeverity("success");
                        setSnackbarMsg("New conversation received from " + data?.conversation?.customerPhoneNumber);
                        setOpenSnackbar(true);
                    }
                    break;
                case TransferConversation:
                    openCifWindow();
                    newConversion.play();
                    handleCreateConversation(data, TransferConversation);
                    // dispatch(updateConversationAsReadApiCall(data.conversation.conversationId))
                    break;
                case SelfAssignConversation:
                    openCifWindow();
                    setConvLoading(true);
                    handleCreateConversation(data, SelfAssignConversation);
                    if (crmMode !== "false") {
                        setOpenDrawer(false);
                        setOpenConversationDrawer(false);
                    }
                    // dispatch(updateConversationAsReadApiCall(data.conversation.conversationId))
                    break;
                case SelectConversation:
                    // let newConv = mapConversation(data.conversation, userInfo.msgSupportNumber);
                    //   setSelectedConv(newConv)
                    //   dispatch(getMessagesForConversationId(newConv.conversationId));
                    setConvLoading(false);
                    dataService.setData({ selectedConversation: data });
                    break;
                case MultiTabEndConversation:
                    //from client side we involk this event by passing conversationId in data object
                    //but from service side we were getting in data.conversation object so to extract added below condition
                    if(data.conversation){//if we have conversation object in data then only this block will execute.
                        data.conversationId = data.conversation.conversationId;
                    }
                    dataService.setData({ multiTabEndConversation: data });
                    break;

                case OutBoundMessage:
                    dispatch(AddMessageToConv(data));
                    dispatch(updateConversationList(data.message.conversationId));
                    break;
                case Notification:
                    if (data.convNotification.notificationType === UnAssignedAdd) {
                        dispatch(addNotificationToList(data.convNotification));
                        dispatch(getUnassignedConversationAPI(appContext, true))
                    } else if (
                        data.convNotification.notificationType === UnAssignedRemove
                    ) {
                        if (data.convNotification.userId !== userInfo.userId) {//if current userId  and incoming userId is not same then only update state.
                            let convIdObj={conversationId:data.convNotification.conversationId}
                            setAssignedConvId({...convIdObj})//this state change will call one useEffect which will setSelectedConv as null.
                        }
                        dispatch(
                            removeNotificationfromList(data.convNotification.conversationId)
                        );
                        dispatch(
                            removeUnassignedConversation(
                                data.convNotification.conversationId)
                        )
                    } else if (data.convNotification.notificationType === InActiveCount) {
                        var count = 0;
                        var isTeamExist = inactiveConvCountByTeams.some(y => y.teamId === data.convNotification.teamId)
                        if (!isTeamExist && userInfo.teams?.some(y => y.teamId === data.convNotification.teamId))
                            inactiveConvCountByTeams.push(data.convNotification)
                        if (inactiveConvCountByTeams && inactiveConvCountByTeams.length > 0) {
                            inactiveConvCountByTeams.forEach(element => {
                                if (element.teamId === data.convNotification.teamId) {
                                    element.count = data.convNotification.count
                                }
                                count += element.count
                            });
                        }
                        setInactiveConvCount(count);
                        setInactiveConvCountByTeams(inactiveConvCountByTeams);
                    } else if (data.convNotification.notificationType === MessageRead) {
                        ;
                        dispatch(updateMessageRead(data.convNotification))
                    } else if (data.convNotification.notificationType === "TypingPreviewIn") {
                        dispatch(toggleTypingPreview(data.convNotification, true));
                    }
                    else if (data.convNotification.notificationType === "TypingPreviewOut") {
                        dispatch(toggleTypingPreview(data.convNotification,false));
                    }


                    break;
                case InboundMessage:
                    newMessage.play();
                    dispatch(AddMessageToConv(data));
                    dispatch(updateConversationList(data.message.conversationId));
                    dispatch(updateConversationRead(data.message)); // calling this method to update showNotification value and show/hide red dot on ui.
                    //Calculate sentiment of the conversation if its enabled. 
                    console.log("enableSentimentCalculation: " + enableSentimentCalculation);
                    if (enableSentimentCalculation === "Y") {
                        const state = chatStore.getState();     //Get state from the store as state is not accessible in non react callback method
                        var conversations = state.conversations.conversations;
                        var conv = conversations.find((x) => x.conversationId === data.message.conversationId);
                        console.log("conv.messageList.length: " + conv.messageList.length);
                        if (conv.messageList.length > 0) {
                            console.log("conv.msgCountForSentiment: " + conv.msgCountForSentiment);
                            console.log("sentimentCalculateEveryXMessages: " + sentimentCalculateEveryXMessages);
                            // Make sentiment call if # of messages from customer is > configured number
                            if (conv.msgCountForSentiment >= sentimentCalculateEveryXMessages) {
                                console.log("Calculate Sentiment");
                                dispatch(updateSentimentInStore({ conversationId: conv.conversationId, messageList:conv.messageList }));
                            }
                        }
                    }
                    openCifWindow();
                    break;
                case RemoveNewConversationFromList:
                    if (newConversations.length === 0) {
                        setOpenConversationDrawer(false); //new conversion drawer is closed by this
                        setHideNewConvMenu(true);
                    }
                    dispatch(removeNewConversation(data.conversation?.conversationId));
                    sessionStorage.removeItem("newConversations")
                    // if(crmMode!=="false"){
                    // setOpenDrawer(false);
                    // }
                    break;
                case UserStatus:
                    //Update status in userinfo state
                    let statusColor = availableColor;
                    if (data.userInfo.userStatus === availableStatus) {
                        statusColor = availableColor;
                    } else if (data.userInfo.userStatus === busyStatus) {
                        statusColor = busyColor;
                    } else {
                        statusColor = offlineColor;
                    }
                    setUserStatusBgColor(statusColor);

                    break;
                case CreateUnAssigned:
                    newConv = mapConversation(
                        data.conversation,
                        data.conversation.teamPhoneNumber
                    );
                    newConv.isReadOnly = true;
                    dispatch(addUnassignedConversationToList(newConv));
                    break;
                case MessageDeliveryStatus:
                    setMsgLoadingState(false);
                    dispatch(updateMessageToConv(data));
                    break;
                case RemoveConvFromActiveList:
                    let convIdObj={conversationId:data.conversation.conversationId}
                    setAssignedConvId({...convIdObj})//this state change will call one useEffect which will setSelectedConv as null.
                    dispatch(removeConversation(data.conversation.conversationId));
                    break;
                case UpdateUserStatus:
                    //Update UserInfo.Status with value returned from data.user.status
                    setUserInfo({ ...userInfo, status: data.user.status })
                    break;
                case DocuSignCode:
                    setDocuSignCode(data.code);
                    getAccessToken(data.code);
                    break;
                case ConversationEndedBySystem:
                case ConversationEndedByMember:
                    newConv = mapConversation(
                        data.conversation,
                        data.conversation.teamPhoneNumber
                    );
                    newConv.isReadOnly = true;
                    newConv.status = "Completed";
                    newConv.isConversationClosed = true
                    setSelectedConv(newConv);
                    dispatch(getMessagesForConversationId(data.conversation.conversationId, setConvLoading,true));
                    dispatch(updateCloseWebChatConversation(data.conversation.conversationId));
                    break;

                default:
            }
        };
    };

    useEffect(() => {
        //debugger;
        props.handleDrawerOperation(openConversationDrawer);
            if(startConvWithTemplate && openConversationDrawer){
                //when drawer open close start conversation with template dialog
                handleCloseTeams();
            }
        console.log(openConversationDrawer, "changed");
    }, [openConversationDrawer]);

    const toggleDrawer = (open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        setOpenDrawer(open);
    };
    const handleAlertModalClose = () => {
        setShowSelfAssignButton(false);
        setShowDialogtoReconnect(false);
        setShowAlert(false);
    };

    const reconnectToPubSub = () => {
        dispatch(resetConversationList())
        dispatch(resetUnAssignedNotifList())
        setShowLoader(true);
        updateappContext();
        setShowDrawer(false);
    };

    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== "open",
    })(({ open }) => ({
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    }));

    const StyledBadge = styled(Badge)(({ theme }) => ({
        "& .MuiBadge-badge": {
            backgroundColor: "#dc3545",
            color: "#dc3545",
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            "&::after": {
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                borderRadius: "50%",
                border: "1px solid currentColor",
                content: '""',
            },
        },
    }));

    useEffect(() => {
        if (openConversationDrawer && !matches) {
            setShowbackdrop(true);
        } else {
            setShowbackdrop(false);
        }
    }, [openConversationDrawer]);

    const handleCloseBackdrop = (e) => {
        setShowbackdrop(false);
        setOpenConversationDrawer(false);
        setOpenDrawer(false);
    }
    useEffect(() => {
        if (teamList.length > 0) {
            setSelectedTeam(userInfo.teamId);
            let seletecedteam = teamList.filter(item => item.teamId === userInfo.teamId);
            setStartConvWithTemplate(seletecedteam[0].isStartConvWithTemplate);
        }
    }, [userInfo.teamId, teamList])

    function getAccessToken(code) {
        let accessTokenReq = {

            code: code,
            userId: userInfo.userId,

        };

        InvokePostServiceCall(apiConfig.GET_VALIDATE_ACCESS_TOKEN, accessTokenReq)
            .then((data) => {
                if (!data || !data.data || !data.data.access_token || data.data.access_token == "") {
                    setDocuSignCode("");
                }
                console.log("Success : GetOrValidateToken");
            })
            .catch((error) => {
                console.log(error, "Error Message");
                setDocuSignCode("");
                setSeverity("error");
                setSnackbarMsg("Token Expired. Plese Login Again.");
                setOpenSnackbar(true);
            });

    }

    async function getTemplateList(tempArr, tempData) {
        let finalArr = [];
        tempArr.forEach(async (item) => {
            item.templateBody = await GetTemplatesWithTokens(
                item,
                tempData,
                tokens,
                userInfo,
                selectedTeam
            );
            finalArr.push(item);
        });
        return finalArr;
    }
    function getTemplates() {
        let temparray = JSON.parse(JSON.stringify(templatesData));
        let filteredTemplatesListByTeam = temparray.filter(
            (templateItem) =>
                templateItem.teamId.toLowerCase() ===
                selectedTeam.toLowerCase()
        );
        let linkedEntities = ["user", "userattributes", "teamattributes"];
        let tempArr = [];
        let tempData = JSON.parse(
            startConvReqObjData.conversation.ConversationAttributes
        );
        if (tempData.EntityLogicalName?.toLowerCase() == 'opportunity') {
            linkedEntities.push("opportunity");
        }
        if (tempData.EntityLogicalName?.toLowerCase() == 'contact') {
            if (crmProvider == 'S') {
                linkedEntities.push("scontact");
            }
            else {
                linkedEntities.push("contact");
            }
        }
        filteredTemplatesListByTeam.forEach((item) => {
            if ((userInfo.department == "" || (item.department == undefined || item.department == "" || userInfo.department == item.department)) && item.category?.toLowerCase().includes("introduction")) {
                let relatedObjectsArr = [];
                if (item.relatedObjects) {
                    relatedObjectsArr = item.relatedObjects.split(",");
                    if (relatedObjectsArr.every((r) => linkedEntities.includes(r))) {
                        tempArr.push(item);
                    }
                }
                else if ((item.relatedObjects == undefined || item.relatedObjects == null || item.relatedObjects == "")) {
                    tempArr.push(item);
                }
                else {
                    console.log(
                        "Did not find all of",
                        relatedObjectsArr,
                        "in",
                        linkedEntities
                    );
                }
            }
        });
        getTemplateList(tempArr, tempData).then((data) => {
            setTemplatesList(data);
        });
        if (tempArr.length < 1) {
            setShowTemplatesDialog(true);
            setTemplateLoading(false);
            setTemplateMsg(true);
        }

    }
    useEffect(() => {  //useEffect to get all templates on selection of team.
        if (selectedTeam) {
            getTemplates()
        }
    }, [startConvReqObjData])

    useEffect(() => { //show templates data in dialog.
        if (templatesList.length)
            setShowTemplatesDialog(true);
        setTemplateLoading(false);
    }, [templatesList])
    const handleCloseSnackbar = (event, reason) => {
        // if (reason === "clickaway") {
        //     return;
        // } //Commented to close Snackbar when click any where on page
        setOpenSnackbar(false);
    };
    const getInActiveConversations = () => {
        setConvLoading(true); // set loading true before calling the api
        setSelectedMenu("inactive");
        setCollapseInactive(true);
        let requestBody = {
            teamId: userInfo.teamId,
        };
        InvokePostServiceCall(apiConfig.GET_INACTIVE_CONVERSATIONS, requestBody)
            .then((data) => {

                console.log(data, "InActiveConversationCounts");
                let inactiveConversationsArr = [];
                for (var i = 0; i < data?.data?.conversations?.length; i++) {
                    let obj = mapConversation(data?.data?.conversations[i], data?.data?.conversations[i].userPhoneNumber);
                    inactiveConversationsArr.push(obj);
                }
                setInactiveConversations(inactiveConversationsArr)
                setInactiveConvCount(inactiveConversationsArr.length);
                setHeaderNotification(false);
                setNotificationFilter(!notiFicationFilter);
                setConvLoading(false);
                if (inactiveConversationsArr.length === 0) {
                    setSelectedMenu("");
                }
            })
            .catch((error) => {
                setConvLoading(false);
                console.log(error);
            });
    }

    const startConversationFromDialog=async()=>{
        if (selectedTeam) {
            if(startConvWithTemplate){
                    sethasTeams(false);
                    setTemplateLoading(true);
                let convObjwctData = await checkStartConvEligibility(selectedTeam);
                if (!convObjwctData || convObjwctData?.isNonTcpa) {
                    //if don't have convObjwctData details closing the start conv dailog box 
                    if (!convObjwctData?.isNonTcpa) {
                        handleCloseTeams();
                    }
                    return;
                }
                setStartConvReqObjData(convObjwctData);
               }
               else{
                setShowStartConvModal(false);
                setConvLoading(true);
                sethasTeams(false);
                startConversation(paramStrteams, accounts[0].localAccountId, userInfo.crmMemberFields, selectedTeam, crmProvider);
                //setDefaultTeamId(event.target.value);
               }}
    }
   const handleConfirmationDialog = (isConfirm) => {
        setShowConfirmationDialog(false);
        console.log(`Non-TCPA Confirmation on scheduled message is: ${isConfirm ? "Confirm" : "Cancel"}`)
       if (isConfirm) {
           openTemplatesDailog(startConvConfirmationObj?.startConversationObj);//setting start conv request object 
       }
       else {
           handleCloseTeams() //tempate selection is enabled
       }
    }
    return (
        <>
            <SnackBar
                openSnackbar={openSnackbar}
                handleCloseSnackbar={handleCloseSnackbar}
                severity={severity}
                userMessage={snackbarMsg}
                {...closeSnackbarManually &&
                { isManualClose: true }}
            />
            {!showDocuSignMessage && <>
                <div>
                    {showprofile && (
                        <UserProfile
                            setShowprofile={setShowprofile}
                        />
                    )}


                    <Dialog
                        open={showStartConvModal}
                        onClose={handleCloseTeams}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth
                        sx={theme.customStyle.startConvWithTemplateDialog}
                        fullScreen={true}
                        hideBackdrop={true}
                    >
                        {!showTemplatesDialog &&
                            <><DialogContent sx={{ height: "292px" }}>
                                <span style={{ fontSize: "16px" }}>
                                    <strong>Select Team</strong>
                                </span>
                                <Divider sx={{ margin: "4px 0px 50px 0px" }} />
                                <FormControl fullWidth margin="dense">
                                    <InputLabel id="demo-simple-select-label">Team</InputLabel>
                                    <Select
                                        labelId="select-agent"
                                        id="select-agent"
                                        label="Team"
                                        onChange={handleTeamChange}
                                        value={selectedTeam}
                                    >
                                        {teamList?.map((a) => {
                                            let badgeColor = "#b8bfb3";
                                            return (
                                                <MenuItem key={a.teamId} value={a.teamId}>
                                                    <div>
                                                        <div>
                                                            <div
                                                            // style={{
                                                            //     position: "relative",
                                                            //     width: "10px",
                                                            //     height: "10px",
                                                            //     borderRadius: "6px",
                                                            //     backgroundColor: badgeColor,
                                                            //     display: "inline-block",
                                                            // }}
                                                            ></div>
                                                            <span style={{ marginLeft: "10px" }}>
                                                                {a.teamName}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </DialogContent>
                                <DialogActions>
                                    <LoadingButton
                                    loading={templateLoading}
                                        onClick={startConversationFromDialog}
                                    >
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <span>
                                                <strong> {startConvWithTemplate?"Next":"Start"}</strong>
                                            </span>
                                        </div>
                                    </LoadingButton>
                                    <Button onClick={handleCloseTeams}>
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <span>
                                                <strong> Cancel</strong>
                                            </span>
                                        </div>
                                    </Button>
                                </DialogActions>
                            </>}
                        {showTemplatesDialog && <StartConvWithTemplatesDialog
                            warningMsg={warningMsg}
                            showTeamTemplateDialog={showTeamTemplateDialog}
                            handleCloseTeams={handleCloseTeams}
                            templateLoading={templateLoading}
                            showTemplatesDialog={showTemplatesDialog}
                            selectedTemplateBody={selectedTemplateBody}
                            setTemplateSearchVal={setTemplateSearchVal}
                            templatesList={templatesList}
                            templateSearchVal={templateSearchVal}
                            startConvReqObjData={startConvReqObjData}
                            setStartConvReqObjData={setStartConvReqObjData}
                            setSelectedTemplateName={setSelectedTemplateName}
                            setSelectedTemplateBody={setSelectedTemplateBody}
                            selectedTemplateName={selectedTemplateName}
                            sethasTeams={sethasTeams}
                            userInfo={userInfo}
                            setShowTemplatesDialog={setShowTemplatesDialog}
                            setConvLoading={setConvLoading}
                            startConvWithTemplate={startConvWithTemplate}
                            templateMsg={templateMsg}
                            setTemplateMsg={setTemplateMsg}
                            crmProvider={crmProvider}
                            setSeverity={setSeverity}
                            setSnackbarMsg={setSnackbarMsg}
                            setOpenSnackbar={setOpenSnackbar}
                        />
                        }
                    </Dialog>


                    <ConfirmationComponent
                        showConfirmationDialog={showConfirmationDialog}
                        handleConfirmation={handleConfirmationDialog}
                        confirmationDialogContent={confirmationText}
                    ></ConfirmationComponent>

                    {convLoading && (
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={convLoading}
                        >
                            <Stack gap={1} justifyContent="center" alignItems="center">
                                <CircularProgress color="inherit" />
                                <Typography>Loading...</Typography>
                            </Stack>
                        </Backdrop>
                    )}
                </div>
                <div>
                    {showAlert && (
                        <Dialog
                            open={showAlert}
                            onClose={handleAlertModalClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            fullWidth={dialogFullWidth}
                        >
                            <DialogTitle>Alert</DialogTitle>
                            <Divider />
                            <DialogContent>
                                <div style={{ display: "flex", padding: "5px" }}>
                                    <Typography gutterBottom>{alertText}</Typography>
                                </div>                      </DialogContent>
                            <DialogActions>
                                {showSelfAssignButton && (
                                    <Button onClick={assignConversationToMe}>
                                        <div style={{ display: "flex" }}>
                                            <span>
                                                <strong> Assign to  me</strong>
                                            </span>
                                        </div>
                                    </Button>
                                )}
                                <Button onClick={handleAlertModalClose}>
                                    <div style={{ display: "flex" }}>
                                        <span>
                                            <strong> Cancel</strong>
                                        </span>
                                    </div>
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )}

                </div>
                {showDialogtoReconnect && (
                    <Dialog
                        open={showDialogtoReconnect}
                        onClose={handleAlertModalClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth={dialogFullWidth}
                    >
                        <DialogTitle>Alert</DialogTitle>
                        <Divider />
                        <DialogContent>
                            {showLoader ? (
                                <Box sx={{ display: "flex", marginLeft: "40%" }}>
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <div>
                                    <Typography gutterBottom>
                                        Messenger disconnected due to inactivity. Please click on
                                        reconnect to connect back again
                                    </Typography>
                                </div>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={reconnectToPubSub} autoFocus>
                                Reconnect
                            </Button>
                            <Button onClick={handleAlertModalClose}>Close</Button>
                        </DialogActions>
                    </Dialog>
                )}

                {!crmEmbededView ?
                    (<Box sx={{ flexGrow: 1 }}>
                        <AppBar
                            position="fixed"
                            style={{ backgroundColor: theme.customStyle.appBarBGColor }}
                        >
                            <Toolbar>
                                <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 2, marginLeft: "-18px" }}
                                    onClick={() => {
                                        setOpenDrawer(!openDrawer);
                                        dataService.setData({ convDrawer: !openDrawer });
                                        if (!setupCollapse) {
                                            if (selectedMenu === "active" || selectedMenu === "unassigned" || selectedMenu === "inactive" || selectedMenu === "new") {
                                                setOpenConversationDrawer(!openConversationDrawer);
                                            }
                                        }
                                    }}
                                >
                                    {showNotificationIndicator || newConversations.length > 0 ? (
                                        <StyledBadge
                                            sx={{
                                                position: "relative",
                                                left: "25px",
                                                bottom: "10px",
                                                "& .MuiBadge-badge": {
                                                    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
                                                    "&::after": {
                                                        position: "absolute",

                                                        top: 0,
                                                        left: 0,
                                                        width: "100%",
                                                        height: "100%",
                                                        borderRadius: "50%",
                                                        animation: "ripple 1.2s infinite ease-in-out",
                                                        border: "1px solid currentColor",
                                                        content: '""',
                                                    },
                                                },
                                            }}
                                            badgeContent=""
                                            color="error"
                                            overlap="circular"
                                            variant="dot"
                                        ></StyledBadge>
                                    ) : null}
                                    <MenuIcon />
                                </IconButton>

                                <Typography
                                    variant="h6"
                                    component="div"
                                    sx={{ flexGrow: 1, textAlign: "center" }}
                                ></Typography>
                                <div style={{ display: "flex" }}>

                                    {userInfo.role === "Supervisor" || userInfo.role === "Admin" ? (
                                        <HeaderNotifications
                                            setSelectedMenu={setSelectedMenu}
                                            unassignedConvCounts={unAssignedNotifList.length}
                                            getInActiveConversations={getInActiveConversations}
                                        />
                                    ) : null}
                                    <SignIn
                                        isConnected={isConnected}
                                        userInfo={userInfo}
                                        userStatusBgColor={userStatusBgColor}
                                        setUserStatusBgColor={setUserStatusBgColor}
                                        setShowprofile={setShowprofile}
                                    />
                                </div>
                            </Toolbar>
                        </AppBar>
                    </Box>) : (<></>)}
                <Drawer
                    sx={{
                        flexShrink: 0,
                        "& .MuiDrawer-paper": {
                            width: drawerWidthNew,
                            boxSizing: "border-box",
                            overflowX: "hidden",
                            backgroundColor: "#efefef",
                        },
                        overflowX: "hidden",
                    }}
                    anchor={"left"}
                    variant="persistent"
                    open={true}
                    onClose={toggleDrawer(false)}
                >
                    {showDrawer ? (
                        < DrawerListCmpNew selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} handleDrawerOperation={props.handleDrawerOperation}
                         handleCloseTeams={handleCloseTeams}
                         startConvWithTemplate={showTemplatesDialog}
                        />
                    ) : <></>
                    }
                </Drawer>
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer - 1 }}
                    open={showBackdrop}
                    onClick={handleCloseBackdrop}
                ></Backdrop>
            </>}
        </>
    );
}

