/***************
* 07-28-2023    SUDAM B CHAVAN     TEXTING-1450 Templates Setup Redesign: Reuse Templates across teams / user
* 08-01-2023    SUDAM B CHAVAN     TEXTING-1522 - templates across teams observations.
* 08-29-2023 => ONKAR => TEXTING-1578 -REACT Upgrade
* 08-30-2023 => ONKAR => TEXTING-1578 -REACT Upgrade
***********/
import React, { useState, useEffect, useContext } from "react";
import { DataGrid } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import {
    IconButton,
    Button,
    Box,
    Dialog,
    DialogContent,
    DialogActions,
    InputAdornment
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import apiConfig from "../../api/apiConfig";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../AuthContext";
import { InvokePostServiceCall } from "../../api/serviceUtil";
import { useTheme } from "@mui/material/styles";
import RemoveIcon from "@mui/icons-material/Remove";
import SnackBar from "../../components/SnackBar"
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import { TextField } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import AddTeamsToTemplateDialog from "./AddTeamsToTemplateDialog";
import { StyledDataGrid } from "../../components/styledComponents/StyledDataGrid";


const AssociateTeamsComp = (props) => {
    const { messageApiToken, userInfo } = useContext(AuthContext);
    const theme = useTheme();
    const [teamsData1, setTeamsData1] = useState([]);
    const [teamsDataCopy, setTeamsDataCopy] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [teamsForAssociation, setTeamsForAssociation] = React.useState([]);
    const [dialogLoading, setDialogLoading] = useState(true);
    const [selectedDialog, setSelectedDialog] = useState("add");
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [removeSelectionModel, setRemoveSelectionModel] = useState([]);
    const [btnLoading, setBtnLoading] = useState(false);
    const [addSelectionModel, setAddSelectionModel] = useState([]);
    const [openWarningDialog, setOpenWarningDialog] = useState(false);
    const teamsArr1 = [];
    const [severity, setSeverity] = useState("error");

    const columns = [
        // { field: "id", headerName: "ID", width: 70 },
        {
            field: "teamName",
            headerName: "Team Name",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "type",
            headerName: "Category",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "msgSupportNumber",
            headerName: "SMS Number",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        }
    ];

    useEffect(() => {
        //this useEffect will execute on 1st time on component render and when messageApiToken value chenged
        if (messageApiToken) {
            getTeamsByTemplateId();
        }
    }, [messageApiToken]);
    const getTeamsByTemplateId = () => {
        if (props.templateId) {
            setIsLoading(true);
            InvokePostServiceCall(apiConfig.GET_TEMPLATE_ASSOCIATED_TEAMS, {
                templateId: props.templateId,
            })
                .then((data) => {
                    let tempArr = [];
                    data.data.associatedTeams.forEach((team, index) => {
                        tempArr.push({
                            ...team, id: index
                        })
                    })
                    setTeamsData1(tempArr);
                    setTeamsDataCopy(tempArr);
                    setIsLoading(false);
                    console.log(data, "@@@@@@@@@@@");
                })
                .catch((error) => {
                    console.log("TemplatesApi", error);
                    setSnackbarMsg(error.message);
                    setSeverity("error");
                    setOpenSnackbar(true);
                    setIsLoading(false);
                });
        }
    }
    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };
    const handleClose = () => {
        setOpenWarningDialog(false);
    };
    const handleDialogClose = () => {
        setOpenAddDialog(false);
        setOpenWarningDialog(false);
    };

    const SaveTeamAssociations = (actionType) => {
        setBtnLoading(true);
        let templateToTeamsArr = [];
        if (actionType === "Add") {
            teamsForAssociation.forEach((item) => {
                let present = addSelectionModel.some((data) => data === item.id);
                if (present === true) {
                    templateToTeamsArr.push({
                        templateId: props.templateId,
                        teamId: item.teamId,
                    });
                }
            });
        } 
        else if (actionType === "Delete") {
            teamsDataCopy.forEach((item) => {
                let present = removeSelectionModel.some((data) => data === item.id);
                if (present === true) {
                    templateToTeamsArr.push({
                        templateId: props.templateId,
                        teamId: item.teamId,
                    });
                }
            });
            setRemoveSelectionModel([]);
        } 
        
        let requestBody = {
            actionType: actionType,
            TemplateToTeam: templateToTeamsArr,
        };
        InvokePostServiceCall(apiConfig.MANAGE_TEAMS_TO_TEMPLATE_ASSOCIATION, requestBody)
            .then((data) => {
                console.log(data);
                getTeamsByTemplateId();
                handleDialogClose();
                setTeamsDataCopy([])
                setRemoveSelectionModel([]);
                setBtnLoading(false);
                setSnackbarMsg(`Teams ${actionType === 'Delete' ? "Removed" : "Added"} Successfully`);
                setSeverity("success");
                setOpenSnackbar(true);
            })
            .catch((error) => {
                console.log(error, "Error Message");
                setSnackbarMsg(error.message);
                setSeverity("error");
                setOpenSnackbar(true);
                setBtnLoading(false);
            });
    };
    const filterTable = (value) => {
        let usArr = [];
        let val = value?.toLowerCase();
        teamsDataCopy.forEach((item) => {

            if (
                item.teamName?.toLowerCase().includes(val) ||
                item.type?.toLowerCase().includes(val) ||
                item.msgSupportNumber?.toLowerCase().includes(val) ||
                item.createdDateFormat?.toLowerCase().includes(val) ||
                item.lastModifiedDateFormat?.toLowerCase().includes(val)
            ) {
                usArr.push(item);
            }
        });
        setTeamsData1(usArr);
    }
    const getAllTeams = () => {
        setTeamsForAssociation([]);
        setDialogLoading(true);
            InvokePostServiceCall(apiConfig.GET_TEAMS, { isActive: true, userId: userInfo.userId })
                .then((data) => {
                    const teamsArr = [];
                    const tempArr = [];
                    console.log("teamsDataCopy :: ", teamsDataCopy)
                    data.data.teamDetails.forEach((item, index) => {
                        debugger
                        let present = teamsDataCopy.some((t) => item.team.teamId === t.teamId);
                        if (present === false) {
                            tempArr.push(item.team);
                        }
                    });
                    tempArr.forEach((item, index) => {
                        teamsArr.push({
                            ...item
                            ,id: item.teamId                            
                        });
                    });
                    setTeamsForAssociation(teamsArr);
                    //Set Timeout added To fix No record found message issue
                    setTimeout(() => {
                        setDialogLoading(false);
                    }, 1);

                })
                .catch((error) => {
                    console.log(error, "Error Message");
                    setDialogLoading(false);
                    setSnackbarMsg(error.message);
                    setSeverity("error");
                    setOpenSnackbar(true);
                });
    };

    return (
        <>
            {props.isAdd && !props.templateId
                ? <>
                    <h5 style={{color: "red",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "center"}} >Please add template first to associate team.</h5>
                </> :


                <>
                    <SnackBar
                        openSnackbar={openSnackbar}
                        handleCloseSnackbar={handleCloseSnackbar}
                        severity={severity}
                        userMessage={snackbarMsg}
                    />
                    <div>
                        <Box sx={theme.customStyle.tableRow}>
                            <h2 style={{ marginLeft: 10 }}>Teams</h2>
                        </Box>
                    </div>
                    <div style={{ display: "flex", alignItems: "flex-start", paddingLeft: 10 }}>
                        <Box sx={theme.customStyle.tableRow}>
                            <div>
                                <Box sx={theme.customStyle.tableRow}>
                                    <IconButton
                                        disabled={removeSelectionModel.length > 0 ? true : false}
                                        sx={theme.customStyle.teamsAddIconBtn}
                                        onClick={() => {
                                            setSelectedDialog("add");
                                            getAllTeams();
                                            setOpenAddDialog(true);
                                        }}
                                    >
                                        <AddIcon />
                                    </IconButton>
                                    <IconButton
                                        disabled={removeSelectionModel.length < 1 ? true : false}
                                        sx={{
                                            backgroundColor: "red",
                                            color: "white",
                                            "&:hover": { backgroundColor: "#db0000" },
                                        }}
                                        onClick={() => {
                                            setOpenWarningDialog(true);
                                        }}
                                    >
                                        <RemoveIcon />
                                    </IconButton>
                                </Box>
                            </div>
                            <AddTeamsToTemplateDialog
                                rows={teamsForAssociation}
                                loading={dialogLoading}
                                columns={columns}
                                open={openAddDialog}
                                handleClose={handleDialogClose}
                                SaveTeamAssociations={SaveTeamAssociations}
                                checkboxSelection={true}
                                selectionModel={addSelectionModel}
                                setSelectionModel={setAddSelectionModel}
                                dialogTitle={"Add Teams"}
                                btnText={"Add"}
                                btnLoading={btnLoading}
                                isFromTemplate={true}
                            />
                            <Dialog
                                open={openWarningDialog}
                                onClose={handleClose}
                                fullWidth
                                maxWidth={"sm"}
                            >
                                <DialogContent>
                                    <h6>
                                        Are you sure you want to remove {removeSelectionModel.length} teams
                                        from template ?
                                    </h6>
                                </DialogContent>
                                <DialogActions>
                                    <LoadingButton
                                        loading={btnLoading}
                                        size="small"
                                        onClick={() => {
                                            SaveTeamAssociations("Delete");
                                        }}
                                    >
                                        Remove
                                    </LoadingButton>
                                    <Button
                                        size="small"
                                        onClick={() => {
                                            setOpenWarningDialog(false);
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                <TextField
                                    sx={{ marginRight: "10px", marginBottom: "10px" }}
                                    size="small"
                                    type="search"
                                    variant="outlined"
                                    margin="normal"
                                    id="outlined-search"
                                    label=""
                                    placeholder="Teams Search"
                                    autoComplete="off"
                                    onChange={(e) => {
                                        if (e.target.value) {
                                            filterTable(e.target.value);
                                        } else {
                                            setTeamsData1(teamsDataCopy);
                                        }
                                    }}
                                    InputLabelProps={{
                                        shrink: false,
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Box>
                        </Box>
                    </div>

                    <Box
                        sx={theme.customStyle.tableHead}
                    >
                        <StyledDataGrid
                            loading={isLoading}
                            rows={teamsData1}
                            columns={columns}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                            checkboxSelection
                            disableSelectionOnClick
                            components={{
                                NoRowsOverlay: CustomNoRowsOverlay,
                            }}
                            onSelectionModelChange={(data) => {
                                setRemoveSelectionModel(data);
                            }}


                        />
                    </Box>
                </>
            }
        </>
    );
};

export default AssociateTeamsComp;