/***************
* Description: This file is used to send the message to parent window.
* 04-28-2023 =>Rajesh - Implemented menthods to send the messages to parentwview.
  

***********/

/*export async function GetToken(msg) {

  return new Promise(async(resolve) => {
    var responseData = await window.parent.GetParentData(data);
    resolve(responseData);
  });

} */

//create a js function to post the message to parent window
export function SendNotificationToParent(msg) {
  if (msg.event === "minimizeWindow") {
     window.parent.postMessage(msg, msg.targetOrigin);    //Rajesh - Target origin is the parent window url configured in the webchat config
    let param = { data: msg };
    let data = JSON.stringify(param);
    if (!!window.Android) {
      window.Android.MinimizeWindow(data);
    }  else if (!!window.webkit && !!window.webkit.messageHandlers &&  !!window.webkit.messageHandlers.MinimizeWindow) {
      window.webkit.messageHandlers.MinimizeWindow.postMessage(data);
    } 
  }
  if (msg.event === "newMessage") {
    window.parent.postMessage(msg, msg.targetOrigin);       
  }
  if (msg.event === "newMessageMobile") {   
    let param = { data: msg };
    let data = JSON.stringify(param);
    if (!!window.Android) {
      window.Android.NewMessage(data);
    } else if (!!window.webkit && !!window.webkit.messageHandlers && !!window.webkit.messageHandlers.NewMessage) {
      window.webkit.messageHandlers.NewMessage.postMessage(data);
    } 
  }
  if (msg.event === "clearNewMessageNotification") {   
    let param = { data: msg };
    let data = JSON.stringify(param);
    if (!!window.Android) {
      window.Android.ClearNewMessageNotification(data);
    } else if (!!window.webkit && !!window.webkit.messageHandlers && !!window.webkit.messageHandlers.ClearNewMessageNotification) {
      window.webkit.messageHandlers.ClearNewMessageNotification.postMessage(data);
    } 
  }
  if (msg.event === "endSession") {
    window.parent.postMessage(msg, msg.targetOrigin);
    let param = { data: msg };
    let data = JSON.stringify(param);
    if (!!window.Android) {
      window.Android.EndSession(data); // this should clear the session data for that conv
    }  else if (!!window.webkit && !!window.webkit.messageHandlers && !!window.webkit.messageHandlers.EndSession) {
      window.webkit.messageHandlers.EndSession.postMessage(data);
    } 
  }
  if (msg.event === "getCustomAttributes") {
    let param = { data: msg };
    let data = JSON.stringify(param);
      if (!!window.Android) {
          var cAttr = window.Android.GetCAttributes(data);
          alert(JSON.stringify(cAttr));
          return cAttr; // this should clear the session data for that conv
    }  
  }
  
  if (msg.event === "sendConvId") {
    let param = { data: msg };
    let data = JSON.stringify(param);
    if (!!window.Android) {
      window.Android.StartConv(data); // this webSite will send id to Mobile APP for store in your db
    } else if (!!window.webkit && !!window.webkit.messageHandlers && !!window.webkit.messageHandlers.StartConv) {
       window.webkit.messageHandlers.StartConv.postMessage(data);
    } 
  }
  if (msg.event === "newMessageSwiftUI") {   
    let param = { data: msg };
    let data = JSON.stringify(param);
    if (!!window.webkit && !!window.webkit.messageHandlers && !!window.webkit.messageHandlers.NewMessageSwiftUI) {
      window.webkit.messageHandlers.NewMessageSwiftUI.postMessage(data);
    } 
  }
  if(msg.event === "getToken"){
    window.parent.postMessage(msg, msg.targetOrigin);
  }
  if (msg.event === "getConvIdFromApp") {
    let param = { data: msg };
    let data = JSON.stringify(param);

    
    window.parent.postMessage(msg,msg.targetOrigin);

    if (!!window.Android) {
      return  window.Android.GetConvId(data); ///Pass the id to the webSite from mobile  db
    }
    else if (!!window.webkit && !!window.webkit.messageHandlers && !!window.webkit.messageHandlers.GetConvId) {
     window.webkit.messageHandlers.GetConvId.postMessage(data);
    } 
  }
  if (msg.event === "downloadFile") {
    let param = { data: msg };
    let data = JSON.stringify(param);
    if (!!window.Android) {
       window.Android.DownloadFile(data); ///Pass the id to the webSite from mobile  db
    }
   else if (!!window.webkit && !!window.webkit.messageHandlers && !!window.webkit.messageHandlers.DownloadFile) {
      window.webkit.messageHandlers.DownloadFile.postMessage(data);
    } 
  }
}
