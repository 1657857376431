////11-11-2022 => Texting-794 2nd active conv page is not opening when agent accepts 2nd request with 1st conversation contact details screen -fixed
////01-24-2023 - Display MRM Object Name when conversation linked to CRM rather than generic CRM name
////04/25/2023 => TEXTING-1214-Issue 2 - Conversation ended by member is becoming blank when click on END button in Agent UI.
////05/10/2023 => ONKAR => Texting-1252 If conversation page is in open state, then new inbound messages for agent are automatically showing as read.(red dot issue)
////05/23/2023 => SUDAM B CHAVAN => TEXTING-1278 => Self-assigning the active conversation for same user to different team is not showing the active conversation until reload.
////06-30-2023 => ONKAR => TEXTING-1389 - Sentiment UI
////07-04-2023 => ONKAR => TEXTING-1403 - Sentiment UI enhancements.
////07-05-2023 => ONKAR => TEXTING-1403 - Sentiment UI enhancements.
////07-10-2023 => SUDAM B CHAVAN =>  TEXTING-1417 - GPT Component Pending observations.
////07-11-2023 => ONKAR => TEXTING-1404 - Sentiment emoji is not showing correct emotion - Not consistent.(updated emojis as per new api changes).
////07-24-2023 => ONKAR => TEXTING-1475 - Critical: Sentiment Optimization
////08-04-2023 => ONKAR => TEXTING-1514 - Texting-1514 Continued web messaging for authenticated customers - Web - UI
// 08-30-2023 => ONKAR => TEXTING-1578 -REACT Upgrade 
// 09-05-2023 => ONKAR =>TEXTING-1661- Switch back conversation history for unauthenticated & SMS conversations to old view.
//09-20-2023 => ONKAR => TEXTING-1722 - 0: Conversation read api calls are made twice.
//09-29-2023 => ONKAR =>TEXTING-1699 -Conversation History Enhancements.
//10-12-2023 => ONKAR => TEXTING-1796 - Sentiment Re-Calculate button to calculate sentiment with latest messages.
//10-16-2023 => ONKAR => TEXTING-1796 - Sentiment Re-Calculate button to calculate sentiment with latest messages.
//10-19-2023 => ONKAR => TEXTING-1798 - Auto suggestion message prompts are disabling when click on suggestions on Non-TCPA hrs and click on do not send button on Schedule popup.
//10-19-2023 => ONKAR =>TEXTING-1815 -Number 0 is showing on omni channel, History tab instead of Neutral emoji If overall sentiment of conversation is '0'.
//11-09-2023 => ONKAR =>TEXTING-1857 -Click on 'end' button is making the Texting screen blank.
//11-12-2023 => ONKAR =>TEXTING-1883 -Click on reconnect is auto highlighting the active icon in left panel.
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import LoadingButton from "@mui/lab/LoadingButton";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import apiConfig from "../../api/apiConfig";
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import ChatIcon from '@mui/icons-material/Chat';
import {
  endConversation,
  getUnassignedConversationAPI,
  updateSentimentInStore,
} from "../../api/messageServices";
import { AuthContext } from "../../AuthContext";
import ChatHistory from "../../components/ChatHistory";
import Messenger from "../../components/Messenger";
import { dataService } from "../../dataService";
import { UpdateConversationAsRead } from "../../api/messageServices";

import {
    openContextRecordInCRMWrapper, closeActivityinCRM
} from "../../extensions/crmWrapper";
import {
  conversationsSelector,
  removeConversation,
    removeUnassignedConversation, conversationSelected,
    updateConversationRead,
    updateMsgCountForSentiment,
    updateOverallSentiment
} from "../../storage/slices/messageSlice";
import { InvokePostServiceCall } from "../../api/serviceUtil";
import ConversationContactTab from "../../components/ConversationContactTab";
import { getEntityDisplayName } from "../../extensions/Util";
import axios from "axios"
import CloseIcon from '@mui/icons-material/Close';
import { SendToAllUserConnections } from "../../azure/pubSub";
import TaskAltTwoToneIcon from '@mui/icons-material/TaskAltTwoTone';
import CustomTabList from "../../components/CustomTabList";
import ConversationHistory from "../../components/ConversationHistory";
import SentimentEmoji from "../../components/SentimentEmoji";
import { useNavigate } from "react-router-dom";


export default function Conversations() {
    const { isCompLoading, unassignedConversations, selectConversation,conversations } = useSelector(
    conversationsSelector
  );
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const [showCircularProgress, setShowCircularProgress] = useState(true);
  const [convDrawer, setConvDrawer] = React.useState(true);
  const appContext = useContext(AuthContext);
  const {
    setSearchConvOpen,
    userInfo,
    selectedConvId,
    setSelectedConvId,
    selectedConv,
    setSelectedConv,
    headerNotification,
    notiFicationFilter,
    inactiveConversations,
    setInactiveConversations,
    collapseInactive,
    activityDueDataHours,
    convLoading,
    templatesData,enableVideoCall,
    enableSentimentCalculation,
    sentimentCalculateEveryXMessages,
    applicationSettings,
    setOpenActive
  } = useContext(AuthContext);
    const [msgAutoSuggestions, setMsgAutoSuggestions] = useState([]);
    const [dimensions, setDimension] = useState(window.innerWidth);
  const [value, setValue] = React.useState("1");
    const tabList = [
        { label: "Messages", value: "1", displayOrder: 1 },
        { label: "History", value: "2", displayOrder: 2 },
        { label: "Details", value: "3", displayOrder: 3 },
    ];

    useEffect(() => {
        const url = new URL(window.location);
        window.history.replaceState({}, '', url);
    }, []);
  //UseEffect - OnLoad
  useEffect(() => {
    if (isCompLoading === false) {
      setShowCircularProgress(false);
    }
  }, [isCompLoading]);

  
 

  //useEffect for header notification functionality
  useEffect(() => {
    if (headerNotification === true) {
      loadUnassignedConversations();
      setSearchConvOpen(true);
      setConvDrawer(true);
      dataService.setData({ headerDrawer: true });
    }
    if (collapseInactive) {
      openDrawer();
    }
  }, [notiFicationFilter]);

  //close conversation list drawer
  useEffect(() => {
    if (appContext.crmMode === "true" && convDrawer === true) {
      setConvDrawer(false);
      dataService.setData({ headerDrawer: false });
    }
  }, [selectedConv]);


  //When selected conversation is changed reset tab status
    useEffect(() => {
        setValue("1");
    }, [selectedConv?.conversationId]);

  const loadUnassignedConversations = () => {
    dispatch(
      getUnassignedConversationAPI(appContext)
    );
  };

    const handleinputMsgClick = (data) => (event) => {
        if (selectConversation.isUnReadConv == true) {
            var conv = Object.assign({}, selectConversation);
            let index = conversations.findIndex(
              (x) => x.conversationId === selectConversation.conversationId
            );
           if (index > -1 ) {
              if(conversations[index].showNotification===true){//check unread message is present and mark it as read.
                conv.isUnReadConv = false;
                conv.showNotification=false;
                dispatch(conversationSelected(conv));
                dispatch(updateConversationRead(conv));// calling this method to update showNotification value and show/hide red dot on ui.
              }
            }
           
        }
    }

  ///End the conversation
  const handleEndConversation = (conv) => (event) => {
    setValue("1");
    if (appContext.crmMode === "true") {
        closeActivityinCRM(conv, appContext.crmProvider);
      }
      //Get messages sent by customer
      var msgs = conv.messageList.filter((x) => (x.fromObjectType === "Customer"));
      //Calculate sentiment when conversation have atleast one message from customer
      if (enableSentimentCalculation === "Y" && msgs.length > 0) {
          dispatch(updateSentimentInStore({ conversationId: conv.conversationId, messageList:conv.messageList })); 
      }
    dispatch(
      endConversation(
        appContext.userInfo.userId,
        conv.conversationId,
        appContext.userInfo.teamId
      )
    );
    setSelectedConv(null);
    setOpenActive(false);
    navigate("/")
  };

  const openDrawer = () => {
    setConvDrawer(true);
    dataService.setData({ headerDrawer: true });
  };

  const onAssignConvSuccess = (userId) => {
    var convIndex = null;
    if (selectedConv.status == "InActive") {
      convIndex = inactiveConversations.findIndex(
        (x) => x.conversationId === selectedConv.conversationId
      );
      if (convIndex > -1) {
        inactiveConversations.splice(convIndex, 1);
      }
      setInactiveConversations(inactiveConversations);
      if (userInfo.userId !== userId) {
        setSelectedConv(null);
        setSelectedConvId("");
      }
    } else {
      convIndex = unassignedConversations.findIndex(
        (x) => x.conversationId === selectedConv.conversationId
      );
      if (convIndex > -1) {
        dispatch(
          removeUnassignedConversation(
            unassignedConversations[convIndex].conversationId
          )
        );
        // conversationList.splice(convIndex, 1);
        // setConversationList(conversationList);
        if (userInfo.userId !== userId) {
          setSelectedConv(null);
          setSelectedConvId("");
        }
      } else {
        if (userInfo.userId !== userId) {
          dispatch(removeConversation(selectedConv.conversationId));
          setSelectedConv(null);
          setSelectedConvId("");
        }
      }
    }
  };

  const onCloseConv = () => {
    let endConversationBody = {
      messageType: "MultiTabEndConversation",
      conversationId: "" + selectedConv.conversationId + "",
      AgentId: "" + userInfo.userId + "",
    };
    let convId = selectedConv.conversationId;
    setSelectedConv(null);//clear the selected conv from screen/display
    navigate("/")
    SendToAllUserConnections(userInfo.userId, JSON.stringify(endConversationBody));
    dispatch(removeConversation(convId));
  };
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    };

    const linkedEnityName = () => {
        return selectedConv?.conversationAttributes ? getEntityDisplayName(JSON.parse(selectedConv?.conversationAttributes).EntityLogicalName, selectedConv?.channel) : '';
    };

    useEffect(() => {
      setMsgAutoSuggestions([]);
        if (appContext.enableAutoSuggestion === "Y" && selectConversation?.messageList?.length > 0) {
        let msgList=selectConversation.messageList;
        if(msgList[msgList.length-1].fromObjectType==="Customer"){
          let finalMsgList=msgList[msgList.length-1].messages;
          axios
              .post(apiConfig.GET_MESSAGE_SUGGESTIONS, {
                  content: finalMsgList[finalMsgList.length-1].messageBody,
              })
              .then((response) => {
                  console.log(response, "response");
                  let suggestionsArr = [];
                  response.data.suggestions.forEach((item, index) => {
                      item = item.replace(/(^\"+|\"+$)/mg, "")
                      if (suggestionsArr.length < 3 && item.length <= 30) {
                      suggestionsArr.push(item);
                      }
                  });
                  setMsgAutoSuggestions(suggestionsArr);
              })
              .catch((error) => {
                  console.log(error);
              });
            }
          }
         
      
    }, [selectConversation.messageList]);


    const getDimension = () => {
      setDimension(window.innerWidth)
    }
    
    useEffect(() => {
      window.addEventListener('resize', getDimension);
      
      return(() => {
          window.removeEventListener('resize', getDimension);
      })
    }, [dimensions])

    let hasCustomerMsg=function (){
      for(let i=0; i<selectConversation?.messageList?.length; i++){
        if(selectConversation?.messageList?.[i].fromObjectType==="Customer"){
          return true;
         }
      }
     
    }();
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          {selectedConv != null ? (
            <>
              <Box sx={{ pt: 0, pl: 1, pr: 1, pb: 0 }}>
                <div style={theme.customStyle.convBoxHeader}>
                  <div style={{width:"50%"}}>
                    <div>
                                          <div>
                                             
                                              <div style={{...theme.customStyle.convBoxHeader,justifyContent:"flex-start"}}>
                          {(selectedConv?.channel === 'Chat' && selectedConv?.authenticationStatus === 'Authenticated')
                            && <Tooltip title="Authenticated">
                              <TaskAltTwoToneIcon color="success" sx={{ marginRight: "3px" }} />
                            </Tooltip>}
                                             <Tooltip title={selectedConv.customerName}>     
                          <span style={theme.customStyle.convHeaderCustName}>
                                                      {selectedConv.customerName}
                                                     
                          </span>
                          </Tooltip>
                          {selectedConv?.channel === 'Chat' ?
                                                          (<Tooltip title="Chat"><ChatIcon color="primary" sx={{ marginLeft: "5px" }} /></Tooltip>)
                                                          : (<Tooltip title="SMS"><PhoneIphoneIcon color="primary" sx={{ marginLeft: "5px" }} /></Tooltip>)}
                          <div>
                            {appContext.crmMode === "true" && (
                              <div>
                                {/* <Tooltip title="Details">
                                  <IconButton
                                    aria-label="Details"
                                    onClick={openContextRecordInCRM(
                                      selectedConv,
                                      appContext.userInfo.crmMemberFields,
                                      true
                                    )}
                                    edge="start"
                                    style={{
                                      marginLeft: "5px",
                                      paddingTop: "2px",
                                    }}
                                  >
                                    <DisplaySettingsIcon color="primary" />
                                  </IconButton>
                                </Tooltip> */}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{display: "flex",alignItems: "center"}}>
                    <div style={{
                       whiteSpace: "nowrap",
                       overflow: "hidden",
                       textOverflow: "ellipsis",}}>
                          <Tooltip title={selectedConv.customerPhoneNumber ? selectedConv.customerPhoneNumber :selectedConv.email}>
                      <span style={theme.customStyle.convHeaderCustPhone}>
                                              
                         {selectedConv.customerPhoneNumber ? selectedConv.customerPhoneNumber :selectedConv.email}
                      </span>
                      </Tooltip>
                    </div>
                    {enableSentimentCalculation==="Y"&& selectedConv?.isReadOnly===false && hasCustomerMsg && (selectConversation?.overallSentiment || selectConversation?.overallSentiment===0) &&
                     <SentimentEmoji overallSentiment={selectConversation?.overallSentiment} convDetails={selectConversation} isRefresh={true} isConvPage={true}/>
                    }
                    {enableSentimentCalculation==="Y"&& selectedConv?.isReadOnly===true && (selectedConv?.overallSentiment || selectedConv?.overallSentiment===0) &&
                     <SentimentEmoji overallSentiment={selectedConv?.overallSentiment}/>
                    }
                    </div>
                  </div>
                     <Box sx={{ marginLeft: "5px", display: "flex",flexWrap: "wrap",
                        justifyContent: "flex-end",alignItems:"center"}}>
                      {appContext.crmMode === "true" &&  linkedEnityName() &&  ( 
                      <Button
                        variant="contained"
                        style={{ ...theme.customStyle.endConvBtn, marginRight: "5px" }}
                          onClick={openContextRecordInCRMWrapper(
                          selectedConv,
                          appContext.userInfo.crmMemberFields,
                          true,
                          appContext.crmProvider
                        )}
                      > 
                             {linkedEnityName()} 
                      </Button>
                     )} 
                    {selectedConv?.isReadOnly === true ||
                      selectedConv?.agentId !== userInfo.userId ? (
                      <></>
                    ) : (
                      <LoadingButton
                        sx={{ ...theme.customStyle.endConvBtn,marginRight: dimensions<600?"5px":"0px" }}
                        loadingPosition="start"
                        // startIcon={<CloseIcon />}
                        variant="contained"
                        onClick={handleEndConversation(selectedConv)}
                      >
                      End
                      </LoadingButton>
                    )}
                    {selectedConv?.isConversationClosed === true && (
                      <LoadingButton
                        sx={{ ...theme.customStyle.endConvBtn,marginRight: dimensions<600?"5px":"0px" }}
                        loadingPosition="start"
                        // startIcon={<CloseIcon />}
                        variant="contained"
                        onClick={onCloseConv}
                      >
                      End
                      </LoadingButton>
                    )}
                  </Box>
                </div>
              </Box>
              <TabContext value={value} >
              <CustomTabList tabList={tabList} showTabsCount={dimensions < 600 && tabList.length > 3 ? 2 : 3} handleChangeTab={(event, selectedTabValue) => {
                  setValue(selectedTabValue)
                }}></CustomTabList>
                              <TabPanel value="1" sx={{ padding: "0px" }} onClick={handleinputMsgClick()}>
                  <Messenger
                     conversation={selectedConv}
                    onAssignConvSuccess={onAssignConvSuccess}
                    // onCloseConv={onCloseConv}
                     appContext={appContext}
                     context={"searchConv"}
                     chatLoading={convLoading}
                     enableVideoCall={enableVideoCall}
                     templatesData={templatesData}
                     msgAutoSuggestions={msgAutoSuggestions}
                     setMsgAutoSuggestions={setMsgAutoSuggestions}
                     isMobileView={dimensions<600}
                     theme={theme}
                  />
                </TabPanel>
                <TabPanel sx={{ padding: "0px" }} value="2">
                 {applicationSettings.ShowAllConversationHistory==="Y"? 
                  <ConversationHistory
                    conversationDetails={selectedConv}
                  />:
                  <ChatHistory
                    conversation={selectedConv?.customerPhoneNumber}
                    conversationId={selectedConv?.conversationId}
                    email={selectedConv?.email}
                  />}
                </TabPanel>
                <TabPanel sx={{ padding: "0px" }} value="3">
                  <ConversationContactTab
                                      convData={selectedConv}
                                      conversationAttributes={
                                          selectedConv?.conversationAttributes
                                      }
                                      crmMemberFields={appContext.userInfo.crmMemberFields}
                                      activityDueDataHours={activityDueDataHours}
                                      crmMode={appContext.crmMode}
                                      userInfo={appContext.userInfo}
                                      crmProvider={appContext.crmProvider}
                  />
                              </TabPanel>
              </TabContext>
            </>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </>
  );
}
