/*  
 =========================================
* 3/11/2022 Texting:712- Currected the spelling of refreshed
* 4/11/2022  Texting-749- Added TeamId as props for search conversion now it will only users of respected team
* 8/11/2022 Texting-744 -Admin will able to see the all users 
* 11/09/2022 => Texting-775 Added Conversations tab in Omni channel to load the active conversations for the selected team in filter. (added new component for conversation tab)
* 11/11/2022 => Texting-783 On load chip should show the team name which is used to load data.
* 15/11/2022 => Texting-802 Adde new State to for search button clicked
* 11/09/2022 => ONKAR => Texting-775 Added Conversations tab in Omni channel to load the active conversations for the selected team in filter. (added new component for conversation tab)
* 11/11/2022 => ONKAR => Texting-783 On load chip should show the team name which is used to load data.
* 11/15/2022 => ONKAR => Texting-783 Issue: When user in multi teams changing the preferred team data and chip showing is not matching -fixed.
* 12/15/2022 => Yogesh=> Texting-873 cheep name is not showing
* 12/01/2022 => ONKAR => Texting-875 Previously opened conversation chat is showing for few seconds when opening the conversation from Search conversation and omni channel fixed.
* 12/15/2022 => ONKAR => Texting-914 Admin UI is showing blank page on click of browser refresh button fixed.
* 05/08/2023 => SUDAM B CHAVAN => TEXTING-1250 - Authentication indicator should be added to conversation page in Omni channel and Search conversations
* 05-15-2023 => SUDAM B CHAVAN => TEXTING-1251 - Conversation attributes is missing in info of webchat conversation in Omnichannel page.
* 07-10-2023 SUDAM B CHAVAN  TEXTING-1417 - GPT Component Pending observations.
// 07-25-2023 => ONKAR => TEXTING-1444 - Omnichannel Conversations UI should show Sentiment on the card.
// 10-06-2023 => ONKAR => TEXTING-1780  - Sentiment Emoji showing up for first outbound message in search conversations.
// 10-12-2023 => ONKAR =>  TEXTING-1796 - Sentiment Re-Calculate button to calculate sentiment with latest messages.
// 10-16-2023 => ONKAR =>  TEXTING-1796 - Sentiment Re-Calculate button to calculate sentiment with latest messages.
// 10-16-2023 => ONKAR =>  TEXTING-1801 - Self-assigning the conversation from Omni channel agents view is not the showing the conversation on Admin UI active panel until page refresh.
// 11-30-2023 => ONKAR =>  TEXTING-1880 - Refreshing the omni channel page is not showing agents data.
 */
import React, { useContext, useState, useEffect } from "react";
import { Box, Tab, CircularProgress, IconButton, Chip } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { InvokePostServiceCall } from "../../api/serviceUtil";
import apiConfig from "../../api/apiConfig";
import { AuthContext } from "../../AuthContext";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { conversationsSelector, removeConversation, removeUnassignedConversation } from "../../storage/slices/messageSlice";
import SearchConvChat from "../../components/SearchConvChat";
import UsersTable from "../../components/UsersTable";
import TeamsTable from "../../components/TeamsTable";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import AgentsFilter from "../../components/AgentsFilter";
import RefreshIcon from '@mui/icons-material/Refresh';
import OmniChannelConversationsTable from "../../components/OmniChannelConversationsTable";
import CustomTabList from "../../components/CustomTabList";
import { updateSentimentInSearchAndChannel } from "../../api/messageServices";
function OmniChannnel() {
  const [showFilter, setShowFilter] = useState(false)
  const [keyDeleted, setKeyDeleted] = useState(false)
  const [teamChanged, setTeamChanged] = useState(false)
  const appContext = useContext(AuthContext);
  const [chatLoading, setChatLoading] = useState(false)
  const { messageApiToken, userInfo,enableSentimentCalculation } = useContext(AuthContext);
  const [value, setValue] = useState("1");
  const [originalUserList, setOriginalUsersList] = useState([])
  const [showConvDialog, setShowConvDialog] = useState(false);
  const [userList, setUserList] = useState([]);
  const [teams, setTeams] = useState([])
  const [selectedConversation, setSelectedConverstion] = useState("")
  const [messageList, setMessageList] = useState([])
  const [loading, setLoading] = useState(true)
  const [refreshTime, setRefreshTime] = useState(null)
  const theme = useTheme();
  const [filterData, setFilterData] = useState({ teamId: userInfo.teamId });
  const [filterTeamName, setFilterTeamName] = useState("")
  const dispatch = useDispatch();
  const [pageNo, setPageNO] = useState(0)
  const [seacrhClicked, setSeacrhClicked] = useState(false); { /* added new state for click on search */ }
  const style = {
    fontSize: "1rem",
  };
  const {
    unassignedConversations,
  } = useSelector(conversationsSelector);
  const {
    inactiveConversations,
    setInactiveConversations,
  } = useContext(AuthContext);
  const onAssignConvSuccess = (userId) => {
    setShowConvDialog(false)
    getUsers();
  };

  const flex = {
    display: "flex",
    alignItems: "center"
  }

  const getMesseges = (convDetails,tableContext="") => {
    setChatLoading(true)
    //added tableContext because we are getting field names in pascal case from userTable, to handle it we added tableContext.
    InvokePostServiceCall(apiConfig.GET_MESSAGES, { conversationId:tableContext==="usersTable"?convDetails.ConversationId:convDetails.conversationId || selectedConversation.ConversationId })
      .then((data) => {
        setChatLoading(false)
        let conversation = {};
        conversation.messageList = data.data.messagesInfo;
        conversation.refreshMessages = false;
        let overallSentimentVal=tableContext==="usersTable"?convDetails.OverallSentiment:convDetails.overallSentiment;
        if(!overallSentimentVal && enableSentimentCalculation==="Y"){
        updateSentimentInSearchAndChannel(convDetails,data.data.messagesInfo,setSelectedConverstion);
        }
        setMessageList(conversation);
      })
      .catch((error) => {
        console.log(error, "Error Message");
      });
  }
  useEffect(() => {
    if (keyDeleted) {
      setKeyDeleted(false)
      dataFilter(originalUserList)
    }
  }, [keyDeleted])


  useEffect(() => {
    if(userInfo.teams){
    setFilterData({...filterData,teamId:userInfo.teamId})
    getTeams();
    getUsers(userInfo.teamId);
    if (userInfo.teams.length > 0) { // Added team name in initial load
      userInfo.teams.forEach((item) => {
        if (item.teamId === userInfo.teamId) {
          setFilterTeamName(item.teamName)
        }
      })
    }
  }
  }, [userInfo.teams]);
  /*useEffect(() => {
    if (teamChanged) getUsers()
    else dataFilter(originalUserList)
  }, [value])*/

  const getUsers = async (value, isCallFrom) => {
    setUserList([]);
    setOriginalUsersList([])
    var currentdate = new Date();
    var datetime = "Last refreshed at: " + (currentdate.getMonth() + 1) + "/"
      + currentdate.getDate() + "/"
      + currentdate.getFullYear() + " "
      + currentdate.toLocaleTimeString([], { hour12: true })
    setRefreshTime(datetime)
    setLoading(true)
    await InvokePostServiceCall(apiConfig.GET_USERS_SUMMERY, { userId: userInfo.userId, teamid: value ? userInfo.teamId : filterData.teamId })
      .then((data) => {
        if (filterData && (filterData.name || filterData.status) && isCallFrom !=="clear") {
          dataFilter(data.data.userDetails.map(data => { return { ...data, colapse: false } }));
        } else
          setUserList(data.data.userDetails.map(data => { return { ...data, colapse: false } }))
        setOriginalUsersList(data.data.userDetails.map(data => { return { ...data, colapse: false } }));
        setLoading(false)
      })
      .catch((error) => {
        console.log(error, "Error Message");
      });
  };

  const getTeams = () => {
    InvokePostServiceCall(apiConfig.GET_USERS_SUMMARY_TEAMS, { userId: userInfo.userId })
      .then((data) => {
        setTeams(data.data.teamDashBoard)
      })
      .catch((error) => {
        console.log(error, "Error Message");
      });
  };




  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const clearFilter = () => {// on clear filter setting default team as users default team.
    setFilterData({ teamId: userInfo.teamId });
    getUsers("defaultTeam", "clear");
    teams.forEach((item) => {
      if (item.teamId === userInfo.teamId) {
        setFilterTeamName(item.teamName)
      }
    })
  }

  const handleSearch = async () => {
    setPageNO(0);
    setShowFilter(false)
    if (teamChanged) {
      setTeamChanged(false);
      await getUsers();
    } else
      dataFilter(originalUserList)
  }
  const dataFilter = (data) => {
    setPageNO(0);
    setSeacrhClicked(true)
    let list = data ? data : originalUserList
    if (filterData && (filterData.name || filterData.status)) {
      setUserList(list.filter(data => {
        return (
          (filterData.name && filterData.name.length > 0 ? data.user.UserName.toLowerCase().includes(filterData.name.toLowerCase()) : data)
          &&
          (filterData.status && filterData.status.length > 0 ? data.user.Status === filterData.status : data)
        )
      }))
    }
    else setUserList(list)
  }
  const handleDelete = (key) => {
    let data = { ...filterData }
    if (key === "teamId") { //on delete click set default team as users team and if team changed call api.
      data = { ...filterData, teamId: userInfo.teamId }
      teams.forEach(async (item) => {
        if (item.teamId === userInfo.teamId) {
          setFilterTeamName(item.teamName)
        }
        if (filterData.teamId !== userInfo.teamId) {
          await getUsers("defaultTeam");
        }
      })
    } else {
      delete data[key]
    }
    setKeyDeleted(true)
    setFilterData(data)
  }

  const handleCloseConvDialog = () => {
    if (selectedConversation) {
      //while closing the conversation dialog check if sentiment is refreshed or not and update the value in table.
      let userListDetails = [...userList];
      for (let i = 0; i < userListDetails.length; i++) {
        if (userListDetails[i].conversations.length > 0) {
          for (let j = 0; j < userListDetails[i].conversations.length; j++) {
            if ( //match the conversation id if selected conversation with convertsation list from table.
              userListDetails[i].conversations[j].ConversationId ===
                selectedConversation?.ConversationId &&
              userListDetails[i].conversations[j].OverallSentiment !==
                selectedConversation?.OverallSentiment
            ) {
              userListDetails[i].conversations[j].OverallSentiment = selectedConversation?.OverallSentiment; //update the overallSentiment with latest value.
              setUserList([...userListDetails]);
              break;
            }
          }
        }
      }
    }
    setShowConvDialog(false);
    setSelectedConverstion("")
    setMessageList([])
  };
  const tabList = [
    { label: "Agents", value: "1", displayOrder: 1 },
    { label: "Team Conversations", value: "3", displayOrder: 2 },
    { label: "Teams", value: "2", displayOrder: 3 },
  ]
  return (
    <>
      <div style={{ marginLeft: "20px", marginRight: "20px", }}>
        <div style={flex}>
          <h2>Omni-Channel
          </h2>
          <div style={flex}>
            {
              (value == "1" || value == "3")
              && (
                <IconButton
                  sx={{ marginLeft: "5px" }}
                  onClick={() => {
                    setShowFilter(!showFilter);
                  }}
                >
                  <FilterAltIcon />
                </IconButton>
              )

            }
            {
              value !== "2" && !showFilter && filterData && Object.keys(filterData).map((key) => {
                console.log("filterTeamName", filterTeamName)
                if (filterData[key]?.length != 0)
                  return (
                    key === "teamId" ?
                      <div style={{ marginLeft: "5px" }}>
                        {filterTeamName && <Chip label={"Team : " + filterTeamName} onDelete={() => handleDelete(key)} />}
                      </div>
                      :
                      value === "3" && key == "status" ? null :
                        <div style={{ marginLeft: "5px" }}>
                          <Chip label={key.charAt(0).toUpperCase() + key.slice(1) + " : " + filterData[key]} onDelete={() => handleDelete(key)} />
                        </div>
                  )
              })
            }
          </div>
          <IconButton
            onClick={() => {
              teams.forEach(async (item) => {
                if (item.teamId === filterData.teamId) {
                  setFilterTeamName(item.teamName)
                }
              })
              getUsers();
              getTeams();
            }}
          >
            <RefreshIcon />
          </IconButton>
          <span style={{ ...style }} >{refreshTime}</span>
        </div>


        <Box sx={{ width: "100%" }}>
          {(value == "1" || value == "3") && <AgentsFilter setSeacrhClicked={setSeacrhClicked} value={value} setTeamChanged={setTeamChanged} clearFilter={clearFilter} filterData={filterData} getUsers={getUsers} setFilterData={setFilterData} showFilter={showFilter} setShowFilter={setShowFilter} teams={userInfo.role == "Admin" ? userInfo.allTeams : userInfo.teams} handleSearch={handleSearch} setFilterTeamName={setFilterTeamName} />}  {/* If user is admin then drop down shows all teams */}
          {loading ?
            <Box style={theme.customStyle.loaderStyle} >
              <CircularProgress />
            </Box>
            :
            <>
              <TabContext value={value}>
                <CustomTabList tabList={tabList} showTabsCount={3} handleChangeTab={(event, selectedTabValue) => {
                  setValue(selectedTabValue)
                }}></CustomTabList>
                <TabPanel value="1">
                  <UsersTable enableSentimentCalculation={enableSentimentCalculation} setPageNO={setPageNO} pageNo={pageNo} getMesseges={getMesseges} userList={userList} setUserList={setUserList} setSelectedConverstion={setSelectedConverstion} setShowConvDialog={setShowConvDialog} loading={loading} />
                </TabPanel>
                <TabPanel value="2">
                  <TeamsTable teams={teams} />
                </TabPanel>
                <TabPanel value="3">
                  <OmniChannelConversationsTable
                    getMesseges={getMesseges}
                    filterData={filterData}
                    chatLoading={chatLoading}
                    messageList={messageList}
                    onAssignConvSuccess={onAssignConvSuccess}
                    seacrhClicked={seacrhClicked}
                    setSeacrhClicked={setSeacrhClicked}
                    setPageNO={setPageNO}
                    keyDeleted={keyDeleted}
                    pageNo={pageNo}
                  />
                </TabPanel>
              </TabContext>
            </>
          }

        </Box>
      </div>
      <SearchConvChat
        chatLoading={chatLoading}
        msgList={messageList}
        selectedConv={selectedConversation && selectedConversation.ConversationId}
        showConvDialog={showConvDialog}
        setShowConvDialog={setShowConvDialog}
        convDetails={{
          isReadOnly: true,
          teamId: selectedConversation && selectedConversation.TeamId,
          conversationId: selectedConversation && selectedConversation.ConversationId,
          customerName: selectedConversation && selectedConversation.CustomerName,
          customerPhoneNumber: selectedConversation && selectedConversation.CustomerPhoneNumber,
          createdDate: selectedConversation && selectedConversation.CreatedDate,
          createdBy: selectedConversation && selectedConversation.CreatedBy,
          source: selectedConversation && selectedConversation.Source,
          agentName: selectedConversation && selectedConversation.AgentName,
          teamName: selectedConversation && selectedConversation.TeamName,
          userPhoneNumber: selectedConversation && selectedConversation.UserPhoneNumber,
          agentId: selectedConversation && selectedConversation.AgentId,
          teamType: selectedConversation && selectedConversation.TeamType,
          channel: selectedConversation && selectedConversation.Channel,
          authenticationStatus: selectedConversation && selectedConversation.AuthenticationStatus,
          conversationAttributes: selectedConversation && selectedConversation.ConversationAttributes,
          email: selectedConversation && selectedConversation.Email,
          overallSentiment:selectedConversation?.OverallSentiment
        }}
        appContext={appContext}
        onAssignConvSuccess={onAssignConvSuccess}
        handleClose={handleCloseConvDialog}
        selectedConvForSentiment={selectedConversation}
        setConvDetails={setSelectedConverstion}
      />
    </>
  );
}

export default OmniChannnel;
