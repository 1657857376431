
/********************************************************************************************************************************************
Name: CIFUTIL
=============================================================================================================================================
Purpose:
Contains all the CIF methods to connect with D:
History:
VERSION     AUTHOR              DATE            DETAILS
1.0         Rajesh              12/01/2021      Initial creation of CIF component
1.1         Rajesh              11/08/2022      Updated CIF to start conversation from the member profile
////11-01-2022 => Texting-734 IB/OB, Team Name changes  
////11-01-2022 => Texting-734 IB/OB, Team Name changes
////11-03-2022 => Texting-734 IB/OB, Team Name changes reopen bugs fixed.
////11-04-2022 => Texting-734 IB/OB, Team Name changes reopen bugs fixed (changed values to lowercase for comparing).*/
////11-28-2022 => Texting-847 Ability to take over existing conversation assigned to someone else by agent.
////12-01-2022 => ONKAR  => Texting-874 Assign to me option is showing for missed conversations, nonwhite list alert, Consent unavailable alert fixed.
////12/09/2022 => ONKAR => Texting-913 Prompt the Templates to the User before start the conversation
////25/01/2022 => RAJESH => FIxed the SF linked entities names issue
////06/14/2023 => ONKAR => Texting-1339 Inbound Conversation from Unassigned - Queue.
////06/14/2023 => ONKAR => Texting-1347 Red is not disappearing after viewing the customer’s message on the conversation initiated from team that is not opted for start conv with templates and on conv from individual Team.
////06/15/2023 => ONKAR => undo Texting-1347 changes.
////06-23-2023 => ONKAR => Texting-1364 Team is changing to users default team when member assigns unassigned conversation to himself.
////07-18-2023 => SUDAM B CHAVAN => TEXTING-1436 - Team Level Attributes to use in tokens



import { InvokePostServiceCall } from "../api/serviceUtil";
import apiConfig from "../api/apiConfig";
import { dataService } from "../dataService";
import moment from "moment";

export async function searchAndScreenPop(mobileNo) {
    if (window.sforce != null) {

        window.sforce.opencti.searchAndScreenPop({
            searchParams: mobileNo, callType: window.sforce.opencti.CALL_TYPE.INBOUND , deferred: false, callback: function (result) {
                if (result.success) {
                    console.log(result.returnValue);
                } else {
                    console.log(result.errors);
                }
            } });

    } 
}


export async function searchOppandFinAccountSF(recordId) {
    var searchResults = new Object();
    searchResults.finAccountResults = [];
    searchResults.oppResults = [];
    return searchResults;

}

var isClicked;
export async function startConversationSF(data, azureID, crmMemberFields, teamId) {

    console.log("startCustomConversation-cifutil-start - IN SF" + data);

    console.log("startCustomConversation- cifutil - executing" + data);
         var contactFields = ["createddate","email","name","MailingState","csg__TimeZone__c"];

        let ObjData = JSON.parse(data);
        var recordId = ObjData.recordId;

        crmMemberFields.forEach((val, key) => {
        if (val.includes(".")) {
            if (val.split(".")[0].toLowerCase() == 'scontact' && val.split(".")[1].toLowerCase() != 'name' && val.split(".")[1].toLowerCase() != 'email') {
                contactFields.push(val.split(".")[1].toLowerCase());
            }
        }
        });
         var contactFieldsString =  contactFields.join(',');
        //Invokes API method
        var getContactParam = 'conId=' + recordId + '&fieldNames=' + contactFieldsString;
        window.sforce.opencti.runApex({
            apexClass: "TextingUtil",
            methodName: "getContact",
            methodParams: getContactParam,
            callback: function (response) {
                if (response.success == true && response.returnValue.runApex!='') {
                try {
                var conResponse = JSON.parse(response.returnValue.runApex);
                var convAttributes = {};
               

                var reqDataObj = {};
                reqDataObj.conversation = {};

                    crmMemberFields.forEach((val, key) => {
                        if (!convAttributes.hasOwnProperty(key)) {
                            if (val.includes(".")) {
                                if (val.split(".")[0].toLowerCase() == 'scontact') {
                                    convAttributes[key] = conResponse[val.split(".")[1]];
                                }
                            }

                        }
                    });
                reqDataObj.conversation.source = 'CRM';
                reqDataObj.conversation.agentId = azureID;
                reqDataObj.conversation.customerPhoneNumber = ObjData.number;
                reqDataObj.conversation.externalId = recordId;
                reqDataObj.conversation.externalType = "Contact"
                reqDataObj.contact = {};
                var customerName = ObjData.recordName.split(" ");
                if (customerName.length > 1) {
                    reqDataObj.contact.firstName = customerName[0];
                    reqDataObj.contact.lastName = customerName[1];
                }
                else {
                    reqDataObj.contact.firstName = ObjData.recordName;
                }
                reqDataObj.contact.stateCode = conResponse.MailingState;
                reqDataObj.contact.timezone = conResponse.csg__TimeZone__c;

                reqDataObj.contact.email = conResponse.Email;
                reqDataObj.contact.contactAttributes = "{ \"ExternalId\": \"" + recordId + "\",\"ContactId\": \"" + recordId + "\"}";
                convAttributes.wcu_externalid = recordId;
                convAttributes.ActivityId = "";
                convAttributes.EntityLogicalName = "contact";
                convAttributes.EntityKeyfield = "id";
                convAttributes.EntityId = recordId;
                convAttributes.PhoneNumber = ObjData.number;
                convAttributes.parentcontactid = ObjData.recordId;
                reqDataObj.teamId = teamId;
                
                reqDataObj.conversation.ConversationAttributes = JSON.stringify(convAttributes);
                isClicked = null;
                var reqData = JSON.stringify(reqDataObj);
                let url = apiConfig.START_CONVERSATION;
                InvokePostServiceCall(url, reqData)
                    .then((resp) => {
                        var response = resp.data;
                        if (response != null && response.status === "SUCCESS") {
                            console.log("Conversation Created succesfully" + JSON.stringify(response));
                            dataService.setData({ showAlertMessage: response.status });
                        }
                        else if (response != null && response.status === 'FAILED' && response.errorDescription != null) {
                            if (response.isActiveinCommList == true || response.errorDescription.includes("Customer is already in active conversation")||response.errorDescription.includes("Conversation is in unassigned queue")) {
                                var selfAssignConvObj = new Object();
                                selfAssignConvObj.conversationId = response.conversationId;
                                selfAssignConvObj.alertText = response.errorDescription;
                                selfAssignConvObj.teamId = response.teamId;
                                dataService.setData({ showSelfAssignAlert: selfAssignConvObj });
                            }
                            else {
                                dataService.setData({ showAlertMessage: response.errorDescription });
                            }
                        }
                        else {
                            dataService.setData({ showAlertMessage: "" });
                        }

                    })
                    .catch((err) => {
                        console.log(err, "Error Message");
                        dataService.setData({ showAlertMessage: "" });
                    });
            } catch (err) {
                console.log(err, "Error Message - Custom");
                dataService.setData({ showAlertMessage: "Something went wrong with member data." });
                isClicked = null;
            }

            } else {
            }
            }
        });


       

}

export async function getConvAttributesSF(data, azureID, crmMemberFields, teamId) {


    return new Promise((resolve) => {

        console.log("startCustomConversation-cifutil-start - IN SF" + data);

        console.log("startCustomConversation- cifutil - executing" + data);
        var contactFields = ["createddate","name","email","MailingState","csg__TimeZone__c"];

        let ObjData = JSON.parse(data);
        var memberProfile = null;
        var recordId = ObjData.recordId;

        crmMemberFields.forEach((val, key) => {
            if (val.includes(".")) {
                if (val.split(".")[0].toLowerCase() == 'scontact' && val.split(".")[1].toLowerCase() != 'name' && val.split(".")[1].toLowerCase() != 'email') {
                    contactFields.push(val.split(".")[1].toLowerCase());
                }
            }
        });
        var contactFieldsString = contactFields.join(',');
        //Invokes API method
        var getContactParam = 'conId=' + recordId + '&fieldNames=' + contactFieldsString;
        window.sforce.opencti.runApex({
            apexClass: "TextingUtil",
            methodName: "getContact",
            methodParams: getContactParam,
            callback: function (response) {
                if (response.success == true && response.returnValue.runApex != '') {
                    try {
                        var conResponse = JSON.parse(response.returnValue.runApex);
                        var convAttributes = {};


                        var reqDataObj = {};
                        reqDataObj.conversation = {};

                        crmMemberFields.forEach((val, key) => {
                            if (!convAttributes.hasOwnProperty(key)) {
                                if (val.includes(".")) {
                                    if (val.split(".")[0].toLowerCase() == 'scontact') {
                                        convAttributes[key] = conResponse[val.split(".")[1]];
                                    }
                                }

                            }
                        });
                        reqDataObj.conversation.source = 'CRM';
                        reqDataObj.conversation.agentId = azureID;
                        reqDataObj.conversation.customerPhoneNumber = ObjData.number;
                        reqDataObj.conversation.externalId = recordId;
                        reqDataObj.conversation.externalType = "Contact"
                        reqDataObj.contact = {};
                        var customerName = ObjData.recordName.split(" ");
                        if (customerName.length > 1) {
                            reqDataObj.contact.firstName = customerName[0];
                            reqDataObj.contact.lastName = customerName[1];
                        }
                        else {
                            reqDataObj.contact.firstName = ObjData.recordName;
                        }
                        reqDataObj.contact.stateCode = conResponse.MailingState;
                        reqDataObj.contact.timezone = conResponse.csg__TimeZone__c;
                        reqDataObj.contact.email = conResponse.Email;
                        reqDataObj.contact.contactAttributes = "{ \"ExternalId\": \"" + recordId + "\",\"ContactId\": \"" + recordId + "\"}";
                        convAttributes.wcu_externalid = recordId;
                        convAttributes.ActivityId = "";
                        convAttributes.EntityLogicalName = "contact";
                        convAttributes.EntityKeyfield = "id";
                        convAttributes.EntityId = recordId;
                        convAttributes.PhoneNumber = ObjData.number;
                        convAttributes.parentcontactid = ObjData.recordId;
                        reqDataObj.teamId = teamId;

                        reqDataObj.conversation.ConversationAttributes = JSON.stringify(convAttributes);
                        resolve(reqDataObj);
                    } catch (err) {
                        console.log(err, "Error Message - Custom");
                        dataService.setData({ showAlertMessage: "Something went wrong with member data." });
                        isClicked = null;
                    }

                } else {
                }
            }
        });
    })

}

export async function startConvWithTemplatesSF(reqDataObj) {
    var reqData = JSON.stringify(reqDataObj);
    let url = apiConfig.START_CONVERSATION;
    InvokePostServiceCall(url, reqData)
        .then((resp) => {
            var response = resp.data;
            if (response != null && response.status === "SUCCESS") {
                console.log("Conversation Created succesfully" + JSON.stringify(response));
                dataService.setData({ showAlertMessage: response.status });
            }
            else if (response != null && response.status === 'FAILED' && response.errorDescription != null) {
                if (response.isActiveinCommList == true || response.errorDescription.includes("Customer is already in active conversation")||response.errorDescription.includes("Conversation is in unassigned queue")) {
                    var selfAssignConvObj = new Object();
                    selfAssignConvObj.conversationId = response.conversationId;
                    selfAssignConvObj.alertText = response.errorDescription;
                    selfAssignConvObj.teamId = response.teamId;
                    dataService.setData({ showSelfAssignAlert: selfAssignConvObj });
                }
                else {
                    dataService.setData({ showAlertMessage: response.errorDescription });
                }
            }
            else {
                dataService.setData({ showAlertMessage: "" });
            }

        })
        .catch((err) => {
            console.log(err, "Error Message");
            dataService.setData({ showAlertMessage: "" });
        });

}

export async function searchContactandProspectsSF(searchText) {

    return new Promise((resolve) => {
        if (searchText != null && searchText != '' && searchText != ' ') {
            try {
                var searchItem = searchText;
                if (searchItem && searchItem.indexOf('1') === 0) {
                    searchItem = searchItem.substring(1);
                }
                var searchResults = new Object();
                var parms = 'searchText=' + searchItem;

                searchResults.contactResult = [];
                searchResults.prospectResult = [];
                window.sforce.opencti.runApex({
                    apexClass: "TextingUtil",
                    methodName: "searchContacts",
                    methodParams: parms,
                    callback: function (response) {
                        if (response.success == true && response.returnValue.runApex != '') {
                            var searchResponse = JSON.parse(response.returnValue.runApex);
                            searchResponse.forEach((val, key) => {
                                var con = new Object();
                                con.fullname = val.Name;
                                con.contactid = val.Id;
                                con.mobilephone = val.Phone;
                                con.telephone2 = val.MobilePhone;
                                con.telephone1 = val.OtherPhone;
                                searchResults.contactResult.push(con);

                            });
                           
                        }
                        resolve(searchResults);

                    }
                });


            }
            catch (err) {
                console.log(err, "Error Message - Custom");
            }
        }
    });


        /*  var formattedMobileNumber = '';
         var mobile = searchText.replaceAll(/[^a-zA-Z0-9]/g, '');
          if (mobile && mobile.indexOf('1') === 0) {
              mobile = mobile.substring(1);
          }
          formattedMobileNumber = '%25' + mobile.replace(/.{1}/g, '$&%25');
          var searchResults = new Object();
          let contactFilter = "?$select=mobilephone,wcu_emailaddress1,telephone1,
          telephone2,contactid,firstname,lastname, fullname, wcu_mobilephone &$filter=contains( mobilephone,'" + formattedMobileNumber + "') or contains( telephone1,'" + formattedMobileNumber + "') or contains( telephone2,'" + formattedMobileNumber + "') or  wcu_emailaddress1 eq '" + searchText + "' or wcu_telephone1 eq '" + searchText + "' or  firstname eq '" + searchText + "' or lastname eq '" + searchText + "' or fullname eq '" + searchText + "'";
          searchResults.contactResult = await window.Microsoft.CIFramework.searchAndOpenRecords("contact", contactFilter, true);
          let prospectFilter = "?$select=emailaddress1,leadid,firstname,lastname,fullname,telephone1,telephone2,telephone3,mobilephone &$filter=contains( mobilephone,'" + formattedMobileNumber + "') or contains( telephone1,'" + formattedMobileNumber + "') or contains( telephone2,'" + formattedMobileNumber + "') or  firstname eq '" + searchText + "' or lastname eq '" + searchText + "' or fullname eq '" + searchText + "'";
          searchResults.prospectResult = await window.Microsoft.CIFramework.searchAndOpenRecords("lead", prospectFilter, true); */


    

}







export async function updateActivitySF(payload) {
    try {
        var data = {};
        var partyList = [];
        var jsonData = '';
        var convObj = JSON.parse(payload.conv.conversationAttributes);
        var params = 'contactId=' + payload.entityId + '&activityId=' + convObj.ActivityId;
        convObj.EntityId = payload.entityId;
        convObj.EntityLogicalName = 'contact';
        var conversation = Object.assign({}, payload.conv);
        conversation.conversationAttributes = JSON.stringify(convObj);
        //dataService.setData({ updateConversation: conversation });
        return new Promise((resolve) => {
            window.sforce.opencti.runApex({
                apexClass: "TextingUtil",
                methodName: "updateTaskWithActivity",
                methodParams: params,
                callback: function (response) {
                    if (response.success == true && response.returnValue.runApex != '') {
                       
                        openRecordinCRMSF(conversation, payload.crmMemberFields, false);
                        resolve("");
                    } else {
                    }
                }
            });
        });


       
       
    }
    catch (exce) {
        console.log("exception" + exce);
    }

}
export async function closeActivityinCRMSF(conv) {
    try {
        if (conv.conversationAttributes != null && conv.conversationAttributes !== '') {
            var convObj = JSON.parse(conv.conversationAttributes);
            if (convObj.ActivityId != null && convObj.ActivityId !== '') {
                window.sforce.opencti.saveLog({
                    value: {
                        entityApiName: "task",
                        Id: convObj.ActivityId,
                        Status : 'completed'
                    },
                    callback: function (response) {
                        if (response.success == true && response.returnValue != null) {
                            console.log("Update succesful");
                        }
                    }
                });
            }
        }
    }
    catch (err) {
        console.log(err, "Error Message");
    }

}


export async function openCifWindow() {
    if (window.Microsoft != null && window.Microsoft.CIFramework != null) {
        window.Microsoft.CIFramework.setMode(1);
    }
}

export async function createOrSearchTaskinSF(conv, crmMemberFields, createTask, activityDueDataHours, context, userTeams) {
    try {
        debugger;
        console.log("createOrSearchTaskinCRMD-context" + context);
        console.log("createOrSearchTaskinCRMD-createTask" + createTask);

        if (window.sforce != null) {
         
                console.log("createOrSearchTaskinCRMD-Inside Create Task");
                var data = {};
                var reqDataObj = {};
                var convObj = {};
                var searchActivityinCRM = false;
            console.log("createOrSearchTaskinCRMD-CIF");
            if (conv.conversationAttributes != null && conv.conversationAttributes !== '' && conv.conversationAttributes != undefined) {
                convObj = JSON.parse(conv.conversationAttributes);
            }
           
                var convAttributes = {};
                convAttributes.conversationId = conv.conversationId;
                convAttributes.userId = conv.agentId != null ? conv.agentId.toLowerCase() : "";
                convAttributes.agentName = conv.agentName;
                convAttributes.wcu_firstname = conv.wcu_firstname;
                convAttributes.EntityLogicalName = 'Task';
               
                
            
                var result = "";
                var activityId = "";
            try {
                let taskname = 'Conversation with ' + conv.customerName;
                var teamAttributesobj = userTeams.filter(t => t.teamId === conv.teamId)?.[0]?.teamAttributes;
                var crmTeamId = teamAttributesobj ? JSON.parse(teamAttributesobj)?.CRMTeamId : null;
                var tskPayLoad = new Object();

                var conResult = {};

                if (!!conv.authId) {

                  conResult =   await getContactsByEXTID(conv.authId);
                }

                tskPayLoad = {
                    entityApiName: "task",
                    Subject: taskname,
                    wcu_externalsystem__c: "Texting",
                    wcu_externalid__c: conv.conversationId,
                    wcu_teamname__c: String(crmTeamId),
                    wcu_to__c: conv.customerPhoneNumber,
                    wcu_messagetype__c: conv.source.toLowerCase(),
                    
                    status: 'In Progress'
                }

                if (!!conResult) {
                    debugger;
                    tskPayLoad.WhoId = conResult.Id;
                    window.sforce.opencti.screenPop({
                        type: window.sforce.opencti.SCREENPOP_TYPE.SOBJECT, //Review the arguments section.
                        params: { recordId: conResult.Id } //Depends on the SCREENPOP_TYPE. Review the arguments section.
                    })
                }
                if (conv.source.toLowerCase() === "inbound" && conv.channel == 'SMS') {
                    console.log("createOrSearchTaskinCRM-source Inbound");
                    var mobile = conv.customerPhoneNumber.replaceAll(" ", "").slice(-10);
                    // Serach for the existing contact in crm
                    if (!tskPayLoad.WhoId) {
                        searchAndScreenPop(mobile);
                    }
                    convObj.EntityId = "";
                    convObj.EntityLogicalName = 'Task';
                }
                else if (conv.channel == 'Chat') {
                    convObj.EntityId = "";
                    convObj.EntityLogicalName = 'Task';   
                    if (!tskPayLoad.WhoId) {
                        searchAndScreenPop(conv.email);
                    }
                    else {
                        convObj.EntityId = tskPayLoad.WhoId;
                        convObj.EntityLogicalName = 'contact';
                    }                   
                    
                }            


                return new Promise((resolve) => {

                    window.sforce.opencti.saveLog({
                        value: tskPayLoad,
                        callback: function (response) {
                            if (response.success == true && response.returnValue != null) {


                                activityId = response.returnValue.recordId;

                                convObj.ActivityId = activityId;
                             
                                
                                reqDataObj.conversation = {};
                                reqDataObj.conversation.conversationId = conv.conversationId;
                                reqDataObj.conversation.ConversationAttributes = JSON.stringify(convObj);
                                var conversation = Object.assign({}, conv);
                                conversation.conversationAttributes = reqDataObj.conversation.ConversationAttributes;
                                dataService.setData({ updateConversation: conversation });
                                resolve(conversation);
                                console.log("createOrSearchTaskinCRMD-updateConversation" + result);
                                updateConversation(reqDataObj); //DB
                            }
                            else {
                                resolve("");
                            }
                        }
                    });
                });
            }
                catch (err) {
                   
                }
                finally {
                    console.log("createOrSearchTaskinCRMD-Create SMS Task " + result);

                   
                }
            }
        }
    
    catch (err) {
        console.log(err, "Error Message");
    }


}

export function updateConversation(reqDataObj) {
    let url = apiConfig.UPDATE_CONVERSATION;
    InvokePostServiceCall(url, JSON.stringify(reqDataObj))
        .then((resp) => {
            console.log("Conversation updated");
        })
        .catch((err) => {
            console.log(err, "Error Message");
        });

}


export async function getContactsByEXTID(authId) {
    debugger;
    var parms = 'authId=' + authId;
    return new Promise((resolve) => {
        window.sforce.opencti.runApex({
            apexClass: "TextingUtil",
            methodName: "getContactByExternalId",
            methodParams: parms,
            callback: function (response) {
                if (response.success == true && response.returnValue.runApex != '') {
                    var conRes = JSON.parse(response.returnValue.runApex);                    
                    resolve(conRes);
                } else {
                    resolve("");
                }
            }
        });
    });


}

export async function getLinkedEntitiesSF(activityId) {
    var linkedRecords = {};
    linkedRecords.isRecordAvailable = false;
    var parms = 'activityId=' + activityId;


    return new Promise((resolve) => {
        window.sforce.opencti.runApex({
            apexClass: "TextingUtil",
            methodName: "getTask",
            methodParams: parms,
            callback: function (response) {
                if (response.success == true && response.returnValue.runApex != '') {
                    var tskRespone = JSON.parse(response.returnValue.runApex);
                    var con = new Object();
                    var contacts = [];
                    if (tskRespone.WhoId != undefined) {
                        con.fullname = tskRespone.Who.Name;
                        con.contactid = tskRespone.WhoId
                        contacts.push(con);
                        linkedRecords.contact = JSON.stringify(contacts);
                        linkedRecords.isRecordAvailable = true;
                    }
                    resolve(linkedRecords);
                } else {
                    resolve("");
                }
            }
        });
    });

   

    /*var filter = '?$select= participationtypemask,_partyid_value,_activityid_value &$orderby= participationtypemask asc &$filter= (participationtypemask eq 2 or participationtypemask eq 8) and  _activityid_value  eq ' + activityId + ' ';
    var result = await window.Microsoft.CIFramework.searchAndOpenRecords("activityparty", filter, true);  // Retrieve activity party records which are "To" and "Regarding"
    var activities = JSON.parse(result);
    for (var i = 0; i < Object.keys(activities).length; i++) {
        if (activities[i]["_partyid_value@Microsoft.D.CRM.lookuplogicalname"] == "contact") {
            let contactFilter = "?$select=wcu_emailaddress1,wcu_telephone1,wcu_telephone2,contactid,firstname,lastname, fullname, wcu_mobilephone &$filter= contactid eq " + activities[i]["_partyid_value"] + "";
            linkedRecords.contact = await window.Microsoft.CIFramework.searchAndOpenRecords("contact", contactFilter, true);
            linkedRecords.isRecordAvailable = true;
        }
        if (activities[i]["_partyid_value@Microsoft.D.CRM.lookuplogicalname"] == "lead") {
            let prospectFilter = "?$select=emailaddress1,leadid,firstname,lastname,fullname,telephone1,telephone2,telephone3 &$filter=leadid  eq " + activities[i]["_partyid_value"] + "";
            linkedRecords.lead = await window.Microsoft.CIFramework.searchAndOpenRecords("lead", prospectFilter, true);
            linkedRecords.isRecordAvailable = true;
        }
        if (activities[i]["_partyid_value@Microsoft.D.CRM.lookuplogicalname"] == "opportunity") {
            let oppFilter = "?$select=name,wcu_externalid,wcu_productname,opportunityid &$filter=opportunityid eq " + activities[i]["_partyid_value"] + "";
            linkedRecords.opportunity = await window.Microsoft.CIFramework.searchAndOpenRecords("opportunity", oppFilter, true);
            linkedRecords.isRecordAvailable = true;
        }
        if (activities[i]["_partyid_value@Microsoft.D.CRM.lookuplogicalname"] == "wcu_finaccount") {
            var properties = ["wcu_finproducttype", "wcu_finproductid", "wcu_accountid", "wcu_name"].join();
            let finAccountFilter = "?$select=" + properties + " &$filter=wcu_finaccountid eq " + activities[i]["_partyid_value"] + "";
            linkedRecords.finaccount = await window.Microsoft.CIFramework.searchAndOpenRecords("wcu_finaccount", finAccountFilter, true);
            linkedRecords.isRecordAvailable = true;
        }

    } */

  


}


export async function getLinkedEntityNamesSF(activityId) {
 
    var linkedEntitiesNames = [];
    linkedEntitiesNames.push("scontact");
    linkedEntitiesNames.push("users");
    linkedEntitiesNames.push("userattributes");
    linkedEntitiesNames.push("teamattributes");
    return linkedEntitiesNames;
}



export async function openRecordinCRMSF(conv, crmMemberFields, openRecord) {
    try {

        

        console.log("openRecordinCRM-Started");
        let filter = '';

        if (conv.conversationAttributes != null && conv.conversationAttributes !== '') {
            var convObj = JSON.parse(conv.conversationAttributes);
            var entityKeyField = convObj.EntityKeyfield;
            var entityId = convObj.EntityId;
            if (entityId === null || entityId == undefined || entityId == "") {
                entityId = convObj.ActivityId;
            }
            var entityLogicalName = "contact";
            if (openRecord == true) {
                window.sforce.opencti.screenPop({
                    type: window.sforce.opencti.SCREENPOP_TYPE.SOBJECT, //Review the arguments section.
                    params: { recordId: entityId } //Depends on the SCREENPOP_TYPE. Review the arguments section.
                });
            }
            updateTokenswithCRMdata(conv, crmMemberFields);
            
        }
        console.log("openRecordinCRM-Ended");
    }
    catch (err) {
        console.log(err, "Error Message");
    }
}


export async function openEnityinCRMSF(entityId, entityLogicalName, entityKeyField){
    window.sforce.opencti.screenPop({
        type: window.sforce.opencti.SCREENPOP_TYPE.SOBJECT, //Review the arguments section.
        params: { recordId: entityId } //Depends on the SCREENPOP_TYPE. Review the arguments section.
    });
}





export const openContextRecordInCRMSF = (conv, crmMemberFields, entity) => (event) => {
    openRecordinCRMSF(conv, crmMemberFields, entity);
}

export async function updateTokenswithCRMdata(conv, crmMemberFields) {
    try {
        let conversation = {};
        let convAttributes = {};
        let contact = new Object();
       
        var contactFields = ['createddate','name','email',"MailingState","csg__TimeZone__c"];
        conversation = Object.assign({}, conv);
        convAttributes = JSON.parse(conversation.conversationAttributes);
        crmMemberFields.forEach((val, key) => {
            if (val.includes(".")) {
                if (val.split(".")[0].toLowerCase() == 'scontact' && val.split(".")[1].toLowerCase() != 'name' && val.split(".")[1].toLowerCase() != 'email') {
                    contactFields.push(val.split(".")[1].toLowerCase());
                }               
            }
        });

        var contactFieldsString = contactFields.join(',');
        //Invokes API method
        var getContactParam = 'conId=' + convAttributes.EntityId + '&fieldNames=' + contactFieldsString;
        window.sforce.opencti.runApex({
            apexClass: "TextingUtil",
            methodName: "getContact",
            methodParams: getContactParam,
            callback: function (response) {
                if (response.success == true && response.returnValue.runApex != '') {
                    try {
                        var conResponse = JSON.parse(response.returnValue.runApex);


                        var reqDataObj = {};
                        reqDataObj.conversation = {};

                        crmMemberFields.forEach((val, key) => {
                            if (!convAttributes.hasOwnProperty(key)) {
                                if (val.includes(".")) {
                                    if (val.split(".")[0].toLowerCase() == 'scontact') {
                                        convAttributes[key] = conResponse[val.split(".")[1]];
                                    }
                                }

                            }
                        });
                        convAttributes.EntityLogicalName = 'contact';
                        conversation.requiredConvAttrUpdate = false;
                        conversation.conversationAttributes = JSON.stringify(convAttributes);
                        conversation.linkedEntities = "";
                        conversation.customerName = conResponse.Name ? conResponse.Name : conversation.customerName;

                        dataService.setData({ updateContextConv: conversation });           // Updates the conversation customerName on the client side.
                        var reqDataObj = {};
                        reqDataObj.conversation = conversation;
                        reqDataObj.contact = {};
                        reqDataObj.contact.email = conResponse.Email;
                        reqDataObj.contact.stateCode = conResponse.MailingState;
                        reqDataObj.contact.timezone = conResponse.csg__TimeZone__c;
                        var customerName = conversation.customerName.split(" ");
                        if (customerName.length > 1) {
                            reqDataObj.contact.firstName = customerName[0];
                            reqDataObj.contact.lastName = customerName[1];
                        }
                        else {
                            reqDataObj.contact.firstName = conversation.customerName;
                        }
                        updateConversation(reqDataObj);
                    }
                    catch (Ex) {

                    }
                }
            }
        });
       
    }
    catch (exception) {
        console.log("exception" + exception)
    }
    // }
}
