/***************
* 11-27-2023 => SUDAM B CHAVAN => TEXTING-1872 Chat Survey Questions Configuration - Admin UI
*12-04-2023 => SUDAM B CHAVAN =>TEXTING-1896 Survey: Member UI, Admin UI pending observations.
*12-05-2023 => SUDAM B CHAVAN =>TEXTING-1891- Survey : Update Questions Admin UI to add options and save , separated in backend
***********/
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import apiConfig from "../../api/apiConfig";
import { useLocation, useNavigate } from "react-router-dom";
import { InvokePostServiceCall } from "../../api/serviceUtil";
import SnackBar from "../../components/SnackBar";
import LoadingButton from "@mui/lab/LoadingButton";
import {
    TextField,
    Button,
    Checkbox,
    MenuItem

} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';


export default function AddUpdateSurveyQuestion() {
    const AddSurveyQuestion = "Add Survey Question";
    const UpdateSurveyQuestion = "Update Survey Question";
    const location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();
    const isAddSurveyQuestion = location.state ? location.state.isAddSurveyQuestion : true;
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [severity, setSeverity] = useState("success");
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [options, setOptions] = useState(isAddSurveyQuestion ? [""] : location.state.surveyQuestion?.options?.split("##SEP##"));
    const [surveyQuestionData, setSurveyQuestionData] = useState({
        surveyCategory: null,
        questionBody: null,
        questionName: null,
        surveyQuestionId: null,
        answerRequired: true,
        questionType: null,
    });
    let lastModifiedByName = isAddSurveyQuestion ? "" : location.state.surveyQuestion?.lastModifiedByName
    let lastModifiedDate = moment(isAddSurveyQuestion ? "" : location.state.surveyQuestion?.lastModifiedDate).local().format("LLL")
    let createdByName = isAddSurveyQuestion ? "" : location.state.surveyQuestion?.createdByName
    let createdDate = moment(isAddSurveyQuestion ? "" : location.state.surveyQuestion?.createdDate).local().format("LLL")
    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        if (severity === "success") {
            navigate("/surveyQuestions", { replace: true });
        }
        setOpenSnackbar(false);
    };

    useEffect(() => {
        if (!isAddSurveyQuestion) {
            let surveyQuestion = location.state.surveyQuestion;
            debugger
            setSurveyQuestionData({
                surveyQuestionId: surveyQuestion.surveyQuestionId,
                questionName: surveyQuestion.questionName,
                questionBody: surveyQuestion.questionBody,
                answerRequired: surveyQuestion.answerRequired ?? false,
                questionType: surveyQuestion.questionType,
                options: surveyQuestion.options,
            });
        }
    }, []);


    const handleAddUpdateSurveyQuestion = () => {
        if (surveyQuestionData.questionType === "Radio" || surveyQuestionData.questionType === "Dropdown"){
            let isElementEmpty = options.some(e => e ==='');
            if (isElementEmpty){
                setSeverity("error")
                setSnackbarMsg("options should not empty!")
                console.log("options should not empty!");
                setOpenSnackbar(true);
                return
           }
        }
        setLoadingBtn(true);
        let SurveyQuestion = {
            questionName: surveyQuestionData.questionName,
            questionBody: surveyQuestionData.questionBody,
            answerRequired: surveyQuestionData.answerRequired,
            questionType: surveyQuestionData.questionType,
            options: (surveyQuestionData.questionType === "Radio" || surveyQuestionData.questionType === "Dropdown") ? options.join("##SEP##") :"",
        }
        if (!isAddSurveyQuestion) {
            SurveyQuestion.surveyQuestionId = surveyQuestionData.surveyQuestionId
        }
        let requestBody = {
            actionType: isAddSurveyQuestion ? "Add" : "Update",
            SurveyQuestion,
        };
        console.log("CREATE_SURVEY_QUESTION -> requestBody", requestBody);
        InvokePostServiceCall(
            apiConfig.CREATE_SURVEY_QUESTION,
            requestBody
        )
            .then((data) => {
                setSeverity("success");
                if (isAddSurveyQuestion) {
                    setSurveyQuestionData({
                        ...surveyQuestionData,
                        surveyQuestionId: data.data.surveyQuestionId,
                    });
                }
                setSnackbarMsg(`Survey Question ${isAddSurveyQuestion ? "added " : "updated"} successfully`);
                setOpenSnackbar(true);
                setLoadingBtn(false);
            })
            .catch((err) => {
                setSeverity("error")
                setSnackbarMsg(err.message)
                console.log(err.message, "ERROR DATA");
                setOpenSnackbar(true);
                setLoadingBtn(false);
            });
    };


    return (
        <>
            <SnackBar
                openSnackbar={openSnackbar}
                handleCloseSnackbar={handleCloseSnackbar}
                severity={severity}
                userMessage={snackbarMsg}
            />
            <div style={{ marginLeft: 20, marginRight: 20 }}>
                <h2>{isAddSurveyQuestion ? AddSurveyQuestion : UpdateSurveyQuestion}</h2>

                <div>
                    <Box sx={theme.customStyle.textRow}>
                        <TextField
                            size="small"
                            margin="dense"
                            variant="standard"
                            value={surveyQuestionData.questionName}
                            fullWidth
                            required
                            label="Question Name"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            id="UpdateQuestionName"
                            inputProps={{ //not show suggetions
                                autoComplete: 'new-password',
                                form: {
                                    autocomplete: 'off',
                                },
                            }}
                            onChange={(e) => {
                                setSurveyQuestionData({
                                    ...surveyQuestionData,
                                    questionName: e.target.value,
                                });
                            }}
                        />
                    </Box>

                    <Box sx={theme.customStyle.textRow}>
                        <TextField
                            margin="dense"
                            label="Question Body"
                            multiline
                            rows={2}
                            inputProps={{
                                maxLength: 500, autoComplete: 'new-password',
                                form: {
                                    autocomplete: 'off',
                                },
                            }}
                            id="updateQuestionBody"
                            value={surveyQuestionData.questionBody}
                            type="text"
                            fullWidth
                            required
                            variant="standard"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => {
                                setSurveyQuestionData({
                                    ...surveyQuestionData,
                                    questionBody: e.target.value,
                                });
                            }}
                        />
                    </Box>
                    <div>
                        <span>Answer Required </span>
                        <Checkbox
                            checked={surveyQuestionData.answerRequired}
                            onChange={(e) => {
                                setSurveyQuestionData({
                                    ...surveyQuestionData,
                                    answerRequired: e.target.checked,
                                });
                            }}
                        />
                    </div>
                    <Box sx={{ ...theme.customStyle.textRow, marginTop: 2 }}>
                        <TextField
                            size="small"
                            select
                            required
                            label="Question Type"
                            value={surveyQuestionData.questionType}
                            fullWidth
                            onChange={(e) => {
                                setSurveyQuestionData({
                                    ...surveyQuestionData,
                                    questionType: e.target.value,
                                });
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            id="UpdateQuestionType"
                            inputProps={{ //not show suggetions
                                autoComplete: 'new-password',
                                form: {
                                    autocomplete: 'off',
                                },
                            }}
                            helperText="Please select question type."
                            variant="standard"
                        >
                            <MenuItem value={"Text"}>Text</MenuItem>
                            <MenuItem value={"Radio"}>Radio</MenuItem>
                            <MenuItem value={"Dropdown"}>Dropdown</MenuItem>
                        </TextField>
                    </Box>

                    {(surveyQuestionData.questionType === "Radio" || surveyQuestionData.questionType === "Dropdown") &&
                    <>
                        <span>Add Options </span>
                            {options.map((option, index) => {
                                return (
                                    <Box sx={theme.customStyle.textRow}>
                                        <TextField
                                            margin="dense"
                                            // multiline
                                            // rows={2}
                                            required
                                            inputProps={{
                                                maxLength: 500, autoComplete: 'new-password',
                                                form: {
                                                    autocomplete: 'off',
                                                },
                                            }}
                                            id={`option_${index}`}
                                            value={option}
                                            type="text"
                                            fullWidth
                                            variant="standard"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => {
                                                options[index] = e.target.value;
                                                setOptions([...options]);
                                            }}
                                        />

                                       {
                                           ( (options.length > 1 && index < options.length-1) || index === 9) &&
                                       <Button
                                            size="small"
                                            onClick={() => {
                                                let arr = [...options];
                                                arr.splice(index, 1);
                                                console.log("Options :", arr)
                                                setOptions(arr);
                                            }}
                                            sx={{
                                                ...theme.customStyle.modalbtn02, height: "35px",
                                                width: "50px",
                                                position: "relative",
                                                top: "6px"
                                            }}
                                        >
                                        <DeleteOutlineIcon />
                                        </Button>}
                                        {(options.length - 1 === index && index !== 9) && <Button
                                            size="small"
                                            onClick={() => {
                                                let arr = [...options];

                                                arr.push("");
                                                console.log("Options :", arr)
                                                setOptions(arr);
                                            }}
                                            sx={{
                                                ...theme.customStyle.modalbtn02, height: "35px",
                                                width: "50px",
                                                position: "relative",
                                                top: "6px"
                                            }}
                                        >
                                            <AddIcon />
                                        </Button>}
                                    </Box>)}
                        )}
                        </>
                    }
                    {!isAddSurveyQuestion && (
                        <>
                            <Box sx={theme.customStyle.textRow}>
                                <TextField
                                    margin="dense"
                                    disabled
                                    label="Created By"
                                    type="text"
                                    value={`${createdByName + " (" + createdDate + ")"}`}
                                    fullWidth
                                    variant="standard"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    id="updateCreatedByName"
                                    inputProps={{ //not show suggetions
                                        autoComplete: 'new-password',
                                        form: {
                                            autocomplete: 'off',
                                        },
                                    }}
                                />
                            </Box>
                            <Box sx={theme.customStyle.textRow}>
                                <TextField
                                    margin="dense"
                                    label="Modified By"
                                    disabled
                                    type="text"
                                    value={`${lastModifiedByName + " (" + lastModifiedDate + ")"}`}
                                    fullWidth
                                    variant="standard"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    id="updateLastModifiedByName"
                                    inputProps={{ //not show suggetions
                                        autoComplete: 'new-password',
                                        form: {
                                            autocomplete: 'off',
                                        },
                                    }}
                                />
                            </Box>
                        </>
                    )}
                    <LoadingButton
                        loading={loadingBtn}
                        variant="contained"
                        disabled={
                            !surveyQuestionData.questionName ? true :
                                !surveyQuestionData.questionBody ? true :
                                    !surveyQuestionData.questionType ? true :false
                        }
                        onClick={() => {
                            handleAddUpdateSurveyQuestion();
                        }}
                    >
                        {!isAddSurveyQuestion ? UpdateSurveyQuestion : AddSurveyQuestion}
                    </LoadingButton>
                    <Button
                        sx={{ marginLeft: 2 }}
                        variant="contained"
                        onClick={() => {
                            navigate("/surveyQuestions");
                        }}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </>
    );
}