/**
 * 08-18-2023 => SUDAM B CHAVAN => TEXTING-1579 - Login History
 * 08-30-2023 => ONKAR => TEXTING-1578 -REACT Upgrade 
 */
import React, {  useEffect, useState } from "react";
import apiConfig from "../../api/apiConfig"
import { useTheme } from "@mui/material/styles";
import { InvokePostServiceCall } from "../../api/serviceUtil";
import moment from "moment";
import {
    TableContainer,
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    Box,
    Paper,
    Button,
    Divider,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
export default function LoginHistory(props) {
    const theme = useTheme();
    const [loginHistories, setLoginHistories] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        getLoginHistories();
    }, []);

    const getLoginHistories = () => {
        debugger
        setLoading(true); // set loading true before calling the api
        InvokePostServiceCall(apiConfig.GET_LOGIN_HISTORY, {
            userId: props.userId
        })
            .then((data) => {
                const tempArr = [];
                data.data.loginHistories &&
                    data.data.loginHistories.forEach((item, index) => {
                        var obj = {
                            userAgent: item.userAgent,
                            loggedInDate: moment(item.loggedInDate).local().format("MM-DD-YYYY") + ", " + moment(item.loggedInDate).local().format("LT"),
                            ip: item.sourceIp,
                            country: item.country,
                            id: index,
                            loginType: item.loginType
                        }
                        tempArr.push(obj);
                    });
                setLoginHistories(tempArr)
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error, "error message");
            });
    }
    return (
        <Dialog
            open={true}
            onClose={props.handleModalClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth="md"
        >
            <DialogTitle>
                <Box>
                    <strong>Login History</strong>
                </Box>
            </DialogTitle>
            <Divider />
            <DialogContent>
        <>
            {
                loading ? (
                    <Box sx={{ display: "flex", marginLeft: "40%" }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <>

                            {loginHistories && loginHistories.length > 0 ?
                             <Box sx={{ ...theme.customStyle.tableHead, height: "450px", padding: "0px" }}>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="simple table">
                                            <TableHead sx={theme.customStyle.loginHistoryTHead}>
                                                <TableRow>
                                                    <TableCell>User Agent</TableCell>
                                                    <TableCell>Logged In Date</TableCell>
                                                    <TableCell>IP</TableCell>
                                                    <TableCell>Country</TableCell>
                                                    <TableCell>Login Type</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody sx={theme.customStyle.loginHistoryTBody}>
                                                {loginHistories && loginHistories.length > 0 ? loginHistories.map((item, index) => (
                                                    <TableRow
                                                        key={index}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {item.userAgent}
                                                        </TableCell>
                                                        <TableCell>{item.loggedInDate}</TableCell>
                                                        <TableCell >{item.ip}</TableCell>
                                                        <TableCell>{item.country}</TableCell>
                                                        <TableCell>{item.loginType}</TableCell>
                                                    </TableRow>
                                                )) : null}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                        </Box> : <span>
                                            <strong >Login history not available.</strong>
                                        </span>}
                    </>)}
        </>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleModalClose} autoFocus>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <CloseIcon size={20} color="primary" />
                        <span>
                            <strong> Close</strong>
                        </span>
                    </div>
                </Button>
            </DialogActions>
        </Dialog>
    );
}
