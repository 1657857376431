/***************
 * 01-14-2023 => RAJESH => RAJESH Implemented the Chat Config UI for admin
 * 01-20-2023 => RAJESH => Fixed issue with the creation of first record
 * 06-23-2023 => ONKAR =>  Texting-1162 Admin UI JSON Generator
 * 07-25-2023 => PRABHAT => Texting-1448 Chat Config Labels added extra fields label,helptext.
 ***********/
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    Box,
    Button,
    TextField,
    CircularProgress,
    MenuItem,
    Typography,
    Checkbox,
    FormControl,
    InputLabel,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTheme } from "@mui/material/styles";
import JSONInput from "react-json-editor-ajrm";
import { InvokePostServiceCall } from "../../api/serviceUtil";
import apiConfig from "../../api/apiConfig";
import SnackBar from "../../components/SnackBar";
import { AuthContext } from "../../AuthContext";
import { Tooltip } from "@mui/material";
import { Avatar } from "@mui/material";
import AvatarFile from "../../components/AvatarFileComp";


function AddUpdatechatConfigs() {
    const location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();
    const numberRegEx = /^(0|[1-9]\d*)$/;
    const isAddchatConfigs = location.state
        ? location.state.isAddchatConfigs
        : true;
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const { messageApiToken } = useContext(AuthContext);
    const [severity, setSeverity] = useState("success");
    const [appchatConfigsData, setAppchatConfigsData] = useState([]);
    const [btnLoading, setBtnLoading] = useState(false);
    const [loading,setLoading]=useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [settingsArr, setSettingsArr] = useState([]);
    const [addchatConfigs, setAddchatConfig] = useState({
        name: "",
        chatConfiguration: {},
        isActive: true,
        agentLogo: "",
        chatbotLogo: "",
        companyLogo: "",
        webChatInActiveConversationHours:"",
    });
    const handleChange = (event) => {
        setAddchatConfig({ ...addchatConfigs, isActive: event.target.checked });
    };
    const [errors, setErrors] = useState({
        chatConfigValueErr: false,
    });


    useEffect(() => {
        if (!isAddchatConfigs) {
            let chatConfigDetails = location.state.chatConfigDetails;
            let chatConfigObj = JSON.parse(
                location.state.chatConfigDetails.chatConfiguration
            );
            setAddchatConfig({
                name: chatConfigDetails.name,
                chatConfiguration: {...chatConfigObj},
                chatConfigId: chatConfigDetails.chatConfigId,
                isActive: chatConfigDetails.isActive
                    ? chatConfigDetails.isActive
                    : false,
                agentLogo: chatConfigDetails.agentLogo,
                chatbotLogo: chatConfigDetails.chatbotLogo,
                companyLogo: chatConfigDetails.companyLogo,
                webChatInActiveConversationHours: chatConfigDetails.webChatInActiveConversationHours,
            });
        }
    }, []);


    function getchatConfigs() {
        InvokePostServiceCall(apiConfig.GET_CHATCONFIG, {})
            .then((data) => {
                setAppchatConfigsData(data.data.chatConfig);
            })
            .catch((error) => {
                setSeverity("error");
                setSnackbarMsg(error.message);
                console.log(error, "error message");
                setOpenSnackbar(true);
            });
    }

    function getChatConfigFromAllSettings() {
        setLoading(true);
        InvokePostServiceCall(apiConfig.GET_SETTINGS, { isActive: true })
            .then((data) => {
                console.log(data, "settingsData");
                if (data?.data?.settings.length > 0) {
                    let settingsArr = data?.data?.settings;
                    for (let i = 0; i < settingsArr.length; i++) {
                        if (settingsArr[i].settingsKey === "WebChatConfig") {
                            let webChatSettingsArr = JSON.parse(settingsArr[i].settingsValue);

                            let configObjArr = [];

                            webChatSettingsArr.forEach((item) => {
                                let configObj = {};
                                configObj.name = item.name;
                                configObj.type = item.type;
                                configObj.label = item.label;
                                configObj.helpText = item.helpText;
                                if (!isAddchatConfigs) {
                                    if (item.name ==="webChatInActiveConversationHours"){
                                        configObj.settingsValue = location.state.chatConfigDetails[item.name]; 
                                    }
                                    else{
                                    let chatConfigObj = JSON.parse(
                                        location.state.chatConfigDetails.chatConfiguration
                                    );
                                    configObj.settingsValue = chatConfigObj[item.name];
                                    }
                                }
                                configObjArr.push(configObj);
                            });

                            setSettingsArr(configObjArr);
                            break;
                        }
                    }
                }
                setLoading(false);
            })
            .catch((error) => {
                setSeverity("error");
                setSnackbarMsg(error.message);
                setLoading(false);
                console.log(error, "error message");
                setOpenSnackbar(true);
            });
    }

    useEffect(() => {
        if (messageApiToken) {
            getchatConfigs();
            getChatConfigFromAllSettings();
        }
    }, [messageApiToken]);


    // Function to save chat configurations to the server
    function savechatConfigs(updatedConfigs) {
        setLoading(true);
        const requestBody = { chatConfig: updatedConfigs };
        InvokePostServiceCall(apiConfig.SAVE_CHATCONFIG, requestBody)
            .then((data) => {
                setSeverity("success");
                setSnackbarMsg(
                    isAddchatConfigs
                        ? "chat Configuration Added Successfully."
                        : "Chat Configuration Updated Successfully."
                );
                console.log(data, "Success Response");
                setLoading(false);
                setOpenSnackbar(true);
            })
            .catch((error) => {
                setSeverity("error");
                setSnackbarMsg(error.message);
                console.log(error, "Error Message");
                setLoading(false);
                setOpenSnackbar(true);
            });
    }
    // Function to add a new chat configuration
    function addchatConfig() {
        const newConfig = {
            name: addchatConfigs.name,
            chatConfiguration: JSON.stringify(addchatConfigs.chatConfiguration),
            isActive: addchatConfigs.isActive,
            agentLogo: addchatConfigs.agentLogo,
            chatbotLogo: addchatConfigs.chatbotLogo,
            companyLogo: addchatConfigs.companyLogo,
            webChatInActiveConversationHours: addchatConfigs.webChatInActiveConversationHours
        };
        setAppchatConfigsData((prevConfigs) => [...prevConfigs, newConfig]);
        savechatConfigs([newConfig]); // Wrap the newConfig object in an array
    }

    // Function to update an existing chat configuration
    function updatechatConfigs() {
        const indexToUpdate = appchatConfigsData.findIndex(
            (item) => item.chatConfigId === addchatConfigs.chatConfigId
        );

        if (indexToUpdate !== -1) {
            const updatedConfigs = [...appchatConfigsData];
            updatedConfigs[indexToUpdate] = {
                ...updatedConfigs[indexToUpdate],
                name: addchatConfigs.name,
                chatConfiguration: JSON.stringify(addchatConfigs.chatConfiguration),
                isActive: addchatConfigs.isActive,
                agentLogo: addchatConfigs.agentLogo,
                chatbotLogo: addchatConfigs.chatbotLogo,
                companyLogo: addchatConfigs.companyLogo,
                webChatInActiveConversationHours: addchatConfigs.webChatInActiveConversationHours
            };
            setAppchatConfigsData(updatedConfigs);
            savechatConfigs([updatedConfigs[indexToUpdate]]); // Wrap the updatedConfig object in an array
        }
    }


    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        if (severity === "success") {
            navigate("/chatConfigs", { replace: true });
        }
        setOpenSnackbar(false);
    };
    const isJsonString = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    };

    // Helper function to determine tooltip placement based on available space
    const getTooltipPlacement = (cellIndex) => {
        const totalCells = settingsArr.length;
        if (cellIndex < totalCells / 2) {
            return 'top-end'; // Show tooltip on the top-right for the left half of the table
        } else {
            return 'top-start'; // Show tooltip on the top-left for the right half of the table
        }
    };

    // Helper function to handle long setting names in tooltips
    const getTooltipTitle = (title) => {
        const maxTooltipWidth = 200; // Adjust this value based on your preference
        if (title.length > maxTooltipWidth) {
            return title.slice(0, maxTooltipWidth) + '...';
        }
        return title;
    };

    function updateSettingsValue(value, index, name) {
        let settingArr = [...settingsArr];
        settingArr[index].settingsValue = value;
        setSettingsArr(settingArr);
        if (name === "webChatInActiveConversationHours") {
            addchatConfigs[name]= value
            setAddchatConfig(addchatConfigs)
         }
        else {
            let chatConfig = addchatConfigs.chatConfiguration;
            chatConfig[name] = value;
            setAddchatConfig({ ...addchatConfigs, chatConfiguration: chatConfig })
        }
        console.log(value);
    }

    return (
        <div style={{ padding:"0px 10px 20px 10px" }}>

            <Box sx={theme.customStyle.tableRow}>
                <h2>
                    {isAddchatConfigs
                        ? "Add Chat Configuration"
                        : "Update Chat Configuration"}
                </h2>
            </Box>


            <SnackBar
                openSnackbar={openSnackbar}
                handleCloseSnackbar={handleCloseSnackbar}
                severity={severity}
                userMessage={snackbarMsg}
            />

            <>
                <Box sx={{ ...theme.customStyle.textRow, marginTop: "20px" }}>
                    <TextField
                        margin="dense"
                        label="Chat Config Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={addchatConfigs.name}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        id="updatechatConfigsname"
                        inputProps={{
                            //not show suggetions
                            autoComplete: "new-password",
                            form: {
                                autocomplete: "off",
                            },
                        }}
                        onChange={(e) => {
                            setAddchatConfig({
                                ...addchatConfigs,
                                name: e.target.value,
                            });
                        }}
                    />
                </Box>


                <div style={{ marginBottom: "10px" }}>
                    <span>Is Active</span>
                    <Checkbox
                        checked={addchatConfigs.isActive}
                        onChange={handleChange}
                    />
                </div>

                <div style={{display:'flex',justifyContent:"center",padding:"20px"}}>
                    {loading && <CircularProgress/>}
                </div>

                {settingsArr.length > 0 && (
                    <>
                        <TableContainer>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow sx={{ "td, th": { borderBottom: 0 } }}>
                                    <TableCell>
                                        <span style={{ fontSize: "16px" }}>
                                            <strong>Setting Name</strong>
                                        </span>
                                    </TableCell>
                                    <TableCell>
                                        <span style={{ fontSize: "16px" }}>
                                            <strong>Setting Value</strong>
                                        </span>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {settingsArr.map((row, index) => (
                                    <TableRow key={index} sx={{ "td, th": { borderBottom: 0 } }}>
                                        <TableCell>
                                            <Tooltip
                                                title={getTooltipTitle(row.helpText)}
                                                placement={getTooltipPlacement(index)}
                                                PopperProps={{
                                                    disablePortal: true,
                                                    style: { transform: 'none', top: 0, left: '50%', transform: 'translateX(-50%)' },
                                                }}>
                                                <span style={{ fontSize: "16px" }}>
                                                    {row.label || row.name}
                                                </span>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell>
                                            {(row.type === "text"|| row.type==="number") && (
                                                <TextField
                                                    sx={{ minWidth: "500px", paddingLeft: "9px" }}
                                                    variant="standard"
                                                    type={row.type}
                                                    defaultValue={row.settingsValue}
                                                    onChange={(e) => {
                                                        updateSettingsValue(e.target.value,index, row.name);
                                                    }}
                                                />
                                            )}
                                            {row.type === "boolean" && (
                                                <Checkbox
                                                    checked={row.settingsValue === "Y"}
                                                    onChange={(e) => {
                                                        updateSettingsValue(
                                                            e.target.checked ? "Y" : "N",
                                                            index,
                                                            row.name
                                                        );
                                                    }}
                                                    inputProps={{ "aria-label": "controlled" }}
                                                />
                                            )}
                                            {row.type === "color" && (
                                                <input
                                                    style={{marginLeft:"9px"}}
                                                    type="color"
                                                    defaultValue={row.settingsValue}
                                                    onChange={(e) => {
                                                        updateSettingsValue(e.target.value,index, row.name);
                                                    }}
                                                ></input>
                                            )}
                                            {row.type === "AvatarFile" && (
                                                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                    {row.settingsValue ? (
                                                        <>
                                                            <Avatar alt={row.label} src={row.settingsValue} />
                                                            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                                                <AvatarFile
                                                                    name={row.name}
                                                                    label={row.label}
                                                                    value={row.settingsValue}
                                                                    onChange={(value, name) => {
                                                                        updateSettingsValue(value, index, name);

                                                                        // Update the specific state variable based on the name prop
                                                                        if (name === "agentLogo") {
                                                                            setAddchatConfig({
                                                                                ...addchatConfigs,
                                                                                agentLogo: value,
                                                                            });
                                                                        } else if (name === "chatbotLogo") {
                                                                            setAddchatConfig({
                                                                                ...addchatConfigs,
                                                                                chatbotLogo: value,
                                                                            });
                                                                        } else if (name === "companyLogo") {
                                                                            setAddchatConfig({
                                                                                ...addchatConfigs,
                                                                                companyLogo: value,
                                                                            });
                                                                        }
                                                                    }}
                                                                />
                                                                
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Avatar alt={row.label} />
                                                            <AvatarFile
                                                                name={row.name}
                                                                label={row.label}
                                                                value={row.settingsValue}
                                                                onChange={(value, name) => {
                                                                    updateSettingsValue(value, index, name);

                                                                    // Update the specific state variable based on the name prop
                                                                    if (name === "agentLogo") {
                                                                        setAddchatConfig({
                                                                            ...addchatConfigs,
                                                                            agentLogo: value,
                                                                        });
                                                                    } else if (name === "chatbotLogo") {
                                                                        setAddchatConfig({
                                                                            ...addchatConfigs,
                                                                            chatbotLogo: value,
                                                                        });
                                                                    } else if (name === "companyLogo") {
                                                                        setAddchatConfig({
                                                                            ...addchatConfigs,
                                                                            companyLogo: value,
                                                                        });
                                                                    }
                                                                }}
                                                            />
                                                        </>
                                                    )}
                                                </div>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    </>
                )}
                <Button
                    variant="contained"
                    onClick={() => {
                        navigate("/chatConfigs", { replace: true });
                    }}
                >
                    Cancel
                </Button>
                <LoadingButton
                    style={{ marginLeft: "10px" }}
                    variant="contained"
                    loading={btnLoading}
                    disabled={
                        // jsonError === true
                        //   ? true
                        //   : !addchatConfigs.chatConfiguration
                        //   ? true
                        //   : !addchatConfigs.name
                        //   ? true
                        //   : errors.chatConfigValueErr
                        //   ? true
                        //   : false
                        !addchatConfigs.name
                            ? true: false
                    }
                    onClick={() => {
                        isAddchatConfigs ? addchatConfig() : updatechatConfigs();
                    }}
                    autoFocus
                >
                    {isAddchatConfigs ? "Add" : "Update"}
                </LoadingButton>
            </>

        </div>
    );
}

export default AddUpdatechatConfigs;


