////11-16-2022 => ONKAR => Texting-817 => Show Tokens nav item, Holiday and Bus Hrs. only for Admins.
////05-05-2023 => ONKAR => updating selectconversation value to empty obj on click of icons in drawer.
////06-27-2022 => ONKAR => Texting-1378 TCPA UI Changes
// 06-30-2023 =>  ONKAR => Texting-1391 TCPA UI observations.
////09-15-2023 => ONKAR =>TEXTING-1697 - Client Req: Ability to view the chat history of the member without initiating the conversation
////10-17-2023 => SUDAM B CHAVAN => TEXTING-1646 Reporting - Realtime Reports Design(Added settings to show/hide comp)
////11-27-2023 => SUDAM B CHAVAN => TEXTING-1872 Chat Survey Questions Configuration - Admin UI
////12-01-2023 => SUDAM B CHAVAN => TEXTING - 1872 - Chat Survey Questions Configuration - Admin UI
////12-12-2023 => SUDAM B CHAVAN => TEXTING-1916 0: Configuration to Enable Survey in appsettings

import React, { useContext } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MoveToInboxTwoToneIcon from "@mui/icons-material/MoveToInboxTwoTone";
import GroupsTwoToneIcon from "@mui/icons-material/GroupsTwoTone";
import { NavLink } from "react-router-dom";
import SettingsOutlined from "@mui/icons-material/SettingsOutlined";
import PeopleOutlineTwoToneIcon from "@mui/icons-material/PeopleOutlineTwoTone";
import PaddingTwoToneIcon from "@mui/icons-material/PaddingTwoTone";
import TagTwoToneIcon from "@mui/icons-material/TagTwoTone";
import { AuthContext } from "../AuthContext";
import ManageSearchTwoToneIcon from "@mui/icons-material/ManageSearchTwoTone";
import AcUnitTwoToneIcon from '@mui/icons-material/AcUnitTwoTone';
import AppSettingsAltTwoToneIcon from "@mui/icons-material/AppSettingsAltTwoTone";
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import CampaignTwoToneIcon from "@mui/icons-material/CampaignTwoTone";
import AccessTimeTwoToneIcon from "@mui/icons-material/AccessTimeTwoTone";
import ContactPhoneTwoToneIcon from "@mui/icons-material/ContactPhoneTwoTone";
import { styled, useTheme } from "@mui/material/styles";
import { ListItemButton, Collapse } from "@mui/material";
import HomeTwoToneIcon from "@mui/icons-material/HomeTwoTone";
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import HolidayVillageTwoToneIcon from "@mui/icons-material/HolidayVillageTwoTone";
import DrawerConvListNew from "./DrawerConvListNew";
import { useDispatch } from "react-redux";
import {
  conversationSelected,
} from "../storage/slices/messageSlice";
import DateRangeTwoToneIcon from '@mui/icons-material/DateRangeTwoTone';
import HelpCenterTwoToneIcon from '@mui/icons-material/HelpCenterTwoTone';
import PollTwoToneIcon from '@mui/icons-material/PollTwoTone';



const StyledList = styled(List)({
  // selected and (selected + hover) states
  "&& .MuiListItem-button": {
    // backgroundColor: "#D3D3D3",
    borderLeft: "4px solid #D3D3D3",
    "&, & .MuiListItemIcon-root": {
      color: "black",
    },
  },
  "&& .MuiListItem-button:hover": {
    // backgroundColor: "#fff",
    borderLeft: "4px solid #fff",
    "&, & .MuiListItemIcon-root": {
      color: "black",
    },
  },
  "&& .Mui-selected, && .Mui-selected:hover": {
    // backgroundColor: "#D3D3D3",
    borderLeft: "4px solid rgb(0, 102, 255)",
    "&, & .MuiListItemIcon-root": {
      color: "black",
    },
  },
  "& .MuiListItemButton-root": {
    // backgroundColor: "#D3D3D3",
    borderLeft: "4px solid #D3D3D3",
    "&, & .MuiListItemIcon-root": {
      color: "black",
    },
  },
  // hover states
  "& .MuiListItemButton-root:hover": {
    // backgroundColor: "#D3D3D3",
    "&, & .MuiListItemIcon-root": {
      color: "black",
    },
  },
  "& div .conversationitem": {
    backgroundColor: "#fff !important",
    borderLeft: "4px solid #fff !important",
    "&, & .MuiListItemIcon-root": {
      color: "black",
    },
  },
});

export default function DrawerListCmpNew(props) {
  const appContext = useContext(AuthContext);
  const theme = useTheme();
  const {
    userInfo,
    crmMode,
    crmEmbededView,
    openDrawer,
    setOpenDrawer,
    setupCollapse,
    setSetupCollapse,
    matches,
    openConversationDrawer,setOpenConversationDrawer,
  } = useContext(AuthContext);
  const dispatch = useDispatch();
  const setupMenuTop = crmEmbededView
    ? "calc(100vh - 65px)"
    : "calc(100vh - 130px)";
    const drawerWidth ="49px";
    function setSelectedConvNone(){
      dispatch(conversationSelected({}));
    }
  return (
    <>
      <Box role="presentation">
        {!appContext.crmEmbededView ? (
          <>
            <List>
              <ListItem>
                <p></p>
              </ListItem>
            </List>
            <Divider />
          </>
        ) : (
          <></>
        )}
        <div style={{ display: setupCollapse ? "none" : "block" }}>
          <StyledList>
            <NavLink
              to={"/"}
              style={styles.navLink}
              onClick={(e) => {
                openDrawer && setOpenDrawer(!openDrawer);
                setOpenConversationDrawer(false);
              }}
            >
              <ListItemButton
                selected={props.selectedMenu === "/"}
                title="Home"
                sx={
                    theme.customStyle.listItemIconDrawerClosedNew
                }
                onClick={(e) => {
                  setOpenConversationDrawer(false);
                  setSelectedConvNone();
                  if(props.startConvWithTemplate){
                  props.handleCloseTeams();
                  }
                }}
              >
                <ListItemIcon sx={theme.customStyle.listItemIcon}>
                  <HomeTwoToneIcon
                    iconSize={theme.customStyle.iconSize}
                    sx={
                      props.selectedMenu === "/"
                        ?  theme.customStyle.activeDrawerMenuDrawerClosed
                        :  theme.customStyle.inActiveDrawerMenuDrawerClosed
                    }
                  />
                 
                    <Box sx={{ ...theme.customStyle.listItemLabelDiv, left: "-1px" }}>
                      <span style={{ fontSize: "10px" }}>Home</span>
                    </Box>

                </ListItemIcon>
              </ListItemButton>
            </NavLink>
            {(userInfo.role === "Admin" || userInfo.role === "Supervisor") &&
              (crmEmbededView || crmMode === "false") && userInfo.isDashboardEnabled &&
              <NavLink
                to={"/dashboard"}
                style={styles.navLink}
                onClick={(e) => {
                  openDrawer && setOpenDrawer(!openDrawer);
                  setOpenConversationDrawer(false);
                }}
              >
                <ListItemButton
                  selected={props.selectedMenu === "/dashboard"}
                  title="Dashboard"
                  sx={
                    theme.customStyle.listItemIconDrawerClosedNew
                  }
                  onClick={(e) => {
                    setOpenConversationDrawer(false);
                    setSelectedConvNone();
                    if (props.startConvWithTemplate) {
                      props.handleCloseTeams();
                    }
                  }}
                >
                  <ListItemIcon sx={theme.customStyle.listItemIcon}>
                    <DashboardTwoToneIcon
                      iconSize={theme.customStyle.iconSize}
                      sx={
                        props.selectedMenu === "/dashboard"
                          ? theme.customStyle.activeDrawerMenuDrawerClosed
                          : theme.customStyle.inActiveDrawerMenuDrawerClosed
                      }
                    />

                    <Box sx={{ ...theme.customStyle.listItemLabelDiv, left: "-9px" }}>
                      <span style={{ fontSize: "9px" }}>Dashboard</span>
                    </Box>

                  </ListItemIcon>
                </ListItemButton>
              </NavLink>
            }
            {crmMode === "false" &&
              (userInfo.role === "Admin" || userInfo.role === "Supervisor") &&
              !crmEmbededView ? (
              <NavLink to={"/contacts"} style={styles.navLink}>
                <ListItemButton
                  selected={
                    props.selectedMenu === "/contacts" ||
                    props.selectedMenu.includes("/addUpdateContact")
                  }
                  title="Contacts"
                  sx={
                     theme.customStyle.listItemIconDrawerClosedNew
                  }
                  onClick={(e) => {
                    setOpenConversationDrawer(false);
                    setSelectedConvNone()
                  }}
                >
                  <ListItemIcon sx={theme.customStyle.listItemIcon}>
                    <MoveToInboxTwoToneIcon
                      iconSize={theme.customStyle.iconSize}
                      sx={
                        props.selectedMenu === "/contacts" ||
                        props.selectedMenu.includes("/addUpdateContact")
                          ?  theme.customStyle.activeDrawerMenuDrawerClosed
                          :  theme.customStyle.inActiveDrawerMenuDrawerClosed
                      }
                    />
                  
                      <Box sx={{ ...theme.customStyle.listItemLabelDiv, left: "-8px" }}>
                        <span style={{ fontSize: "10px" }}>Contacts</span>
                      </Box>
                    
                  </ListItemIcon>
                </ListItemButton>
              </NavLink>
            ) : (
              <></>
            )}

           
              <>
                <DrawerConvListNew
                  selectedMenu={props.selectedMenu}
                  setSelectedMenu={props.setSelectedMenu}
                  setSelectedConvNone={setSelectedConvNone}
                />
              </>
          
            {userInfo.role === "Admin" || userInfo.role === "Supervisor" ? (
              (crmEmbededView || crmMode === "false") && (
                <>
                  <NavLink to={"/communications"} style={styles.navLink}>
                    <ListItemButton
                      sx={
                         theme.customStyle.listItemIconDrawerClosedNew
                      }
                      selected={
                        props.selectedMenu === "/communications" ||
                        props.selectedMenu.includes("/addUpdateCommunications")
                      }
                      title="Communication Lists"
                      onClick={(e) => {
                        setOpenConversationDrawer(false);
                        setSelectedConvNone()
                      }}
                    >
                      <ListItemIcon sx={theme.customStyle.listItemIcon}>
                        <CampaignTwoToneIcon
                          iconSize={theme.customStyle.iconSize}
                          sx={
                            props.selectedMenu === "/communications" ||
                            props.selectedMenu.includes("/addUpdateCommunications")
                              ?  theme.customStyle.activeDrawerMenuDrawerClosed
                              : theme.customStyle.inActiveDrawerMenuDrawerClosed
                          }
                        />
                        
                          <Box sx={{ ...theme.customStyle.listItemLabelDiv, left: "3px" }}>
                            <span style={{ fontSize: "10px" }}>List</span>
                          </Box>
                    
                      </ListItemIcon>
                     
                    </ListItemButton>
                  </NavLink>
                  <NavLink to={"/searchConversation"} style={styles.navLink}>
                    <ListItemButton
                      sx={
                        theme.customStyle.listItemIconDrawerClosedNew
                      }
                      selected={props.selectedMenu === "/searchConversation"}
                      title="Search Conversations"
                      onClick={(e) => {
                        setOpenConversationDrawer(false);
                        setSelectedConvNone()
                      }}
                    >
                      <ListItemIcon sx={theme.customStyle.listItemIcon}>
                        <ManageSearchTwoToneIcon
                          iconSize={theme.customStyle.iconSize}
                          sx={
                            props.selectedMenu === "/searchConversation"
                              ?  theme.customStyle.activeDrawerMenuDrawerClosed
                              :  theme.customStyle.inActiveDrawerMenuDrawerClosed
                          }
                        />

                       
                          <Box sx={{ ...theme.customStyle.listItemLabelDiv, left: "-4px" }}>
                            <span style={{ fontSize: "10px" }}>Search</span>
                          </Box>
                     
                      </ListItemIcon>
                    </ListItemButton>
                  </NavLink>
                  <NavLink to={"/omniChannel"} style={styles.navLink}>
                    <ListItemButton
                      sx={
                        theme.customStyle.listItemIconDrawerClosedNew
                      }
                      selected={props.selectedMenu === "/omniChannel"}
                      title="Omni-Channel"
                      onClick={(e) => {
                        setOpenConversationDrawer(false);
                        setSelectedConvNone()
                      }}
                    >
                      <ListItemIcon sx={theme.customStyle.listItemIcon}>
                        <AcUnitTwoToneIcon
                          iconSize={theme.customStyle.iconSize}
                          sx={
                            props.selectedMenu === "/omniChannel"
                              ? theme.customStyle.activeDrawerMenuDrawerClosed
                              :  theme.customStyle.inActiveDrawerMenuDrawerClosed
                          }
                        />
                       
                          <Box sx={{ ...theme.customStyle.listItemLabelDiv, left: "-6px" }}>
                            <span style={{ fontSize: "10px" }}>Channel</span>
                          </Box>
                     
                      </ListItemIcon>
                    </ListItemButton>
                  </NavLink>
                </>
              )
            ) : (
              <></>
            )}
          </StyledList>
        </div>
        {userInfo.role === "Admin" || userInfo.role === "Supervisor" ? (
          (crmEmbededView || crmMode === "false") && (
            <Box
              sx={{
                position: "fixed",
                bottom: 0,
                width: drawerWidth,
              }}
            >
              <Divider sx={styles.divider} />
              <StyledList>
                <ListItem
                  onClick={() => {
                    if(!setupCollapse && openConversationDrawer ){
                      props.handleDrawerOperation(false)
                    }else {
                      props.handleDrawerOperation(openConversationDrawer)
                    }
                    setSetupCollapse(!setupCollapse);
                  
                  }}
                  sx={{
                    ...styles.setup,
                    paddingLeft: "12px",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "#e6e6e6",
                    },
                    paddingTop:  "0px" ,
                    paddingBottom:  "0px" ,
                  }}
                >
                  <ListItemIcon sx={theme.customStyle.listItemIcon}>
                    <SettingsOutlined sx={{ position: "relative", top:  "7px" }} />
                   
                      <Box sx={theme.customStyle.listItemLabelDiv}>
                        <span style={{ fontSize: "10px" }}>Setup</span>
                      </Box>
            
                  </ListItemIcon>
                
                </ListItem>
              </StyledList>
              <Divider sx={styles.divider} />
              <Collapse in={setupCollapse}>
                <Box
                  sx={crmEmbededView
                    ?theme.customStyle.drawerSetupStyleCrm
                    :theme.customStyle.drawerSetupStyle}
                >
                  <StyledList>
                    <NavLink to="/users" style={styles.navLink}>
                      <ListItemButton
                        sx={
                           theme.customStyle.listItemIconDrawerClosedNew
                        }
                        selected={
                          props.selectedMenu === "/users" ||
                          props.selectedMenu.includes("/addUpdateUser") 
                        }
                        title="Users"
                        onClick={()=>{ setSelectedConvNone();}}
                      >
                        <ListItemIcon sx={theme.customStyle.listItemIcon}>
                          <PeopleOutlineTwoToneIcon
                            iconSize={theme.customStyle.iconSize}
                            sx={
                              props.selectedMenu === "/users" ||
                              props.selectedMenu.includes("/addUpdateUser") 
                                ?  theme.customStyle
                                    .activeDrawerMenuDrawerClosed
                                :  theme.customStyle
                                    .inActiveDrawerMenuDrawerClosed
                            }
                          />
                           <Box sx={{ ...theme.customStyle.listItemLabelDiv }}>
                            <span style={{ fontSize: "10px" }}>Users</span>
                          </Box>
                        </ListItemIcon>
                        <ListItemText primary={"Users"} />
                      </ListItemButton>
                    </NavLink>
                    <NavLink to="/teams" style={styles.navLink}>
                      <ListItemButton
                      onClick={()=>{ setSelectedConvNone();}}
                        sx={
                          theme.customStyle.listItemIconDrawerClosedNew
                        }
                        selected={
                          props.selectedMenu === "/teams" ||
                          props.selectedMenu.includes("/addUpdateTeam") 
                        }
                        title="Teams"
                      >
                        <ListItemIcon sx={theme.customStyle.listItemIcon}>
                          <GroupsTwoToneIcon
                            iconSize={theme.customStyle.iconSize}
                            sx={
                              props.selectedMenu === "/teams" ||
                              props.selectedMenu.includes("/addUpdateTeam")
                                ?  theme.customStyle
                                    .activeDrawerMenuDrawerClosed
                                : theme.customStyle
                                    .inActiveDrawerMenuDrawerClosed
                            }
                          />
                          <Box sx={{ ...theme.customStyle.listItemLabelDiv, left: "-2px" }}>
                            <span style={{ fontSize: "10px" }}>Teams</span>
                          </Box>
                        </ListItemIcon>
                      </ListItemButton>
                    </NavLink>
                    <NavLink to="/templates" style={styles.navLink}>
                      <ListItemButton
                        sx={
                           theme.customStyle.listItemIconDrawerClosedNew
                        }
                        selected={
                          props.selectedMenu === "/templates" ||
                          props.selectedMenu.includes("/addUpdateTemplate")
                        }
                        title="Templates"
                      >
                        <ListItemIcon sx={theme.customStyle.listItemIcon}>
                          <PaddingTwoToneIcon
                            iconSize={theme.customStyle.iconSize}
                            sx={
                              props.selectedMenu === "/templates" ||
                              props.selectedMenu.includes("/addUpdateTemplate")
                                ?  theme.customStyle
                                    .activeDrawerMenuDrawerClosed
                                :  theme.customStyle
                                    .inActiveDrawerMenuDrawerClosed
                            }
                          />
                           <Box sx={{ ...theme.customStyle.listItemLabelDiv, left: "-10px" }}>
                            <span style={{ fontSize: "10px" }}>Templates</span>
                          </Box>
                        </ListItemIcon>
                      </ListItemButton>
                    </NavLink>
                    {
                      userInfo.isSurveyEnabledAppSettings && <>
                        <NavLink to="/surveyTemplate" style={styles.navLink}>
                          <ListItemButton
                            sx={
                              theme.customStyle.listItemIconDrawerClosedNew
                            }
                            selected={
                              props.selectedMenu === "/surveyTemplate" ||
                              props.selectedMenu.includes("/addUpdateSurveyTemplate")
                            }
                            title="Survey Template"
                          >
                            <ListItemIcon sx={theme.customStyle.listItemIcon}>
                              <PollTwoToneIcon
                                iconSize={theme.customStyle.iconSize}
                                sx={
                                  props.selectedMenu === "/surveyTemplate" ||
                                    props.selectedMenu.includes("/addUpdateSurveyTemplate")
                                    ? theme.customStyle
                                      .activeDrawerMenuDrawerClosed
                                    : theme.customStyle
                                      .inActiveDrawerMenuDrawerClosed
                                }
                              />
                              <Box sx={{ ...theme.customStyle.listItemLabelDiv, left: "-2px" }}>
                                <span style={{ fontSize: "10px" }}>Survey</span>
                              </Box>
                            </ListItemIcon>
                          </ListItemButton>
                        </NavLink>
                        <NavLink to="/surveyQuestions" style={styles.navLink}>
                          <ListItemButton
                            sx={
                              theme.customStyle.listItemIconDrawerClosedNew
                            }
                            selected={
                              props.selectedMenu === "/surveyQuestions" ||
                              props.selectedMenu.includes("/addUpdateSurveyQuestions")
                            }
                            title="Questions"
                          >
                            <ListItemIcon sx={theme.customStyle.listItemIcon}>
                              <HelpCenterTwoToneIcon
                                iconSize={theme.customStyle.iconSize}
                                sx={
                                  props.selectedMenu === "/surveyQuestions" ||
                                    props.selectedMenu.includes("/addUpdateSurveyQuestions")
                                    ? theme.customStyle
                                      .activeDrawerMenuDrawerClosed
                                    : theme.customStyle
                                      .inActiveDrawerMenuDrawerClosed
                                }
                              />
                              <Box sx={{ ...theme.customStyle.listItemLabelDiv, left: "-10px" }}>
                                <span style={{ fontSize: "10px" }}>Questions</span>
                              </Box>
                            </ListItemIcon>
                          </ListItemButton>
                        </NavLink>
                      </>
                    }

                    {userInfo.role === "Admin" &&<NavLink to="/tokens" style={styles.navLink}>
                      <ListItemButton
                      onClick={()=>{ setSelectedConvNone();}}
                        sx={
                           theme.customStyle.listItemIconDrawerClosedNew
                        }
                        selected={
                          props.selectedMenu === "/tokens" ||
                          props.selectedMenu.includes("/addUpdateToken") 
                        }
                        title="Tokens"
                      >
                        <ListItemIcon sx={theme.customStyle.listItemIcon}>
                          <TagTwoToneIcon
                            iconSize={theme.customStyle.iconSize}
                            sx={
                              props.selectedMenu === "/tokens" ||
                              props.selectedMenu.includes("/addUpdateToken")
                                ?  theme.customStyle
                                    .activeDrawerMenuDrawerClosed
                                :  theme.customStyle
                                    .inActiveDrawerMenuDrawerClosed
                            }
                          />
                           <Box sx={{ ...theme.customStyle.listItemLabelDiv, left: "-3px" }}>
                            <span style={{ fontSize: "10px" }}>Tokens</span>
                          </Box>
                        </ListItemIcon>
                      </ListItemButton>
                    </NavLink>}
                    <NavLink to="/phoneNumberOptOut" style={styles.navLink}>
                      <ListItemButton
                      onClick={()=>{ setSelectedConvNone();}}
                        sx={
                           theme.customStyle.listItemIconDrawerClosedNew
                        }
                        selected={props.selectedMenu === "/phoneNumberOptOut"}
                        title="Phone Number Opt Out"
                      >
                        <ListItemIcon sx={theme.customStyle.listItemIcon}>
                          <ContactPhoneTwoToneIcon
                            iconSize={theme.customStyle.iconSize}
                            sx={
                              props.selectedMenu === "/phoneNumberOptOut"
                                ?  theme.customStyle
                                    .activeDrawerMenuDrawerClosed
                                : theme.customStyle
                                    .inActiveDrawerMenuDrawerClosed
                            }
                          />
                       <Box sx={{ ...theme.customStyle.listItemLabelDiv, left: "-5px" }}>
                            <span style={{ fontSize: "10px" }}>OptOut</span>
                          </Box>
                        </ListItemIcon>
                      </ListItemButton>
                    </NavLink>
                    {userInfo.role === "Admin" &&<><NavLink to="/businessHoursConfig" style={styles.navLink}>
                      <ListItemButton
                      onClick={()=>{ setSelectedConvNone();}}
                        sx={
                          theme.customStyle.listItemIconDrawerClosedNew
                        }
                        selected={
                          props.selectedMenu === "/businessHoursConfig" ||
                          props.selectedMenu.includes("/addUpdateBusinessHoursConfig")
                        }
                        title="Business Hours Config"
                      >
                        <ListItemIcon sx={theme.customStyle.listItemIcon}>
                          <AccessTimeTwoToneIcon
                            iconSize={theme.customStyle.iconSize}
                            sx={
                              props.selectedMenu === "/businessHoursConfig" ||
                              props.selectedMenu.includes("/addUpdateBusinessHoursConfig")
                                ? theme.customStyle
                                    .activeDrawerMenuDrawerClosed
                                :  theme.customStyle
                                    .inActiveDrawerMenuDrawerClosed
                            }
                          />
                        <Box sx={{ ...theme.customStyle.listItemLabelDiv, left: "-10px" }}>
                            <span style={{ fontSize: "10px" }}>Bus.Hours</span>
                          </Box>
                        </ListItemIcon>
                        
                      </ListItemButton>
                    </NavLink>
                   <NavLink to="/holidays" style={styles.navLink}>
                      <ListItemButton
                      onClick={()=>{ setSelectedConvNone();}}
                        sx={
                          theme.customStyle.listItemIconDrawerClosedNew
                        }
                        selected={
                          props.selectedMenu === "/holidays" ||
                          props.selectedMenu.includes("/addUpdateHolidays")
                        }
                        title="Holidays"
                      >
                        <ListItemIcon sx={theme.customStyle.listItemIcon}>
                          <HolidayVillageTwoToneIcon
                            iconSize={theme.customStyle.iconSize}
                            sx={
                              props.selectedMenu === "/holidays" ||
                              props.selectedMenu.includes("/addUpdateHolidays")
                                ?  theme.customStyle
                                    .activeDrawerMenuDrawerClosed
                                :  theme.customStyle
                                    .inActiveDrawerMenuDrawerClosed
                            }
                          />
                         <Box sx={{ ...theme.customStyle.listItemLabelDiv, left: "-6px" }}>
                            <span style={{ fontSize: "10px" }}>Holidays</span>
                          </Box>
                        </ListItemIcon>
                      </ListItemButton>
                    </NavLink></>}
                    <NavLink to="/phoneNumbers" style={styles.navLink}>
                      <ListItemButton
                      onClick={()=>{ setSelectedConvNone();}}
                        sx={
                          theme.customStyle.listItemIconDrawerClosedNew
                        }
                        selected={
                          props.selectedMenu === "/phoneNumbers" ||
                          props.selectedMenu.includes("/addUpdatePhoneNumber")
                        }
                        title="phoneNumbers"
                      >
                        <ListItemIcon sx={theme.customStyle.listItemIcon}>
                          <ContactPhoneTwoToneIcon
                            iconSize={theme.customStyle.iconSize}
                            sx={
                              props.selectedMenu === "/phoneNumbers" ||
                              props.selectedMenu.includes("/addUpdatePhoneNumber")
                                ?  theme.customStyle
                                    .activeDrawerMenuDrawerClosed
                                :  theme.customStyle
                                    .inActiveDrawerMenuDrawerClosed
                            }
                          />
                           <Box sx={{ ...theme.customStyle.listItemLabelDiv, left: "-9px" }}>
                            <span style={{ fontSize: "10px" }}>WhiteList</span>
                          </Box>
                        </ListItemIcon>
                      </ListItemButton>
                                      </NavLink>

                                      {userInfo.role === "Admin" && (
                                          <NavLink
                                              to={"/tcpa"}
                                              style={styles.navLink}
                                          >
                                              <ListItemButton
                                              onClick={()=>{ setSelectedConvNone();}}
                                                  sx={
                                                      theme.customStyle.listItemIconDrawerClosedNew
                                                  }
                                                  selected={
                                                      props.selectedMenu === "/tcpa"
                                                  }
                                                  title="TCPA"
                                              >
                                                  <ListItemIcon sx={theme.customStyle.listItemIcon}>
                                                      <DateRangeTwoToneIcon
                                                          iconSize={theme.customStyle.iconSize}
                                                          sx={
                                                              props.selectedMenu === "/tcpa" 
                                                                  ? theme.customStyle
                                                                      .activeDrawerMenuDrawerClosed
                                                                  : theme.customStyle
                                                                      .inActiveDrawerMenuDrawerClosed
                                                          }
                                                      />
                                                      <Box sx={{ ...theme.customStyle.listItemLabelDiv}}>
                                                          <span style={{ fontSize: "10px" }}>TCPA</span>
                                                      </Box>
                                                  </ListItemIcon>

                                              </ListItemButton>
                                          </NavLink>
                                      )}

                                      {userInfo.role === "Admin" && (
                                          <NavLink
                                              to={"/applicationSettings"}
                                              style={styles.navLink}
                                          >
                                              <ListItemButton
                                              onClick={()=>{ setSelectedConvNone();}}
                                                  sx={
                                                      theme.customStyle.listItemIconDrawerClosedNew
                                                  }
                                                  selected={
                                                      props.selectedMenu === "/applicationSettings" ||
                                                      props.selectedMenu.includes("/addUpdateSettings")
                                                  }
                                                  title="Application Settings"
                                              >
                                                  <ListItemIcon sx={theme.customStyle.listItemIcon}>
                                                      <AppSettingsAltTwoToneIcon
                                                          iconSize={theme.customStyle.iconSize}
                                                          sx={
                                                              props.selectedMenu === "/applicationSettings" ||
                                                                  props.selectedMenu.includes("/addUpdateSettings")
                                                                  ? theme.customStyle
                                                                      .activeDrawerMenuDrawerClosed
                                                                  : theme.customStyle
                                                                      .inActiveDrawerMenuDrawerClosed
                                                          }
                                                      />
                                                      <Box sx={{ ...theme.customStyle.listItemLabelDiv, left: "-5px" }}>
                                                          <span style={{ fontSize: "10px" }}>Settings</span>
                                                      </Box>
                                                  </ListItemIcon>

                                              </ListItemButton>
                                          </NavLink>
                                      )}
                                      {userInfo.role === "Admin" && (
                                          <NavLink
                                              to={"/chatConfigs"}
                                              style={styles.navLink}
                                          >
                                              <ListItemButton
                                              onClick={()=>{ setSelectedConvNone();}}
                                                  sx={
                                                      theme.customStyle.listItemIconDrawerClosedNew
                                                  }
                                                  selected={
                                                      props.selectedMenu === "/chatConfigs" ||
                                                      props.selectedMenu.includes("/addUpdatechatConfig")
                                                  }
                                                  title="Chat"
                                              >
                                                  <ListItemIcon sx={theme.customStyle.listItemIcon}>
                                                      <DisplaySettingsIcon
                                                          iconSize={theme.customStyle.iconSize}
                                                          sx={
                                                              props.selectedMenu === "/chatConfigs" ||
                                                                  props.selectedMenu.includes("/addUpdatechatConfig")
                                                                  ? theme.customStyle
                                                                      .activeDrawerMenuDrawerClosed
                                                                  : theme.customStyle
                                                                      .inActiveDrawerMenuDrawerClosed
                                                          }
                                                      />
                                                      <Box sx={{ ...theme.customStyle.listItemLabelDiv, left: "-5px" }}>
                                                          <span style={{ fontSize: "10px" }}>Chat Config</span>
                                                      </Box>
                                                  </ListItemIcon>

                                              </ListItemButton>
                                          </NavLink>
                                      )}

                  </StyledList>
                </Box>
              </Collapse>
            </Box>
          )
        ) : (
          <></>
        )}
      </Box>
    </>
  );
}
const styles = {
  navLink: {
    textDecoration: "none",
    color: "#212121",
  },
};
