/**************************
 ** 12-27-2022 => SUDAM => Added loader for InActive Conv Count Api Call
 */
import React, { useContext, useEffect } from "react";
import { Avatar, Paper, Grid, Tooltip, Badge} from "@mui/material";
import SmsIcon from "@mui/icons-material/Sms";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import { AuthContext } from "../../AuthContext";
import apiConfig from "../../api/apiConfig";
import { useTheme } from "@mui/material/styles";
import { InvokePostServiceCall } from "../../api/serviceUtil";
import { useDispatch, useSelector } from "react-redux";
import {
    notificationSelector
} from "../../storage/slices/notificationSlice";
import {
    conversationsSelector,
} from "../../storage/slices/messageSlice";
import { mapConversation } from "../../extensions/Util";
import DocuSignMessage from "../docuSign/DocuSignMessage";



const DashboardCards = (props) => {
    const { setNotificationFilter, notiFicationFilter, setOpenDrawer, setHeaderNotification, setupCollapse, setSetupCollapse, setCollapseInactive, crmEmbededView, setOpenConversationDrawer, setSelectedMenu } = useContext(AuthContext);
    const theme = useTheme();

    console.log("props.filterName", props.filterName)
    return (

        <Grid item xs={2} sm={4} md={4}>
            <Tooltip title={props.title}>
                <Paper
                    elevation={4}
                    style={{ borderRadius: "20px" }}
                    onClick={(event) => {

                        if (crmEmbededView == 'false' || !crmEmbededView) {

                            setOpenDrawer(true);
                            if (props.filterName === "Missed") {
                                if (props.conversationCount > 0) {
                                    setHeaderNotification(true);
                                    setNotificationFilter(!notiFicationFilter);
                                    setSelectedMenu("unassigned")
                                    setOpenConversationDrawer(true);
                                    setSetupCollapse(false)
                                }
                            } else if (props.filterName === "Active") {
                                if (props.conversationCount > 0) {
                                    setHeaderNotification(false);
                                    setCollapseInactive(false);
                                    setNotificationFilter(!notiFicationFilter);
                                    setSelectedMenu("active")
                                    setOpenConversationDrawer(true);
                                    setSetupCollapse(false)
                                }
                            }
                            else if (props.filterName === "Inactive") {
                                props.getInActiveConversations();
                                if (props.conversationCount > 0) {
                                    setCollapseInactive(true);
                                    setHeaderNotification(false);
                                    setNotificationFilter(!notiFicationFilter);
                                    setSelectedMenu("inactive")
                                    setOpenConversationDrawer(true);
                                    setSetupCollapse(false)
                                }
                            }
                        }
                    }}
                >
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div
                            style={theme.customStyle.dashboardCard}
                        >
                            <div className={props.filterName == "Inactive" && "orangeColor"}>
                                <Badge
                                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    showZero
                                    overlap="circular"
                                    sx={{ "& .MuiBadge-badge": { fontSize: 15, height: 25, minWidth: 25, backgroundColor: props.filterName === "Inactive" && "orange" }, }}
                                    badgeContent={props.conversationCount}
                                    color={
                                        props.filterName === "Active" ? "primary" :
                                            props.filterName === "Missed" ? "error" : "warning"
                                    }
                                >
                                    <Avatar sx={theme.customStyle.dashboardAvatar}>
                                        {props.iconData}
                                    </Avatar>
                                </Badge>
                            </div>
                            <div style={theme.customStyle.dashboardTextCenter}>
                                <span style={theme.customStyle.dashboardContent}>
                                    {props.filterName} Conversations
                                </span>
                            </div>
                        </div>
                        {/*<div style={theme.customStyle.dashboardConvCountDiv}>*/}
                        {/*    <span style={theme.customStyle.dashboardConvCount}>*/}
                        {/*        {props.conversationCount}*/}
                        {/*    </span>*/}
                        {/*</div>*/}
                    </div>
                </Paper>
            </Tooltip>
        </Grid >

    );
};

function HomePage(props) {
    const { conversations } = useSelector(conversationsSelector);
    const { unAssignedNotifList } = useSelector(notificationSelector);
    const { userInfo, inactiveConvCount, setInactiveConvCount, setInactiveConversations, showDocuSignMessage, setShowDocuSignMessage, crmEmbededView, setConvLoading, setOpenConversationDrawer, setSelectedMenu } = useContext(AuthContext);
    const userId = userInfo.userId;

    useEffect(() => {
        if (userInfo != null && userInfo.userId != null && userInfo.role != null) {

            getDashboardCounts();
        }
    }, [userInfo]);

    const getDashboardCounts = () => {
        if (userInfo != null && userInfo.userId != null && userInfo.role != null) {
            InvokePostServiceCall(apiConfig.GET_CONVERSATIONS_DASHBOARD_SUMMARY, {
                userId: userId,
                role: userInfo.role,
            })
                .then((data) => {
                    console.log(data, "data");
                    setInactiveConvCount(data.data.dashboardCounts.inactiveConversationsCount)
                    if (data.data.dashboardCounts.inactiveConversationsCount === 0)
                        setInactiveConversations([])

                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    const getInActiveConversations = () => {
        setConvLoading(true); // set loading true before calling the api
        let requestBody = {
            teamId: userInfo.teamId,
        };
        InvokePostServiceCall(apiConfig.GET_INACTIVE_CONVERSATIONS, requestBody)
            .then((data) => {
                setConvLoading(false);
                console.log(data, "InActiveConversationCounts");
                let inactiveConversationsArr = [];
                for (var i = 0; i < data?.data?.conversations?.length; i++) {
                    let obj = mapConversation(data?.data?.conversations[i], data?.data?.conversations[i].teamPhoneNumber);
                    inactiveConversationsArr.push(obj);
                }
                setInactiveConversations(inactiveConversationsArr)
                setInactiveConvCount(inactiveConversationsArr.length);
                if (inactiveConversationsArr.length === 0 ) {
                    setSelectedMenu("");
                }
            })
            .catch((error) => {
                setConvLoading(false);
                console.log(error);
            });
    }

    return (
        <>
            {!showDocuSignMessage ? <div style={{ margin: "15px" }} >
                {!userInfo.teams || userInfo.teams.length == 0 || crmEmbededView ?
                    (
                        <>
                            {crmEmbededView ? <h5 style={{ justifyContent: "center", display: "flex", padding: "10px", color: "rgb(0, 20, 51)" }}>Welcome To Enterprise Texting</h5> : <h7 style={{ justifyContent: "center", display: "flex", padding: "10px", color: "rgb(0, 20, 51)" }}>Welcome To Enterprise Texting</h7>}
                        </>
                    )
                    :
                    <Grid
                        container
                        spacing={{ xs: 2, md: 3 }}
                        columns={{ xs: 2, sm: 8, md: 12 }}

                    >
                        <DashboardCards
                            filterName={"Active"}
                            conversationCount={conversations.length}
                            iconData={<SmsIcon />}
                            title={conversations.length + " Active conversions"}
                        />
                        {userInfo.role === "Supervisor" || userInfo.role === "Admin" ? (
                            <DashboardCards
                                filterName={"Missed"}
                                conversationCount={unAssignedNotifList.length}
                                title={unAssignedNotifList.length + " Missed conversions"}
                                iconData={<AssignmentIndIcon
                                />}
                            />
                        ) : null}
                        {userInfo.role === "Supervisor" || userInfo.role === "Admin" ? (
                            <DashboardCards
                                filterName={"Inactive"}
                                conversationCount={inactiveConvCount}
                                title={inactiveConvCount + " Inactive conversions"}
                                getInActiveConversations={getInActiveConversations}
                                iconData={<PendingOutlinedIcon
                                />}
                            />
                        ) : null}
                    </Grid>
                }
            </div> :
                <>
                    <DocuSignMessage />
                </>}
        </>
    );
}
export default HomePage;