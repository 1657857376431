/*
* 25/11/2022 => yogesh  Texting-861=> added new state if the user update the template now it will redirect to first pageafter update
  12-06-2022 => ONKAR => Table Search - Templates table.
  12-13-2022 => ONKAR => Texting-846 Table Search - Templates table reopen issue fixed.
  1/9/2023   => Mohit => TEXTING-963 - closed reopen issue
  05/05/2023 => ONKAR => TEXTING-1237 - Templates list table search is not working department fields data.
  07/31/2023 => PRABHAT => TEXTING-1424 - Modified by name and date is not updating on Admin UI Templates.
* 08-01-2023 => SUDAM B CHAVAN => TEXTING-1522 - templates across teams observations.
* 08-03-2023 => SUDAM B CHAVAN => TEXTING-1522 - templates across teams observations.
* 08-29-2023 => ONKAR => TEXTING-1578 -REACT Upgrade 
* 08-30-2023 => ONKAR => TEXTING-1578 -REACT Upgrade
* 08-31-2023 => PRABHAT => TEXTING-1632 - Implementation changes in object history for templates
*/
import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import apiConfig from "../../api/apiConfig";
import { DataGrid } from "@mui/x-data-grid";
import SearchIcon from '@mui/icons-material/Search';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { AuthContext } from "../../AuthContext";
import { useTheme } from "@mui/material/styles";
import { InvokePostServiceCall } from "../../api/serviceUtil";
import moment from "moment";
import SnackBar from "../../components/SnackBar";
import { Checkbox, IconButton, TextField, InputAdornment } from "@mui/material";
import AddUpdateTemplateComp from "./AddUpdateTemplateComp";
import ObjectHistoryComp from "./ObjectHistoryComp";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import HistoryIcon from '@mui/icons-material/History';
import { StyledDataGrid } from "../../components/styledComponents/StyledDataGrid";

function TemplatesComp(props) {
    const theme = useTheme();
    const { messageApiToken } = useContext(AuthContext);
    const [templateDetails, setTemplateDetails] = useState([]);
    const [templatesArr, setTemplatesArr] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [selectedTemplateDetails, setSelectedTemplateDetails] = useState();
    const [isAddTemplate, setIsAddTemplate] = useState(false);
    const [isTemplateHistory, setIsTemplateHistory] = useState(false);
    const [openTemplateHistory, setOpenTemplateHistory] = React.useState(false);
    const [refresh, setRefresh] = useState(true);
    const [page, setPage] = useState(0)
    const [searchText, setSearchText] = useState([]);
    const handleClose = () => {
        setOpen(false);
        setOpenTemplateHistory(false);
        setRefresh(true);
    };

    useEffect(() => {
        if (messageApiToken && refresh) {
            setTemplateDetails([]);
            setLoading(true);
            var requestBody = { teamId: props.teamId };
            InvokePostServiceCall(apiConfig.GET_TEMPLATES, requestBody)
                .then((data) => {
                    let templatesArr = [];
                    console.log(data.data, "templaytes data");
                    if (data.data.templates.length > 0) {
                        data.data.templates.forEach((item, index) => {
                            //handling teams specific template at server side
                            // if (item.teamId === props.teamId) {
                                templatesArr.push({
                                    azureAd: item.azureAd,
                                    azureAdid: item.azureAdid,
                                    createdBy: item.createdBy,
                                    createdByName: item.createdByName,
                                    createdByNavigation: item.createdByNavigation,
                                    createdDate: item.createdDate,
                                    createdDateFormat: moment(item.createdDate)
                                        .local()
                                        .format("MM-DD-YYYY"),
                                    isActive: item.isActive,
                                    lastModifiedBy: item.lastModifiedBy,
                                    lastModifiedByName: item.lastModifiedByName,
                                    lastModifiedByNavigation: item.lastModifiedByNavigation,
                                    lastModifiedDate: item.lastModifiedDate,
                                    lastModifiedDateFormat: moment(item.lastModifiedDate)
                                        .local()
                                        .format("MM-DD-YYYY"),
                                    team: item.team,
                                    teamId: item.teamId,
                                    templateBody: item.templateBody,
                                    templateContext: item.templateContext,
                                    templateId: item.templateId,
                                    templateName: item.templateName,
                                    templateType: item.templateType,
                                    category: item.category,
                                    department: item.department === "" ? "None" : item.department,
                                    user: item.user,
                                    userId: item.userId,
                                    id: index,
                                });
                            // }
                        });
                    }
                    setTemplateDetails(templatesArr);
                    setTemplatesArr(templatesArr)
                    setRefresh(false);
                    setLoading(false);
                })
                .catch((err) => {
                    console.log(err, "errorData");
                    setSnackbarMsg(err.message);
                    setOpenSnackbar(true);
                    setLoading(false);
                });
        }
    }, [messageApiToken, refresh]);
    const columns = [
        {
            field: "templateName",
            headerName: "Template Name",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "templateBody",
            headerName: "Template Content",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "department",
            headerName: "Department",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "category",
            headerName: "Category",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "createdDateFormat",
            headerName: "Created Date",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "lastModifiedDateFormat",
            headerName: "Modified Date",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "isActive",
            headerName: "Is Active",
            flex: 1,
            minWidth: 50,
            filterable: false,

            headerClassName: "super-app-theme--header",
            renderCell: (params) => {
                return (
                    <div style={{ marginLeft: "5px" }}>
                        <Checkbox disabled checked={params.row.isActive} />
                    </div>
                );
            }
        }
,           {
               field: "history",
               headerName: "-",
               flex: 1,
               minWidth: 50,
               filterable: false,
    
               headerClassName: "super-app-theme--header",
               renderCell: (params) => {
                   return (
                       <div style={{ marginLeft: "5px" }}>
                           <IconButton
                        //sx={theme.customStyle.HistoryIconBtn}
                       onClick={(e) => {
                           setSelectedTemplateDetails(params.row);
                           setIsTemplateHistory(true);
                           setOpenTemplateHistory(true);
                       }}
                   >
                       <HistoryIcon />
                   </IconButton>
                       </div>
                   );
               }
        }
    ];
    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    function filterTable(value) {
        let tempArr = [];
        let val = value.toLowerCase();
        templatesArr.forEach((item) => {
            if (
                item.templateName.toLowerCase().includes(val) ||
                item.templateBody.toLowerCase().includes(val) ||
                item.templateContext.toLowerCase().includes(val) ||
                item.templateType.toLowerCase().includes(val) ||
                item.category.toLowerCase().includes(val) ||
                item.createdDateFormat.toLowerCase().includes(val) ||
                item.lastModifiedDateFormat.toLowerCase().includes(val)||
                item.department.toLowerCase().includes(val)

            ) {
                tempArr.push(item);
            }
        });
        setTemplateDetails(tempArr);
    }
    return (
        <>
            <SnackBar
                openSnackbar={openSnackbar}
                handleCloseSnackbar={handleCloseSnackbar}
                severity={"error"}
                userMessage={snackbarMsg}
            />
            <div style={{ display: "flex", alignItems: "flex-end", paddingLeft: 10 }}>
                <Box sx={theme.customStyle.tableRow}>
                    {/* Commented Add template for teams page */}
                    {/* <IconButton
                        sx={theme.customStyle.teamsAddIconBtn}
                        onClick={() => {
                            setIsAddTemplate(true);
                            setOpen(true);
                            
                        }}
                    >
                        <AddIcon />
                    </IconButton> */}
                <Box sx={{ display: "flex", justifyContent: "flex-end", width:"100%"}}>

                
                    <TextField
                        sx={{ marginRight: "10px", marginBottom: "10px",paddingdown: 5 }}
                        size="small"
                        type="search"
                        variant="outlined"
                        margin="normal"
                        id="outlined-search"
                        label=""
                        placeholder="Templates search"
                        autoComplete="off"
                        value={searchText}
                        onChange={(e) => {
                            setSearchText(e.target.value)

                            if (e.target.value) {
                                filterTable(e.target.value);
                            } else {
                                setTemplateDetails(templatesArr);
                            }
                        }}
                        InputLabelProps={{
                            shrink: false,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            )
                        }}
                    />
                    </Box>
                </Box>
            </div>
            <Box sx={theme.customStyle.tableHead}>
                <StyledDataGrid
                    loading={loading}
                    rows={templateDetails}
                    columns={columns}
                    pageSize={10}
                    page={page}
                    disableSelectionOnClick
                    components={{
                        NoRowsOverlay: CustomNoRowsOverlay,
                    }}
                    onPageChange={(page) => setPage(page)}
                    rowsPerPageOptions={[10]}
                    onRowDoubleClick={(e) => {
                        setSelectedTemplateDetails(e.row);
                        setIsAddTemplate(false);
                        setOpen(true);
                    }}
                    columnVisibilityModel={{ history: false }} //hide the columns from the grid
                initialState={{
                 sorting: {
                   sortModel: [{ field: "lastModifiedDateFormat", sort: "desc" }],
                  },
                 }}
                />
            </Box>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"lg"}>
                <DialogContent>
                    <AddUpdateTemplateComp
                        open={open}
                        handleClose={handleClose}
                        templateDetails={selectedTemplateDetails}
                        isAddTemplate={isAddTemplate}
                        templatesArr={templatesArr}
                        setTemplatesArr={setTemplatesArr}
                        setTemplateDetails={setTemplateDetails}
                        teamName={props.teamName}
                        setSearchText={setSearchText}
                        teamId={props.teamId}
                        refresh={refresh}
                        setRefresh={setRefresh}
                    />
                </DialogContent>
            </Dialog>
            <Dialog open={openTemplateHistory} onClose={handleClose} fullWidth maxWidth={"lg"}>
                <DialogContent>
                    <ObjectHistoryComp
                        open={open}
                        handleClose={handleClose}
                        setSearchText={setSearchText}
                        objectName="Template"
                        objectKeyname={selectedTemplateDetails}
                        numOfRows = {10}
                        refresh={refresh}
                        setRefresh={setRefresh}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
}

export default TemplatesComp;