/***************
* 07-28-2023    SUDAM B CHAVAN     TEXTING-1450 Templates Setup Redesign: Reuse Templates across teams / user
* 08-29-2023 => ONKAR => TEXTING-1578 -REACT Upgrade
* 08-30-2023 => ONKAR => TEXTING-1578 -REACT Upgrade
* 12-06-2023 => ONKAR => TEXTING-1867 -0: Selected Team or User in add Team/users tab is removing if filter the table data.
***********/
import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    InputAdornment,
    Checkbox
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTheme } from "@mui/material/styles";
import SearchIcon from '@mui/icons-material/Search';
import { TextField } from "@mui/material";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import { StyledDataGrid } from "../../components/styledComponents/StyledDataGrid";
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid';


function AddTeamsToTemplateDialog(props) {
    const theme = useTheme();
    const [teamArr, setTeamArr] = useState([]);
    const [selectedTeams, setSelectedTeams] = useState([]);
    const [searchText, setSearchText] = useState([]);

    useEffect(() => {
        //this useEffect will execute on 1st time on component render and when props.rows are chenged
        setTeamArr(props.rows);
        setSearchText('');
        setSelectedTeams([]);
    }, [props.rows]);

    function filterTable(value) {
        let val = value?.toLowerCase();
        let tempArr = [];
        props.rows.forEach((item) => {
            if (
                item.teamName?.toLowerCase().includes(val) ||
                item.type?.toLowerCase().includes(val) ||
                item.msgSupportNumber?.toLowerCase().includes(val)
            ) {
                tempArr.push(item);
            }
        });
        console.log("filterTeams ::",tempArr)
        setTeamArr(tempArr);
    }

    const columns = [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        field: "checkBox",
        renderHeader: (params) => (
          <Checkbox
            sx={{ marginLeft: "-5px" }}
            onClick={() => {
              let selectedTeamsArr = [...selectedTeams];
              if (props.rows.length === selectedTeamsArr.length) {
                selectedTeamsArr = [];
              } else {
                selectedTeamsArr = [];
                props.rows.forEach((item) => {
                  selectedTeamsArr.push(item.teamId);
                });
              }
              setSelectedTeams([...selectedTeamsArr]);
              props.setSelectionModel([...selectedTeamsArr]);
            }}
            checked={selectedTeams.length === props.rows.length}
          />
        ),
        renderCell: (params) => {
          return (
            <Checkbox
              onClick={() => {
                let isPresent = selectedTeams.some(
                  (item) => item === params.row.teamId
                );
                let selectedTeamsArr = [...selectedTeams];
                if (!isPresent) {
                  selectedTeamsArr.push(params.row.teamId);
                  setSelectedTeams([...selectedTeamsArr]);
                  props.setSelectionModel([...selectedTeamsArr]);
                } else {
                  let index = selectedTeams.indexOf(params.row.teamId);
                  if (index !== -1) {
                    selectedTeamsArr.splice(index, 1);
                    setSelectedTeams([...selectedTeamsArr]);
                    props.setSelectionModel([...selectedTeamsArr]);
                  }
                }
              }}
              checked={selectedTeams.includes(params.row.teamId)}
            />
          );
        },
        headerClassName: "super-app-theme--header",
        sortable: false,
        filterable: false,
      },

      {
        field: "teamName",
        headerName: "Team Name",
        flex: 1,
        minWidth: 100,
        headerClassName: "super-app-theme--header",
      },
      {
        field: "type",
        headerName: "Category",
        flex: 1,
        minWidth: 100,
        headerClassName: "super-app-theme--header",
      },
      {
        field: "msgSupportNumber",
        headerName: "SMS Number",
        flex: 1,
        minWidth: 100,
        headerClassName: "super-app-theme--header",
      },
    ];


    return (
        <>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                fullWidth
                maxWidth={"md"}
            >
                <div>
                    <Box sx={theme.customStyle.tableRow}>
                        <DialogTitle>{props.dialogTitle}</DialogTitle>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <TextField
                            sx={{ marginRight: "25px", marginTop: "10px" }}
                            size="small"
                            type="search"
                            variant="outlined"
                            margin="normal"
                            id="outlined-search"
                            label=""
                            value={searchText}
                            placeholder="Teams Search"
                            autoComplete="off"
                            onChange={(e) => {
                                setSearchText(e.target.value)
                                if (e.target.value) {
                                    filterTable(e.target.value);
                                }
                                else {
                                    setTeamArr(props.rows);
                                }

                            }}
                            InputLabelProps={{
                                shrink: false,
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Box>
                </div>
                <DialogContent sx={{ paddingTop: "0px" }}>
                    <Box sx={theme.customStyle.teamsTableHead}>
                        <StyledDataGrid
                            loading={props.loading}
                            rows={teamArr}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            disableSelectionOnClick
                            components={{
                                NoRowsOverlay: CustomNoRowsOverlay,
                            }}
                        />
                    </Box>

                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        disabled={!(selectedTeams && selectedTeams.length > 0)}
                        size="small"
                        loading={props.btnLoading}
                        onClick={() => {
                            props.SaveTeamAssociations(props.btnText);
                        }}
                    >
                        {props.btnText}
                    </LoadingButton>
                    <Button size="small" onClick={props.handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default AddTeamsToTemplateDialog;
