////07-04-2023 => ONKAR => TEXTING-1292 - Message delivered, Read status ticks should show in SMS history and SMS activity pages.
////08-30-2023 => SUDAM B CHAVAN => TEXTING-1644 - Authenticated Conversations history issue
////09-15-2023 => ONKAR =>TEXTING-1697 - Client Req: Ability to view the chat history of the member without initiating the conversation
////09-28-2023 => ONKAR =>TEXTING-1699 -Conversation History Enhancements
////10-03-2023 => ONKAR =>TEXTING-1766 -The current active conversation messages is showing in History tab with old view.
////10-05-2023 => ONKAR =>TEXTING-1786 -If 'Sentiment Calculation' is disabled then also History records is showing Sentiment emoji.
////10-19-2023 => ONKAR =>TEXTING-1815 -Number 0 is showing on omni channel, History tab instead of Neutral emoji If overall sentiment of conversation is '0'.
////11-01-2023 => ONKAR =>TEXTING-1819 -On history tab expanding the multiple records with more messages is moving the scroll bar to bottom.
import React, { useEffect, useState, useContext } from "react";
import { Grid, CircularProgress, Button, Divider, Paper, Tooltip } from "@mui/material";
import { InvokePostServiceCall } from "../api/serviceUtil";
import apiConfig from "../api/apiConfig";
import { AuthContext } from "../AuthContext";
import ChatMsg from "./ChatMsg";
import moment from "moment";
import SnackBar from "./SnackBar";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import ChatIcon from "@mui/icons-material/Chat";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useTheme } from "@mui/material/styles";
import SentimentEmoji from "./SentimentEmoji";

function ChatHistory(props) {
  const theme = useTheme();
  const {enableSentimentCalculation,applicationSettings}=useContext(AuthContext)
  const [conversationList, setConversationList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chatLoading, setChatLoading] = useState(false);
  const [msgList, setMsgList] = useState([]);
  const [selectedConv, setSelectedConv] = useState(null);
  const [customerName, setCustomerName] = useState("");
  const [channelName, setChannelName] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [endedByName, setEndedByName] = useState("");
  const [endedByDate, setEndedByDate] = useState("");
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [collapseConv, setCollapseConv] = React.useState(false);
  const [collapseSummary, setCollapseSummary] = useState(false);
  const [conversationSummary,setConversationSummary]=useState("")
  const includeTypeOfMessageToReq = [
    "INBOUND",
    "OUTBOUND",
    "INFO",
    "BOTMSG"
]
//get the summary prompt message from app settings
let summaryPromptMessage=applicationSettings.GPTChatBotConfiguration?JSON.parse(applicationSettings.GPTChatBotConfiguration):"";
  useEffect(() => {
    setLoading(true);
    let requestBody = props.conversation
      ? {
          filterMode: "PhoneNumber",
          phoneNum: props.conversation,
        }
      : props.externalId
      ? {
          filterMode: "externalId",
          externalId: props.externalId,
        }
      : {
          filterMode: "email",
          email: props.email,
        };
    InvokePostServiceCall(apiConfig.GET_CONVERSATION_BY_FILTER, requestBody)
      .then((data) => {
        setConversationList(data.data.conversations);
        setLoading(false);
      })
      .catch((error) => {
        setSnackbarMsg(error.message);
        setOpenSnackbar(true);
        setLoading(false);
      });
  }, []);

  const getSummary=(messageList)=>{
    let trns = "";
    messageList.forEach((msg) => {
        msg.messages.forEach((m) => {
            if (includeTypeOfMessageToReq.indexOf(m.messageType) >= 0) {
                let role = msg.fromObjectType === 'User' ? 'Wescom/Agent' : 'Customer';               
                trns = trns + moment(m.messageDate).format("lll") + " " + role + " : " + m.messageBody + "\n";
            }
        })
    });
    var request = {
        chatHistoryWithGPT: [{role:"user",content:summaryPromptMessage?.SummaryPromptMessage}],
        isCallFrom: "summary",
        conversationTranscript: trns
    }
    InvokePostServiceCall(apiConfig.GET_TRANSCRIPT_SUMMARY_WITH_API, request )
            .then((data) => {
                setConversationSummary(data.data.gptResponse)
            })
            .catch((error) => {
                setSnackbarMsg(error.message);
                setOpenSnackbar(true);
                setChatLoading(false);
            })

  }

  const getMessages = (convId) => {
    setChatLoading(true);
    setConversationSummary("")
    var requestBody = { conversationId: convId ? convId : selectedConv };

    InvokePostServiceCall(apiConfig.GET_MESSAGES, requestBody)
      .then((data) => {
        let conversation = {};
        conversation.messageList = data.data.messagesInfo;
        conversation.refreshMessages = false;
        setMsgList(conversation);
        if(conversation?.messageList?.length>0){
            getSummary(conversation?.messageList)
        }
        setChatLoading(false);
      })
      .catch((err) => {
        setSnackbarMsg(err.message);
        setOpenSnackbar(true);
        setChatLoading(false);
      });
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };
  let divHeight = props.isStartWithTemplate
    ? "calc(100vh - 245px)"
    : "calc(100vh - 201px)";
  return (
    <>
      <SnackBar
        openSnackbar={openSnackbar}
        handleCloseSnackbar={handleCloseSnackbar}
        severity={"error"}
        userMessage={snackbarMsg}
      />
      <div
        className="customScroll"
        style={{
          height: props.isFromModal ? "calc(85vh - 194px)" : divHeight,
          overflow: "auto",
        }}
      >
        <div style={{ padding: props.isStartWithTemplate ? "0px" : "10px" }}>
          {loading ? (
            <div style={styles.progressStyle}>
              <CircularProgress />
            </div>
          ) : (
            <>
              {conversationList.length > 1 && !loading ? (
                <Grid
                  container
                  spacing={{ xs: 1, md: 1}}
                  columns={{ xs: 2, sm: 8, md: 12 }}
                  sx={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  {conversationList.map((item, index) => (
                    <>
                      {index < 20 && 
                      item.conversationId !== props.conversationId &&(
                        <Grid item xs={2} sm={4} md={4} key={index}>
                          <Paper
                            elevation={2}
                            style={{
                              padding: "10px",
                              borderRadius: 4,
                              border: "1px solid #ebebeb",
                            }}
                          >
                            <div
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                              id={item.conversationId}
                              onClick={() => {
                                debugger
                                // handleClickOpen(item.channel);
                                setCollapseSummary(false);
                                if (collapseConv === item.conversationId) {
                                  setCollapseConv("");
                                } else {
                                  setCollapseConv(item.conversationId);
                                  setCustomerName(item.customerName);
                                  getMessages(item.conversationId);
                                  setSelectedConv(item.conversationId);
                                  setEndedByName(item.conversationEndedByName);
                                  setEndedByDate(item.conversationEndedDate);
                                  setChannelName(item.channel);
                                  document.getElementById(item.conversationId).scrollIntoView()
                                }
                              }}
                            > 
                            <div style={{...theme.customStyle.flexAlignCenter,width:"50%"}}>
                                  {item?.channel === "Chat" ? (
                                    <ChatIcon color="primary"
                                      sx={{
                                        marginTop: "2px",
                                        marginRight:"5px"
                                      }}
                                    />
                                  ) : (
                                    <PhoneIphoneIcon color="primary" sx={{marginRight:"5px"}}/>
                                  )} 
                                  <div style={theme.customStyle.convHeaderEmailEllipsis}>
                                    <Tooltip title={item.customerPhoneNumber ? item.customerPhoneNumber :item.email}>
                                   <span>
                                    {item.customerPhoneNumber
                                    ? item.customerPhoneNumber
                                    : item.email}
                                    </span>
                                    </Tooltip>
                                 </div>
                                    {enableSentimentCalculation === "Y" && (item.overallSentiment || item.overallSentiment===0 ) && <SentimentEmoji overallSentiment={item.overallSentiment}/>}
                                
                                </div>
                                <div style={{...theme.customStyle.flexAlignCenter,width:"50%",justifyContent:"flex-end"}}>
                                <div style={theme.customStyle.convHeaderEmailEllipsis}>
                                    <Tooltip title={`${moment(item.createdDate).format("MM/DD/YY")} ${moment(item.createdDate)
                                    .local()
                                    .format("LT")}`}>
                                <span>
                                  {moment(item.createdDate).format("MM/DD/YY")}{" "}
                                  {moment(item.createdDate)
                                    .local()
                                    .format("LT")}
                                </span>
                                </Tooltip>
                                </div>
                                {collapseConv === item.conversationId?<KeyboardArrowUpIcon/>:<KeyboardArrowDownIcon/>}
                                </div>
                            </div>
                            <Collapse in={item.conversationId === collapseConv}>
                            <Divider sx={{marginTop:"10px"}} />
                              <>
                                
                                 {conversationSummary && <> <Button
                                  sx={{color:"#000000",width:"100%"}}
                                  onClick={() => {
                                    setCollapseSummary(!collapseSummary);
                                  }}>Summary {!collapseSummary?<KeyboardArrowDownIcon/>:<KeyboardArrowUpIcon/>}</Button>

                                <Collapse
                                  in={
                                    item.conversationId === collapseConv &&
                                    collapseSummary
                                  }
                                >
                                  {collapseSummary &&
                                    item.conversationId === collapseConv && (
                                    <div style={{marginBottom:"10px"}}>
                                       <strong> {conversationSummary}</strong>
                                    </div>
                                    )}
                                </Collapse>
                                <Divider sx={{marginBottom:"10px"}} /></>}
                                <div
                                  // style={{ maxHeight: "calc(100vh - 320px)" }}
                                >
                                  <div
                                    className="customScroll"
                                    style={{
                                      // maxHeight: "calc(100vh - 320px)",
                                      overflow: "auto",
                                    }}
                                  >
                                    {selectedConv !== null && chatLoading ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          marginTop: "80px",
                                          overflow:"hidden"
                                        }}
                                      >
                                        <CircularProgress />
                                      </div>
                                    ) : selectedConv !== null &&
                                      msgList &&
                                      msgList.messageList &&
                                      msgList.messageList.length > 0 ? (
                                      msgList.messageList.map((msg, index) => {
                                        let DateCmp = "";
                                        if (index !== 0) {
                                          if (
                                            moment(
                                              msgList.messageList[index]
                                                .messageDate
                                            ).format(" MMM Do YY") !==
                                            moment(
                                              msgList.messageList[index - 1]
                                                .messageDate
                                            ).format(" MMM Do YY")
                                          ) {
                                            DateCmp =
                                              moment(
                                                msgList.messageList[index]
                                                  .messageDate
                                              )
                                                .local()
                                                .format("Do MMMM YY") ===
                                              moment(new Date()).format(
                                                "Do MMMM YY"
                                              )
                                                ? "Today"
                                                : moment(
                                                    msgList.messageList[index]
                                                      .messageDate
                                                  )
                                                    .local()
                                                    .format("Do MMMM YY");
                                          } else {
                                            DateCmp = "";
                                          }
                                        }
                                        return (
                                          <>
                                            {index === 0 && (
                                              <div style={styles.dateStyle}>
                                                <hr style={styles.hrStyle} />
                                                {moment(
                                                  msgList.messageList[index]
                                                    .messageDate
                                                )
                                                  .local()
                                                  .format("Do MMMM YY") ===
                                                moment(new Date()).format(
                                                  "Do MMMM YY"
                                                )
                                                  ? "Today"
                                                  : moment(msg.messageDate)
                                                      .local()
                                                      .format("Do MMMM YY")}
                                                <hr style={styles.hrStyle} />
                                              </div>
                                            )}
                                            {DateCmp !== "" && (
                                              <div style={styles.dateStyle}>
                                                <hr style={styles.hrStyle} />
                                                {DateCmp}
                                                <hr style={styles.hrStyle} />
                                              </div>
                                            )}
                                            <ChatMsg
                                              avatar={""}
                                              side={`${
                                                msg.fromObjectType === "User"
                                                  ? "right"
                                                  : "left"
                                              }`}
                                              messages={msg.messages}
                                              customerName={customerName}
                                              agentNm={msg.fromIdName}
                                              endedByName={endedByName}
                                              endedByDate={endedByDate}
                                              isHistory={true}
                                              isReadOnly={true}
                                              getMessages={getMessages}
                                              channelName={channelName}
                                            />
                                          </>
                                        );
                                      })
                                    ) : (
                                      <div
                                        style={{
                                          textAlign: "center",
                                          marginTop: "80px",
                                        }}
                                      >
                                        No Messages
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </>
                            </Collapse>                        
                          </Paper>
                        </Grid>
                      )}
                    </>
                  ))}
                </Grid>
              ) : (
                <div style={{ textAlign: "center" }}>No SMS History Found</div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
const styles = {
  dateStyle: {
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    fontSize: 10,
  },
  progressStyle: {
    display: "flex",
    justifyContent: "center",
    marginTop: "30px",
  },
  hrStyle: {
    width: "40%",
  },
};
export default ChatHistory;